import React, { useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import {
  FormControlLabel,
  FormLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  changeValueHandler,
  changeValueHandler1,
  changeValueHandler2,
} from "../../../store/actions";
import { errorHandler } from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  buttonClass: {},
  rowDirection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  columDirection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  groupLabelClass: {
    fontWeight: 500,
    width: "75px",
  },
  formControlLabel: {
    "&:label": {},
  },
}));

interface RadioButtonsProps {
  buttons: string[];
  group_label: string;
  directionRow: boolean;
  name: string;
  reducer_name: string;
  values?: any;
}

const Checkboxes: React.FC<RadioButtonsProps> = ({
  buttons,
  group_label,
  directionRow,
  name,
  reducer_name,
  values,
}) => {
  const [checked, setChecked] = React.useState<String[]>([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  let handler: any;

  const getHandler = () => {
    switch (reducer_name) {
      case "inspectionChecklistFormReducer":
        handler = changeValueHandler;
        break;
      case "engineeringDetailsFormReducer":
        handler = changeValueHandler1;
        break;
      case "inspectionDetailsFormReducer":
        handler = changeValueHandler2;
        break;

      default:
        dispatch(errorHandler("Form failure please refresh the page and try again. " + reducer_name))
    }
  };

  getHandler();

  const onCheckBoxChange = (name: string, fieldName: string, event: any) => {
    try {
      const add = event.target.checked;
      if (add) {
        const newChecked = checked;
        const index = newChecked.includes(fieldName);
        if (!index) {
          newChecked.push(fieldName);
          dispatch(handler(name, newChecked));
          setChecked(newChecked);
        }
      } else {
        const newChecked = checked;
        const index = newChecked.indexOf(fieldName);
        if (index > -1) {
          newChecked.splice(index, 1);
          setChecked(newChecked);
          dispatch(handler(name, newChecked));
        }
      }
    } catch (e) {
      dispatch(errorHandler("Checkbox failed: " + e));
    }
  };

  const getChecked = (key: string) => {
    if (values) {
      if (values.includes(key)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (values) {
      setChecked(values);
    }
  }, [values]);


  return (
    <div
      className={directionRow ? classes.rowDirection : classes.columDirection}
    >
      <FormLabel className={classes.groupLabelClass} component="legend">
        <Typography
          component={"span"}
          variant={"body2"}
          className={classes.groupLabelClass}
        >
          {group_label}
        </Typography>
      </FormLabel>

      {buttons.map((btn) => {
        let isChecked = getChecked(btn);
        return (
          <FormControlLabel
            className={classes.formControlLabel}
            key={btn}
            control={
              <Checkbox
                color="primary"
                value={isChecked}
                checked={isChecked}
                onChange={(value) => onCheckBoxChange(name, btn, value)}
                name={btn}
              />
            }
            label={btn}
          />
        );
      })}
    </div>
  );
};

export default Checkboxes;
