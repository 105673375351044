import { useFormik } from "formik";
import * as yup from "yup";
import DateFnsUtils from "@date-io/date-fns";
import React, { useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useStyles } from "./taskStyles";
import RichEditor from "../RichEditor";
import {
  TaskTypeEnum,
  useCreateTaskMutation,
  useUpdateTaskMutation,
} from "../../generated/graphql";
import { useLazyQuery } from "@apollo/client";
import {
  QUERY_JOB_LIST,
  READ_CURRENT_ORGANISATION_USERS,
} from "../JobList/query";
import AlertMessage from "../AlertMessage/AlertMessage";
import { doYouNeedRefetch, errorHandler } from "../../store/actions";
import { useDispatch } from "react-redux";

enum ERegisteredFields {
  subject = "Subject",
  description = "Description",
  assignedTo = "Assigned to",
  jobNumber = "Job Number",
  jobState = "Job State",
  dueDate = "Due date",
}

interface ITaskActions {
  task?: any;
  jobId?: any;
}

const TaskActions: React.FC<ITaskActions> = ({ task, jobId }) => {
  const validationSchema = yup.object({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const [createTask, createTaskResponse] = useCreateTaskMutation();
  const [updateTask, updateTaskResponse] = useUpdateTaskMutation();
  const [availableUsers, setAvailableUsers] = useState([]);
  const [open1, setOpen1] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const [readCurrentOrgUsers, readCurrentOrgUsersResp] = useLazyQuery(
    READ_CURRENT_ORGANISATION_USERS
  );

  const [readJobs, readJobsResp] = useLazyQuery(QUERY_JOB_LIST);

  useEffect(() => {
    readCurrentOrgUsers();
    readJobs();
  }, []);

  useEffect(() => {
    if (readCurrentOrgUsersResp.data) {
      setAvailableUsers(
        readCurrentOrgUsersResp.data.readCurrentOrganisationUsers
      );
    }
  }, [readCurrentOrgUsersResp]);

  const formik = useFormik({
    initialValues: {
      subject: task ? task.subject : "",
      description: task ? task.description : "",
      assignedTo: task ? task.assignedTo.id : "",
      jobId: jobId ? jobId : task?.job?.id,
      jobNumber: task ? task.job.id : null,
      jobState: task ? task.jobState : "",
      dueDate: task ? task.dueDate : "2030-11-11",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (task) {
          await updateTask({
            variables: {
              task: {
                subject: values.subject,
                description: values.description,
                assignedToId: values.assignedTo,
                jobId: values.jobNumber,
                jobNumber: values.jobNumber,
                jobState: values.jobState,
                dueDate: values.dueDate,
              },
              id: task.id,
            },
          });
          dispatch(doYouNeedRefetch());
          setOpen2(true);
        } else {
          await createTask({
            variables: {
              task: {
                subject: values.subject,
                description: values.description,
                assignedToId: values.assignedTo,
                jobId: values.jobNumber,
                jobNumber: values.jobNumber,
                jobState: values.jobState,
                dueDate: values.dueDate,
              },
              taskType: TaskTypeEnum.Assign,
            },
          });
          dispatch(doYouNeedRefetch());
          setOpen1(true);
        }
      } catch (e) {
        dispatch(errorHandler("Assigning tasks failed " + e));
      }
    },
  });

  const onChangeDateHandler = (fieldName: string, value: any) => {
    let date = JSON.stringify(value);
    date = date.slice(1, 11);
    formik.setFieldValue(fieldName, date);
  };

  const inputProps = {
    style: {
      padding: 10,
    },
  };

  if (readJobsResp.data) {
    return (
      <div style={{ minWidth: "40vw" }}>
        <Typography variant="h6" component="h6" style={{ marginTop: "15px" }}>
          {task ? "Update Task" : "Create New Task"}
        </Typography>

        {/* subject */}
        <div className={classes.formRow}>
          <TextField
            style={{ minWidth: "200px" }}
            disabled={false}
            inputProps={inputProps}
            id="subject"
            name="subject"
            label={ERegisteredFields.subject}
            value={formik.values.subject}
            onChange={formik.handleChange}
            error={formik.touched.subject && Boolean(formik.errors.subject)}
            helperText={
              formik.touched.subject && Boolean(formik.errors.subject)
                ? "input error"
                : null
            }
          />
        </div>
        {/* assignedTo */}
        <InputLabel>{ERegisteredFields.assignedTo}</InputLabel>
        <Select
          className={classes.formRow}
          style={{ minWidth: "200px" }}
          id="assignedTo"
          name="assignedTo"
          label={ERegisteredFields.assignedTo}
          value={formik.values.assignedTo}
          onChange={formik.handleChange}
          error={formik.touched.assignedTo && Boolean(formik.errors.assignedTo)}
        >
          <MenuItem
            value={task?.assignedTo?.firstName ? task.assignedTo.firstName : ""}
          >
            <em>
              {task?.assignedTo?.firstName ? task?.assignedTo.firstName : ""}
            </em>
          </MenuItem>
          {availableUsers.map((user: any) => {
            return (
              <MenuItem
                value={user.id}
              >{`${user.firstName} ${user.lastName} | ${user.position}`}</MenuItem>
            );
          })}
        </Select>

        {/* jobNumber */}
        {readJobsResp.data && (
          <>
            <InputLabel>{ERegisteredFields.jobNumber}</InputLabel>
            <Select
              className={classes.formRow}
              style={{ minWidth: "200px" }}
              id="jobNumber"
              name="jobNumber"
              label={ERegisteredFields.jobNumber}
              value={formik.values.jobNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.jobNumber && Boolean(formik.errors.jobNumber)
              }
            >
              {task && (
                <MenuItem value={task.job.jobNumber}>
                  <em></em>
                </MenuItem>
              )}
              {readJobsResp.data.readJobs.map((job: any) => {
                return <MenuItem value={job.id}>{job.jobNumber}</MenuItem>;
              })}
            </Select>
          </>
        )}

        {/* description */}
        <div className={classes.formRow} style={{ maxWidth: "600px" }}>
          <Typography variant="body2" component="span">
            Description
          </Typography>
          <RichEditor
            value={formik.values.description}
            onChangeHandler={(e: any) =>
              formik.setFieldValue("description", e.target.value)
            }
          />
        </div>
        {/* dueDate */}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div>
            <KeyboardDatePicker
              margin="normal"
              id="dueDate"
              label={ERegisteredFields.dueDate}
              format="yyyy-MM-dd"
              value={formik.values.dueDate}
              onChange={(value) => onChangeDateHandler("dueDate", value)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
        {task ? (
          <div className={classes.formRow}>
            <Button
              disabled={createTaskResponse.loading}
              size="small"
              onClick={formik.submitForm}
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </div>
        ) : (
          <div className={classes.formRow}>
            <Button
              disabled={createTaskResponse.loading}
              size="small"
              onClick={formik.submitForm}
              variant="contained"
              color="primary"
            >
              Create
            </Button>
          </div>
        )}
        {open1 && (
          <AlertMessage
            alertWidth="500px"
            open={open1}
            setOpen={setOpen1}
            color="info"
            message="Task have been assigned successfully!"
          />
        )}
        {open2 && (
          <AlertMessage
            alertWidth="500px"
            open={open2}
            setOpen={setOpen2}
            color="info"
            message="Task have been updated successfully!"
          />
        )}
      </div>
    );
  } else {
    return <p>Loading...</p>;
  }
};

export default TaskActions;
