import { ActionTypes, Action } from "../actions";

interface StateType {
  [key: string]: any;
}
const initState: StateType = {
  components: [],
  basis_of_certification: [],
  reference_documentation: [],
  class_of_building_structure: "",
  construction_type: "",
  part_of_building: "",
  rise_in_storeys: "",
  effective_height: "",
  type_of_construction: ""
};

export const engineeringDetailsForm = (state = initState, action: Action) => {
  switch (action.type) {
    case ActionTypes.changeValueHandler1:
      if (action.payload) {
        const property_t: any = action.payload.key;
        const new_state = { ...state };
        new_state[property_t] = action.payload.value;

        return new_state;
      }
      return state;

    case ActionTypes.changeAllFormValues1:
      if (action.payload) {
        return action.payload;
      }
      return state;

    case ActionTypes.clearForm:
      return initState;

    case ActionTypes.selectAllEngineeringForm:
      if (action.payload) {
        const { components, basis_of_certification, reference_documentation } = action.payload;
        if (
          state.components.length === components.length &&
          state.basis_of_certification.length === basis_of_certification.length &&
          state.reference_documentation.length === reference_documentation.length
        ) {
          return {
            ...state,
            components: [],
            basis_of_certification: [],
            reference_documentation: [],
          };
        }
        const newState = {
          ...state,
          components,
          basis_of_certification,
          reference_documentation,
        };
        return newState;
      }
      return state;

    default:
      return state;
  }
};
