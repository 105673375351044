import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formContainer: {
    maxWidth: "850px",
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.success.main,
    borderRadius: "3px",
  },
  formRow: {
    marginBottom: "10px",
  },
  formMainHeading: {
    minHeight: "35px",
    paddingLeft: "5px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formSubHeading: {
    paddingLeft: "5px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    marginBottom: "1px",
    maxHeight: "25px",
    minHeight: "15px",
  },
  accordionContainer: {
    "&:expanded": {
      margin: "2px",
    },
  },
  AccordionDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  checkBoxesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    maxWidth: "100%",
  },
  checkBoxRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  checkBoxControl: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  checkBox: {
    color: theme.palette.primary.main,
  },
  divider2: {
    margin: "5px 0px",
    width: "100%",
  },
  commentFieldContainer: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "3px",
    padding: "6px",
    width: "100%",
    marginBottom: "15px",
  },
  substatus_row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  linearIndeterminate: {
    marginTop: "10px",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  generateButtonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
    marginBottom: "5px",
  },
  error_msg: {
    color: "red",
  },
  alertClass: {
    color: "#fff",
    backgroundColor: "green",
    margin: "7px",
  },
  selectorButton: {
    color: theme.palette.primary.main,
  },
  checkBoxesContainer1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    maxWidth: "75%",
  },
  helpIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },


}));
