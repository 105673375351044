import { ActionTypes, Action } from "../actions";

const initState = {
  user:null
};

export const user = (state = initState, action: Action) => {
  switch (action.type) {
    case ActionTypes.addCurrentUser:
      if (action.payload) {
        return {
          ...state,
          user:action.payload
        };
      }
      return state;
    default:
      return state;
  }
};
