import { ActionTypes, Action } from "../actions";

// ------------------------- //
//  INSPECTION DETAILS FORM  //
// ------------------------- //

interface StateType {
  [key: string]: any
}
const initState: StateType = {
  stage:null,
  building_description:null,
  reference_documentation:[],
  inspection_date: null
};

export interface SelectAllInspectionDetailsForm{
  type: ActionTypes.selectAllInspectionDetailsForm;
  payload:{
    reference_documentation: string[];
  }
}

export const inspectionDetailsForm = (state = initState, action: Action) => {
  switch (action.type) {
    case ActionTypes.changeValueHandler2:
      if (action.payload) {
        const property_t: any = action.payload.key
        const new_state = {...state}
        new_state[property_t] = action.payload.value

        return new_state
      }
      return state;

    case ActionTypes.changeAllFormValues2:
      if(action.payload){
        return action.payload          
      }
      return state;

    case ActionTypes.selectAllInspectionDetailsForm:
      if(action.payload){
        const {reference_documentation} = action.payload;
        if(reference_documentation.length === state.reference_documentation.length){
          return {
            ...state,
            reference_documentation:[]
          }
        }
        return {
          ...state,
            reference_documentation:[...reference_documentation]
        }          
      }
      return state;

    case ActionTypes.clearForm:
      return initState;
      
    default:
      return state;
  }
};