import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { READ_ORGANISATIONS } from '../../JobList/query';
import OrganisationList from './OrganisationList';


const Organisations = () => {
    const [organisations, setOrganisations] = useState([])

    const [readOrganisations, readOrganisationsResponse] = useLazyQuery(
        READ_ORGANISATIONS
      );
    
    useEffect(() => {
        readOrganisations()
    }, [])

    useEffect(()=>{
        // console.log(readOrganisationsResponse.data)
        if(readOrganisationsResponse.data){
            setOrganisations(readOrganisationsResponse.data.readOrganisations)
        }
    },[readOrganisationsResponse])

    return (
        <div>
           <OrganisationList title="All organisations" organisations={organisations}/>
        </div>
    )
}

export default Organisations