import { Divider, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  image: {
    marginTop: theme.spacing(9),
    backgroundImage:
      "url(https://images.pexels.com/photos/1769409/pexels-photo-1769409.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)",
    minHeight: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
  },
  messageContainer: {
    height: "fit-content",
    marginTop: "5%",
    padding: "2%",
  },
}));

const JoinOrCreateInstructions = () => {
  const classes = useStyles();

  return (
    <div className={classes.image}>
      <Paper elevation={3} className={classes.messageContainer}>
        <Typography align="center" variant="h6" component="h6">
          Thank you for using EngiFlow. <br />
        </Typography>
        <Divider />
        <br/>
        <Typography align="left" variant="body2" component="h6">
          If you <span style={{ fontWeight: "bold" }}>created</span> a new
          organisation, please wait for approval.
          <br />
          It can take up to 24 hours for our team to approve your account.<br/>
          <span style={{fontStyle:"italic", color:"red"}}>Note: Please logout & login back again once you are approved.</span>
          <br />
          <br />
        </Typography>

        <Typography align="left" variant="body2" component="h6">
          If you <span style={{ fontWeight: "bold" }}>joined</span> an existing organisation,
          please wait for the organisation owner to assign your user roles.
          <br />
          The organisation owner can assign your user roles through the organisation owner dashboard. <br/>
          <span style={{fontStyle:"italic", color:"red"}}>Note: Please logout & login back again once owner assign you a new role.</span>
        </Typography>
        <br/>
      </Paper>
    </div>
  );
};

export default JoinOrCreateInstructions;
