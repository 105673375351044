import {
  Button,
  TextField,
} from "@material-ui/core";
import React from "react";
import useStyles from "./jobCommentsStyles";
import useJobComments from "./useJobComments";

const JobCommentInput = ({jobId}:any) => {
  const classes = useStyles();
  const { formikJobComment ,createCommentResponse }: any = useJobComments(parseInt(jobId));

  return (
    <div className={classes.commentFieldContainer}>
      <TextField
        fullWidth
        multiline
        rows={2}
        rowsMax={50}
        id="jobCommentInput"
        name="jobCommentInput"
        label="Add New Comment"
        value={formikJobComment.values.Comment}
        onChange={(e) =>
          formikJobComment.setFieldValue("Comment", e.target.value)
        }
        error={
          formikJobComment.touched.Comment &&
          Boolean(formikJobComment.errors.Comment)
        }
        helperText={
          formikJobComment.touched.Comment &&
          Boolean(formikJobComment.errors.Comment)
            ? formikJobComment.errors.Comment
            : null
        }
      />
      <Button
        onClick={() => {
          formikJobComment.submitForm()
        }}
        style={{ marginTop: "5px" }}
        size="small"
        variant="contained"
        color="primary"
        disabled={createCommentResponse.loading}
      >
        Post
      </Button>
    </div>
  );
};

export default JobCommentInput;
