import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { READ_JOB_AUDIT, READ_USER } from "../JobList/query";
import { useLazyQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import JobAuditDetail from "./JobAuditDetail";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { getJobStateName, renderDateAndTime } from "../../utils/helpers";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	tableCell: {
		padding: "8px",
		minWidth: 100,
	},
	tableRowAdmin: {
		"&:hover": {
			backgroundColor: "#f5f5f5",
			cursor: "pointer",
		},
	},
	tableRow: {
		"&:hover": {
			backgroundColor: "#f5f5f5",
		},
	},
	loadingSpinner: {
		width: "10px",
	},
	spinnerContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
});

function createData(id: string, dateTime: string, name: string) {
	return { id, dateTime, name };
}

interface rowType {
	id: number;
	dateTime: string;
	userName: string;
	name: string;
	description: string;
	type: string;
	from: string;
	to: string;
	comment: string;
}

interface JobHistoryProps {
	jobHistoryData: any;
	jobId: number;
}

const JobHistory: React.FC<JobHistoryProps> = ({ jobHistoryData, jobId }) => {
	const classes = useStyles();
	const [rows, setRows] = useState<rowType[]>([]);
	const [clickedAudit, setClickedAudit] = useState<any>(null);
	const currentUser: any = useSelector((state: RootState) => state.user);
	const { getAccessTokenSilently } = useAuth0();
	const [readJobAudit, readJobAuditResponse] = useLazyQuery(READ_JOB_AUDIT);

	// Test FBS-------------------------------------------------------------------------------------------
	const [allTest, setTest] = useState<rowType[]>([]);
	// Test FBS-------------------------------------------------------------------------------------------

	const logToRow = (log: any) => {
		const { id, dateTime, userId, userName, name, description, type } = log;
		const isChangeState = ["ChangeState", "AdvanceState", "RejectState"].includes(type);
		const changeStateProperties = {
			from: isChangeState ? getJobStateName(log.sourceState) : "",
			to: isChangeState ? getJobStateName(log.destinationState) : "",
			comment: isChangeState ? log.comment : "",
			isChangeState,
		};
		const row = {
			id,
			dateTime,
			name,
			description,
			type,
			userName,
			...changeStateProperties,
		};
		return row;
	};

	//Read job audit
	useEffect(() => {
		getAccessTokenSilently().then((token) => {
			const config = {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			};
			axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/log/job/${jobId}`, config).then((response: any) => {
				const logs = response.data ?? [];
				setRows(logs.map(logToRow));
			});

			// Test FBS--------------------------------------------------------------------------------------------
			axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/log/job`, config).then((response: any) => {
				const allLogs = response.data ?? [];
				setTest(allLogs);
			});
			// Test FBS-------------------------------------------------------------------------------------------
		});
	}, [jobId]);

	//Click audit handler
	const seeAuditDetails = (audit: any) => {
		// if(currentUser.user.roles.includes("Administrator")){
		//   setClickedAudit(audit)
		// }
	};

	//render: loading
	if (readJobAuditResponse.loading) {
		return (
			<div className={classes.spinnerContainer}>
				<CircularProgress className={classes.loadingSpinner} size={20} />
				Loading job audit...
			</div>
		);
	}

	//render: no data
	if (rows.length === 0) {
		return <div className={classes.spinnerContainer}>This job does not have any history entries.</div>;
	}

	//render: data
	if (rows.length > 0) {
		return (
			<TableContainer style={{ marginBottom: "20px" }} component={Paper}>
				<Table className={classes.table} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell className={classes.tableCell} align="left">
								Time
							</TableCell>
							<TableCell className={classes.tableCell} align="left">
								User
							</TableCell>
							<TableCell className={classes.tableCell} align="left">
								Action
							</TableCell>
							<TableCell className={classes.tableCell} align="left">
								Source State
							</TableCell>
							<TableCell className={classes.tableCell} align="left">
								Destination State
							</TableCell>
							<TableCell className={classes.tableCell} align="left">
								Comment
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow
								//onClick={() => seeAuditDetails(row)}
								className={currentUser.user.roles.includes("Administrator") ? classes.tableRowAdmin : classes.tableRow}
								key={row.id}
							>
								<TableCell className={classes.tableCell} align="left">
									{renderDateAndTime(row.dateTime)}
								</TableCell>
								<TableCell className={classes.tableCell} align="left">
									{row.userName}
								</TableCell>
								<TableCell className={classes.tableCell} align="left">
									{row.name}
								</TableCell>
								<TableCell className={classes.tableCell} align="left">
									{row.from}
								</TableCell>
								<TableCell className={classes.tableCell} align="left">
									{row.to}
								</TableCell>
								<TableCell className={classes.tableCell} align="left">
									{row.comment}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				{/* {readJobAuditResponse.data.readJobAudit && (
          <JobAuditDetail
            audit={
              readJobAuditResponse.data.readJobAudit[rows.indexOf(clickedAudit)]
            }
            setClickedAudit={setClickedAudit}
          />
        )} */}
			</TableContainer>
		);
	}

	return <>Error</>;
};

export default JobHistory;
