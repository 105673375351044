import React, { useEffect, useState } from "react";
import { Button, CircularProgress, createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import { JobFilterCriteria, useFilterJobsLazyQuery } from "../../generated/graphql";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import stringify from "csv-stringify";
import streamSaver from "streamsaver";
import { write } from "fs";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		exportButton: {
			backgroundColor: theme.palette.primary.main,
			cursor: "pointer",
			justifyContent: "center",
			alignItems: "center",
			marginTop: "5px",
			borderRadius: "0px",
			padding: "12px",
			width: "100%",
			height: "44px",
			color: theme.palette.primary.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.primary.light,
			},
		},
		spinner: {
			color: theme.palette.secondary.main,
		},
	})
);

// Interfaces --------------------------------------------------------------------------------------------------------------------------------
export interface CSVExportButtonProps {
	filterCriteria: JobFilterCriteria;
}
export enum DateFormatOptions {
	enAU = "en-AU",
	enUS = "en-US",
}
export enum FormatType {
	ddmmyyyy = 1,
	yyyymmdd = 2,
}

// COMPONENT ----------------------------------------------------------------------------------------------------------------------------------
export const CSVExportButton: React.FC<CSVExportButtonProps> = ({ filterCriteria }) => {
	const classes = useStyles();
	const { getAccessTokenSilently } = useAuth0();
	const [csvLoading, setCsvLoading] = useState<boolean>(false);
	const [filter, filterResponse] = useFilterJobsLazyQuery();
	const [filters, setFilters] = useState<string>("");
	const [firstLoad, setFirstLoad] = useState<boolean>(true);
	const [createLogs, setCreateLogs] = useState<any>([]);

	// APIs all functions ----------------------------------------------------------------------------------------------------------------------
	function FormatDateOptions(date: Date, localTime: DateFormatOptions = DateFormatOptions.enAU, type: FormatType = FormatType.ddmmyyyy) {
		// DD/MM/YYYY
		if (type === 1) {
			return new Date(date).toLocaleDateString(localTime);
		}
		// YYYY-MM-DD
		if (type === 2) {
			let newDate = new Date(date).toLocaleDateString(localTime);
			let dateArray = newDate.split("/");
			newDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
			return newDate;
		}
	}

	function GetJobsData(jobs: any[] | undefined | null, createLogs: any[]) {
		let JobsData: object[] = [];
		if (jobs) {
			createLogs.map((log: any) => {
				let job = jobs.find((job) => job.id === log.jobId && log.type.toLowerCase().includes("create"));
				if (job) {
					let obj = { jobData: job, logData: log };
					JobsData.push(obj);
				}
			});
		}
		return JobsData;
	}

	async function GetCreateLogs() {
		let createLogs = await getAccessTokenSilently().then((token) => {
			const config = {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			};
			axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/log/job`, config).then((response: any) => {
				const allLogs = response.data ?? [];
				const createLogs = allLogs.filter((log: any) => log.type.toLowerCase().includes("create"));
				setCreateLogs(createLogs);
				return createLogs;
			});
		});
		return createLogs;
	}

	// Aux functions --------------------------------------------------------------------------------------------------------------------------------
	const columnTitles = [
		"JOB NUMBER",
		"ORGANISATION",
		"CREATION DATE",
		"CREATED BY",
		"STATE",
		"PLAN DELIVERY DATE",
		"BUILDER",
		"ADDRESS",
		"LEVEL",
		"PRIORITY",
	];
	function generateCSV(JobsData: any[]) {
		// JobsData?.map((data: any) => console.log("Data: ", data));
		const tableData =
			JobsData?.map((data: any) => [
				data?.jobData?.jobNumber,
				data?.jobData?.organisation?.name.toUpperCase() ?? "",
				FormatDateOptions(data?.logData?.dateTime),
				data?.logData?.userName?.toUpperCase(),
				data?.jobData?.state?.toUpperCase(),
				data?.jobData?.deliveryDate?.slice(0, 10),
				data?.jobData?.builder?.toUpperCase(),
				`${data?.jobData?.jobAddress?.streetNumber.toUpperCase() ?? ""} ${data?.jobData?.jobAddress?.street?.toUpperCase() ?? ""}`,
				data?.jobData?.level,
				data?.jobData?.priority,
			]) ?? [];

		stringify(
			tableData,
			{
				header: true,
				columns: columnTitles,
			},
			(error, csvData) => {
				if (!error) {
					const byteData = new TextEncoder().encode(csvData);
					//save csvData with streamsaver
					const fileStream = streamSaver.createWriteStream("EngiFlow_Jobs_" + FormatDateOptions(new Date()) + ".csv", {
						size: byteData.byteLength,
					});

					const writer = fileStream.getWriter();
					writer.write(byteData);
					writer.close();
				}
				setCsvLoading(false);
			}
		);
	}

	const handleClick = async () => {
		setCsvLoading(true);
		await GetCreateLogs().then(() => {
			filter({
				variables: {
					page: 0,
					rowsPerPage: 0,
					jobFilterCriteria: filterCriteria,
				},
			});
		});
	};

	// UseEffect functions ------------------------------------------------------------------------------------------------------------------------------------

	useEffect(() => {
		if (filterResponse?.data?.filterJobs && csvLoading && createLogs) {
			const jobs = filterResponse.data.filterJobs.jobs;
			let JobsData = GetJobsData(jobs, createLogs);
			if (JobsData) {
				generateCSV(JobsData);
			}
		}
	}, [createLogs]);

	// Render result ------------------------------------------------------------------------------------------------------------------------------------------
	return (
		<Button onClick={handleClick} className={classes.exportButton} disabled={csvLoading}>
			Generate CSV Export
		</Button>
	);
};
