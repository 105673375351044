import React from "react";
import { useSelector } from "react-redux";
import Client from "./Client";
import Provider from "./Provider";
import { RootState } from "../../store";
import Unauthorized from "../Unauthorized";
import { devRole, ROLES_CONFIG } from "../../utils/helpers";

const AwaitingForDocuments = (job: any) => {
  let currentUser: any = useSelector((state: RootState) => state.user.user);

  if (devRole.roles.length > 0) {
    currentUser = devRole;
  }

  let user_role;
  if (
    currentUser.roles.includes(ROLES_CONFIG.Client.RoleName) || 
    currentUser.roles.includes(ROLES_CONFIG.Owner.RoleName) ||
    currentUser.roles.includes(ROLES_CONFIG.Administrator.RoleName)
  ) {
    user_role = "Client";
  }
  else if(
    currentUser.roles.includes(ROLES_CONFIG.Inspector.RoleName) ||
    currentUser.roles.includes(ROLES_CONFIG.Engineer.RoleName) ||
    currentUser.roles.includes(ROLES_CONFIG.Detailer.RoleName)
    
  ) {
    user_role = "Provider";
  }

  switch (user_role) {
    case "Provider":
      return <Provider />;
    case "Client":
      return <Client job={job} />;
    default:
      return <Unauthorized />;
  }
};

export default AwaitingForDocuments;
