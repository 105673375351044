import gql from "graphql-tag";

export const QUERY_JOB_LIST = gql`
  query JobList {
    readJobs {
      builder
      buildingDescription
      storeys
      deliveryDate
      id
      level
      detailingRequested
      engineeringRequested
      inspectionRequested
      prelimDesignRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobNumber
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      organisation {
        id
        name
      }
      priority
      state
    }
  }
`;

export const QUERY_JOB_BY_ID = gql`
  query JobById($jobId: Int!) {
    readJob(jobId: $jobId) {
      id
      builder
      buildingDescription
      storeys
      confirmedWindRating
      deliveryDate
      detailingRequested
      engineeringRequested
      inspectionRequested
      prelimDesignRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      jobEngineering {
        id
        data
      }
      jobInspection {
        id
        data
      }
      jobInspectionChecklist {
        id
        data
      }
      jobNumber
      level
      organisation {
        id
        name
        address {
          addressLine1
          addressLine2
          country
          id
          postcode
          province
          suburb
        }
        prelimDesignFormPermission
      }
      priority
      state
      windRating
    }
  }
`;

export const QUERY_READ_JOB_ENGINEERING_FORM_DATA = gql`
  query readJobEngineeringFormData($jobId: Int!) {
    readJobEngineeringFormData(jobId: $jobId) {
      id
      data
    }
  }
`;

export const QUERY_UPDATE_JOB_ENGINEERING_FORM_DATA = gql`
  mutation updateJobEngineeringFormData($jobId: Int!, $formData: JobEngineeringInput!) {
    updateJobEngineeringFormData(jobId: $jobId, formData: $formData) {
      id
      data
    }
  }
`;

export const QUERY_READ_JOB_INSPECTION_FORM_DATA = gql`
  query readJobInspectionFormData($jobId: Int!) {
    readJobInspectionFormData(jobId: $jobId) {
      id
      data
    }
  }
`;

export const QUERY_UPDATE_JOB_INSPECTION_FORM_DATA = gql`
  mutation updateJobInspectionFormData($jobId: Int!, $formData: JobInspectionInput!) {
    updateJobInspectionFormData(jobId: $jobId, formData: $formData) {
      id
      data
    }
  }
`;

export const QUERY_READ_JOB_INSPECTION_CHECKLIST_FORM_DATA = gql`
  query readJobInspectionChecklistFormData($jobId: Int!) {
    readJobInspectionChecklistFormData(jobId: $jobId) {
      id
      data
    }
  }
`;

export const QUERY_UPDATE_JOB_INSPECTION_CHECKLIST_FORM_DATA = gql`
  mutation updateJobInspectionChecklistFormData($jobId: Int!, $formData: JobInspectionChecklistInput!) {
    updateJobInspectionChecklistFormData(jobId: $jobId, formData: $formData) {
      id
      data
    }
  }
`;

export const QUERY_FILTER_JOBS = gql`
  query FilterJobs($jobFilterCriteria: JobFilterCriteria, $page: Int, $rowsPerPage: Int) {
    filterJobs(jobFilterCriteria: $jobFilterCriteria, page: $page, rowsPerPage: $rowsPerPage) {
      totalJobs
      page
      rowsPerPage
      jobs {
        id
        builder
        buildingDescription
        storeys
        confirmedWindRating
        deliveryDate
        detailingRequested
        engineeringRequested
        inspectionRequested
        prelimDesignRequested
        engineeringIssued
        prelimDesignIssued
        inspectionIssued
        jobAddress {
          id
          localGovernment
          lot
          postcode
          province
          street
          streetNumber
          suburb
        }
        jobEngineering {
          id
          data
        }
        jobInspection {
          id
          data
        }
        jobInspectionChecklist {
          id
          data
        }
        jobNumber
        level
        organisation {
          id
          name
          address {
            addressLine1
            addressLine2
            country
            id
            postcode
            province
            suburb
          }
        }
        priority
        state
        windRating
      }
    }
  }
`;

export const QUERY_ADD_NEW_JOB = gql`
mutation CreateJob($job: JobInput!){
  createJob(job:$job){
    builder
    buildingDescription
    storeys
    level
    deliveryDate
    id
    jobNumber
    detailingRequested
    engineeringRequested
    inspectionRequested
    prelimDesignRequested
    engineeringIssued
    prelimDesignIssued
    inspectionIssued
    jobAddress {
      id
      localGovernment
      lot
      postcode
      province
      street
      streetNumber
      suburb
    }
    organisation {
      id
      name
    }
    priority
    state
  }
}
`;

export const QUERY_UPDATE_JOB = gql`
mutation UpdateJob($job: JobInput!, $jobId: Int!){
  updateJob(job:$job, jobId: $jobId){
    builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      prelimDesignRequested
      inspectionRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      organisation {
        id
        name
      }
      priority
      state
  }
}
`;

export const QUERY_ADD_NEW_ORGANISATION = gql`
mutation CreateOrganisation($organisation:OrganisationInput!){
  createOrganisation(organisation:$organisation){
    name
    address 
    {
      addressLine1
      addressLine2
      postcode
      suburb
      province
      country
    }
  }
}
`;

export const QUERY_UPDATE_ORGANISATION = gql`
mutation UpdateOrganisation($organisation: OrganisationInput!, $organisationId:Int!){
  updateOrganisation(organisation:$organisation, organisationId:$organisationId){
    name
    address{
      addressLine1
      addressLine2
      postcode
      suburb
      province
      country
    }
  }
}
`;

export const CREATE_USER = gql`
mutation CreateUser($user: UserInput!){
  createUser(user:$user){
    address{
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    },
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    phone
    position
  }
}
`

export const UPDATE_USER = gql`
mutation UpdateCurrentUser($user: UserInput!, $userId: Int!){
  updateUser(user:$user, userId:$userId){
    address{
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    },
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    phone
    position
  }
}
`

export const READ_CURRENT_USER = gql`
query ReadCurrentUser{
  readCurrentUser{
    address{
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    },
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    organisation{
      address{
        addressLine1
        addressLine2
        country
        postcode
        province
        suburb
      }
      id
      name
    }
    phone
    position
    notificationSettings{
      eMail
      signalR
      id
    }
  }
}
`;

export const READ_USERS = gql`
query ReadUsers{
  readUsers{
    address{
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    },
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    organisation{
      address{
        addressLine1
        addressLine2
        country
        postcode
        province
        suburb
      }
      id
      name
    }
    phone
    position
  }
}
`

export const READ_USERS_WITHOUT_ROLE = gql`
  query ReadUsersWithoutRole{
    readUsersWithoutRole{
      address{
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      },
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation{
        address{
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
  }
`

export const READ_CURRENT_ORGANISATION = gql`
query ReadCurrentOrganisation{
  readCurrentOrganisation{
    address{
      addressLine1
      addressLine2
      country
      id
      postcode
      postcode
      province
      suburb
    }
    id
    name
  }
}
`;

export const READ_ORGANISATION_USERS = gql`
  query ReadOrganisationUsers($organisationId:Int!){
    readOrganisationUsers(organisationId:$organisationId){
      address{
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      },
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation{
        address{
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
  }
` 

export const ADVANCE_JOB = gql`
  mutation AdvanceJob($jobId: Int!){
    advanceJob(jobId: $jobId){
      builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      prelimDesignRequested
      inspectionRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      organisation {
        id
        name
      }
      priority
      state
    }
  }
`

export const REJECT_JOB = gql`
  mutation RejectJob($jobId:Int!, $comment: String!){
    rejectJob(jobId:$jobId, comment:$comment){
      builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      prelimDesignRequested
      inspectionRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      organisation {
        id
        name
      }
      priority
      state
    }
  }
`

export const REVERT_RECTIFY_DETAILS = gql`
  mutation RevertRectifyDetailsJob($jobId:Int!){
    revertRectifyDetailsJob(jobId:$jobId){
      builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      prelimDesignRequested
      inspectionRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      organisation {
        id
        name
      }
      priority
      state
    }
  }
`

export const REVERT_REQUEST_INSPECTION = gql`
  mutation RevertRequestInspectionJob($jobId:Int!){
    revertRequestInspectionJob(jobId:$jobId){
      builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      prelimDesignRequested
      inspectionRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      organisation {
        id
        name
      }
      priority
      state
    }
  }
`

export const CREATE_INVITATION = gql`
  mutation CreateInvitation($email:String!){
    createInvitation(email:$email){
      code
      expiryDate
      id
      userEmail
    }
  }
`

export const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($code:String!){
    acceptInvitation(code:$code){
      address{
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      },
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation{
        address{
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
  }
`

export const READ_USER_ROLES = gql`
query ReadRoles($userId: Int!){
  readRoles(userId: $userId)
}
`

export const READ_USER = gql`
query ReadUser($userId: Int!){
  readUser(userId: $userId) {
    address{
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    },
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    organisation{
      address{
        addressLine1
        addressLine2
        country
        postcode
        province
        suburb
      }
      id
      name
    }
    phone
    position
    notificationSettings{
      eMail
      signalR
      id
    }
  }
}
`;

export const ASSIGN_USER_ROLE = gql`
  mutation AssignRole($userId:Int!, $role:String!){
    assignRole(userId:$userId, role:$role)
  }
`

export const REMOVE_USER_ROLE = gql`
  mutation RemoveRole($userId:Int!, $role:String!){
    removeRole(userId:$userId, role:$role)
  }
`

export const READ_ORGANISATIONS = gql`
query ReadOrganisations{
  readOrganisations{
    address{
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    },
    id,
    name
  }
}
`

export const READ_JOB_AUDIT = gql`
query ReadJobAudit($jobId:Int!){
  readJobAudit(jobId:$jobId){
    id
    dateTime
    name
    jobId
    userId
  }
}
`

export const CREATE_JOB_COMMENT = gql`
mutation CreateComment($jobId:Int!,$text: String!){
  createComment(jobId:$jobId,text:$text){
    dateTime
    id
    text
  }
}
`

export const READ_JOB_COMMENTS = gql`
query ReadComments($jobId:Int!,$page:Int!,$rowsPerPage:Int!){
  readComments(jobId:$jobId, page:$page, rowsPerPage:$rowsPerPage){
    comments{
      dateTime
      id
      text
      user{
        firstName
        lastName
      }
    }
    page
    rowsPerPage
    totalComments
  }
}
`

export const REQUEST_QUOTE = gql`
  mutation RequestQuote($RequestQuote:RequestQuoteInput!){
    requestQuote(requestQuote:$RequestQuote){
      firstName
      lastName
      eMail
      mobileNumber
      message
    }
  }
`

export const MAINTENANCE_MODE = gql`
  query MaintenanceMode{
    maintenanceMode
  }
`

export const UPDATE_MAINTENANCE_MODE=gql`
  mutation UpdateMaintenanceMode($state:Int!){
    updataMaintenanceMode(state:$state)
  }
`

export const READ_JOB_TASKS = gql`
  query ReadJobTasks($JobId: Int!){
    readJobTasks(jobId:$JobId){
      id
      assignedBy{
        address{
          addressLine1
          addressLine2
          country
          id
          postcode
          province
          suburb
        },
        contactPerson
        firstName
        id
        lastName
        licenseClass
        licenseNumber
        mobileNumber
        organisation{
          address{
            addressLine1
            addressLine2
            country
            postcode
            province
            suburb
          }
          id
          name
        }
        phone
        position
      }
      assignedTo{
        address{
          addressLine1
          addressLine2
          country
          id
          postcode
          province
          suburb
        },
        contactPerson
        firstName
        id
        lastName
        licenseClass
        licenseNumber
        mobileNumber
        organisation{
          address{
            addressLine1
            addressLine2
            country
            postcode
            province
            suburb
          }
          id
          name
        }
        phone
        position
      }
      description
      subject
      dueDate
      finishedDate
      taskStatus
      job{
        builder
        level
        buildingDescription
        storeys
        deliveryDate
        id
        jobNumber
        detailingRequested
        engineeringRequested
        inspectionRequested
        prelimDesignRequested
        engineeringIssued
        prelimDesignIssued
        inspectionIssued
        jobAddress {
          id
          localGovernment
          lot
          postcode
          province
          street
          streetNumber
          suburb
      }
      organisation {
        id
        name
      }
      priority
      state
      }
    }
  }
`

export const READ_TASK_BY_ID = gql`
  query ReadTaskById($taskId: Int!){
    readTaskById(taskId:$taskId){
      id
      assignedBy{
        address{
          addressLine1
          addressLine2
          country
          id
          postcode
          province
          suburb
        },
        contactPerson
        firstName
        id
        lastName
        licenseClass
        licenseNumber
        victoriaLicenseNumber
        licenseArea
        mobileNumber
        organisation{
          address{
            addressLine1
            addressLine2
            country
            postcode
            province
            suburb
          }
          id
          name
        }
        phone
        position
      }
      assignedTo{
        address{
          addressLine1
          addressLine2
          country
          id
          postcode
          province
          suburb
        },
        contactPerson
        firstName
        id
        lastName
        licenseClass
        licenseNumber
        victoriaLicenseNumber
        licenseArea
        mobileNumber
        organisation{
          address{
            addressLine1
            addressLine2
            country
            postcode
            province
            suburb
          }
          id
          name
        }
        phone
        position
      }
      description
      subject
      dueDate
      finishedDate
      taskStatus
      job{
        builder
        level
        buildingDescription
        storeys
        deliveryDate
        id
        jobNumber
        detailingRequested
        engineeringRequested
        inspectionRequested
        prelimDesignRequested
        engineeringIssued
        prelimDesignIssued
        inspectionIssued
        jobAddress {
          id
          localGovernment
          lot
          postcode
          province
          street
          streetNumber
          suburb
      }
      organisation {
        id
        name
      }
      priority
      state
      }
    }
  }
`

export const READ_USER_TASKS = gql`
  query ReadUserTasks{
    readUserTasks{
      id
      assignedBy{
        address{
          addressLine1
          addressLine2
          country
          id
          postcode
          province
          suburb
        },
        contactPerson
        firstName
        id
        lastName
        licenseClass
        licenseNumber
        victoriaLicenseNumber
        licenseArea
        mobileNumber
        organisation{
          address{
            addressLine1
            addressLine2
            country
            postcode
            province
            suburb
          }
          id
          name
        }
        phone
        position
      }
      assignedTo{
        address{
          addressLine1
          addressLine2
          country
          id
          postcode
          province
          suburb
        },
        contactPerson
        firstName
        id
        lastName
        licenseClass
        licenseNumber
        victoriaLicenseNumber
        licenseArea
        mobileNumber
        organisation{
          address{
            addressLine1
            addressLine2
            country
            postcode
            province
            suburb
          }
          id
          name
        }
        phone
        position
      }
      description
      subject
      dueDate
      finishedDate
      taskStatus
      job{
        builder
        level
        buildingDescription
        storeys
        deliveryDate
        id
        jobNumber
        detailingRequested
        engineeringRequested
        inspectionRequested
        prelimDesignRequested
        engineeringIssued
        prelimDesignIssued
        inspectionIssued
        jobAddress {
          id
          localGovernment
          lot
          postcode
          province
          street
          streetNumber
          suburb
      }
      organisation {
        id
        name
      }
      priority
      state
      }
    }
  }
`

export const CREATE_TASK = gql`
mutation CreateTask($task:TaskInput!, $taskType: TaskTypeEnum!){
  createTask(task:$task, taskType: $taskType){
    id
    assignedBy{
      address{
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      },
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation{
        address{
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    assignedTo{
      address{
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      },
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation{
        address{
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    description
    subject
    dueDate
    finishedDate
    taskStatus
    job{
      builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      inspectionRequested
      prelimDesignRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
    }
    organisation {
      id
      name
    }
    priority
    state
    }
  }
}
`

export const FILTER_TASKS = gql`
query FilterTasks($taskFilterCriteria: TaskFilterCriteria!){
  filterTasks(taskFilterCriteria: $taskFilterCriteria){
    id
    assignedBy{
      address{
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      },
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation{
        address{
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    assignedTo{
      address{
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      },
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation{
        address{
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    description
    subject
    dueDate
    finishedDate
    taskStatus
    job{
      builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      inspectionRequested
      prelimDesignRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
    }
    organisation {
      id
      name
    }
    priority
    state
    }
  }
}
`

export const UPDATE_TASK_STATE = gql`
mutation UpdateTaskState($taskId: Int!, $taskStatus: TaskStatus!){
  updateTaskState(taskId:$taskId, taskStatus:$taskStatus){
    taskStatus
  }
}
`

export const UPDATE_TASK = gql`
mutation UpdateTask($task:TaskInput!, $id: Int! ){
  updateTask(task:$task,id:$id){
    id
    assignedBy{
      address{
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      },
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation{
        address{
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    assignedTo{
      address{
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      },
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation{
        address{
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    description
    subject
    dueDate
    finishedDate
    taskStatus
    job{
      builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      inspectionRequested
      prelimDesignRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
    }
    organisation {
      id
      name
    }
    priority
    state
    }
  }
}
`

export const READ_CURRENT_ORGANISATION_USERS = gql`
query ReadCurrentOrganisationUsers{
  readCurrentOrganisationUsers{
    address{
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    },
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    phone
    position
  }
}
`

export const MARK_NOTIFICATION_AS_RECEIVED = gql`
mutation MarkNotificationAsReceived($notificationId: Int!){
  markNotificationAsReceived(notificationId:$notificationId){
    id
    notification {
      dateTime
      description
      id
      subject
      type
    }
    received
  }
}
`
