import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  commentContainer: {
    padding: "10px",
    marginBottom: "5px",
  },
  commentFooter: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  commentText: {
    fontSize: "14px",
    color: "#1A1A1B",
    maxWidth: "600px",
  },
  commentFieldContainer: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "3px",
    padding: "6px",
    minWidth: "500px",
    marginBottom: "15px",
  },
  loadingSpinner: {
    width: "10px",
  },
  loadingContainer:{
    display: "flex",
    flexDirection:"row",
    justifyContent: "center",
    alignItems: "center",
    width:"100%",
    margin:"5px"
  }
}));

export default useStyles;
