import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core";
import MainInfo from "./MainInfo";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import AddSignature from "./AddSignature";
import NotificationSettings from "./NotificationSettings";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    ProfileContainer: {
      backgroundColor: theme.palette.success.main,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      padding: "15px 10px",
      marginTop: theme.spacing(15),
      borderRadius: "3px",
    },
    avatar: {
      borderRadius: "50%",
      maxWidth: "100px",
    },
    userMainInfo: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "5px",
      margin: "1px",
      minHeight: "210px",
    },
    userSecondaryInfo: {
      padding: "20px",
      margin: "1px",
      minHeight: "210px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
    userOrganizationPanel: {
      minHeight: "300px",
    },
    somethingElse: {
      minHeight: "220px",
    },
  })
);

interface ProfileProps {
  user: any;
}

const Profile: React.FC<ProfileProps> = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();
  const currentUser: any = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (currentUser?.user?.roles.length === 0) {
      history.push("/sign-up");
    }
  }, [currentUser]);

  if (user.roles === undefined) {
    return <></>;
  }

  return (
    <Container maxWidth="md">
      {/* <pre>{JSON.stringify(currentUser, null, 2)}</pre> */}
      <div className={classes.ProfileContainer}>
        <Grid
          container
          spacing={2}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={12} md={4} lg={4}>
            <Paper className={classes.userMainInfo}>
              <img
                className={classes.avatar}
                src={user.picture}
                alt={`${user.nickname}_alt`}
              />
              <Typography component="h5" variant="h6">
                {user.nickname}
              </Typography>
              <Typography component="h5" variant="body2">
                {user.email}
              </Typography>
              {user.roles
                ? user.roles.map((role: any) => {
                    return (
                      <Typography component="h5" variant="body2">
                        {role}
                      </Typography>
                    );
                  })
                : null}
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <MainInfo user={user} />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <AddSignature user={user}/>
          </Grid>
          <Grid item xs={6} md={8} lg={8}>
            <NotificationSettings/>
          </Grid>

          {/* {user.roles.includes("Administrator") && 
          <Grid item xs={12} md={12} lg={10}>
            <Paper className={classes.userOrganizationPanel}>
              <OwnerDashboard user={user} heading="Organisation owner dashboard"/>
            </Paper>
          </Grid>
          } */}
        </Grid>
      </div>
    </Container>
  );
};

export default Profile;
