export interface Notification {
    id: number,
    type: number,
    dateTime: string,
    subject: string,
    description: string
    link: string,
    jobId: number,
    taskId: number
}
  
export interface NotificationsState {
    notifications: Notification[]
}

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS'

interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION
  payload: Notification
}

interface RemoveNotificationAction {
  type: typeof REMOVE_NOTIFICATION
  payload: Notification
}

interface SetNotificationsAction {
    type: typeof SET_NOTIFICATIONS
    payload: Notification[]
  }

interface FetchNotificationsAction {
    type: typeof FETCH_NOTIFICATIONS
}

export type NotificationActionTypes = AddNotificationAction | RemoveNotificationAction | SetNotificationsAction | FetchNotificationsAction
