import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as yup from "yup";
import { useDispatch} from "react-redux";
import { useFormik } from "formik";
import { useAcceptInvitationMutation } from "../../generated/graphql";
import { errorHandler } from "../../store/actions";
import BusinessIcon from '@material-ui/icons/Business';
import { showComponentEnum } from "../../views/JoinOrCreateView/JoinOrCreateView";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  image: {
    marginTop: theme.spacing(9),
    backgroundImage: "url(https://images.pexels.com/photos/1769409/pexels-photo-1769409.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)",
    minHeight:"100vh",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    display:"flex",
    justifyContent:"center",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor:"#fff",
    borderRadius:"3px",
    flexBasis:"fit-content"

  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "90%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formContainer:{
    marginTop:"5%",
    marginBottom:"50px",
    borderRadius:"5px",
    justifyContent:"center",
    display:"flex",
    height:"fit-content"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  inputContainer:{
    margin:'5px',
    padding:'4px!important'
  },
  linearProgress: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));


const validationSchema = yup.object({
  invitationCode: yup.string().required(),
});

interface JoinOrganisationProps {
  setShowComponent?: any;
}

export const JoinOrganisation: React.FC<JoinOrganisationProps> = ({ setShowComponent }) => {
  const classes = useStyles();
  const dispatch = useDispatch();


  const formik = useFormik({
    initialValues: {
      invitationCode:""
    },
    validationSchema:validationSchema,
    onSubmit: async (values) => {
      //alert(JSON.stringify(values, null, 2));
      try {
        await joinOrganisation();
      } catch (error) {
        dispatch(
          errorHandler(`${error.message}, check your internet connection`)
        );
      }
      //Here i need query which does the following:
      //-Change user onboarding status enum
    },
  });

  const [joinOrganisation, joinOrganisationResponse] = useAcceptInvitationMutation({
    variables: {
      code:formik.values.invitationCode
    },
  });

  useEffect(() => {
    // console.log('response: ',joinOrganisationResponse)
    if(joinOrganisationResponse.data?.acceptInvitation){
      // console.log(joinOrganisationResponse.data.acceptInvitation)
      setShowComponent(showComponentEnum["WAIT FOR APPROVAL"]);
    }
  },[joinOrganisationResponse])



  enum joinFormFields {
    invitationCode = "Invitation Code",
  }

  const form = () => {
    return (
      <>
        <Avatar className={classes.avatar}>
          <BusinessIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Join Organisation
        </Typography>
        <Typography align="center" component="h6" variant="body2">
          Please enter the unique invitation code that was given to you by the organisation owner to join the organisation.<br/> 
          If you dont have an invitation code yet, please contact your organisation owner. <br/>
          Make sure you are using the same email address when signing up that was supplied when creating your invitation.
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          {/* firstName*/}
          <TextField
          inputProps={{
              style:{
                padding:10
              }
            }}
            className={classes.inputContainer}
            variant="outlined"
            margin="normal"
            fullWidth
            id="invitationCode"
            name="invitationCode"
            label={joinFormFields.invitationCode}
            value={formik.values.invitationCode}
            autoFocus
            onChange={formik.handleChange}
            error={formik.touched.invitationCode && Boolean(formik.errors.invitationCode)}
          />
          {/* lastName */}
         

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Complete
          </Button>

          {joinOrganisationResponse.loading && (
            <div className={classes.linearProgress}>
              <LinearProgress />
            </div>
          )}
          <Typography align="center" variant="body2" component="h6">
            Note: The invitation code will expire within 3 days from date of creation.
          </Typography>
        </form>
      </>
    );
  };

  return (
    <Grid container component="main" className={classes.image}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={8} lg={4} component={Paper} className={classes.formContainer} elevation={6} square>
        <div className={classes.paper}>
          {form()}
        </div>
      </Grid>
    </Grid>
  );
};
