import React from "react";
import { Route, Switch } from "react-router-dom";
import Privacy from "./components/Privacy/Privacy";
import OwnerDashboard from "./components/OwnerDashboard/OwnerDashboard";
import JoinOrCreateView from "./views/JoinOrCreateView/JoinOrCreateView";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import NewJobFormContainer from "./components/NewJobForm/NewJobFormContainer";
import JobDetailViewContainer from "./components/JobDetail/JobDetailContainer";
import { JobsView } from "./views/JobsView";
import { CreateUser } from "./components/CreateUser/CreateUser";
import { ContactUs } from "./components/ContactUs/ContactUs";
import EditJobForm from "./components/EditJobForm/EditJobForm";
import Profile from "./components/Profile/Profile";
import Dashboard from "./components/AdminDashboard/Dashboard";
import { LandingPage } from "./components/LandingPage/LandingPage";
import { useMaintenanceModeQuery } from "./generated/graphql";
import MaintenanceMode from "./components/MaintenanceMode/MaintenanceMode";
import { TasksView } from "./views/TasksView";
import { TaskView } from "./views/TaskView";

interface RoutesProps {
  token: String;
  user: any;
  navigation: React.ReactNode;
  footer: React.ReactNode;
}

const Routes: React.FC<RoutesProps> = ({ token, user, navigation, footer }) => {
  const { loading, data } = useMaintenanceModeQuery();

  if (
    data?.maintenanceMode &&
    user?.roles.includes("Administrator") === false
  ) {
    return <MaintenanceMode />;
  }

  let routes;
  if (token === null || token === "") {
    routes = (
      <>
        <Route exact path="/" render={(props) => <LandingPage />} />
        <Route exact path="/contact-us" render={(props) => <ContactUs />} />
        <Route exact path="/privacy" render={(props) => <Privacy />} />
        <Route
          exact
          path="/terms-and-conditions"
          render={(props) => <TermsAndConditions />}
        />
      </>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path="/" render={(props) => <JobsView />} />
        <Route
          exact
          path="/sign-up"
          render={(props) => <CreateUser test={user} />}
        />
        <Route exact path="/jobs" render={(props) => <JobsView />} />
        <Route
          exact
          path="/jobs/new"
          render={(props) => <NewJobFormContainer />}
        />
        <Route
          exact
          path="/jobs/:id"
          render={(props) => <JobDetailViewContainer />}
        />
        <Route exact path="/contact-us" render={(props) => <ContactUs />} />
        <Route
          exact
          path="/organisation-dashboard"
          render={(props) => (
            <OwnerDashboard
              user={user}
              heading="Organisation owner dashboard"
            />
          )}
        />
        <Route
          exact
          path="/edit-job"
          render={(props) => (
            <EditJobForm state={props.history.location.state} />
          )}
        />
        <Route
          exact
          path="/profile"
          render={(props) => <Profile user={user} />}
        />
        <Route exact path="/tasks" render={(props) => <TasksView />} />
        <Route exact path="/tasks/:id" render={(props) => <TaskView />} />
        <Route
          exact
          path="/join-or-create"
          render={(props) => <JoinOrCreateView />}
        />
        <Route
          exact
          path="/admin_dashboard"
          render={(props) => <Dashboard user={user} />}
        />
        <Route exact path="/privacy" render={(props) => <Privacy />} />
        <Route
          exact
          path="/terms-and-conditions"
          render={(props) => <TermsAndConditions />}
        />
      </Switch>
    );
  }

  return (
    <div>
      <div style={{ minHeight: "79vh" }}>
        {navigation}
        {routes}
      </div>
      {footer}
    </div>
  );
};

export default Routes;
