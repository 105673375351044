import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import grey from "@material-ui/core/colors/grey";

//
const primaryMain = '#1a74ba' // metalic-blue
const primaryLight = "#1b81d0" // metalic-blue(light)
const primaryDark = "#223f82"
const secondaryMain = '#fff' // white
const successMain = '#F5F5F5' //grayish
const textPrimary = '#000000' //black
const textSecondary = '#878080' //gray
const successSecondary = '#4caf50' //green

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryMain,
      light: primaryLight,
      dark:primaryDark
    },
    secondary: {
      main: secondaryMain,
      light:grey[300]
    },
    success:{
      main:successMain,
      dark:successSecondary
    },
    text:{
      secondary:textSecondary
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        "&:focus": {
          outline: "none",
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:focus": {
          border: "none",
          outline: "none",
        },
      },
    },
    MuiToolbar: {
      root: {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    MuiBox: {
      root: {
        display: "flex",
        justifyContent: "center",
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: "2px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    MuiStepper: {
      root: {
        padding: "0px",
        marginTop: "10px",
      },
    },
    MuiTableSortLabel:{
      root:{
        color:`${secondaryMain}!important`
      },
      icon:{
        color:`${secondaryMain}!important`
      }
    },
    MuiFormLabel:{
      root:{
        color:textPrimary
      }
    },
    MuiAccordionSummary:{
      root:{
        '&$expanded': {
          minHeight: 12,
        },
      },
    },
    MuiAccordion:{
      root:{
        '&$expanded': {
          margin:2,
          marginBottom:10
        },
      },
    }

  },
});

theme.typography.h3 = {
  fontSize: "1.25rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};
