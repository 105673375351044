import moment from "moment"
import React from "react";


export const stagesText = {
  stage1: `Please upload all relevant documents needed for the subsequent job states.
    `,
  stage2: `Detailing in progress. Please wait for the detailing process to complete.
    `,
  stage3: `Engineering in progress. Please wait for the engineering process to complete.
    `,
  stage4: `Inspection in progress. Please wait for the inspection process to complete.
    `,
  stage5: `Inspection approval. Please wait for the inspection results to be reviewed and approved.
    `,
  stage6: `The job has finished.
    `,
};

export const devRole = {
  roles: [],
};

export const renderDateAndTime = (utcString: string) => {
  let date: any = moment.utc(utcString).toDate();
  date = moment(date).format("YYYY-MM-DD HH:mm:ss");
  return date;
};

export const ROLES_CONFIG = {
  Administrator: {
    RoleName: "Administrator",
    Description: "Administrator role description",
  },
  Client: {
    RoleName: "Client",
    Description: "Client role description",
  },
  Detailer: {
    RoleName: "Detailer",
    Description: "Detailer role description",
  },
  Engineer: {
    RoleName: "Engineer",
    Description: "Engineer role description",
  },
  Inspector: {
    RoleName: "Inspector",
    Description: "Inspector role description",
  },
  Owner: {
    RoleName: "Owner",
    Description: "Owner role description",
  },
};

export const getTaskStateName = (state: string) => {
  switch (state) {
    case "ASSIGNED":
      return "Outstanding";
    case "IN_PROGRESS":
      return "In Progress";
    case "DONE":
      return "Finished";

    default:
      return "unknown";
  }
};
export const getJobStateName = (state: number) => {
  switch (state) {
    case 0:
      return "Awaiting for documents";
    case 1:
      return "Detailing";
    case 2:
      return "Engineering";
    case 3:
      return "Waiting for inspection";
    case 4:
      return "Waiting for approval";
    case 5:
      return "Finish";

    default:
      return "unknown";
  }
};

export const getJobStateName2 = (state: string) =>{
  switch (state) {
    case "AWAITING_DOCUMENTS":
      return "Awaiting for documents";
    case "DETAILING":
      return "Detailing";
    case "ENGINEERING":
      return "Engineering";
    case "REVIEW_INSPECTION_RESULTS":
      return "Review inspection results";
    case "WAITING_FOR_INSPECTION_RESULTS":
      return "Waiting for inspection results";
    case "FINISHED":
      return "Finished";

    default:
      break;
  }
}


export const jobStatesList = [
  "Awaiting for documents",
  "Detailing",
  "Engineering",
  "Waiting for inspection",
  "Waiting for approval",
  "Finish",
];

export const removeHTMLtags = (body: string) => {
  const regex = /(<([^>]+)>)/gi;
  return body.replace(regex, "");
};

export const SERVICES = [
  {
    title: "Estimating Manager",
    image: "/images/GetInTouch/Estimating",
    text: "EngiFlow’s estimating module is suited to a wide range of users including estimators, quantity surveyors, builders, contractors and subcontractors by integrating quantity take-off with a customised catalogue of prices to ensure a seamless estimating process."
  },
  {
    title: "Detailing Manager",
    image: "/images/GetInTouch/DetailingManager",
    text: "EngiFlow’s detailing module is suited to architects, fabricators, builders and engineering firms involved in the development of Building Information Models (BIM). EngiFlow is able to be utilised with a range of BIM software and tracks non-conformance reports (NCRs) and requests for information (RFIs) to enable the detailing process to be delivered efficiently."
  },
  {
    title: "Engineering Manager",
    image: "/images/GetInTouch/EngineeringManager",
    text: "The EngiFlow system is built for fast turnaround, cost-effective volume housing. Yet the system can also be utilised to design complex, luxury housing as well as government, commercial and industrial style buildings. "
  },
  {
    title: "Inspection Manager",
    image: "/images/GetInTouch/Inspection",
    text: "Compliance is an important part of the building process to ensure that all work meets the relevant design documentation approved for construction. EngiFlow’s inspection module can be utilised for the construction phase, to track compliance inspections in accordance with Australian standards and the approved designs. "
  },
  {
    title: "Buildings",
    image: "/images/GetInTouch/InspectionManager",
    text: `<div>
      <p>
        Online design and construction management made easy.
      </p>
      <br />
      <p>
        Run your jobs remotely with EngiFlow by EngiSeek design and construction management software.
      </p>
      <br />
      <p>
        Do it all in one place, from anywhere estimating,
        design management, inspections and reporting.
      </p>
      <br />
      <p>
        Enjoy greater visibility over your projects, and your profit.
      </p>
    </div>`
  },
  {
    title: "Infrastructure",
    image: "/images/GetInTouch/Infrastructure",
    text: `<div>
      <p>
        EngiFlow is utilised on infrastructure projects to maintain construction project information and process management (managing processes, documents, drawings, building information models, communications, workflows, audit trails, and other project information online - both mobile and web access)
      </p>
      <br />
      <p>
        Construction field management (managing field inspections and issues from mobile devices)
      </p>
      <br />
      <p>
        O&M manuals (developing digital operation and maintenance manuals for post-construction handover to asset owner and dynamic management of asset information)
      </p>
      <br />
      <p>
        BIM data and process management (accessing and managing BIM models at the object level for project management)
      </p>
      <br />
      <p>
        Interface management (identifying and resolving interface issues on projects)
      </p>
    </div>`
  },

]

export const QUOTES = [
  {
    id: 1,
    text: "EngiFlow is an innovative live platform where the user can input asset data in real time, so it can be updated and viewed from anywhere, instantly.",
    title: "Faster"
  },
  {
    id: 2,
    text: "Instantaneous data input provides real time reporting summaries, in any format, so you can spend less time on formatting and more time constructing.",
    title: "Easier"
  },
  {
    id: 3,
    text: "EngiFlow reduces thousands of hours which used to be spent filling out paper forms, saving you time, money, and thousands of pieces of paper in Queensland and nationally every year.",
    title: "Greener"
  }
]

export const dummyUsers = [
  {
    id: "1",
    firstName: "Emir",
    lastName: "kovacevic",
    position: "Software developer",
  },
  {
    id: "2",
    firstName: "Jan",
    lastName: "Raddatz",
    position: "Software developer",
  },
  {
    id: "3",
    firstName: "Average",
    lastName: "Joe",
    position: "Bouncer",
  },
];
