import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react'
import { stagesText } from '../../utils/helpers'
import InstructionsContainer from '../InstructionsContainer';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "20px",
    },
    button: {
      marginRight: theme.spacing(1),
      marginTop:"15px"
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    instructionsContainer: {
      backgroundColor: "#e2f4ff",
      maxWidth:'600px',
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "5px",
      padding: "10px",
      fontSize: "14px",
      color: "black",
    },
    toDoBottomContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    mainContainer:{
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center'
    },
  })
);

const Client = () => {
    const classes = useStyles();
    
    return (
        <div className={classes.mainContainer}>
            <InstructionsContainer instructionsText={stagesText.stage2} />
        </div>
    )
}

export default Client
