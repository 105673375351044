import { JobListQuery } from "../../generated/graphql";
import { ActionTypes } from "./types";
import { Dispatch } from "redux";

//JOB ACTION NAMES
export interface FetchJobsAction {
  type: ActionTypes.fetchJobs;
  payload: JobListQuery;
}
export interface AddNewJobAction {
  type: ActionTypes.addJob;
}
export interface FilterJobsAction {
  type: ActionTypes.filterJobs;
  payload: {
    jobs: [];
    totalJobs: number;
  };
}
export interface ChangePageAction {
  type: ActionTypes.changePage;
  payload: number;
}
export interface ChangeRowsPerPageAction {
  type: ActionTypes.changeRowsPerPage;
  payload: number;
}
export interface SaveFilterStateAction {
  type: ActionTypes.saveFilterStateAction;
  payload: any;
}
export interface ResetPageAction {
  type: ActionTypes.resetPage;
  payload: number;
}
export interface DoYouNeedRefetchAction {
  type: ActionTypes.doYouNeedRefetch;
  payload: any;
}
export interface ErrorHandlerAction{
  type:ActionTypes.errorHandler;
  payload?: string;
}
export interface ClickOnJobHandlerAction{
  type:ActionTypes.clickOnJob;
  payload:any;
}
export interface AdvanceJobHandlerAction{
  type:ActionTypes.advanceJob;
  payload:number;
}
export interface RejectJobHandlerAction{
  type:ActionTypes.rejectJob;
  payload:any;
}
export interface AddCurrentJobAction{
  type:ActionTypes.addCurrentJob;
  payload:any;
}
export interface SaveJobListColumnSettings{
  type:ActionTypes.saveJobListColumnSettings;
  payload:any;
}



export const addCurentJob = (job:any) =>{
  try {
    return async (dispatch: Dispatch) => {
      dispatch({type: ActionTypes.addCurrentJob, payload:job})
    };
  } catch (e) {
    console.log(e);
  }
}

export const advanceJobRedux = (job:any) =>{
  try {
    return async (dispatch: Dispatch) => {
      dispatch({ type: ActionTypes.advanceJob, payload: job });
    };
  } catch (e) {
    console.log(e);
  }
}

export const rejectJobRedux = (job:any) =>{
  try {
    return async (dispatch: Dispatch) => {
      dispatch({ type: ActionTypes.rejectJob, payload: job });
    };
  } catch (e) {
    console.log(e);
  }
}

export const clickOnJob = (job:any) =>{
  try {
    return async (dispatch: Dispatch) => {
      dispatch({ type: ActionTypes.clickOnJob, payload: job });
    };
  } catch (e) {
    console.log(e);
  }
}

export const getAllJobs = (jobs: []) => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch({ type: ActionTypes.fetchJobs, payload: jobs });
    };
  } catch (e) {
    console.log(e);
  }
};

export const getJobById = (id: number) => {
  try {
    return async (dispatch: Dispatch) => {
      //Do not use useHooks inside actions at all...
      //use it in component then pass pure data (job)
      // const { data, error, loading } = await useJobByIdQuery({
      //   variables: { jobId: 1 },
      // });
      // console.log("getJobById... ", data);
    };
  } catch (e) {
    console.log(e);
  }
};

export const filterJobs = (jobs: any, totalJobs: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionTypes.filterJobs, payload: { jobs, totalJobs } });
    } catch (e) {
      console.log(e);
    }
  };
};

export const changePage = (page: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionTypes.changePage, payload: page });
    } catch (e) {
      console.log(e);
    }
  };
};

export const changeRowsPerPage = (rowsPerPage: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ActionTypes.changeRowsPerPage, payload: rowsPerPage });
    } catch (e) {
      console.log(e);
    }
  };
};

export const saveFilterStateAction = (formState: any) => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch({ type: ActionTypes.saveFilterStateAction, payload: formState });
    };
  } catch (e) {
    console.log(e);
  }
};

export const resetPage = () => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch({ type: ActionTypes.resetPage, payload: 0 });
    };
  } catch (e) {
    console.log(e);
  }
};

export const doYouNeedRefetch = () => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch({ type: ActionTypes.doYouNeedRefetch, payload: true });
    };
  } catch (e) {
    console.log(e);
  }
};

export const errorHandler = (errorMessage: string) =>{
  try {
    return async (dispatch: Dispatch) =>{
      dispatch({type:ActionTypes.errorHandler, payload:errorMessage})
    }
  } catch (e) {
    console.log(e)
  }
}

export const saveJobListColumnSettings = (settings: string[]) =>{
  try {
    return async (dispatch: Dispatch) =>{
      dispatch({type:ActionTypes.saveJobListColumnSettings, payload:settings})
    }
  } catch (e) {
    console.log(e)
  }
}