import React, { useState, useCallback, useEffect } from "react";
import JobList from "../components/JobList/JobList";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { NewJobForm } from "../components/NewJobForm/NewJobForm";
import FilterJobsComponent from "../components/FilterJobsComponent/FilterJobsComponent";
import { createStyles, Grid, Hidden, makeStyles, Theme } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { READ_CURRENT_USER } from "../components/JobList/query";
import { RootState } from "../store";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    JobsViewContainer: {
      backgroundColor: theme.palette.success.main,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '3px',
      marginTop: theme.spacing(15),
      marginBottom: '50px',
      padding: '10px',
      paddingTop: '20px',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    JobListAndFilterContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: '10px',
      maxWidth: '100%',
    },
    containerTitleText: {
      fontFamily: "-webkit-pictograph"
    }
  })
);

export const JobsView: React.FC = () => {
  const classes = useStyles();
  const [containerTitle, setContainerTitle] = useState<string>(
    "List of available jobs"
  );

  const { currentJob }: any = useSelector((state: RootState) => state.jobs);

  const history = useHistory();
  const currentUser: any = useSelector((state: RootState) => state.user)

  const [readUser, readUserResponse] = useLazyQuery(READ_CURRENT_USER);

  useEffect(() => {
    if (readUserResponse.data?.readUser && currentUser.token) {
      if (readUserResponse.data?.readUser?.organisation) {
        history.push("/join-or-create")
      }
    }
  }, [readUserResponse]);

  useEffect(() => {
    readUser();
  }, [])



  useEffect(() => {
    if (currentUser?.user?.roles.length === 0) {
      history.push("/sign-up");
    }
  }, [currentUser])


  return (
    <Container style={{ maxWidth: "1600px" }} maxWidth="lg">
      <div className={classes.JobsViewContainer}>
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          align="center"
          className={classes.containerTitleText}
        >
          {containerTitle}
          <Divider />
        </Typography>

        <div className={classes.JobListAndFilterContainer}>
          <Grid
            container
            spacing={0}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={6} md={5} lg={3}>
              <FilterJobsComponent test="test" />
            </Grid>
            <Grid item xs={12} md={12} lg={9}>
              <JobList />
            </Grid>
          </Grid>
        </div>
      </div>
    </Container>
  );
};
