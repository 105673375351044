import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    marginTop:theme.spacing(9),

  },
  image: {
    backgroundImage:
      "url(https://images.pexels.com/photos/3862365/pexels-photo-3862365.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "5%",
    maxWidth:"400px",
    color: theme.palette.primary.main,
  },
  rightSideContainer: {
    backgroundColor: theme.palette.success.main,
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  contactInfoRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
  },
}));

export const ContactUs = () => {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        className={classes.rightSideContainer}
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
      >
        <Paper className={classes.paper}>
          <div className={classes.contactInfoRow}>
            <PhoneIcon />
            <Typography component="h6">+61 7 5536 5905</Typography>
          </div>
          <div className={classes.contactInfoRow}>
            <EmailIcon />
            <Typography component="h6">engiflow@engiseek.com</Typography>
          </div>
          <div className={classes.contactInfoRow}>
            <HomeIcon />
            <Typography component="h6">
              Suite 3, 39-41 Nerang Street<br/> Nerang QLD 4211
            </Typography>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
