import { makeStyles, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    padding:"20px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent:'center',
    alignItems:'center',

  },
  link:{"color":theme.palette.primary.main,"padding":"0px 3px 0 3px",    "textDecoration": "underline"},
  borderRight:{
    borderRight: "2px solid white"
  },
  flex:{
    display:"flex"
  },
  divider:{
    height: 15,
    background: theme.palette.primary.main,
    width: 2
  },
  alignCenter:{"alignItems":"center"}
}));

function Copyright() {
  const classes = useStyles();
    return (
      <Typography variant="body2" color="primary" align="center" className={classes.flex}>
        {' © '}
        {new Date().getFullYear()}
      
        {' EngiSeek Pty Ltd. All rights reserved.'}
        <div className={`${classes.alignCenter} ${classes.flex}`}>
          <Link className={`${classes.link} `} to="/terms-and-conditions">
            Terms of Use
          </Link>
          <div className={classes.divider}>

          </div>
          <Link className={classes.link} to="/privacy">
            Privacy Policy
          </Link>
        </div>
      </Typography>
    );
  }

const Footer = () => {
  const classes = useStyles();

  return <div className={classes.footerContainer}>
      <Copyright />
  </div>;
};

export default Footer;
