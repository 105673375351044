import { useLazyQuery } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { TaskTypeEnum, useCreateTaskMutation } from "../../generated/graphql";
import AlertMessage from "../AlertMessage/AlertMessage";
import {
  QUERY_JOB_BY_ID,
  READ_CURRENT_ORGANISATION_USERS,
} from "../JobList/query";
import { useStyles } from "./taskStyles";
import { errorHandler } from "../../store/actions";

interface IAssignTasksTable {
  job: any;
}

enum ERegisteredFields {
  detailer = "detailer",
  detailerDueDate = "Due Date For Detailer",
  engineer = "engineer",
  engineerDueDate = "Due Date For Engineer",
  inspector = "inspector",
  inspectorDueDate = "Due Date For Inspector",
}

const AssignTasksTable: React.FC<IAssignTasksTable> = ({ job }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [availableUsers, setAvailableUsers] = useState([]);
  const [createTask, createTaskResponse] = useCreateTaskMutation();
  const [open1, setOpen1] = useState<boolean>(false);
  const validationSchema = yup.object({});

  const onChangeDateHandler = (fieldName: string, value: any) => {
    let date = JSON.stringify(value);
    date = date.slice(1, 11);
    formik.setFieldValue(fieldName, date);
  };

  const formik = useFormik({
    initialValues: {
      detailer: 0,
      detailerDueDate: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
      engineer: 0,
      engineerDueDate: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
      inspector: 0,
      inspectorDueDate: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.detailer !== 0) {
          await createTask({
            variables: {
              task: {
                subject: "Detailing requested",
                description: "Detailing requested for job",
                assignedToId: values.detailer,
                jobId: job.id ? job.id : null,
                dueDate: values.detailerDueDate
              },
              taskType: TaskTypeEnum.Detailing,
            },
          });
        }
        if (values.engineer !== 0) {
          await createTask({
            variables: {
              task: {
                subject: "Engineering requested",
                description: "Engineering requested for job",
                assignedToId: values.engineer,
                jobId: job.id ? job.id : null,
                dueDate: values.engineerDueDate
              },
              taskType: TaskTypeEnum.Engineering,
            },
          });
        }
        if (values.inspector !== 0) {
          await createTask({
            variables: {
              task: {
                subject: "Inspection requested",
                description: "Inspection requested for job",
                assignedToId: values.inspector,
                jobId: job.id ? job.id : null,
                dueDate: values.inspectorDueDate
              },
              taskType: TaskTypeEnum.Assign,
            },
          });
        }
        setOpen1(true);
      } catch (e) {
        dispatch(errorHandler("Assigning tasks failed " + e));
      }
    },
  });

  const [readCurrentOrgUsers, readCurrentOrgUsersResp] = useLazyQuery(
    READ_CURRENT_ORGANISATION_USERS
  );

  useEffect(() => {
    readCurrentOrgUsers();
  }, []);

  useEffect(() => {
    if (readCurrentOrgUsersResp.data) {
      setAvailableUsers(
        readCurrentOrgUsersResp.data.readCurrentOrganisationUsers
      );
      // console.log(readCurrentOrgUsersResp.data.readCurrentOrganisationUsers);
    }
  }, [readCurrentOrgUsersResp]);

  return (
    <div style={{ marginTop: "10px" }}>
      <Typography variant="h6" component="h6" style={{ marginBottom: "15px" }}>
        Assign Tasks
      </Typography>
      {/* Detailer */}
      {job.detailingRequested && (
        <div className={classes.assignTasksRow}>
          <div>
            <InputLabel>Detailer</InputLabel>
            <Select
              className={classes.formRow}
              style={{
                minWidth: "200px",
                marginBottom: "1px",
                marginRight: "5px",
              }}
              id="detailer"
              name="detailer"
              label="Detailer"
              value={formik.values.detailer}
              onChange={formik.handleChange}
              error={formik.touched.detailer && Boolean(formik.errors.detailer)}
            >
              {availableUsers.map((user: any) => {
                return (
                  <MenuItem
                    value={user.id}
                  >{`${user.firstName} ${user.lastName} | ${user.position}`}</MenuItem>
                );
              })}
            </Select>{" "}
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
              <KeyboardDatePicker
                margin="normal"
                id="detailerDueDate"
                label={ERegisteredFields.detailerDueDate}
                format="yyyy-MM-dd"
                value={formik.values.detailerDueDate}
                onChange={(value) =>
                  onChangeDateHandler("detailerDueDate", value)
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
      )}

      {job.engineeringRequested && (
        <div className={classes.assignTasksRow}>
          <div>
          <InputLabel>Engineer</InputLabel>
          <Select
            className={classes.formRow}
            style={{
              minWidth: "200px",
              marginBottom: "1px",
              marginRight: "5px",
            }}
            id="engineer"
            name="engineer"
            label="Engineer"
            value={formik.values.engineer}
            onChange={formik.handleChange}
            error={formik.touched.engineer && Boolean(formik.errors.engineer)}
          >
            {availableUsers.map((user: any) => {
              return (
                <MenuItem
                  value={user.id}
                >{`${user.firstName} ${user.lastName} | ${user.position}`}</MenuItem>
              );
            })}
          </Select>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
              <KeyboardDatePicker
                margin="normal"
                id="engineerDueDate"
                label={ERegisteredFields.engineerDueDate}
                format="yyyy-MM-dd"
                value={formik.values.engineerDueDate}
                onChange={(value) =>
                  onChangeDateHandler("engineerDueDate", value)
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
      )}

      {/* Inspector */}
      {job.inspectionRequested && (
        <div className={classes.assignTasksRow}>
          <div>
          <InputLabel>Inspector</InputLabel>
          <Select
            className={classes.formRow}
            style={{
              minWidth: "200px",
              marginBottom: "1px",
              marginRight: "5px",
            }}
            id="inspector"
            name="inspector"
            label="Inspector"
            value={formik.values.inspector}
            onChange={formik.handleChange}
            error={formik.touched.inspector && Boolean(formik.errors.inspector)}
          >
            {availableUsers.map((user: any) => {
              return (
                <MenuItem
                  value={user.id}
                >{`${user.firstName} ${user.lastName} | ${user.position}`}</MenuItem>
              );
            })}
          </Select>
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
              <KeyboardDatePicker
                margin="normal"
                id="inspectorDueDate"
                label={ERegisteredFields.inspectorDueDate}
                format="yyyy-MM-dd"
                value={formik.values.inspectorDueDate}
                onChange={(value) =>
                  onChangeDateHandler("inspectorDueDate", value)
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
      )}
      <div className={classes.formRow}>
        <Button
          disabled={createTaskResponse.loading}
          onClick={formik.submitForm}
          size="small"
          variant="contained"
          color="primary"
        >
          Assign
        </Button>
        {open1 && (
          <AlertMessage
            alertWidth="500px"
            open={open1}
            setOpen={setOpen1}
            color="info"
            message="Tasks have been assigned successfully!"
          />
        )}
      </div>
    </div>
  );
};

export default AssignTasksTable;
