import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      marginTop:"5px",
      marginBottom:"5px",
    },
  }),
);

type Severity = "info" | "warning" | undefined;

interface AlertMessageProps{
    message:string;
    color:Severity;
    alertWidth:string;
    open:boolean;
    setOpen:any;
}

const AlertMessage:React.FC<AlertMessageProps> = ({message,open,setOpen,color,alertWidth}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{width:alertWidth}}>
      <Collapse in={open} >
        <Alert
          color={color}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Collapse>
    </div>
  );
}

export default AlertMessage;