import React, { useEffect, useState } from "react";
import TableHead from "@material-ui/core/TableHead";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MaxWidthDialog from "./MaxWidthDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "auto",
    },
    MainMemberListContainer: {
      backgroundColor: "aqua",
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: "100%",
      maxHeight: 500,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    userRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      minWidth: "650px",
    },
    table: {
      minWidth: 700,
    },
    editIcon: {
      fontSize: "18px",
      color: theme.palette.primary.main,
      "&:hover": {
        cursor: "pointer",
      },
    },
    deleteIcon: {
      fontSize: "18px",
      color: "#c50c0c",
      "&:hover": {
        cursor: "pointer",
      },
    },
    heading: {
      width: "100%",
      textAlign: "center",
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
    tableCell: {
      padding: "10px",
    },
  })
);

interface MemberListProps {
  users: {}[];
  title: string;
}

const MemberList: React.FC<MemberListProps> = ({ users, title }) => {
  const classes = useStyles();
  const [rows, setRows] = useState<any>([]);
  const [clickedUser, setClickedUser] = useState<any>(null);

  useEffect(() => {
    let newRows:any[] = [];

    if(users){
      newRows = users.map((user) => {
        return user;
      });
    }
    

    setRows(newRows);

    console.log("USERS: ", users);
  }, [users]);


  return (
    <TableContainer component={Paper}>
      <Typography className={classes.heading} component="h6" variant="body2">
        {title}
      </Typography>
      <MaxWidthDialog setClickedUser={setClickedUser} clickedUser={clickedUser} />
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell} align="left">
              First name
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              Last Name
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              Position
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              Mobile Number
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              RPEQ License Number
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              RPE (VIC) License Number
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              License Area
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              License class
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              Change Role
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              Remove
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow key={row.timeStamp}>
              <TableCell className={classes.tableCell} align="left">
                {row.firstName}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.lastName}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.position}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.mobileNumber}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.licenseNumber}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.victoriaLicenseNumber}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.licenseArea}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.licenseClass}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <EditIcon
                  onClick={() => {
                    setClickedUser(row)
                  }}
                  className={classes.editIcon}
                />
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                <DeleteForeverIcon
                onClick={() => {
                  setClickedUser(row)
                }}
                className={classes.deleteIcon} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MemberList;
