import React from "react";
import Grid from "@material-ui/core/Grid";
import "date-fns";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  fromAndToDate: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
  },
});

interface FromAndToDateProps {
  idFrom: string;
  idTo: string;
  labelFrom: string;
  labelTo: string;
  valueFrom: string;
  valueTo: string;
  onChangeDateHandler: any;
}

export const FromAndToDate: React.FC<FromAndToDateProps> = ({
  idFrom,
  idTo,
  labelFrom,
  labelTo,
  valueFrom,
  valueTo,
  onChangeDateHandler,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.fromAndToDate}>
      {/*Date From*/}
      <Grid container justify="space-around">
        <KeyboardDatePicker
          margin="normal"
          id={idFrom}
          label={labelFrom}
          format="yyyy-MM-dd"
          value={valueFrom}
          onChange={(value) => onChangeDateHandler(idFrom, value)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>

      {/*Date To*/}
      <Grid container justify="space-around">
        <KeyboardDatePicker
          margin="normal"
          id={idTo}
          label={labelTo}
          format="yyyy-MM-dd"
          value={valueTo}
          onChange={(value) => onChangeDateHandler(idTo, value)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
    </div>
  );
};
