import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { READ_USERS_WITHOUT_ROLE } from '../../JobList/query';
import MemberList from '../MemberList';


const NewUsers = () => {
    const [users, setUsers] = useState([])

    const [readUsersWithoutRoles, readUsersWithoutRolesResponse] = useLazyQuery(
        READ_USERS_WITHOUT_ROLE
      );
    
    useEffect(() => {
        readUsersWithoutRoles()
    }, [])

    useEffect(()=>{
        console.log('readUsersWithoutRolesResponse: ',readUsersWithoutRolesResponse)
        if(readUsersWithoutRolesResponse.data){
            setUsers(readUsersWithoutRolesResponse.data.readUsersWithoutRole)
        }
    },[readUsersWithoutRolesResponse])

    if(readUsersWithoutRolesResponse.loading){
        return <p>Loading...</p>
    }

    return (
        <div>
           <MemberList title="Users without roles" users={users}/>
        </div>
    )
}

export default NewUsers