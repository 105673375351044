import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  LinearProgress,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import { useUpdateOrganisationMutation } from "../../generated/graphql";
import { useLazyQuery } from "@apollo/client";
import { READ_CURRENT_ORGANISATION } from "../JobList/query";
import Alert from "@material-ui/lab/Alert";
import { doYouNeedRefetch, errorHandler } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    userSecondaryInfo: {
      padding: "20px",
      margin: "1px",
      minHeight: "210px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "50%",
    },
    floatingLabelFocusStyle: {
      color: theme.palette.text.primary,
    },
    editInfoForm: {
      display: "flex",
      flexDirection: "column",
    },
    editIconContainer: {
      width: "95%",
      display: "flex",
      justifyContent: "flex-end",
    },
    editIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    alertClass: {
      color: "#fff",
      backgroundColor: "green",
      marginTop: "5px",
      padding: "0px 10px",
      width: "100%",
    },
    linearIndeterminate:{
      marginTop:"10px",
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    loadingSpinner: {
      width: "10px",
    },
    spinnerContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

interface OrganisationMainInfoProps {
  user: any;
}

const OrganisationMainInfo: React.FC<OrganisationMainInfoProps> = ({
  user,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [toggleEdit, setToggleEdit] = useState(false);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const currentState: any = useSelector((state: RootState) => state.jobs);

  const validationSchema = yup.object({
    organisationName: yup.string().defined(),
    addressLine1: yup.string().defined(),
    addressLine2: yup.string(),
    postcode: yup.string().defined(),
    suburb: yup.string().defined(),
    country: yup.string().defined(),
    province: yup.string().defined(),
  });

  enum filterField {
    organisationName = "Organisation Name",
    addressLine1 = "Address line 1",
    addressLine2 = "Address line 2",
    postcode = "Post code",
    suburb = "Suburb",
    country = "Country",
    province = "State/Province",
  }

  const formik = useFormik({
    initialValues: {
      organisationName: "",
      addressLine1: "",
      addressLine2: "",
      postcode: "",
      suburb: "",
      country: "",
      province: "",
      id: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));

      try {
        await updateOrganisationMutation();
        dispatch(doYouNeedRefetch());
        setShowAlert(true);
      } catch (error) {
        dispatch(
          errorHandler(`${error.message}, check your internet connection`)
        );
      }
     
    },
  });

  // let getCurrentOrganisation = useReadCurrentOrganisationQuery();
  const [getCurrentOrganisation, getCurrentOrganisationResponse] = useLazyQuery(
    READ_CURRENT_ORGANISATION
  );

  useEffect(() => {
    getCurrentOrganisation();
  }, []);

  useEffect(() => {
    if (currentState.doYouNeedRefetch) {
      if (getCurrentOrganisationResponse.refetch) {
        getCurrentOrganisationResponse.refetch!(
          getCurrentOrganisationResponse.data
        );
        dispatch(doYouNeedRefetch());
      }
    }
  }, [
    currentState,
    getCurrentOrganisationResponse.refetch,
    getCurrentOrganisationResponse.data,
    dispatch,
  ]);

  //Close alert on change
  useEffect(()=>{
    setShowAlert(false);
  },[formik.values])

  const renderOrganisationInfo = () => {
    if (getCurrentOrganisationResponse.data?.readCurrentOrganisation) {
      const organisationFromDb =
        getCurrentOrganisationResponse.data.readCurrentOrganisation;
      if (organisationFromDb.address) {
        return (
          <div>
            <Typography component="h5" variant="h6">
              {organisationFromDb.name}
              <br />
            </Typography>
            <Typography component="h5" variant="body2">
              Address:
              <br />
              {organisationFromDb.address.addressLine2}{" "}
              {organisationFromDb.address.addressLine1},<br />
              {organisationFromDb.address.province}{" "}
              {organisationFromDb.address.postcode}
              <br />
              {organisationFromDb.address.country}
            </Typography>
            <div className={classes.editIconContainer}>
              <EditIcon
                color="primary"
                className={classes.editIcon}
                onClick={() => setToggleEdit((prev) => !prev)}
              />
            </div>
          </div>
        );
      }
    }
  };

  useEffect(() => {
    // console.log(getCurrentOrganisation);
    if (getCurrentOrganisationResponse.data?.readCurrentOrganisation) {
      const organisationFromDb =
        getCurrentOrganisationResponse.data.readCurrentOrganisation;
      if (organisationFromDb.address) {
        formik.setFieldValue("organisationName", organisationFromDb.name);
        formik.setFieldValue(
          "addressLine1",
          organisationFromDb.address.addressLine1
        );
        formik.setFieldValue(
          "addressLine2",
          organisationFromDb.address.addressLine2
        );
        formik.setFieldValue("postcode", organisationFromDb.address.postcode);
        formik.setFieldValue("suburb", organisationFromDb.address.suburb);
        formik.setFieldValue("country", organisationFromDb.address.country);
        formik.setFieldValue("province", organisationFromDb.address.province);
        formik.setFieldValue("id", organisationFromDb.id);
      }
    }
  }, [getCurrentOrganisationResponse.data]);

  const [
    updateOrganisationMutation,
    updateOrganisationResponse,
  ] = useUpdateOrganisationMutation({
    variables: {
      organisation: {
        address: {
          addressLine1: formik.values.addressLine1,
          addressLine2: formik.values.addressLine2,
          postcode: formik.values.postcode,
          suburb: formik.values.suburb,
          country: formik.values.country,
          province: formik.values.province,
        },
        name: formik.values.organisationName,
      },
      organisationId: formik.values.id,
    },
  });

  if (user.nickname === undefined) {
    return <></>;
  }

  if(getCurrentOrganisationResponse.loading || getCurrentOrganisationResponse.error){
    return (
      <Paper className={classes.userSecondaryInfo}>
      <div className={classes.spinnerContainer}>
        <CircularProgress className={classes.loadingSpinner} size={30} />
        Loading organisation info...
      </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.userSecondaryInfo}>
      {toggleEdit && (
        <form onSubmit={formik.handleSubmit} className={classes.editInfoForm}>
          <div className={classes.editIconContainer}>
            <CloseIcon
              color="primary"
              className={classes.editIcon}
              onClick={() => {
                setShowAlert(false);
                setToggleEdit((prev) => !prev);
              }}
            />
          </div>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="organisationName"
              name="organisationName"
              label={filterField.organisationName}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.organisationName}
              onChange={formik.handleChange}
              error={
                formik.touched.organisationName &&
                Boolean(formik.errors.organisationName)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="addressLine1"
              name="addressLine1"
              label={filterField.addressLine1}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.addressLine1}
              onChange={formik.handleChange}
              error={
                formik.touched.addressLine1 &&
                Boolean(formik.errors.addressLine1)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="addressLine2"
              name="addressLine2"
              label={filterField.addressLine2}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.addressLine2}
              onChange={formik.handleChange}
              error={
                formik.touched.addressLine2 &&
                Boolean(formik.errors.addressLine2)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="postcode"
              name="postcode"
              label={filterField.postcode}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.postcode}
              onChange={formik.handleChange}
              error={formik.touched.postcode && Boolean(formik.errors.postcode)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="suburb"
              name="suburb"
              label={filterField.suburb}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.suburb}
              onChange={formik.handleChange}
              error={formik.touched.suburb && Boolean(formik.errors.suburb)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="country"
              name="country"
              label={filterField.country}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.country}
              onChange={formik.handleChange}
              error={formik.touched.country && Boolean(formik.errors.country)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="province"
              name="province"
              label={filterField.province}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.province}
              onChange={formik.handleChange}
              error={formik.touched.province && Boolean(formik.errors.province)}
            />
          </FormControl>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => formik.submitForm()}
          >
            Update
          </Button>
          {updateOrganisationResponse.loading  && 
                <div className={classes.linearIndeterminate}>
                  <LinearProgress />
                </div>
              }
          {showAlert && (
            <Alert
              className={classes.alertClass}
              onClose={() => {
                setShowAlert(false);
              }}
            >
              Organisation info updated!
            </Alert>
          )}
        </form>
      )}

      {!toggleEdit && renderOrganisationInfo()}
    </Paper>
  );
};

export default OrganisationMainInfo;
