import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button, Container, Dialog, DialogContent, DialogContentText, MobileStepper, useTheme, withStyles } from "@material-ui/core";
import "./LandingPage.css";
import GetInTouch from "./GetInTouch";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { grey } from "@material-ui/core/colors";
import QuoteForm from "./QuoteForm";
import { SERVICES, QUOTES } from "../../utils/helpers";
import { useLocation } from "react-router";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.secondary.main,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    welcomeTextContainer: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: "#f9f0f0",
      backgroundColor: "#080B4D",
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      fontFamily: "monospace",
      fontSize: "30px",
      minHeight: "240px",
      justifyContent: "space-around",
      marginBottom: "20px",
      boxShadow:
        "0px 3px 5px 3px rgb(245 245 245 / 25%), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
      borderRadius: "3px",
    },
    welcomeText: {
      fontSize: "18px",
      letterSpacing: "1px",
      fontFamily: "sans-serif",
      color: "#eaeaea",
    },
    paperWithImage: {
      // padding: "2%",
      textAlign: "center",
      color: theme.palette.secondary.main,
      backgroundImage:
        "url(/images/hero.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],

      backgroundAttachment: "fixed",
      backgroundPosition: "center",
      boxShadow: "inset 0 0 0 2000px rgb(26 116 186 / 15%)",

      backgroundSize: "cover",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "0px",
    },
    landingPageText: {
      maxWidth: "50%",
      textShadow: "1px 1px black",
      color: theme.palette.secondary.main,
    },
    cardsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
    },
    contentContainer: {
      backgroundColor: "rgb(236,236 ,236 ,0.95)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minWidth: "50vw",
      padding: "30px",
      borderRadius: "10px",
      marginBottom: "10%",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    linkClass: {
      color: "black",
    },

    signUpButton: {
      border: "2px solid #fff",
      borderRadius: "5em",
      color: theme.palette.secondary.main,
      transition: "background .2s ease-in",
      "&:hover": {
        backgroundColor: "#fff",
        color: theme.palette.primary.main,
      },
    },
    heroContent: {
      padding: theme.spacing(3, 0, 2),
      backgroundColor: "rgba(176, 182, 198, 0.32)",

    },
    heroSubtitle: {
      animation: ".8s ease-out 0.2s 1 $slideup",
      fontWeight: "bold"
    },
    heroMainText: {
      animation: ".5s ease-out 0.2s 1 $slideup",
      fontWeight: "bold",
      marginBottom: "25px"
    },
    "@keyframes slideup": {
      "0%": {
        transform: "translateY(100%)"
      },
      "100%": {
        transform: "translateY(0%)"
      }
    },
    aboutContent: {
      backgroundColor: theme.palette.primary.main,
      padding: "50px 0px 30px",
      color: theme.palette.secondary.main,
      textAlign: "center"
    },
    serviceContent: {

      backgroundColor: grey[300],
      padding: theme.spacing(8, 0),
      color: theme.palette.primary.dark,
      textAlign: "center"
    },
    serviceText: {
      fontWeight: "lighter", color: theme.palette.primary.main, fontSize: 18
    },
    textBold: {
      fontWeight: "bold"
    },
    getInTouch: {
      maxWidth: 1800
    },
    quoteContainer: {
      "display": "flex", "flexFlow": "column", "alignItems": "center", "marginTop": "100px", "marginBottom": "70px"
    },
    quoteTitle: {
      "color": theme.palette.secondary.main, "marginBottom": 10, "fontSize": "4em"
    },
    quoteText: {
      "color":  theme.palette.secondary.main, "maxWidth": "60%", "fontSize": "1.3em", "textAlign": "center", "marginBottom": "40px"
    },
    quoteRightButton: { "position": "absolute", "top": "50%", "right": "0", "color": theme.palette.secondary.main },
    quoteLeftButton: { "position": "absolute", "top": "50%", "left": "0", "color": theme.palette.secondary.main },
    quoteArrow: { "width": "50px", "height": "50px" },
    quotesContainer: { background: theme.palette.primary.dark, position: "relative" },

    contactUsContainer: { background: theme.palette.primary.main, color: "#fff", padding: "0" },
    full: { "width": "100%", "height": "100%" },
    textNormal: { fontWeight: "normal" },
    hidden: { overflow: "hidden" }

  })
);

const AboutButton = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    fontSize: 20,
    padding: "15px 25px",
    margin: "30px 0",
    boxShadow: "none",
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {

      backgroundColor: theme.palette.primary.dark + "a8",
      boxShadow: "none",
    },
  },
}))(Button);



const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const LandingPage: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const location = useLocation();

  useEffect(() => {
    const path = window.location.href;
    const id = path.slice(path.indexOf("#") + 1);
    if (id) {
      scrollTo(id);
    }
  }, [location])

  const scrollTo = (id: string) => {
    let elem: HTMLElement | null = document.getElementById(id)

    if (elem) {
      window.scrollTo({ top: elem.offsetTop - 108, left: 0, behavior: "smooth" })
    }
  }


  const [serviceModalContent, setServiceModalContent] = React.useState<React.ReactNode | false>(false);

  const handleNext = () => {
    if (activeStep === QUOTES.length - 1) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

  };

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(QUOTES.length - 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleCloseServiceDialog = () => {
    setServiceModalContent(false);
  }

  const scrollToQuote = () => {
    scrollTo("request-quote");
  }



  return (
    <div className={classes.root}>
      <Grid container spacing={0} className={classes.paperWithImage} id="home">
        <Container maxWidth={false} component="div" className={classes.heroContent}>
          <Container maxWidth="md" component="div" >
            <div className={classes.hidden}>
              <Typography component="h1" variant="h1" align="center" className={classes.heroMainText}>
                Go with the EngiFlow
              </Typography>
            </div>
            <div className={classes.hidden}>
              <Typography variant="h4" align="center" component="h4" className={classes.heroSubtitle}>
                The ultimate tool for improving your construction workflow
              </Typography>
            </div>
          </Container>
        </Container>
      </Grid>
      <Container maxWidth={false} component="div" className={classes.aboutContent} id="about-us">
        <Container maxWidth="md" component="div" >
          <Typography component="h3" variant="h3" align="center" gutterBottom className={classes.textBold}>
            About Us
          </Typography>
          <Typography variant="h5" align="center" component="p" style={{ margin: "50px 0" }}>
            EngiFlow is a product developed by EngiSeek which takes all the hassle out of complex workflow management for projects in the Architecture, Engineering, Construction and manufacturing industries.           </Typography>
          <Typography variant="h6" align="center" component="p" style={{ fontWeight: "normal", marginBottom: "50px" }} >
            Designed by engineers for engineers, EngiFlow means that reviewing, saving and approving building forms from the office or on site has never been easier. Keep your clients in the loop, manage your project information and just go with the EngiFlow.          </Typography>
          <Typography variant="h6" align="center" component="p" style={{ fontWeight: "normal" }} >
            EngiSeek Pty Ltd is an Australian owned company based on the Gold Coast, Queensland, Australia.
          </Typography>
          <Typography variant="h6" align="center" component="p" style={{ fontWeight: "normal" }} >
            It was founded in 2018 by engineer Jason Ryan.
          </Typography>

          <AboutButton variant="contained" onClick={scrollToQuote} >
            Request a Quote
          </AboutButton>
        </Container>
      </Container>
      <Container maxWidth={false} component="div" className={classes.serviceContent} id="services">
        <Container maxWidth="md" component="div" >
          <Typography component="h2" variant="h2" align="center" gutterBottom className={classes.textBold}>
            Services
          </Typography>
          <Typography variant="body1" align="center" component="p" className={classes.serviceText} >
            EngiFlow is made of modules which are designed to fit a variety of project phases and roles.
          </Typography>
          <Typography variant="body1" align="center" component="p" className={classes.serviceText} >
            Whatever you need, EngiFlow can help make it easier.
          </Typography>

        </Container>
      </Container>
      <Container maxWidth={false} component="div" className={classes.getInTouch} >
        <Grid container >
          {
            SERVICES.map(item => {
              return <GetInTouch key={`service_component_${item.title}`} title={item.title} image={item.image} onClick={setServiceModalContent}>
                {item.text}
              </GetInTouch>
            })
          }
          <Dialog
            maxWidth='md'
            open={serviceModalContent !== false}
            onClose={handleCloseServiceDialog}
          >
            <DialogContent style={{ background: theme.palette.primary.main, textAlign: "center", padding: 30 }}>
              <DialogContentText id="alert-dialog-description" style={{ color: theme.palette.primary.contrastText }}>
                {
                  serviceModalContent
                }
              </DialogContentText>
            </DialogContent>

          </Dialog>

        </Grid>
      </Container>
      <Container maxWidth={false} component="div" className={classes.quotesContainer} >
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {QUOTES.map(quote => (
            <div key={`quote_${quote.id}`}
              className={classes.quoteContainer}
            >
              <div className={classes.quoteTitle}>
                {quote.title}
              </div>
              <div className={classes.quoteText}>
                {quote.text}
              </div>
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          style={{ background: "transparent",    justifyContent: "center" }}
          variant="dots"
          steps={QUOTES.length}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} className={classes.quoteRightButton}>
              <KeyboardArrowRight className={classes.quoteArrow} />


            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} className={classes.quoteLeftButton}>

              <KeyboardArrowLeft className={classes.quoteArrow} />
            </Button>
          }
        />
      </Container>

      <Container maxWidth={false} component="div" className={classes.contactUsContainer} id="request-quote">
        <Grid container >
          <Grid item xs={6}>
            <div style={{ "height": "100%", "overflow": "hidden" }}>
              <img className={classes.full} src="/images/contact-us.jpg" alt="" />
            </div>
          </Grid>
          <Grid item xs={6}>
            <QuoteForm />
          </Grid>
        </Grid>
      </Container>

    </div>
  );
};
