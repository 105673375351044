import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
};

export type AppQuery = {
  __typename?: 'AppQuery';
  /** Returns a filtered list of jobs */
  filterJobs?: Maybe<FilteredPaginatedJobs>;
  /** Returns a filtered list of tasks */
  filterTasks?: Maybe<Array<Maybe<Task>>>;
  /** Returns maintenance mode */
  maintenanceMode?: Maybe<Scalars['Int']>;
  /** Returns a paginated list of comments for the given job sorted by date in descending order */
  readComments?: Maybe<PaginatedComments>;
  /** Returns the organisation of the currently logged in user */
  readCurrentOrganisation?: Maybe<Organisation>;
  /** Returns an organisations users */
  readCurrentOrganisationUsers?: Maybe<Array<Maybe<User>>>;
  /** Returns the current user */
  readCurrentUser?: Maybe<User>;
  /** Returns the invitation with given id */
  readInvitation?: Maybe<Invitation>;
  /** Returns the invitations */
  readInvitations?: Maybe<Array<Maybe<Invitation>>>;
  /** Returns the job with given id */
  readJob?: Maybe<Job>;
  /** Returns the job address with given id */
  readJobAddress?: Maybe<JobAddress>;
  /** Returns the audit log for the given job */
  readJobAudit?: Maybe<Array<Maybe<AuditLogEntry>>>;
  /** Returns the engineering form data for the given job */
  readJobEngineeringFormData?: Maybe<JobEngineering>;
  /** Returns the job inspection checklist form data for the given job */
  readJobInspectionChecklistFormData?: Maybe<JobInspectionChecklist>;
  /** Returns the job inspection form data for the given job */
  readJobInspectionFormData?: Maybe<JobInspection>;
  /** Returns a list of jobs */
  readJobs?: Maybe<Array<Maybe<Job>>>;
  /** Returns a list of tasks for the particular job */
  readJobTasks?: Maybe<Array<Maybe<Task>>>;
  /** Returns a paginated list of delivered notifications for the current user */
  readNotificationDeliveries?: Maybe<PaginatedNotificationDeliveries>;
  /** Returns the organisation with given id */
  readOrganisation?: Maybe<Organisation>;
  /** Returns a list of organisations */
  readOrganisations?: Maybe<Array<Maybe<Organisation>>>;
  /** Returns an organisations users */
  readOrganisationUsers?: Maybe<Array<Maybe<User>>>;
  /** Returns the roles for the given user */
  readRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Returns a task */
  readTaskById?: Maybe<Task>;
  /** Returns a list of tasks */
  readTasks?: Maybe<Array<Maybe<Task>>>;
  /** Returns the specified user */
  readUser?: Maybe<Array<Maybe<User>>>;
  /** Returns a list of users */
  readUsers?: Maybe<Array<Maybe<User>>>;
  /** Returns a list of users that do not have roles assigned to them */
  readUsersWithoutRole?: Maybe<Array<Maybe<User>>>;
  /** Returns a list of user tasks */
  readUserTasks?: Maybe<Array<Maybe<Task>>>;
};


export type AppQueryFilterJobsArgs = {
  jobFilterCriteria?: Maybe<JobFilterCriteria>;
  page?: Maybe<Scalars['Int']>;
  rowsPerPage?: Maybe<Scalars['Int']>;
};


export type AppQueryFilterTasksArgs = {
  taskFilterCriteria?: Maybe<TaskFilterCriteria>;
};


export type AppQueryReadCommentsArgs = {
  jobId: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  rowsPerPage?: Maybe<Scalars['Int']>;
};


export type AppQueryReadInvitationArgs = {
  invitationId: Scalars['Int'];
};


export type AppQueryReadJobArgs = {
  jobId: Scalars['Int'];
};


export type AppQueryReadJobAddressArgs = {
  jobAddressId: Scalars['Int'];
};


export type AppQueryReadJobAuditArgs = {
  jobId: Scalars['Int'];
};


export type AppQueryReadJobEngineeringFormDataArgs = {
  jobId: Scalars['Int'];
};


export type AppQueryReadJobInspectionChecklistFormDataArgs = {
  jobId: Scalars['Int'];
};


export type AppQueryReadJobInspectionFormDataArgs = {
  jobId: Scalars['Int'];
};


export type AppQueryReadJobTasksArgs = {
  jobId: Scalars['Int'];
};


export type AppQueryReadNotificationDeliveriesArgs = {
  page?: Maybe<Scalars['Int']>;
  rowsPerPage?: Maybe<Scalars['Int']>;
};


export type AppQueryReadOrganisationArgs = {
  organisationId: Scalars['Int'];
};


export type AppQueryReadOrganisationUsersArgs = {
  organisationId: Scalars['Int'];
};


export type AppQueryReadRolesArgs = {
  userId: Scalars['Int'];
};


export type AppQueryReadTaskByIdArgs = {
  taskId: Scalars['Int'];
};


export type AppQueryReadUserArgs = {
  userId: Scalars['Int'];
};

export type Organisation = {
  __typename?: 'Organisation';
  address?: Maybe<Address>;
  /** Organisation Id */
  id: Scalars['Int'];
  /** Organisation Name */
  name: Scalars['String'];
  /** Preliminary design form permission */
  prelimDesignFormPermission: Scalars['Boolean'];
};

export type Address = {
  __typename?: 'Address';
  /** Address Line 1 */
  addressLine1: Scalars['String'];
  /** Address Line 2 */
  addressLine2: Scalars['String'];
  /** Country */
  country: Scalars['String'];
  /** Address id */
  id: Scalars['Int'];
  /** Postcode */
  postcode: Scalars['String'];
  /** Province */
  province: Scalars['String'];
  /** Suburb */
  suburb: Scalars['String'];
};

export type Task = {
  __typename?: 'Task';
  /** The user who assigned to the task */
  assignedBy?: Maybe<User>;
  /** The user who created the task */
  assignedTo?: Maybe<User>;
  /** Task Description */
  description: Scalars['String'];
  /** Due Date */
  dueDate: Scalars['DateTime'];
  /** Finished Date */
  finishedDate: Scalars['DateTime'];
  /** Task Id */
  id: Scalars['Int'];
  job?: Maybe<Job>;
  /** Task Subject */
  subject: Scalars['String'];
  taskStatus?: Maybe<TaskStatus>;
};


export type User = {
  __typename?: 'User';
  address?: Maybe<Address>;
  /** Contact Person */
  contactPerson: Scalars['String'];
  /** First Name */
  firstName: Scalars['String'];
  /** User ID */
  id: Scalars['Int'];
  /** Last Name */
  lastName: Scalars['String'];
  /** License Area of Engineering */
  licenseArea: Scalars['String'];
  /** License Class */
  licenseClass: Scalars['String'];
  /** License Number */
  licenseNumber: Scalars['String'];
  /** Mobile Number */
  mobileNumber: Scalars['String'];
  notificationSettings?: Maybe<NotificationSettings>;
  organisation?: Maybe<Organisation>;
  /** Phone */
  phone: Scalars['String'];
  /** Position */
  position: Scalars['String'];
  /** Victoria License Number */
  victoriaLicenseNumber: Scalars['String'];
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  /** Enable notifications through email */
  eMail: Scalars['Boolean'];
  /** Notification settings id */
  id: Scalars['Int'];
  /** Enable notifications through signalR */
  signalR: Scalars['Boolean'];
};

export type Job = {
  __typename?: 'Job';
  /** Builder */
  builder: Scalars['String'];
  /** Building Description */
  buildingDescription: Scalars['String'];
  confirmedWindRating?: Maybe<WindRatingEnum>;
  /** Delivery Date */
  deliveryDate?: Maybe<Scalars['DateTime']>;
  /** Detailing requested */
  detailingRequested: Scalars['Boolean'];
  /** Engineering issued */
  engineeringIssued?: Maybe<Scalars['Boolean']>;
  /** Engineering requested */
  engineeringRequested: Scalars['Boolean'];
  /** Job ID */
  id: Scalars['Int'];
  /** Inspection issued */
  inspectionIssued?: Maybe<Scalars['Boolean']>;
  /** Inspection requested */
  inspectionRequested: Scalars['Boolean'];
  jobAddress?: Maybe<JobAddress>;
  jobEngineering?: Maybe<JobEngineering>;
  jobInspection?: Maybe<JobInspection>;
  jobInspectionChecklist?: Maybe<JobInspectionChecklist>;
  /** Job Number */
  jobNumber: Scalars['String'];
  /** Level */
  level?: Maybe<Scalars['String']>;
  organisation?: Maybe<Organisation>;
  /** Prelim design issued */
  prelimDesignIssued?: Maybe<Scalars['Boolean']>;
  /** Prelim design requested */
  prelimDesignRequested: Scalars['Boolean'];
  priority?: Maybe<PriorityEnum>;
  state?: Maybe<JobStateEnum>;
  /** Number of Storeys */
  storeys: Scalars['Int'];
  windRating?: Maybe<WindRatingEnum>;
};

export enum JobStateEnum {
  AwaitingDocuments = 'AWAITING_DOCUMENTS',
  Detailing = 'DETAILING',
  Engineering = 'ENGINEERING',
  WaitingForInspectionResults = 'WAITING_FOR_INSPECTION_RESULTS',
  ReviewInspectionResults = 'REVIEW_INSPECTION_RESULTS',
  Finished = 'FINISHED'
}

export enum PriorityEnum {
  Normal = 'NORMAL',
  Urgent = 'URGENT'
}

export type JobAddress = {
  __typename?: 'JobAddress';
  /** Address id */
  id: Scalars['Int'];
  /** Local government */
  localGovernment: Scalars['String'];
  /** Lot number */
  lot: Scalars['String'];
  /** Postcode */
  postcode: Scalars['String'];
  /** Province */
  province: Scalars['String'];
  /** Street name */
  street: Scalars['String'];
  /** Street number */
  streetNumber: Scalars['String'];
  /** Suburb */
  suburb: Scalars['String'];
};

export enum WindRatingEnum {
  N_1 = 'N_1',
  N_2 = 'N_2',
  N_3 = 'N_3',
  N_4 = 'N_4',
  N_5 = 'N_5',
  N_6 = 'N_6',
  C_1 = 'C_1',
  C_2 = 'C_2',
  C_3 = 'C_3',
  C_4 = 'C_4'
}

export type JobEngineering = {
  __typename?: 'JobEngineering';
  /** Job engineering form data in JSON format */
  data: Scalars['String'];
  /** Job engineering id */
  id: Scalars['Int'];
};

export type JobInspection = {
  __typename?: 'JobInspection';
  /** Job inspection form data in JSON format */
  data: Scalars['String'];
  /** Job inspection id */
  id: Scalars['Int'];
};

export type JobInspectionChecklist = {
  __typename?: 'JobInspectionChecklist';
  /** Job inspection JSON data */
  data: Scalars['String'];
  /** Job inspection checklist id */
  id: Scalars['Int'];
};

export enum TaskStatus {
  Assigned = 'ASSIGNED',
  InProgress = 'IN_PROGRESS',
  Done = 'DONE'
}

export type TaskFilterCriteria = {
  assignedById?: Maybe<Scalars['Int']>;
  assignedToId?: Maybe<Scalars['Int']>;
  jobState?: Maybe<JobStateEnum>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['Int']>;
};

export type FilteredPaginatedJobs = {
  __typename?: 'FilteredPaginatedJobs';
  jobs?: Maybe<Array<Maybe<Job>>>;
  page?: Maybe<Scalars['Int']>;
  rowsPerPage?: Maybe<Scalars['Int']>;
  totalJobs?: Maybe<Scalars['Int']>;
};

export type JobFilterCriteria = {
  jobNumber?: Maybe<Scalars['String']>;
  builder?: Maybe<Scalars['String']>;
  state?: Maybe<JobStateEnum>;
  priority?: Maybe<PriorityEnum>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  localGovernment?: Maybe<Scalars['String']>;
  lot?: Maybe<Scalars['String']>;
  organisationName?: Maybe<Scalars['String']>;
  dateRange?: Maybe<JobCustomDateRange>;
};

export type JobCustomDateRange = {
  dateType: JobCustomDateRangeTypeEnum;
  service?: Maybe<JobServiceEnum>;
  state?: Maybe<JobStateEnum>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
};

export enum JobCustomDateRangeTypeEnum {
  Creation = 'CREATION',
  StateEntry = 'STATE_ENTRY',
  StateExit = 'STATE_EXIT',
  ServiceRequest = 'SERVICE_REQUEST',
  ServiceIssue = 'SERVICE_ISSUE',
  Delivery = 'DELIVERY'
}

export enum JobServiceEnum {
  Detailing = 'DETAILING',
  PrelimDesign = 'PRELIM_DESIGN',
  Engineering = 'ENGINEERING',
  Inspection = 'INSPECTION'
}

export type Invitation = {
  __typename?: 'Invitation';
  /** Invitation code to be used by the user to accept the invitation */
  code: Scalars['String'];
  /** The invitations expiry date */
  expiryDate: Scalars['DateTime'];
  /** Invitation id */
  id: Scalars['Int'];
  /** The invited users email */
  userEmail: Scalars['String'];
};

export type AuditLogEntry = {
  __typename?: 'AuditLogEntry';
  /** Creation date time */
  dateTime: Scalars['DateTime'];
  /** Audit log entry name */
  id: Scalars['Int'];
  /** Job ID */
  jobId?: Maybe<Scalars['Int']>;
  /** Audit log entry description */
  name: Scalars['String'];
  /** User ID */
  userId?: Maybe<Scalars['Int']>;
};

export type PaginatedComments = {
  __typename?: 'PaginatedComments';
  comments?: Maybe<Array<Maybe<Comment>>>;
  page?: Maybe<Scalars['Int']>;
  rowsPerPage?: Maybe<Scalars['Int']>;
  totalComments?: Maybe<Scalars['Int']>;
};

export type Comment = {
  __typename?: 'Comment';
  /** Date time of comment creation */
  dateTime: Scalars['DateTime'];
  /** Comment ID */
  id: Scalars['Int'];
  /** Comment text */
  text: Scalars['String'];
  /** The user who created the comment */
  user?: Maybe<User>;
};

export type PaginatedNotificationDeliveries = {
  __typename?: 'PaginatedNotificationDeliveries';
  notificationDeliveries?: Maybe<Array<Maybe<NotificationDelivery>>>;
  page?: Maybe<Scalars['Int']>;
  rowsPerPage?: Maybe<Scalars['Int']>;
  totalNotificationDeliveries?: Maybe<Scalars['Int']>;
};

export type NotificationDelivery = {
  __typename?: 'NotificationDelivery';
  /** Notification delivery id */
  id: Scalars['Int'];
  notification?: Maybe<Notification>;
  /** Received flag indicating whether the user has received and read the notification or not */
  received: Scalars['Boolean'];
};

export type Notification = {
  __typename?: 'Notification';
  /** Creation date */
  dateTime: Scalars['DateTime'];
  /** Description */
  description: Scalars['String'];
  /** Notification Id */
  id: Scalars['Int'];
  /** Subject */
  subject: Scalars['String'];
  type?: Maybe<NotificationTypeEnum>;
};

export enum NotificationTypeEnum {
  JobCreated = 'JOB_CREATED',
  JobFinished = 'JOB_FINISHED',
  JobAdvanced = 'JOB_ADVANCED',
  JobRejected = 'JOB_REJECTED',
  TaskAssigned = 'TASK_ASSIGNED',
  TaskFinished = 'TASK_FINISHED',
  Broadcast = 'BROADCAST',
  NewComment = 'NEW_COMMENT'
}

export type AppMutation = {
  __typename?: 'AppMutation';
  /** Applies the invitation with the given invitation code to the current user if applicable */
  acceptInvitation?: Maybe<User>;
  /** Advances the to the next available state. Returns the updated job. */
  advanceJob?: Maybe<Job>;
  /** Assigns the given role to the user with given id */
  assignRole?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Creates a new comment */
  createComment?: Maybe<Comment>;
  /** Creates a new invitation */
  createInvitation?: Maybe<Invitation>;
  /** Creates a new job */
  createJob?: Maybe<Job>;
  /** Creates a new organisation */
  createOrganisation?: Maybe<Organisation>;
  /** Creates a new task */
  createTask?: Maybe<Task>;
  /** Creates a new user */
  createUser?: Maybe<User>;
  /** Deletes the comment */
  deleteComment?: Maybe<Comment>;
  /** Deletes the invitation */
  deleteInvitation?: Maybe<Scalars['String']>;
  /** Deletes the job */
  deleteJob?: Maybe<Scalars['String']>;
  /** Deletes the organisation */
  deleteOrganisation?: Maybe<Scalars['String']>;
  /** Delete the task */
  deleteTask?: Maybe<Task>;
  /** Deletes the user */
  deleteUser?: Maybe<User>;
  /** Marks the notification with the given notification id as received */
  markNotificationAsReceived?: Maybe<NotificationDelivery>;
  /** Rejects the jobs current state and reverts it to its previous state. Returns the updated job. */
  rejectJob?: Maybe<Job>;
  /** Removes the given role to the user with given id */
  removeRole?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** RequestQuote */
  requestQuote?: Maybe<RequestQuote>;
  /** Reverts the job to the first state to allow for details to be amendments. Returns the updated job. */
  revertRectifyDetailsJob?: Maybe<Job>;
  /** Reverts the job to the inspection state to allow for inspection to be requested after engineering has finished. Returns the updated job. */
  revertRequestInspectionJob?: Maybe<Job>;
  /** Update maintenance mode */
  updataMaintenanceMode?: Maybe<Scalars['Int']>;
  /** Updates the comment */
  updateComment?: Maybe<Comment>;
  /** Updates the job */
  updateJob?: Maybe<Job>;
  /** Updates the jobs engineering form data. Returns the updated engineering form data. */
  updateJobEngineeringFormData?: Maybe<JobEngineering>;
  /** Updates the jobs inspection checklist form data. Returns the updated inspection checklist form data. */
  updateJobInspectionChecklistFormData?: Maybe<JobInspectionChecklist>;
  /** Updates the jobs inspection form data. Returns the updated inspection form data. */
  updateJobInspectionFormData?: Maybe<JobInspection>;
  /** Updates the job state */
  updateJobState?: Maybe<Job>;
  /** Updates the organisation */
  updateOrganisation?: Maybe<Organisation>;
  /** Update task */
  updateTask?: Maybe<Task>;
  /** Update state of task */
  updateTaskState?: Maybe<Task>;
  /** Updates the user */
  updateUser?: Maybe<User>;
};


export type AppMutationAcceptInvitationArgs = {
  code: Scalars['String'];
};


export type AppMutationAdvanceJobArgs = {
  jobId: Scalars['Int'];
};


export type AppMutationAssignRoleArgs = {
  userId: Scalars['Int'];
  role: Scalars['String'];
};


export type AppMutationCreateCommentArgs = {
  jobId: Scalars['Int'];
  text: Scalars['String'];
};


export type AppMutationCreateInvitationArgs = {
  email: Scalars['String'];
};


export type AppMutationCreateJobArgs = {
  job: JobInput;
};


export type AppMutationCreateOrganisationArgs = {
  organisation: OrganisationInput;
};


export type AppMutationCreateTaskArgs = {
  task: TaskInput;
  taskType: TaskTypeEnum;
};


export type AppMutationCreateUserArgs = {
  user: UserInput;
};


export type AppMutationDeleteCommentArgs = {
  commentId: Scalars['Int'];
};


export type AppMutationDeleteInvitationArgs = {
  invitationId: Scalars['Int'];
};


export type AppMutationDeleteJobArgs = {
  jobId: Scalars['Int'];
};


export type AppMutationDeleteOrganisationArgs = {
  organisationId: Scalars['Int'];
};


export type AppMutationDeleteTaskArgs = {
  taskId: Scalars['Int'];
};


export type AppMutationDeleteUserArgs = {
  userId: Scalars['Int'];
};


export type AppMutationMarkNotificationAsReceivedArgs = {
  notificationId: Scalars['Int'];
};


export type AppMutationRejectJobArgs = {
  jobId: Scalars['Int'];
  comment: Scalars['String'];
};


export type AppMutationRemoveRoleArgs = {
  userId: Scalars['Int'];
  role: Scalars['String'];
};


export type AppMutationRequestQuoteArgs = {
  requestQuote: RequestQuoteInput;
};


export type AppMutationRevertRectifyDetailsJobArgs = {
  jobId: Scalars['Int'];
};


export type AppMutationRevertRequestInspectionJobArgs = {
  jobId: Scalars['Int'];
};


export type AppMutationUpdataMaintenanceModeArgs = {
  state: Scalars['Int'];
};


export type AppMutationUpdateCommentArgs = {
  commentId: Scalars['Int'];
  text: Scalars['String'];
};


export type AppMutationUpdateJobArgs = {
  job: JobInput;
  jobId: Scalars['Int'];
};


export type AppMutationUpdateJobEngineeringFormDataArgs = {
  jobId: Scalars['Int'];
  formData: JobEngineeringInput;
};


export type AppMutationUpdateJobInspectionChecklistFormDataArgs = {
  jobId: Scalars['Int'];
  formData: JobInspectionChecklistInput;
};


export type AppMutationUpdateJobInspectionFormDataArgs = {
  jobId: Scalars['Int'];
  formData: JobInspectionInput;
};


export type AppMutationUpdateJobStateArgs = {
  jobId: Scalars['Int'];
  state: JobStateEnum;
};


export type AppMutationUpdateOrganisationArgs = {
  organisation: OrganisationInput;
  organisationId: Scalars['Int'];
};


export type AppMutationUpdateTaskArgs = {
  task: TaskInput;
  id: Scalars['Int'];
};


export type AppMutationUpdateTaskStateArgs = {
  taskId: Scalars['Int'];
  taskStatus: TaskStatus;
};


export type AppMutationUpdateUserArgs = {
  user: UserInput;
  userId: Scalars['Int'];
};

export type TaskInput = {
  id?: Maybe<Scalars['Int']>;
  subject?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  assignedToId: Scalars['Int'];
  jobId: Scalars['Int'];
  jobNumber?: Maybe<Scalars['Int']>;
  jobState?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
};

export enum TaskTypeEnum {
  Assign = 'ASSIGN',
  Detailing = 'DETAILING',
  Engineering = 'ENGINEERING'
}

export type JobInput = {
  jobNumber: Scalars['String'];
  builder: Scalars['String'];
  buildingDescription: Scalars['String'];
  storeys: Scalars['Int'];
  deliveryDate: Scalars['DateTime'];
  level: Scalars['String'];
  priority: PriorityEnum;
  detailingRequested: Scalars['Boolean'];
  prelimDesignRequested: Scalars['Boolean'];
  engineeringRequested: Scalars['Boolean'];
  inspectionRequested: Scalars['Boolean'];
  prelimDesignIssued?: Maybe<Scalars['Boolean']>;
  engineeringIssued?: Maybe<Scalars['Boolean']>;
  inspectionIssued?: Maybe<Scalars['Boolean']>;
  jobAddress: JobAddressInput;
  windRating: WindRatingEnum;
  confirmedWindRating?: Maybe<WindRatingEnum>;
  jobEngineering?: Maybe<JobEngineeringInput>;
  jobInspection?: Maybe<JobInspectionInput>;
  jobInspectionChecklist?: Maybe<JobInspectionChecklistInput>;
};

export type JobAddressInput = {
  /** Street Number */
  streetNumber: Scalars['String'];
  /** Street */
  street: Scalars['String'];
  /** Postcode */
  postcode: Scalars['String'];
  /** Suburb */
  suburb: Scalars['String'];
  /** Local Government */
  localGovernment: Scalars['String'];
  /** Lot info */
  lot: Scalars['String'];
  /** Province */
  province: Scalars['String'];
};

export type JobEngineeringInput = {
  /** Job engineering form data in JSON format */
  data?: Maybe<Scalars['String']>;
};

export type JobInspectionInput = {
  /** Job inspection form data in JSON format */
  data?: Maybe<Scalars['String']>;
};

export type JobInspectionChecklistInput = {
  /** Job inspection form data in JSON format */
  data?: Maybe<Scalars['String']>;
};

export type OrganisationInput = {
  name: Scalars['String'];
  address: AddressInput;
};

export type AddressInput = {
  /** AddressLine1 */
  addressLine1: Scalars['String'];
  /** AddressLine2 */
  addressLine2: Scalars['String'];
  /** Postcode */
  postcode: Scalars['String'];
  /** Suburb */
  suburb: Scalars['String'];
  /** Country */
  country: Scalars['String'];
  /** Province */
  province: Scalars['String'];
};

export type UserInput = {
  /** First Name */
  firstName: Scalars['String'];
  /** Last Name */
  lastName: Scalars['String'];
  /** Phone */
  phone: Scalars['String'];
  /** Position */
  position: Scalars['String'];
  /** Mobile Number */
  mobileNumber?: Maybe<Scalars['String']>;
  /** Contact Person */
  contactPerson?: Maybe<Scalars['String']>;
  /** License Class */
  licenseClass?: Maybe<Scalars['String']>;
  /** License Number */
  licenseNumber?: Maybe<Scalars['String']>;
  /** License Area of Engineering */
  licenseArea?: Maybe<Scalars['String']>;
  /** Victoria License Number */
  victoriaLicenseNumber?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  notificationSettings?: Maybe<NotificationSettingsInput>;
};

export type NotificationSettingsInput = {
  email: Scalars['Boolean'];
  signalr: Scalars['Boolean'];
};

export type RequestQuote = {
  __typename?: 'RequestQuote';
  /** Email */
  eMail: Scalars['String'];
  /** First name */
  firstName: Scalars['String'];
  /** Request quote id */
  id: Scalars['Int'];
  /** Last name */
  lastName: Scalars['String'];
  /** Message */
  message: Scalars['String'];
  /** Mobile number */
  mobileNumber: Scalars['String'];
};

export type RequestQuoteInput = {
  /** First Name */
  firstName: Scalars['String'];
  /** Last Name */
  lastName: Scalars['String'];
  /** Email */
  eMail: Scalars['String'];
  /** Mobile Number */
  mobileNumber: Scalars['String'];
  /** Message */
  message: Scalars['String'];
};

export type JobListQueryVariables = Exact<{ [key: string]: never; }>;


export type JobListQuery = (
  { __typename?: 'AppQuery' }
  & { readJobs?: Maybe<Array<Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'builder' | 'buildingDescription' | 'storeys' | 'deliveryDate' | 'id' | 'level' | 'detailingRequested' | 'engineeringRequested' | 'inspectionRequested' | 'prelimDesignRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'jobNumber' | 'priority' | 'state'>
    & { jobAddress?: Maybe<(
      { __typename?: 'JobAddress' }
      & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )>>> }
);

export type JobByIdQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type JobByIdQuery = (
  { __typename?: 'AppQuery' }
  & { readJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'builder' | 'buildingDescription' | 'storeys' | 'confirmedWindRating' | 'deliveryDate' | 'detailingRequested' | 'engineeringRequested' | 'inspectionRequested' | 'prelimDesignRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'jobNumber' | 'level' | 'priority' | 'state' | 'windRating'>
    & { jobAddress?: Maybe<(
      { __typename?: 'JobAddress' }
      & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
    )>, jobEngineering?: Maybe<(
      { __typename?: 'JobEngineering' }
      & Pick<JobEngineering, 'id' | 'data'>
    )>, jobInspection?: Maybe<(
      { __typename?: 'JobInspection' }
      & Pick<JobInspection, 'id' | 'data'>
    )>, jobInspectionChecklist?: Maybe<(
      { __typename?: 'JobInspectionChecklist' }
      & Pick<JobInspectionChecklist, 'id' | 'data'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name' | 'prelimDesignFormPermission'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
      )> }
    )> }
  )> }
);

export type ReadJobEngineeringFormDataQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type ReadJobEngineeringFormDataQuery = (
  { __typename?: 'AppQuery' }
  & { readJobEngineeringFormData?: Maybe<(
    { __typename?: 'JobEngineering' }
    & Pick<JobEngineering, 'id' | 'data'>
  )> }
);

export type UpdateJobEngineeringFormDataMutationVariables = Exact<{
  jobId: Scalars['Int'];
  formData: JobEngineeringInput;
}>;


export type UpdateJobEngineeringFormDataMutation = (
  { __typename?: 'AppMutation' }
  & { updateJobEngineeringFormData?: Maybe<(
    { __typename?: 'JobEngineering' }
    & Pick<JobEngineering, 'id' | 'data'>
  )> }
);

export type ReadJobInspectionFormDataQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type ReadJobInspectionFormDataQuery = (
  { __typename?: 'AppQuery' }
  & { readJobInspectionFormData?: Maybe<(
    { __typename?: 'JobInspection' }
    & Pick<JobInspection, 'id' | 'data'>
  )> }
);

export type UpdateJobInspectionFormDataMutationVariables = Exact<{
  jobId: Scalars['Int'];
  formData: JobInspectionInput;
}>;


export type UpdateJobInspectionFormDataMutation = (
  { __typename?: 'AppMutation' }
  & { updateJobInspectionFormData?: Maybe<(
    { __typename?: 'JobInspection' }
    & Pick<JobInspection, 'id' | 'data'>
  )> }
);

export type ReadJobInspectionChecklistFormDataQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type ReadJobInspectionChecklistFormDataQuery = (
  { __typename?: 'AppQuery' }
  & { readJobInspectionChecklistFormData?: Maybe<(
    { __typename?: 'JobInspectionChecklist' }
    & Pick<JobInspectionChecklist, 'id' | 'data'>
  )> }
);

export type UpdateJobInspectionChecklistFormDataMutationVariables = Exact<{
  jobId: Scalars['Int'];
  formData: JobInspectionChecklistInput;
}>;


export type UpdateJobInspectionChecklistFormDataMutation = (
  { __typename?: 'AppMutation' }
  & { updateJobInspectionChecklistFormData?: Maybe<(
    { __typename?: 'JobInspectionChecklist' }
    & Pick<JobInspectionChecklist, 'id' | 'data'>
  )> }
);

export type FilterJobsQueryVariables = Exact<{
  jobFilterCriteria?: Maybe<JobFilterCriteria>;
  page?: Maybe<Scalars['Int']>;
  rowsPerPage?: Maybe<Scalars['Int']>;
}>;


export type FilterJobsQuery = (
  { __typename?: 'AppQuery' }
  & { filterJobs?: Maybe<(
    { __typename?: 'FilteredPaginatedJobs' }
    & Pick<FilteredPaginatedJobs, 'totalJobs' | 'page' | 'rowsPerPage'>
    & { jobs?: Maybe<Array<Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'builder' | 'buildingDescription' | 'storeys' | 'confirmedWindRating' | 'deliveryDate' | 'detailingRequested' | 'engineeringRequested' | 'inspectionRequested' | 'prelimDesignRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'jobNumber' | 'level' | 'priority' | 'state' | 'windRating'>
      & { jobAddress?: Maybe<(
        { __typename?: 'JobAddress' }
        & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
      )>, jobEngineering?: Maybe<(
        { __typename?: 'JobEngineering' }
        & Pick<JobEngineering, 'id' | 'data'>
      )>, jobInspection?: Maybe<(
        { __typename?: 'JobInspection' }
        & Pick<JobInspection, 'id' | 'data'>
      )>, jobInspectionChecklist?: Maybe<(
        { __typename?: 'JobInspectionChecklist' }
        & Pick<JobInspectionChecklist, 'id' | 'data'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
        )> }
      )> }
    )>>> }
  )> }
);

export type CreateJobMutationVariables = Exact<{
  job: JobInput;
}>;


export type CreateJobMutation = (
  { __typename?: 'AppMutation' }
  & { createJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'builder' | 'buildingDescription' | 'storeys' | 'level' | 'deliveryDate' | 'id' | 'jobNumber' | 'detailingRequested' | 'engineeringRequested' | 'inspectionRequested' | 'prelimDesignRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'priority' | 'state'>
    & { jobAddress?: Maybe<(
      { __typename?: 'JobAddress' }
      & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateJobMutationVariables = Exact<{
  job: JobInput;
  jobId: Scalars['Int'];
}>;


export type UpdateJobMutation = (
  { __typename?: 'AppMutation' }
  & { updateJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'builder' | 'level' | 'buildingDescription' | 'storeys' | 'deliveryDate' | 'id' | 'jobNumber' | 'detailingRequested' | 'engineeringRequested' | 'prelimDesignRequested' | 'inspectionRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'priority' | 'state'>
    & { jobAddress?: Maybe<(
      { __typename?: 'JobAddress' }
      & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )> }
);

export type CreateOrganisationMutationVariables = Exact<{
  organisation: OrganisationInput;
}>;


export type CreateOrganisationMutation = (
  { __typename?: 'AppMutation' }
  & { createOrganisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'name'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'postcode' | 'suburb' | 'province' | 'country'>
    )> }
  )> }
);

export type UpdateOrganisationMutationVariables = Exact<{
  organisation: OrganisationInput;
  organisationId: Scalars['Int'];
}>;


export type UpdateOrganisationMutation = (
  { __typename?: 'AppMutation' }
  & { updateOrganisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'name'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'postcode' | 'suburb' | 'province' | 'country'>
    )> }
  )> }
);

export type CreateUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'AppMutation' }
  & { createUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
    )> }
  )> }
);

export type UpdateCurrentUserMutationVariables = Exact<{
  user: UserInput;
  userId: Scalars['Int'];
}>;


export type UpdateCurrentUserMutation = (
  { __typename?: 'AppMutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
    )> }
  )> }
);

export type ReadCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadCurrentUserQuery = (
  { __typename?: 'AppQuery' }
  & { readCurrentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
      )> }
    )>, notificationSettings?: Maybe<(
      { __typename?: 'NotificationSettings' }
      & Pick<NotificationSettings, 'eMail' | 'signalR' | 'id'>
    )> }
  )> }
);

export type ReadUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadUsersQuery = (
  { __typename?: 'AppQuery' }
  & { readUsers?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
      )> }
    )> }
  )>>> }
);

export type ReadUsersWithoutRoleQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadUsersWithoutRoleQuery = (
  { __typename?: 'AppQuery' }
  & { readUsersWithoutRole?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
      )> }
    )> }
  )>>> }
);

export type ReadCurrentOrganisationQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadCurrentOrganisationQuery = (
  { __typename?: 'AppQuery' }
  & { readCurrentOrganisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
    )> }
  )> }
);

export type ReadOrganisationUsersQueryVariables = Exact<{
  organisationId: Scalars['Int'];
}>;


export type ReadOrganisationUsersQuery = (
  { __typename?: 'AppQuery' }
  & { readOrganisationUsers?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
      )> }
    )> }
  )>>> }
);

export type AdvanceJobMutationVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type AdvanceJobMutation = (
  { __typename?: 'AppMutation' }
  & { advanceJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'builder' | 'level' | 'buildingDescription' | 'storeys' | 'deliveryDate' | 'id' | 'jobNumber' | 'detailingRequested' | 'engineeringRequested' | 'prelimDesignRequested' | 'inspectionRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'priority' | 'state'>
    & { jobAddress?: Maybe<(
      { __typename?: 'JobAddress' }
      & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )> }
);

export type RejectJobMutationVariables = Exact<{
  jobId: Scalars['Int'];
  comment: Scalars['String'];
}>;


export type RejectJobMutation = (
  { __typename?: 'AppMutation' }
  & { rejectJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'builder' | 'level' | 'buildingDescription' | 'storeys' | 'deliveryDate' | 'id' | 'jobNumber' | 'detailingRequested' | 'engineeringRequested' | 'prelimDesignRequested' | 'inspectionRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'priority' | 'state'>
    & { jobAddress?: Maybe<(
      { __typename?: 'JobAddress' }
      & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )> }
);

export type RevertRectifyDetailsJobMutationVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type RevertRectifyDetailsJobMutation = (
  { __typename?: 'AppMutation' }
  & { revertRectifyDetailsJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'builder' | 'level' | 'buildingDescription' | 'storeys' | 'deliveryDate' | 'id' | 'jobNumber' | 'detailingRequested' | 'engineeringRequested' | 'prelimDesignRequested' | 'inspectionRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'priority' | 'state'>
    & { jobAddress?: Maybe<(
      { __typename?: 'JobAddress' }
      & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )> }
);

export type RevertRequestInspectionJobMutationVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type RevertRequestInspectionJobMutation = (
  { __typename?: 'AppMutation' }
  & { revertRequestInspectionJob?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'builder' | 'level' | 'buildingDescription' | 'storeys' | 'deliveryDate' | 'id' | 'jobNumber' | 'detailingRequested' | 'engineeringRequested' | 'prelimDesignRequested' | 'inspectionRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'priority' | 'state'>
    & { jobAddress?: Maybe<(
      { __typename?: 'JobAddress' }
      & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )> }
);

export type CreateInvitationMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type CreateInvitationMutation = (
  { __typename?: 'AppMutation' }
  & { createInvitation?: Maybe<(
    { __typename?: 'Invitation' }
    & Pick<Invitation, 'code' | 'expiryDate' | 'id' | 'userEmail'>
  )> }
);

export type AcceptInvitationMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type AcceptInvitationMutation = (
  { __typename?: 'AppMutation' }
  & { acceptInvitation?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
      )> }
    )> }
  )> }
);

export type ReadRolesQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type ReadRolesQuery = (
  { __typename?: 'AppQuery' }
  & Pick<AppQuery, 'readRoles'>
);

export type ReadUserQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type ReadUserQuery = (
  { __typename?: 'AppQuery' }
  & { readUser?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
      )> }
    )>, notificationSettings?: Maybe<(
      { __typename?: 'NotificationSettings' }
      & Pick<NotificationSettings, 'eMail' | 'signalR' | 'id'>
    )> }
  )>>> }
);

export type AssignRoleMutationVariables = Exact<{
  userId: Scalars['Int'];
  role: Scalars['String'];
}>;


export type AssignRoleMutation = (
  { __typename?: 'AppMutation' }
  & Pick<AppMutation, 'assignRole'>
);

export type RemoveRoleMutationVariables = Exact<{
  userId: Scalars['Int'];
  role: Scalars['String'];
}>;


export type RemoveRoleMutation = (
  { __typename?: 'AppMutation' }
  & Pick<AppMutation, 'removeRole'>
);

export type ReadOrganisationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadOrganisationsQuery = (
  { __typename?: 'AppQuery' }
  & { readOrganisations?: Maybe<Array<Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
    )> }
  )>>> }
);

export type ReadJobAuditQueryVariables = Exact<{
  jobId: Scalars['Int'];
}>;


export type ReadJobAuditQuery = (
  { __typename?: 'AppQuery' }
  & { readJobAudit?: Maybe<Array<Maybe<(
    { __typename?: 'AuditLogEntry' }
    & Pick<AuditLogEntry, 'id' | 'dateTime' | 'name' | 'jobId' | 'userId'>
  )>>> }
);

export type CreateCommentMutationVariables = Exact<{
  jobId: Scalars['Int'];
  text: Scalars['String'];
}>;


export type CreateCommentMutation = (
  { __typename?: 'AppMutation' }
  & { createComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'dateTime' | 'id' | 'text'>
  )> }
);

export type ReadCommentsQueryVariables = Exact<{
  jobId: Scalars['Int'];
  page: Scalars['Int'];
  rowsPerPage: Scalars['Int'];
}>;


export type ReadCommentsQuery = (
  { __typename?: 'AppQuery' }
  & { readComments?: Maybe<(
    { __typename?: 'PaginatedComments' }
    & Pick<PaginatedComments, 'page' | 'rowsPerPage' | 'totalComments'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'dateTime' | 'id' | 'text'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName'>
      )> }
    )>>> }
  )> }
);

export type RequestQuoteMutationVariables = Exact<{
  RequestQuote: RequestQuoteInput;
}>;


export type RequestQuoteMutation = (
  { __typename?: 'AppMutation' }
  & { requestQuote?: Maybe<(
    { __typename?: 'RequestQuote' }
    & Pick<RequestQuote, 'firstName' | 'lastName' | 'eMail' | 'mobileNumber' | 'message'>
  )> }
);

export type MaintenanceModeQueryVariables = Exact<{ [key: string]: never; }>;


export type MaintenanceModeQuery = (
  { __typename?: 'AppQuery' }
  & Pick<AppQuery, 'maintenanceMode'>
);

export type UpdateMaintenanceModeMutationVariables = Exact<{
  state: Scalars['Int'];
}>;


export type UpdateMaintenanceModeMutation = (
  { __typename?: 'AppMutation' }
  & Pick<AppMutation, 'updataMaintenanceMode'>
);

export type ReadJobTasksQueryVariables = Exact<{
  JobId: Scalars['Int'];
}>;


export type ReadJobTasksQuery = (
  { __typename?: 'AppQuery' }
  & { readJobTasks?: Maybe<Array<Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'description' | 'subject' | 'dueDate' | 'finishedDate' | 'taskStatus'>
    & { assignedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'mobileNumber' | 'phone' | 'position'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
        )> }
      )> }
    )>, assignedTo?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'mobileNumber' | 'phone' | 'position'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
        )> }
      )> }
    )>, job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'builder' | 'level' | 'buildingDescription' | 'storeys' | 'deliveryDate' | 'id' | 'jobNumber' | 'detailingRequested' | 'engineeringRequested' | 'inspectionRequested' | 'prelimDesignRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'priority' | 'state'>
      & { jobAddress?: Maybe<(
        { __typename?: 'JobAddress' }
        & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
      )> }
    )> }
  )>>> }
);

export type ReadTaskByIdQueryVariables = Exact<{
  taskId: Scalars['Int'];
}>;


export type ReadTaskByIdQuery = (
  { __typename?: 'AppQuery' }
  & { readTaskById?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'description' | 'subject' | 'dueDate' | 'finishedDate' | 'taskStatus'>
    & { assignedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
        )> }
      )> }
    )>, assignedTo?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
        )> }
      )> }
    )>, job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'builder' | 'level' | 'buildingDescription' | 'storeys' | 'deliveryDate' | 'id' | 'jobNumber' | 'detailingRequested' | 'engineeringRequested' | 'inspectionRequested' | 'prelimDesignRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'priority' | 'state'>
      & { jobAddress?: Maybe<(
        { __typename?: 'JobAddress' }
        & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type ReadUserTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadUserTasksQuery = (
  { __typename?: 'AppQuery' }
  & { readUserTasks?: Maybe<Array<Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'description' | 'subject' | 'dueDate' | 'finishedDate' | 'taskStatus'>
    & { assignedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
        )> }
      )> }
    )>, assignedTo?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
        )> }
      )> }
    )>, job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'builder' | 'level' | 'buildingDescription' | 'storeys' | 'deliveryDate' | 'id' | 'jobNumber' | 'detailingRequested' | 'engineeringRequested' | 'inspectionRequested' | 'prelimDesignRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'priority' | 'state'>
      & { jobAddress?: Maybe<(
        { __typename?: 'JobAddress' }
        & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
      )> }
    )> }
  )>>> }
);

export type CreateTaskMutationVariables = Exact<{
  task: TaskInput;
  taskType: TaskTypeEnum;
}>;


export type CreateTaskMutation = (
  { __typename?: 'AppMutation' }
  & { createTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'description' | 'subject' | 'dueDate' | 'finishedDate' | 'taskStatus'>
    & { assignedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
        )> }
      )> }
    )>, assignedTo?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
        )> }
      )> }
    )>, job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'builder' | 'level' | 'buildingDescription' | 'storeys' | 'deliveryDate' | 'id' | 'jobNumber' | 'detailingRequested' | 'engineeringRequested' | 'inspectionRequested' | 'prelimDesignRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'priority' | 'state'>
      & { jobAddress?: Maybe<(
        { __typename?: 'JobAddress' }
        & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type FilterTasksQueryVariables = Exact<{
  taskFilterCriteria: TaskFilterCriteria;
}>;


export type FilterTasksQuery = (
  { __typename?: 'AppQuery' }
  & { filterTasks?: Maybe<Array<Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'description' | 'subject' | 'dueDate' | 'finishedDate' | 'taskStatus'>
    & { assignedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
        )> }
      )> }
    )>, assignedTo?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
        )> }
      )> }
    )>, job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'builder' | 'level' | 'buildingDescription' | 'storeys' | 'deliveryDate' | 'id' | 'jobNumber' | 'detailingRequested' | 'engineeringRequested' | 'inspectionRequested' | 'prelimDesignRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'priority' | 'state'>
      & { jobAddress?: Maybe<(
        { __typename?: 'JobAddress' }
        & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
      )> }
    )> }
  )>>> }
);

export type UpdateTaskStateMutationVariables = Exact<{
  taskId: Scalars['Int'];
  taskStatus: TaskStatus;
}>;


export type UpdateTaskStateMutation = (
  { __typename?: 'AppMutation' }
  & { updateTaskState?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'taskStatus'>
  )> }
);

export type UpdateTaskMutationVariables = Exact<{
  task: TaskInput;
  id: Scalars['Int'];
}>;


export type UpdateTaskMutation = (
  { __typename?: 'AppMutation' }
  & { updateTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'description' | 'subject' | 'dueDate' | 'finishedDate' | 'taskStatus'>
    & { assignedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
        )> }
      )> }
    )>, assignedTo?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'postcode' | 'province' | 'suburb'>
        )> }
      )> }
    )>, job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'builder' | 'level' | 'buildingDescription' | 'storeys' | 'deliveryDate' | 'id' | 'jobNumber' | 'detailingRequested' | 'engineeringRequested' | 'inspectionRequested' | 'prelimDesignRequested' | 'engineeringIssued' | 'prelimDesignIssued' | 'inspectionIssued' | 'priority' | 'state'>
      & { jobAddress?: Maybe<(
        { __typename?: 'JobAddress' }
        & Pick<JobAddress, 'id' | 'localGovernment' | 'lot' | 'postcode' | 'province' | 'street' | 'streetNumber' | 'suburb'>
      )>, organisation?: Maybe<(
        { __typename?: 'Organisation' }
        & Pick<Organisation, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type ReadCurrentOrganisationUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadCurrentOrganisationUsersQuery = (
  { __typename?: 'AppQuery' }
  & { readCurrentOrganisationUsers?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'contactPerson' | 'firstName' | 'id' | 'lastName' | 'licenseClass' | 'licenseNumber' | 'victoriaLicenseNumber' | 'licenseArea' | 'mobileNumber' | 'phone' | 'position'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'addressLine1' | 'addressLine2' | 'country' | 'id' | 'postcode' | 'province' | 'suburb'>
    )> }
  )>>> }
);

export type MarkNotificationAsReceivedMutationVariables = Exact<{
  notificationId: Scalars['Int'];
}>;


export type MarkNotificationAsReceivedMutation = (
  { __typename?: 'AppMutation' }
  & { markNotificationAsReceived?: Maybe<(
    { __typename?: 'NotificationDelivery' }
    & Pick<NotificationDelivery, 'id' | 'received'>
    & { notification?: Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'dateTime' | 'description' | 'id' | 'subject' | 'type'>
    )> }
  )> }
);

export type UpdateJobStateMutationVariables = Exact<{
  jobId: Scalars['Int'];
  state: JobStateEnum;
}>;


export type UpdateJobStateMutation = (
  { __typename?: 'AppMutation' }
  & { updateJobState?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
  )> }
);


export const JobListDocument = gql`
    query JobList {
  readJobs {
    builder
    buildingDescription
    storeys
    deliveryDate
    id
    level
    detailingRequested
    engineeringRequested
    inspectionRequested
    prelimDesignRequested
    engineeringIssued
    prelimDesignIssued
    inspectionIssued
    jobNumber
    jobAddress {
      id
      localGovernment
      lot
      postcode
      province
      street
      streetNumber
      suburb
    }
    organisation {
      id
      name
    }
    priority
    state
  }
}
    `;

/**
 * __useJobListQuery__
 *
 * To run a query within a React component, call `useJobListQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobListQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobListQuery(baseOptions?: Apollo.QueryHookOptions<JobListQuery, JobListQueryVariables>) {
        return Apollo.useQuery<JobListQuery, JobListQueryVariables>(JobListDocument, baseOptions);
      }
export function useJobListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobListQuery, JobListQueryVariables>) {
          return Apollo.useLazyQuery<JobListQuery, JobListQueryVariables>(JobListDocument, baseOptions);
        }
export type JobListQueryHookResult = ReturnType<typeof useJobListQuery>;
export type JobListLazyQueryHookResult = ReturnType<typeof useJobListLazyQuery>;
export type JobListQueryResult = Apollo.QueryResult<JobListQuery, JobListQueryVariables>;
export const JobByIdDocument = gql`
    query JobById($jobId: Int!) {
  readJob(jobId: $jobId) {
    id
    builder
    buildingDescription
    storeys
    confirmedWindRating
    deliveryDate
    detailingRequested
    engineeringRequested
    inspectionRequested
    prelimDesignRequested
    engineeringIssued
    prelimDesignIssued
    inspectionIssued
    jobAddress {
      id
      localGovernment
      lot
      postcode
      province
      street
      streetNumber
      suburb
    }
    jobEngineering {
      id
      data
    }
    jobInspection {
      id
      data
    }
    jobInspectionChecklist {
      id
      data
    }
    jobNumber
    level
    organisation {
      id
      name
      address {
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      }
      prelimDesignFormPermission
    }
    priority
    state
    windRating
  }
}
    `;

/**
 * __useJobByIdQuery__
 *
 * To run a query within a React component, call `useJobByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobByIdQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobByIdQuery(baseOptions: Apollo.QueryHookOptions<JobByIdQuery, JobByIdQueryVariables>) {
        return Apollo.useQuery<JobByIdQuery, JobByIdQueryVariables>(JobByIdDocument, baseOptions);
      }
export function useJobByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobByIdQuery, JobByIdQueryVariables>) {
          return Apollo.useLazyQuery<JobByIdQuery, JobByIdQueryVariables>(JobByIdDocument, baseOptions);
        }
export type JobByIdQueryHookResult = ReturnType<typeof useJobByIdQuery>;
export type JobByIdLazyQueryHookResult = ReturnType<typeof useJobByIdLazyQuery>;
export type JobByIdQueryResult = Apollo.QueryResult<JobByIdQuery, JobByIdQueryVariables>;
export const ReadJobEngineeringFormDataDocument = gql`
    query readJobEngineeringFormData($jobId: Int!) {
  readJobEngineeringFormData(jobId: $jobId) {
    id
    data
  }
}
    `;

/**
 * __useReadJobEngineeringFormDataQuery__
 *
 * To run a query within a React component, call `useReadJobEngineeringFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadJobEngineeringFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadJobEngineeringFormDataQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useReadJobEngineeringFormDataQuery(baseOptions: Apollo.QueryHookOptions<ReadJobEngineeringFormDataQuery, ReadJobEngineeringFormDataQueryVariables>) {
        return Apollo.useQuery<ReadJobEngineeringFormDataQuery, ReadJobEngineeringFormDataQueryVariables>(ReadJobEngineeringFormDataDocument, baseOptions);
      }
export function useReadJobEngineeringFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadJobEngineeringFormDataQuery, ReadJobEngineeringFormDataQueryVariables>) {
          return Apollo.useLazyQuery<ReadJobEngineeringFormDataQuery, ReadJobEngineeringFormDataQueryVariables>(ReadJobEngineeringFormDataDocument, baseOptions);
        }
export type ReadJobEngineeringFormDataQueryHookResult = ReturnType<typeof useReadJobEngineeringFormDataQuery>;
export type ReadJobEngineeringFormDataLazyQueryHookResult = ReturnType<typeof useReadJobEngineeringFormDataLazyQuery>;
export type ReadJobEngineeringFormDataQueryResult = Apollo.QueryResult<ReadJobEngineeringFormDataQuery, ReadJobEngineeringFormDataQueryVariables>;
export const UpdateJobEngineeringFormDataDocument = gql`
    mutation updateJobEngineeringFormData($jobId: Int!, $formData: JobEngineeringInput!) {
  updateJobEngineeringFormData(jobId: $jobId, formData: $formData) {
    id
    data
  }
}
    `;
export type UpdateJobEngineeringFormDataMutationFn = Apollo.MutationFunction<UpdateJobEngineeringFormDataMutation, UpdateJobEngineeringFormDataMutationVariables>;

/**
 * __useUpdateJobEngineeringFormDataMutation__
 *
 * To run a mutation, you first call `useUpdateJobEngineeringFormDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobEngineeringFormDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobEngineeringFormDataMutation, { data, loading, error }] = useUpdateJobEngineeringFormDataMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      formData: // value for 'formData'
 *   },
 * });
 */
export function useUpdateJobEngineeringFormDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobEngineeringFormDataMutation, UpdateJobEngineeringFormDataMutationVariables>) {
        return Apollo.useMutation<UpdateJobEngineeringFormDataMutation, UpdateJobEngineeringFormDataMutationVariables>(UpdateJobEngineeringFormDataDocument, baseOptions);
      }
export type UpdateJobEngineeringFormDataMutationHookResult = ReturnType<typeof useUpdateJobEngineeringFormDataMutation>;
export type UpdateJobEngineeringFormDataMutationResult = Apollo.MutationResult<UpdateJobEngineeringFormDataMutation>;
export type UpdateJobEngineeringFormDataMutationOptions = Apollo.BaseMutationOptions<UpdateJobEngineeringFormDataMutation, UpdateJobEngineeringFormDataMutationVariables>;
export const ReadJobInspectionFormDataDocument = gql`
    query readJobInspectionFormData($jobId: Int!) {
  readJobInspectionFormData(jobId: $jobId) {
    id
    data
  }
}
    `;

/**
 * __useReadJobInspectionFormDataQuery__
 *
 * To run a query within a React component, call `useReadJobInspectionFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadJobInspectionFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadJobInspectionFormDataQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useReadJobInspectionFormDataQuery(baseOptions: Apollo.QueryHookOptions<ReadJobInspectionFormDataQuery, ReadJobInspectionFormDataQueryVariables>) {
        return Apollo.useQuery<ReadJobInspectionFormDataQuery, ReadJobInspectionFormDataQueryVariables>(ReadJobInspectionFormDataDocument, baseOptions);
      }
export function useReadJobInspectionFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadJobInspectionFormDataQuery, ReadJobInspectionFormDataQueryVariables>) {
          return Apollo.useLazyQuery<ReadJobInspectionFormDataQuery, ReadJobInspectionFormDataQueryVariables>(ReadJobInspectionFormDataDocument, baseOptions);
        }
export type ReadJobInspectionFormDataQueryHookResult = ReturnType<typeof useReadJobInspectionFormDataQuery>;
export type ReadJobInspectionFormDataLazyQueryHookResult = ReturnType<typeof useReadJobInspectionFormDataLazyQuery>;
export type ReadJobInspectionFormDataQueryResult = Apollo.QueryResult<ReadJobInspectionFormDataQuery, ReadJobInspectionFormDataQueryVariables>;
export const UpdateJobInspectionFormDataDocument = gql`
    mutation updateJobInspectionFormData($jobId: Int!, $formData: JobInspectionInput!) {
  updateJobInspectionFormData(jobId: $jobId, formData: $formData) {
    id
    data
  }
}
    `;
export type UpdateJobInspectionFormDataMutationFn = Apollo.MutationFunction<UpdateJobInspectionFormDataMutation, UpdateJobInspectionFormDataMutationVariables>;

/**
 * __useUpdateJobInspectionFormDataMutation__
 *
 * To run a mutation, you first call `useUpdateJobInspectionFormDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobInspectionFormDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobInspectionFormDataMutation, { data, loading, error }] = useUpdateJobInspectionFormDataMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      formData: // value for 'formData'
 *   },
 * });
 */
export function useUpdateJobInspectionFormDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobInspectionFormDataMutation, UpdateJobInspectionFormDataMutationVariables>) {
        return Apollo.useMutation<UpdateJobInspectionFormDataMutation, UpdateJobInspectionFormDataMutationVariables>(UpdateJobInspectionFormDataDocument, baseOptions);
      }
export type UpdateJobInspectionFormDataMutationHookResult = ReturnType<typeof useUpdateJobInspectionFormDataMutation>;
export type UpdateJobInspectionFormDataMutationResult = Apollo.MutationResult<UpdateJobInspectionFormDataMutation>;
export type UpdateJobInspectionFormDataMutationOptions = Apollo.BaseMutationOptions<UpdateJobInspectionFormDataMutation, UpdateJobInspectionFormDataMutationVariables>;
export const ReadJobInspectionChecklistFormDataDocument = gql`
    query readJobInspectionChecklistFormData($jobId: Int!) {
  readJobInspectionChecklistFormData(jobId: $jobId) {
    id
    data
  }
}
    `;

/**
 * __useReadJobInspectionChecklistFormDataQuery__
 *
 * To run a query within a React component, call `useReadJobInspectionChecklistFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadJobInspectionChecklistFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadJobInspectionChecklistFormDataQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useReadJobInspectionChecklistFormDataQuery(baseOptions: Apollo.QueryHookOptions<ReadJobInspectionChecklistFormDataQuery, ReadJobInspectionChecklistFormDataQueryVariables>) {
        return Apollo.useQuery<ReadJobInspectionChecklistFormDataQuery, ReadJobInspectionChecklistFormDataQueryVariables>(ReadJobInspectionChecklistFormDataDocument, baseOptions);
      }
export function useReadJobInspectionChecklistFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadJobInspectionChecklistFormDataQuery, ReadJobInspectionChecklistFormDataQueryVariables>) {
          return Apollo.useLazyQuery<ReadJobInspectionChecklistFormDataQuery, ReadJobInspectionChecklistFormDataQueryVariables>(ReadJobInspectionChecklistFormDataDocument, baseOptions);
        }
export type ReadJobInspectionChecklistFormDataQueryHookResult = ReturnType<typeof useReadJobInspectionChecklistFormDataQuery>;
export type ReadJobInspectionChecklistFormDataLazyQueryHookResult = ReturnType<typeof useReadJobInspectionChecklistFormDataLazyQuery>;
export type ReadJobInspectionChecklistFormDataQueryResult = Apollo.QueryResult<ReadJobInspectionChecklistFormDataQuery, ReadJobInspectionChecklistFormDataQueryVariables>;
export const UpdateJobInspectionChecklistFormDataDocument = gql`
    mutation updateJobInspectionChecklistFormData($jobId: Int!, $formData: JobInspectionChecklistInput!) {
  updateJobInspectionChecklistFormData(jobId: $jobId, formData: $formData) {
    id
    data
  }
}
    `;
export type UpdateJobInspectionChecklistFormDataMutationFn = Apollo.MutationFunction<UpdateJobInspectionChecklistFormDataMutation, UpdateJobInspectionChecklistFormDataMutationVariables>;

/**
 * __useUpdateJobInspectionChecklistFormDataMutation__
 *
 * To run a mutation, you first call `useUpdateJobInspectionChecklistFormDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobInspectionChecklistFormDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobInspectionChecklistFormDataMutation, { data, loading, error }] = useUpdateJobInspectionChecklistFormDataMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      formData: // value for 'formData'
 *   },
 * });
 */
export function useUpdateJobInspectionChecklistFormDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobInspectionChecklistFormDataMutation, UpdateJobInspectionChecklistFormDataMutationVariables>) {
        return Apollo.useMutation<UpdateJobInspectionChecklistFormDataMutation, UpdateJobInspectionChecklistFormDataMutationVariables>(UpdateJobInspectionChecklistFormDataDocument, baseOptions);
      }
export type UpdateJobInspectionChecklistFormDataMutationHookResult = ReturnType<typeof useUpdateJobInspectionChecklistFormDataMutation>;
export type UpdateJobInspectionChecklistFormDataMutationResult = Apollo.MutationResult<UpdateJobInspectionChecklistFormDataMutation>;
export type UpdateJobInspectionChecklistFormDataMutationOptions = Apollo.BaseMutationOptions<UpdateJobInspectionChecklistFormDataMutation, UpdateJobInspectionChecklistFormDataMutationVariables>;
export const FilterJobsDocument = gql`
    query FilterJobs($jobFilterCriteria: JobFilterCriteria, $page: Int, $rowsPerPage: Int) {
  filterJobs(
    jobFilterCriteria: $jobFilterCriteria
    page: $page
    rowsPerPage: $rowsPerPage
  ) {
    totalJobs
    page
    rowsPerPage
    jobs {
      id
      builder
      buildingDescription
      storeys
      confirmedWindRating
      deliveryDate
      detailingRequested
      engineeringRequested
      inspectionRequested
      prelimDesignRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      jobEngineering {
        id
        data
      }
      jobInspection {
        id
        data
      }
      jobInspectionChecklist {
        id
        data
      }
      jobNumber
      level
      organisation {
        id
        name
        address {
          addressLine1
          addressLine2
          country
          id
          postcode
          province
          suburb
        }
      }
      priority
      state
      windRating
    }
  }
}
    `;

/**
 * __useFilterJobsQuery__
 *
 * To run a query within a React component, call `useFilterJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterJobsQuery({
 *   variables: {
 *      jobFilterCriteria: // value for 'jobFilterCriteria'
 *      page: // value for 'page'
 *      rowsPerPage: // value for 'rowsPerPage'
 *   },
 * });
 */
export function useFilterJobsQuery(baseOptions?: Apollo.QueryHookOptions<FilterJobsQuery, FilterJobsQueryVariables>) {
        return Apollo.useQuery<FilterJobsQuery, FilterJobsQueryVariables>(FilterJobsDocument, baseOptions);
      }
export function useFilterJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterJobsQuery, FilterJobsQueryVariables>) {
          return Apollo.useLazyQuery<FilterJobsQuery, FilterJobsQueryVariables>(FilterJobsDocument, baseOptions);
        }
export type FilterJobsQueryHookResult = ReturnType<typeof useFilterJobsQuery>;
export type FilterJobsLazyQueryHookResult = ReturnType<typeof useFilterJobsLazyQuery>;
export type FilterJobsQueryResult = Apollo.QueryResult<FilterJobsQuery, FilterJobsQueryVariables>;
export const CreateJobDocument = gql`
    mutation CreateJob($job: JobInput!) {
  createJob(job: $job) {
    builder
    buildingDescription
    storeys
    level
    deliveryDate
    id
    jobNumber
    detailingRequested
    engineeringRequested
    inspectionRequested
    prelimDesignRequested
    engineeringIssued
    prelimDesignIssued
    inspectionIssued
    jobAddress {
      id
      localGovernment
      lot
      postcode
      province
      street
      streetNumber
      suburb
    }
    organisation {
      id
      name
    }
    priority
    state
  }
}
    `;
export type CreateJobMutationFn = Apollo.MutationFunction<CreateJobMutation, CreateJobMutationVariables>;

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      job: // value for 'job'
 *   },
 * });
 */
export function useCreateJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobMutation, CreateJobMutationVariables>) {
        return Apollo.useMutation<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument, baseOptions);
      }
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>;
export type CreateJobMutationResult = Apollo.MutationResult<CreateJobMutation>;
export type CreateJobMutationOptions = Apollo.BaseMutationOptions<CreateJobMutation, CreateJobMutationVariables>;
export const UpdateJobDocument = gql`
    mutation UpdateJob($job: JobInput!, $jobId: Int!) {
  updateJob(job: $job, jobId: $jobId) {
    builder
    level
    buildingDescription
    storeys
    deliveryDate
    id
    jobNumber
    detailingRequested
    engineeringRequested
    prelimDesignRequested
    inspectionRequested
    engineeringIssued
    prelimDesignIssued
    inspectionIssued
    jobAddress {
      id
      localGovernment
      lot
      postcode
      province
      street
      streetNumber
      suburb
    }
    organisation {
      id
      name
    }
    priority
    state
  }
}
    `;
export type UpdateJobMutationFn = Apollo.MutationFunction<UpdateJobMutation, UpdateJobMutationVariables>;

/**
 * __useUpdateJobMutation__
 *
 * To run a mutation, you first call `useUpdateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobMutation, { data, loading, error }] = useUpdateJobMutation({
 *   variables: {
 *      job: // value for 'job'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useUpdateJobMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobMutation, UpdateJobMutationVariables>) {
        return Apollo.useMutation<UpdateJobMutation, UpdateJobMutationVariables>(UpdateJobDocument, baseOptions);
      }
export type UpdateJobMutationHookResult = ReturnType<typeof useUpdateJobMutation>;
export type UpdateJobMutationResult = Apollo.MutationResult<UpdateJobMutation>;
export type UpdateJobMutationOptions = Apollo.BaseMutationOptions<UpdateJobMutation, UpdateJobMutationVariables>;
export const CreateOrganisationDocument = gql`
    mutation CreateOrganisation($organisation: OrganisationInput!) {
  createOrganisation(organisation: $organisation) {
    name
    address {
      addressLine1
      addressLine2
      postcode
      suburb
      province
      country
    }
  }
}
    `;
export type CreateOrganisationMutationFn = Apollo.MutationFunction<CreateOrganisationMutation, CreateOrganisationMutationVariables>;

/**
 * __useCreateOrganisationMutation__
 *
 * To run a mutation, you first call `useCreateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganisationMutation, { data, loading, error }] = useCreateOrganisationMutation({
 *   variables: {
 *      organisation: // value for 'organisation'
 *   },
 * });
 */
export function useCreateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>) {
        return Apollo.useMutation<CreateOrganisationMutation, CreateOrganisationMutationVariables>(CreateOrganisationDocument, baseOptions);
      }
export type CreateOrganisationMutationHookResult = ReturnType<typeof useCreateOrganisationMutation>;
export type CreateOrganisationMutationResult = Apollo.MutationResult<CreateOrganisationMutation>;
export type CreateOrganisationMutationOptions = Apollo.BaseMutationOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>;
export const UpdateOrganisationDocument = gql`
    mutation UpdateOrganisation($organisation: OrganisationInput!, $organisationId: Int!) {
  updateOrganisation(organisation: $organisation, organisationId: $organisationId) {
    name
    address {
      addressLine1
      addressLine2
      postcode
      suburb
      province
      country
    }
  }
}
    `;
export type UpdateOrganisationMutationFn = Apollo.MutationFunction<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;

/**
 * __useUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationMutation, { data, loading, error }] = useUpdateOrganisationMutation({
 *   variables: {
 *      organisation: // value for 'organisation'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useUpdateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>) {
        return Apollo.useMutation<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>(UpdateOrganisationDocument, baseOptions);
      }
export type UpdateOrganisationMutationHookResult = ReturnType<typeof useUpdateOrganisationMutation>;
export type UpdateOrganisationMutationResult = Apollo.MutationResult<UpdateOrganisationMutation>;
export type UpdateOrganisationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($user: UserInput!) {
  createUser(user: $user) {
    address {
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    }
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    phone
    position
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateCurrentUserDocument = gql`
    mutation UpdateCurrentUser($user: UserInput!, $userId: Int!) {
  updateUser(user: $user, userId: $userId) {
    address {
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    }
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    phone
    position
  }
}
    `;
export type UpdateCurrentUserMutationFn = Apollo.MutationFunction<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>;

/**
 * __useUpdateCurrentUserMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserMutation, { data, loading, error }] = useUpdateCurrentUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateCurrentUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>) {
        return Apollo.useMutation<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>(UpdateCurrentUserDocument, baseOptions);
      }
export type UpdateCurrentUserMutationHookResult = ReturnType<typeof useUpdateCurrentUserMutation>;
export type UpdateCurrentUserMutationResult = Apollo.MutationResult<UpdateCurrentUserMutation>;
export type UpdateCurrentUserMutationOptions = Apollo.BaseMutationOptions<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>;
export const ReadCurrentUserDocument = gql`
    query ReadCurrentUser {
  readCurrentUser {
    address {
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    }
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    organisation {
      address {
        addressLine1
        addressLine2
        country
        postcode
        province
        suburb
      }
      id
      name
    }
    phone
    position
    notificationSettings {
      eMail
      signalR
      id
    }
  }
}
    `;

/**
 * __useReadCurrentUserQuery__
 *
 * To run a query within a React component, call `useReadCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<ReadCurrentUserQuery, ReadCurrentUserQueryVariables>) {
        return Apollo.useQuery<ReadCurrentUserQuery, ReadCurrentUserQueryVariables>(ReadCurrentUserDocument, baseOptions);
      }
export function useReadCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCurrentUserQuery, ReadCurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<ReadCurrentUserQuery, ReadCurrentUserQueryVariables>(ReadCurrentUserDocument, baseOptions);
        }
export type ReadCurrentUserQueryHookResult = ReturnType<typeof useReadCurrentUserQuery>;
export type ReadCurrentUserLazyQueryHookResult = ReturnType<typeof useReadCurrentUserLazyQuery>;
export type ReadCurrentUserQueryResult = Apollo.QueryResult<ReadCurrentUserQuery, ReadCurrentUserQueryVariables>;
export const ReadUsersDocument = gql`
    query ReadUsers {
  readUsers {
    address {
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    }
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    organisation {
      address {
        addressLine1
        addressLine2
        country
        postcode
        province
        suburb
      }
      id
      name
    }
    phone
    position
  }
}
    `;

/**
 * __useReadUsersQuery__
 *
 * To run a query within a React component, call `useReadUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadUsersQuery(baseOptions?: Apollo.QueryHookOptions<ReadUsersQuery, ReadUsersQueryVariables>) {
        return Apollo.useQuery<ReadUsersQuery, ReadUsersQueryVariables>(ReadUsersDocument, baseOptions);
      }
export function useReadUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUsersQuery, ReadUsersQueryVariables>) {
          return Apollo.useLazyQuery<ReadUsersQuery, ReadUsersQueryVariables>(ReadUsersDocument, baseOptions);
        }
export type ReadUsersQueryHookResult = ReturnType<typeof useReadUsersQuery>;
export type ReadUsersLazyQueryHookResult = ReturnType<typeof useReadUsersLazyQuery>;
export type ReadUsersQueryResult = Apollo.QueryResult<ReadUsersQuery, ReadUsersQueryVariables>;
export const ReadUsersWithoutRoleDocument = gql`
    query ReadUsersWithoutRole {
  readUsersWithoutRole {
    address {
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    }
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    organisation {
      address {
        addressLine1
        addressLine2
        country
        postcode
        province
        suburb
      }
      id
      name
    }
    phone
    position
  }
}
    `;

/**
 * __useReadUsersWithoutRoleQuery__
 *
 * To run a query within a React component, call `useReadUsersWithoutRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUsersWithoutRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUsersWithoutRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadUsersWithoutRoleQuery(baseOptions?: Apollo.QueryHookOptions<ReadUsersWithoutRoleQuery, ReadUsersWithoutRoleQueryVariables>) {
        return Apollo.useQuery<ReadUsersWithoutRoleQuery, ReadUsersWithoutRoleQueryVariables>(ReadUsersWithoutRoleDocument, baseOptions);
      }
export function useReadUsersWithoutRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUsersWithoutRoleQuery, ReadUsersWithoutRoleQueryVariables>) {
          return Apollo.useLazyQuery<ReadUsersWithoutRoleQuery, ReadUsersWithoutRoleQueryVariables>(ReadUsersWithoutRoleDocument, baseOptions);
        }
export type ReadUsersWithoutRoleQueryHookResult = ReturnType<typeof useReadUsersWithoutRoleQuery>;
export type ReadUsersWithoutRoleLazyQueryHookResult = ReturnType<typeof useReadUsersWithoutRoleLazyQuery>;
export type ReadUsersWithoutRoleQueryResult = Apollo.QueryResult<ReadUsersWithoutRoleQuery, ReadUsersWithoutRoleQueryVariables>;
export const ReadCurrentOrganisationDocument = gql`
    query ReadCurrentOrganisation {
  readCurrentOrganisation {
    address {
      addressLine1
      addressLine2
      country
      id
      postcode
      postcode
      province
      suburb
    }
    id
    name
  }
}
    `;

/**
 * __useReadCurrentOrganisationQuery__
 *
 * To run a query within a React component, call `useReadCurrentOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCurrentOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCurrentOrganisationQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadCurrentOrganisationQuery(baseOptions?: Apollo.QueryHookOptions<ReadCurrentOrganisationQuery, ReadCurrentOrganisationQueryVariables>) {
        return Apollo.useQuery<ReadCurrentOrganisationQuery, ReadCurrentOrganisationQueryVariables>(ReadCurrentOrganisationDocument, baseOptions);
      }
export function useReadCurrentOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCurrentOrganisationQuery, ReadCurrentOrganisationQueryVariables>) {
          return Apollo.useLazyQuery<ReadCurrentOrganisationQuery, ReadCurrentOrganisationQueryVariables>(ReadCurrentOrganisationDocument, baseOptions);
        }
export type ReadCurrentOrganisationQueryHookResult = ReturnType<typeof useReadCurrentOrganisationQuery>;
export type ReadCurrentOrganisationLazyQueryHookResult = ReturnType<typeof useReadCurrentOrganisationLazyQuery>;
export type ReadCurrentOrganisationQueryResult = Apollo.QueryResult<ReadCurrentOrganisationQuery, ReadCurrentOrganisationQueryVariables>;
export const ReadOrganisationUsersDocument = gql`
    query ReadOrganisationUsers($organisationId: Int!) {
  readOrganisationUsers(organisationId: $organisationId) {
    address {
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    }
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    organisation {
      address {
        addressLine1
        addressLine2
        country
        postcode
        province
        suburb
      }
      id
      name
    }
    phone
    position
  }
}
    `;

/**
 * __useReadOrganisationUsersQuery__
 *
 * To run a query within a React component, call `useReadOrganisationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOrganisationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOrganisationUsersQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useReadOrganisationUsersQuery(baseOptions: Apollo.QueryHookOptions<ReadOrganisationUsersQuery, ReadOrganisationUsersQueryVariables>) {
        return Apollo.useQuery<ReadOrganisationUsersQuery, ReadOrganisationUsersQueryVariables>(ReadOrganisationUsersDocument, baseOptions);
      }
export function useReadOrganisationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOrganisationUsersQuery, ReadOrganisationUsersQueryVariables>) {
          return Apollo.useLazyQuery<ReadOrganisationUsersQuery, ReadOrganisationUsersQueryVariables>(ReadOrganisationUsersDocument, baseOptions);
        }
export type ReadOrganisationUsersQueryHookResult = ReturnType<typeof useReadOrganisationUsersQuery>;
export type ReadOrganisationUsersLazyQueryHookResult = ReturnType<typeof useReadOrganisationUsersLazyQuery>;
export type ReadOrganisationUsersQueryResult = Apollo.QueryResult<ReadOrganisationUsersQuery, ReadOrganisationUsersQueryVariables>;
export const AdvanceJobDocument = gql`
    mutation AdvanceJob($jobId: Int!) {
  advanceJob(jobId: $jobId) {
    builder
    level
    buildingDescription
    storeys
    deliveryDate
    id
    jobNumber
    detailingRequested
    engineeringRequested
    prelimDesignRequested
    inspectionRequested
    engineeringIssued
    prelimDesignIssued
    inspectionIssued
    jobAddress {
      id
      localGovernment
      lot
      postcode
      province
      street
      streetNumber
      suburb
    }
    organisation {
      id
      name
    }
    priority
    state
  }
}
    `;
export type AdvanceJobMutationFn = Apollo.MutationFunction<AdvanceJobMutation, AdvanceJobMutationVariables>;

/**
 * __useAdvanceJobMutation__
 *
 * To run a mutation, you first call `useAdvanceJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvanceJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advanceJobMutation, { data, loading, error }] = useAdvanceJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useAdvanceJobMutation(baseOptions?: Apollo.MutationHookOptions<AdvanceJobMutation, AdvanceJobMutationVariables>) {
        return Apollo.useMutation<AdvanceJobMutation, AdvanceJobMutationVariables>(AdvanceJobDocument, baseOptions);
      }
export type AdvanceJobMutationHookResult = ReturnType<typeof useAdvanceJobMutation>;
export type AdvanceJobMutationResult = Apollo.MutationResult<AdvanceJobMutation>;
export type AdvanceJobMutationOptions = Apollo.BaseMutationOptions<AdvanceJobMutation, AdvanceJobMutationVariables>;
export const RejectJobDocument = gql`
    mutation RejectJob($jobId: Int!, $comment: String!) {
  rejectJob(jobId: $jobId, comment: $comment) {
    builder
    level
    buildingDescription
    storeys
    deliveryDate
    id
    jobNumber
    detailingRequested
    engineeringRequested
    prelimDesignRequested
    inspectionRequested
    engineeringIssued
    prelimDesignIssued
    inspectionIssued
    jobAddress {
      id
      localGovernment
      lot
      postcode
      province
      street
      streetNumber
      suburb
    }
    organisation {
      id
      name
    }
    priority
    state
  }
}
    `;
export type RejectJobMutationFn = Apollo.MutationFunction<RejectJobMutation, RejectJobMutationVariables>;

/**
 * __useRejectJobMutation__
 *
 * To run a mutation, you first call `useRejectJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectJobMutation, { data, loading, error }] = useRejectJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useRejectJobMutation(baseOptions?: Apollo.MutationHookOptions<RejectJobMutation, RejectJobMutationVariables>) {
        return Apollo.useMutation<RejectJobMutation, RejectJobMutationVariables>(RejectJobDocument, baseOptions);
      }
export type RejectJobMutationHookResult = ReturnType<typeof useRejectJobMutation>;
export type RejectJobMutationResult = Apollo.MutationResult<RejectJobMutation>;
export type RejectJobMutationOptions = Apollo.BaseMutationOptions<RejectJobMutation, RejectJobMutationVariables>;
export const RevertRectifyDetailsJobDocument = gql`
    mutation RevertRectifyDetailsJob($jobId: Int!) {
  revertRectifyDetailsJob(jobId: $jobId) {
    builder
    level
    buildingDescription
    storeys
    deliveryDate
    id
    jobNumber
    detailingRequested
    engineeringRequested
    prelimDesignRequested
    inspectionRequested
    engineeringIssued
    prelimDesignIssued
    inspectionIssued
    jobAddress {
      id
      localGovernment
      lot
      postcode
      province
      street
      streetNumber
      suburb
    }
    organisation {
      id
      name
    }
    priority
    state
  }
}
    `;
export type RevertRectifyDetailsJobMutationFn = Apollo.MutationFunction<RevertRectifyDetailsJobMutation, RevertRectifyDetailsJobMutationVariables>;

/**
 * __useRevertRectifyDetailsJobMutation__
 *
 * To run a mutation, you first call `useRevertRectifyDetailsJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertRectifyDetailsJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertRectifyDetailsJobMutation, { data, loading, error }] = useRevertRectifyDetailsJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useRevertRectifyDetailsJobMutation(baseOptions?: Apollo.MutationHookOptions<RevertRectifyDetailsJobMutation, RevertRectifyDetailsJobMutationVariables>) {
        return Apollo.useMutation<RevertRectifyDetailsJobMutation, RevertRectifyDetailsJobMutationVariables>(RevertRectifyDetailsJobDocument, baseOptions);
      }
export type RevertRectifyDetailsJobMutationHookResult = ReturnType<typeof useRevertRectifyDetailsJobMutation>;
export type RevertRectifyDetailsJobMutationResult = Apollo.MutationResult<RevertRectifyDetailsJobMutation>;
export type RevertRectifyDetailsJobMutationOptions = Apollo.BaseMutationOptions<RevertRectifyDetailsJobMutation, RevertRectifyDetailsJobMutationVariables>;
export const RevertRequestInspectionJobDocument = gql`
    mutation RevertRequestInspectionJob($jobId: Int!) {
  revertRequestInspectionJob(jobId: $jobId) {
    builder
    level
    buildingDescription
    storeys
    deliveryDate
    id
    jobNumber
    detailingRequested
    engineeringRequested
    prelimDesignRequested
    inspectionRequested
    engineeringIssued
    prelimDesignIssued
    inspectionIssued
    jobAddress {
      id
      localGovernment
      lot
      postcode
      province
      street
      streetNumber
      suburb
    }
    organisation {
      id
      name
    }
    priority
    state
  }
}
    `;
export type RevertRequestInspectionJobMutationFn = Apollo.MutationFunction<RevertRequestInspectionJobMutation, RevertRequestInspectionJobMutationVariables>;

/**
 * __useRevertRequestInspectionJobMutation__
 *
 * To run a mutation, you first call `useRevertRequestInspectionJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertRequestInspectionJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertRequestInspectionJobMutation, { data, loading, error }] = useRevertRequestInspectionJobMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useRevertRequestInspectionJobMutation(baseOptions?: Apollo.MutationHookOptions<RevertRequestInspectionJobMutation, RevertRequestInspectionJobMutationVariables>) {
        return Apollo.useMutation<RevertRequestInspectionJobMutation, RevertRequestInspectionJobMutationVariables>(RevertRequestInspectionJobDocument, baseOptions);
      }
export type RevertRequestInspectionJobMutationHookResult = ReturnType<typeof useRevertRequestInspectionJobMutation>;
export type RevertRequestInspectionJobMutationResult = Apollo.MutationResult<RevertRequestInspectionJobMutation>;
export type RevertRequestInspectionJobMutationOptions = Apollo.BaseMutationOptions<RevertRequestInspectionJobMutation, RevertRequestInspectionJobMutationVariables>;
export const CreateInvitationDocument = gql`
    mutation CreateInvitation($email: String!) {
  createInvitation(email: $email) {
    code
    expiryDate
    id
    userEmail
  }
}
    `;
export type CreateInvitationMutationFn = Apollo.MutationFunction<CreateInvitationMutation, CreateInvitationMutationVariables>;

/**
 * __useCreateInvitationMutation__
 *
 * To run a mutation, you first call `useCreateInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitationMutation, { data, loading, error }] = useCreateInvitationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvitationMutation, CreateInvitationMutationVariables>) {
        return Apollo.useMutation<CreateInvitationMutation, CreateInvitationMutationVariables>(CreateInvitationDocument, baseOptions);
      }
export type CreateInvitationMutationHookResult = ReturnType<typeof useCreateInvitationMutation>;
export type CreateInvitationMutationResult = Apollo.MutationResult<CreateInvitationMutation>;
export type CreateInvitationMutationOptions = Apollo.BaseMutationOptions<CreateInvitationMutation, CreateInvitationMutationVariables>;
export const AcceptInvitationDocument = gql`
    mutation AcceptInvitation($code: String!) {
  acceptInvitation(code: $code) {
    address {
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    }
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    organisation {
      address {
        addressLine1
        addressLine2
        country
        postcode
        province
        suburb
      }
      id
      name
    }
    phone
    position
  }
}
    `;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<AcceptInvitationMutation, AcceptInvitationMutationVariables>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
        return Apollo.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, baseOptions);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = Apollo.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export const ReadRolesDocument = gql`
    query ReadRoles($userId: Int!) {
  readRoles(userId: $userId)
}
    `;

/**
 * __useReadRolesQuery__
 *
 * To run a query within a React component, call `useReadRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadRolesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReadRolesQuery(baseOptions: Apollo.QueryHookOptions<ReadRolesQuery, ReadRolesQueryVariables>) {
        return Apollo.useQuery<ReadRolesQuery, ReadRolesQueryVariables>(ReadRolesDocument, baseOptions);
      }
export function useReadRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadRolesQuery, ReadRolesQueryVariables>) {
          return Apollo.useLazyQuery<ReadRolesQuery, ReadRolesQueryVariables>(ReadRolesDocument, baseOptions);
        }
export type ReadRolesQueryHookResult = ReturnType<typeof useReadRolesQuery>;
export type ReadRolesLazyQueryHookResult = ReturnType<typeof useReadRolesLazyQuery>;
export type ReadRolesQueryResult = Apollo.QueryResult<ReadRolesQuery, ReadRolesQueryVariables>;
export const ReadUserDocument = gql`
    query ReadUser($userId: Int!) {
  readUser(userId: $userId) {
    address {
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    }
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    organisation {
      address {
        addressLine1
        addressLine2
        country
        postcode
        province
        suburb
      }
      id
      name
    }
    phone
    position
    notificationSettings {
      eMail
      signalR
      id
    }
  }
}
    `;

/**
 * __useReadUserQuery__
 *
 * To run a query within a React component, call `useReadUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useReadUserQuery(baseOptions: Apollo.QueryHookOptions<ReadUserQuery, ReadUserQueryVariables>) {
        return Apollo.useQuery<ReadUserQuery, ReadUserQueryVariables>(ReadUserDocument, baseOptions);
      }
export function useReadUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUserQuery, ReadUserQueryVariables>) {
          return Apollo.useLazyQuery<ReadUserQuery, ReadUserQueryVariables>(ReadUserDocument, baseOptions);
        }
export type ReadUserQueryHookResult = ReturnType<typeof useReadUserQuery>;
export type ReadUserLazyQueryHookResult = ReturnType<typeof useReadUserLazyQuery>;
export type ReadUserQueryResult = Apollo.QueryResult<ReadUserQuery, ReadUserQueryVariables>;
export const AssignRoleDocument = gql`
    mutation AssignRole($userId: Int!, $role: String!) {
  assignRole(userId: $userId, role: $role)
}
    `;
export type AssignRoleMutationFn = Apollo.MutationFunction<AssignRoleMutation, AssignRoleMutationVariables>;

/**
 * __useAssignRoleMutation__
 *
 * To run a mutation, you first call `useAssignRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignRoleMutation, { data, loading, error }] = useAssignRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useAssignRoleMutation(baseOptions?: Apollo.MutationHookOptions<AssignRoleMutation, AssignRoleMutationVariables>) {
        return Apollo.useMutation<AssignRoleMutation, AssignRoleMutationVariables>(AssignRoleDocument, baseOptions);
      }
export type AssignRoleMutationHookResult = ReturnType<typeof useAssignRoleMutation>;
export type AssignRoleMutationResult = Apollo.MutationResult<AssignRoleMutation>;
export type AssignRoleMutationOptions = Apollo.BaseMutationOptions<AssignRoleMutation, AssignRoleMutationVariables>;
export const RemoveRoleDocument = gql`
    mutation RemoveRole($userId: Int!, $role: String!) {
  removeRole(userId: $userId, role: $role)
}
    `;
export type RemoveRoleMutationFn = Apollo.MutationFunction<RemoveRoleMutation, RemoveRoleMutationVariables>;

/**
 * __useRemoveRoleMutation__
 *
 * To run a mutation, you first call `useRemoveRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRoleMutation, { data, loading, error }] = useRemoveRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useRemoveRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRoleMutation, RemoveRoleMutationVariables>) {
        return Apollo.useMutation<RemoveRoleMutation, RemoveRoleMutationVariables>(RemoveRoleDocument, baseOptions);
      }
export type RemoveRoleMutationHookResult = ReturnType<typeof useRemoveRoleMutation>;
export type RemoveRoleMutationResult = Apollo.MutationResult<RemoveRoleMutation>;
export type RemoveRoleMutationOptions = Apollo.BaseMutationOptions<RemoveRoleMutation, RemoveRoleMutationVariables>;
export const ReadOrganisationsDocument = gql`
    query ReadOrganisations {
  readOrganisations {
    address {
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    }
    id
    name
  }
}
    `;

/**
 * __useReadOrganisationsQuery__
 *
 * To run a query within a React component, call `useReadOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadOrganisationsQuery(baseOptions?: Apollo.QueryHookOptions<ReadOrganisationsQuery, ReadOrganisationsQueryVariables>) {
        return Apollo.useQuery<ReadOrganisationsQuery, ReadOrganisationsQueryVariables>(ReadOrganisationsDocument, baseOptions);
      }
export function useReadOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOrganisationsQuery, ReadOrganisationsQueryVariables>) {
          return Apollo.useLazyQuery<ReadOrganisationsQuery, ReadOrganisationsQueryVariables>(ReadOrganisationsDocument, baseOptions);
        }
export type ReadOrganisationsQueryHookResult = ReturnType<typeof useReadOrganisationsQuery>;
export type ReadOrganisationsLazyQueryHookResult = ReturnType<typeof useReadOrganisationsLazyQuery>;
export type ReadOrganisationsQueryResult = Apollo.QueryResult<ReadOrganisationsQuery, ReadOrganisationsQueryVariables>;
export const ReadJobAuditDocument = gql`
    query ReadJobAudit($jobId: Int!) {
  readJobAudit(jobId: $jobId) {
    id
    dateTime
    name
    jobId
    userId
  }
}
    `;

/**
 * __useReadJobAuditQuery__
 *
 * To run a query within a React component, call `useReadJobAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadJobAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadJobAuditQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useReadJobAuditQuery(baseOptions: Apollo.QueryHookOptions<ReadJobAuditQuery, ReadJobAuditQueryVariables>) {
        return Apollo.useQuery<ReadJobAuditQuery, ReadJobAuditQueryVariables>(ReadJobAuditDocument, baseOptions);
      }
export function useReadJobAuditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadJobAuditQuery, ReadJobAuditQueryVariables>) {
          return Apollo.useLazyQuery<ReadJobAuditQuery, ReadJobAuditQueryVariables>(ReadJobAuditDocument, baseOptions);
        }
export type ReadJobAuditQueryHookResult = ReturnType<typeof useReadJobAuditQuery>;
export type ReadJobAuditLazyQueryHookResult = ReturnType<typeof useReadJobAuditLazyQuery>;
export type ReadJobAuditQueryResult = Apollo.QueryResult<ReadJobAuditQuery, ReadJobAuditQueryVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($jobId: Int!, $text: String!) {
  createComment(jobId: $jobId, text: $text) {
    dateTime
    id
    text
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, baseOptions);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const ReadCommentsDocument = gql`
    query ReadComments($jobId: Int!, $page: Int!, $rowsPerPage: Int!) {
  readComments(jobId: $jobId, page: $page, rowsPerPage: $rowsPerPage) {
    comments {
      dateTime
      id
      text
      user {
        firstName
        lastName
      }
    }
    page
    rowsPerPage
    totalComments
  }
}
    `;

/**
 * __useReadCommentsQuery__
 *
 * To run a query within a React component, call `useReadCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCommentsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      page: // value for 'page'
 *      rowsPerPage: // value for 'rowsPerPage'
 *   },
 * });
 */
export function useReadCommentsQuery(baseOptions: Apollo.QueryHookOptions<ReadCommentsQuery, ReadCommentsQueryVariables>) {
        return Apollo.useQuery<ReadCommentsQuery, ReadCommentsQueryVariables>(ReadCommentsDocument, baseOptions);
      }
export function useReadCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCommentsQuery, ReadCommentsQueryVariables>) {
          return Apollo.useLazyQuery<ReadCommentsQuery, ReadCommentsQueryVariables>(ReadCommentsDocument, baseOptions);
        }
export type ReadCommentsQueryHookResult = ReturnType<typeof useReadCommentsQuery>;
export type ReadCommentsLazyQueryHookResult = ReturnType<typeof useReadCommentsLazyQuery>;
export type ReadCommentsQueryResult = Apollo.QueryResult<ReadCommentsQuery, ReadCommentsQueryVariables>;
export const RequestQuoteDocument = gql`
    mutation RequestQuote($RequestQuote: RequestQuoteInput!) {
  requestQuote(requestQuote: $RequestQuote) {
    firstName
    lastName
    eMail
    mobileNumber
    message
  }
}
    `;
export type RequestQuoteMutationFn = Apollo.MutationFunction<RequestQuoteMutation, RequestQuoteMutationVariables>;

/**
 * __useRequestQuoteMutation__
 *
 * To run a mutation, you first call `useRequestQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestQuoteMutation, { data, loading, error }] = useRequestQuoteMutation({
 *   variables: {
 *      RequestQuote: // value for 'RequestQuote'
 *   },
 * });
 */
export function useRequestQuoteMutation(baseOptions?: Apollo.MutationHookOptions<RequestQuoteMutation, RequestQuoteMutationVariables>) {
        return Apollo.useMutation<RequestQuoteMutation, RequestQuoteMutationVariables>(RequestQuoteDocument, baseOptions);
      }
export type RequestQuoteMutationHookResult = ReturnType<typeof useRequestQuoteMutation>;
export type RequestQuoteMutationResult = Apollo.MutationResult<RequestQuoteMutation>;
export type RequestQuoteMutationOptions = Apollo.BaseMutationOptions<RequestQuoteMutation, RequestQuoteMutationVariables>;
export const MaintenanceModeDocument = gql`
    query MaintenanceMode {
  maintenanceMode
}
    `;

/**
 * __useMaintenanceModeQuery__
 *
 * To run a query within a React component, call `useMaintenanceModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceModeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMaintenanceModeQuery(baseOptions?: Apollo.QueryHookOptions<MaintenanceModeQuery, MaintenanceModeQueryVariables>) {
        return Apollo.useQuery<MaintenanceModeQuery, MaintenanceModeQueryVariables>(MaintenanceModeDocument, baseOptions);
      }
export function useMaintenanceModeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceModeQuery, MaintenanceModeQueryVariables>) {
          return Apollo.useLazyQuery<MaintenanceModeQuery, MaintenanceModeQueryVariables>(MaintenanceModeDocument, baseOptions);
        }
export type MaintenanceModeQueryHookResult = ReturnType<typeof useMaintenanceModeQuery>;
export type MaintenanceModeLazyQueryHookResult = ReturnType<typeof useMaintenanceModeLazyQuery>;
export type MaintenanceModeQueryResult = Apollo.QueryResult<MaintenanceModeQuery, MaintenanceModeQueryVariables>;
export const UpdateMaintenanceModeDocument = gql`
    mutation UpdateMaintenanceMode($state: Int!) {
  updataMaintenanceMode(state: $state)
}
    `;
export type UpdateMaintenanceModeMutationFn = Apollo.MutationFunction<UpdateMaintenanceModeMutation, UpdateMaintenanceModeMutationVariables>;

/**
 * __useUpdateMaintenanceModeMutation__
 *
 * To run a mutation, you first call `useUpdateMaintenanceModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaintenanceModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaintenanceModeMutation, { data, loading, error }] = useUpdateMaintenanceModeMutation({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateMaintenanceModeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMaintenanceModeMutation, UpdateMaintenanceModeMutationVariables>) {
        return Apollo.useMutation<UpdateMaintenanceModeMutation, UpdateMaintenanceModeMutationVariables>(UpdateMaintenanceModeDocument, baseOptions);
      }
export type UpdateMaintenanceModeMutationHookResult = ReturnType<typeof useUpdateMaintenanceModeMutation>;
export type UpdateMaintenanceModeMutationResult = Apollo.MutationResult<UpdateMaintenanceModeMutation>;
export type UpdateMaintenanceModeMutationOptions = Apollo.BaseMutationOptions<UpdateMaintenanceModeMutation, UpdateMaintenanceModeMutationVariables>;
export const ReadJobTasksDocument = gql`
    query ReadJobTasks($JobId: Int!) {
  readJobTasks(jobId: $JobId) {
    id
    assignedBy {
      address {
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      }
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      mobileNumber
      organisation {
        address {
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    assignedTo {
      address {
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      }
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      mobileNumber
      organisation {
        address {
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    description
    subject
    dueDate
    finishedDate
    taskStatus
    job {
      builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      inspectionRequested
      prelimDesignRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      organisation {
        id
        name
      }
      priority
      state
    }
  }
}
    `;

/**
 * __useReadJobTasksQuery__
 *
 * To run a query within a React component, call `useReadJobTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadJobTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadJobTasksQuery({
 *   variables: {
 *      JobId: // value for 'JobId'
 *   },
 * });
 */
export function useReadJobTasksQuery(baseOptions: Apollo.QueryHookOptions<ReadJobTasksQuery, ReadJobTasksQueryVariables>) {
        return Apollo.useQuery<ReadJobTasksQuery, ReadJobTasksQueryVariables>(ReadJobTasksDocument, baseOptions);
      }
export function useReadJobTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadJobTasksQuery, ReadJobTasksQueryVariables>) {
          return Apollo.useLazyQuery<ReadJobTasksQuery, ReadJobTasksQueryVariables>(ReadJobTasksDocument, baseOptions);
        }
export type ReadJobTasksQueryHookResult = ReturnType<typeof useReadJobTasksQuery>;
export type ReadJobTasksLazyQueryHookResult = ReturnType<typeof useReadJobTasksLazyQuery>;
export type ReadJobTasksQueryResult = Apollo.QueryResult<ReadJobTasksQuery, ReadJobTasksQueryVariables>;
export const ReadTaskByIdDocument = gql`
    query ReadTaskById($taskId: Int!) {
  readTaskById(taskId: $taskId) {
    id
    assignedBy {
      address {
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      }
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation {
        address {
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    assignedTo {
      address {
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      }
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation {
        address {
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    description
    subject
    dueDate
    finishedDate
    taskStatus
    job {
      builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      inspectionRequested
      prelimDesignRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      organisation {
        id
        name
      }
      priority
      state
    }
  }
}
    `;

/**
 * __useReadTaskByIdQuery__
 *
 * To run a query within a React component, call `useReadTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadTaskByIdQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useReadTaskByIdQuery(baseOptions: Apollo.QueryHookOptions<ReadTaskByIdQuery, ReadTaskByIdQueryVariables>) {
        return Apollo.useQuery<ReadTaskByIdQuery, ReadTaskByIdQueryVariables>(ReadTaskByIdDocument, baseOptions);
      }
export function useReadTaskByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadTaskByIdQuery, ReadTaskByIdQueryVariables>) {
          return Apollo.useLazyQuery<ReadTaskByIdQuery, ReadTaskByIdQueryVariables>(ReadTaskByIdDocument, baseOptions);
        }
export type ReadTaskByIdQueryHookResult = ReturnType<typeof useReadTaskByIdQuery>;
export type ReadTaskByIdLazyQueryHookResult = ReturnType<typeof useReadTaskByIdLazyQuery>;
export type ReadTaskByIdQueryResult = Apollo.QueryResult<ReadTaskByIdQuery, ReadTaskByIdQueryVariables>;
export const ReadUserTasksDocument = gql`
    query ReadUserTasks {
  readUserTasks {
    id
    assignedBy {
      address {
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      }
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation {
        address {
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    assignedTo {
      address {
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      }
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation {
        address {
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    description
    subject
    dueDate
    finishedDate
    taskStatus
    job {
      builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      inspectionRequested
      prelimDesignRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      organisation {
        id
        name
      }
      priority
      state
    }
  }
}
    `;

/**
 * __useReadUserTasksQuery__
 *
 * To run a query within a React component, call `useReadUserTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadUserTasksQuery(baseOptions?: Apollo.QueryHookOptions<ReadUserTasksQuery, ReadUserTasksQueryVariables>) {
        return Apollo.useQuery<ReadUserTasksQuery, ReadUserTasksQueryVariables>(ReadUserTasksDocument, baseOptions);
      }
export function useReadUserTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUserTasksQuery, ReadUserTasksQueryVariables>) {
          return Apollo.useLazyQuery<ReadUserTasksQuery, ReadUserTasksQueryVariables>(ReadUserTasksDocument, baseOptions);
        }
export type ReadUserTasksQueryHookResult = ReturnType<typeof useReadUserTasksQuery>;
export type ReadUserTasksLazyQueryHookResult = ReturnType<typeof useReadUserTasksLazyQuery>;
export type ReadUserTasksQueryResult = Apollo.QueryResult<ReadUserTasksQuery, ReadUserTasksQueryVariables>;
export const CreateTaskDocument = gql`
    mutation CreateTask($task: TaskInput!, $taskType: TaskTypeEnum!) {
  createTask(task: $task, taskType: $taskType) {
    id
    assignedBy {
      address {
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      }
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation {
        address {
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    assignedTo {
      address {
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      }
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation {
        address {
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    description
    subject
    dueDate
    finishedDate
    taskStatus
    job {
      builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      inspectionRequested
      prelimDesignRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      organisation {
        id
        name
      }
      priority
      state
    }
  }
}
    `;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      task: // value for 'task'
 *      taskType: // value for 'taskType'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, baseOptions);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const FilterTasksDocument = gql`
    query FilterTasks($taskFilterCriteria: TaskFilterCriteria!) {
  filterTasks(taskFilterCriteria: $taskFilterCriteria) {
    id
    assignedBy {
      address {
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      }
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation {
        address {
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    assignedTo {
      address {
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      }
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation {
        address {
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    description
    subject
    dueDate
    finishedDate
    taskStatus
    job {
      builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      inspectionRequested
      prelimDesignRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      organisation {
        id
        name
      }
      priority
      state
    }
  }
}
    `;

/**
 * __useFilterTasksQuery__
 *
 * To run a query within a React component, call `useFilterTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterTasksQuery({
 *   variables: {
 *      taskFilterCriteria: // value for 'taskFilterCriteria'
 *   },
 * });
 */
export function useFilterTasksQuery(baseOptions: Apollo.QueryHookOptions<FilterTasksQuery, FilterTasksQueryVariables>) {
        return Apollo.useQuery<FilterTasksQuery, FilterTasksQueryVariables>(FilterTasksDocument, baseOptions);
      }
export function useFilterTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterTasksQuery, FilterTasksQueryVariables>) {
          return Apollo.useLazyQuery<FilterTasksQuery, FilterTasksQueryVariables>(FilterTasksDocument, baseOptions);
        }
export type FilterTasksQueryHookResult = ReturnType<typeof useFilterTasksQuery>;
export type FilterTasksLazyQueryHookResult = ReturnType<typeof useFilterTasksLazyQuery>;
export type FilterTasksQueryResult = Apollo.QueryResult<FilterTasksQuery, FilterTasksQueryVariables>;
export const UpdateTaskStateDocument = gql`
    mutation UpdateTaskState($taskId: Int!, $taskStatus: TaskStatus!) {
  updateTaskState(taskId: $taskId, taskStatus: $taskStatus) {
    taskStatus
  }
}
    `;
export type UpdateTaskStateMutationFn = Apollo.MutationFunction<UpdateTaskStateMutation, UpdateTaskStateMutationVariables>;

/**
 * __useUpdateTaskStateMutation__
 *
 * To run a mutation, you first call `useUpdateTaskStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskStateMutation, { data, loading, error }] = useUpdateTaskStateMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      taskStatus: // value for 'taskStatus'
 *   },
 * });
 */
export function useUpdateTaskStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskStateMutation, UpdateTaskStateMutationVariables>) {
        return Apollo.useMutation<UpdateTaskStateMutation, UpdateTaskStateMutationVariables>(UpdateTaskStateDocument, baseOptions);
      }
export type UpdateTaskStateMutationHookResult = ReturnType<typeof useUpdateTaskStateMutation>;
export type UpdateTaskStateMutationResult = Apollo.MutationResult<UpdateTaskStateMutation>;
export type UpdateTaskStateMutationOptions = Apollo.BaseMutationOptions<UpdateTaskStateMutation, UpdateTaskStateMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation UpdateTask($task: TaskInput!, $id: Int!) {
  updateTask(task: $task, id: $id) {
    id
    assignedBy {
      address {
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      }
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation {
        address {
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    assignedTo {
      address {
        addressLine1
        addressLine2
        country
        id
        postcode
        province
        suburb
      }
      contactPerson
      firstName
      id
      lastName
      licenseClass
      licenseNumber
      victoriaLicenseNumber
      licenseArea
      mobileNumber
      organisation {
        address {
          addressLine1
          addressLine2
          country
          postcode
          province
          suburb
        }
        id
        name
      }
      phone
      position
    }
    description
    subject
    dueDate
    finishedDate
    taskStatus
    job {
      builder
      level
      buildingDescription
      storeys
      deliveryDate
      id
      jobNumber
      detailingRequested
      engineeringRequested
      inspectionRequested
      prelimDesignRequested
      engineeringIssued
      prelimDesignIssued
      inspectionIssued
      jobAddress {
        id
        localGovernment
        lot
        postcode
        province
        street
        streetNumber
        suburb
      }
      organisation {
        id
        name
      }
      priority
      state
    }
  }
}
    `;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      task: // value for 'task'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, baseOptions);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const ReadCurrentOrganisationUsersDocument = gql`
    query ReadCurrentOrganisationUsers {
  readCurrentOrganisationUsers {
    address {
      addressLine1
      addressLine2
      country
      id
      postcode
      province
      suburb
    }
    contactPerson
    firstName
    id
    lastName
    licenseClass
    licenseNumber
    victoriaLicenseNumber
    licenseArea
    mobileNumber
    phone
    position
  }
}
    `;

/**
 * __useReadCurrentOrganisationUsersQuery__
 *
 * To run a query within a React component, call `useReadCurrentOrganisationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCurrentOrganisationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCurrentOrganisationUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadCurrentOrganisationUsersQuery(baseOptions?: Apollo.QueryHookOptions<ReadCurrentOrganisationUsersQuery, ReadCurrentOrganisationUsersQueryVariables>) {
        return Apollo.useQuery<ReadCurrentOrganisationUsersQuery, ReadCurrentOrganisationUsersQueryVariables>(ReadCurrentOrganisationUsersDocument, baseOptions);
      }
export function useReadCurrentOrganisationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadCurrentOrganisationUsersQuery, ReadCurrentOrganisationUsersQueryVariables>) {
          return Apollo.useLazyQuery<ReadCurrentOrganisationUsersQuery, ReadCurrentOrganisationUsersQueryVariables>(ReadCurrentOrganisationUsersDocument, baseOptions);
        }
export type ReadCurrentOrganisationUsersQueryHookResult = ReturnType<typeof useReadCurrentOrganisationUsersQuery>;
export type ReadCurrentOrganisationUsersLazyQueryHookResult = ReturnType<typeof useReadCurrentOrganisationUsersLazyQuery>;
export type ReadCurrentOrganisationUsersQueryResult = Apollo.QueryResult<ReadCurrentOrganisationUsersQuery, ReadCurrentOrganisationUsersQueryVariables>;
export const MarkNotificationAsReceivedDocument = gql`
    mutation MarkNotificationAsReceived($notificationId: Int!) {
  markNotificationAsReceived(notificationId: $notificationId) {
    id
    notification {
      dateTime
      description
      id
      subject
      type
    }
    received
  }
}
    `;
export type MarkNotificationAsReceivedMutationFn = Apollo.MutationFunction<MarkNotificationAsReceivedMutation, MarkNotificationAsReceivedMutationVariables>;

/**
 * __useMarkNotificationAsReceivedMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsReceivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReceivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReceivedMutation, { data, loading, error }] = useMarkNotificationAsReceivedMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useMarkNotificationAsReceivedMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationAsReceivedMutation, MarkNotificationAsReceivedMutationVariables>) {
        return Apollo.useMutation<MarkNotificationAsReceivedMutation, MarkNotificationAsReceivedMutationVariables>(MarkNotificationAsReceivedDocument, baseOptions);
      }
export type MarkNotificationAsReceivedMutationHookResult = ReturnType<typeof useMarkNotificationAsReceivedMutation>;
export type MarkNotificationAsReceivedMutationResult = Apollo.MutationResult<MarkNotificationAsReceivedMutation>;
export type MarkNotificationAsReceivedMutationOptions = Apollo.BaseMutationOptions<MarkNotificationAsReceivedMutation, MarkNotificationAsReceivedMutationVariables>;
export const UpdateJobStateDocument = gql`
    mutation updateJobState($jobId: Int!, $state: JobStateEnum!) {
  updateJobState(jobId: $jobId, state: $state) {
    id
  }
}
    `;
export type UpdateJobStateMutationFn = Apollo.MutationFunction<UpdateJobStateMutation, UpdateJobStateMutationVariables>;

/**
 * __useUpdateJobStateMutation__
 *
 * To run a mutation, you first call `useUpdateJobStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobStateMutation, { data, loading, error }] = useUpdateJobStateMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useUpdateJobStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobStateMutation, UpdateJobStateMutationVariables>) {
        return Apollo.useMutation<UpdateJobStateMutation, UpdateJobStateMutationVariables>(UpdateJobStateDocument, baseOptions);
      }
export type UpdateJobStateMutationHookResult = ReturnType<typeof useUpdateJobStateMutation>;
export type UpdateJobStateMutationResult = Apollo.MutationResult<UpdateJobStateMutation>;
export type UpdateJobStateMutationOptions = Apollo.BaseMutationOptions<UpdateJobStateMutation, UpdateJobStateMutationVariables>;