import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {
  createStyles,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useHistory, useParams } from "react-router-dom";
import TasksPipeline from "../components/Tasks/TasksPipeline";
import TaskActions from "../components/Tasks/TaskActions";
import { useLazyQuery, useQuery } from "@apollo/client";
import Task, { ETaskComponentMode } from "../components/Tasks/Task";
import { READ_TASK_BY_ID } from "../components/JobList/query";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    TasksViewContainer: {
      backgroundColor: theme.palette.success.main,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "3px",
      marginTop: "20px",
      marginBottom: "50px",
      padding: "10px",
      paddingTop: "20px",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    JobListAndFilterContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "10px",
      maxWidth: "100%",
    },
    containerTitleText: {
      fontFamily: "-webkit-pictograph",
    },
    addTaskButton: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    filterContainer: {
      backgroundColor: "green",
      marginTop: "10px",
      marginBottom: "3px",
      width: "100%",
      padding: "3px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      borderRadius: "3px",
    },
  })
);

interface ParamsType {
  id: string;
}

export const TaskView: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const currentUser: any = useSelector((state: RootState) => state.user);
  const [createTaskDialog, setCreateTaskDialog] = useState(false);
  const params = useParams<ParamsType | null>();
  const [currentTask, setCurrentTask] = useState();

  const { error, data } = useQuery(READ_TASK_BY_ID, {
    variables: {
      taskId: params?.id,
    },
  });

  useEffect(() => {
    if (data) {
      setCurrentTask(data.readTaskById);
    }
  }, [data]);

  return (
    <Container maxWidth="md" style={{ marginTop: "150px" }}>
      <div style={{ margin: "50px" }}>
        <a
          href={`http://localhost:3000/tasks/${params?.id}`}
        >{`Open Task #${params?.id}`}</a>
      </div>
      <Task
        task={currentTask}
        initialMode={ETaskComponentMode.View}
        setClickedTask={() => {}}
      />
    </Container>
  );
};
