import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {
  createStyles,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useHistory } from "react-router-dom";
import TasksPipeline from "../components/Tasks/TasksPipeline";
import TaskActions from "../components/Tasks/TaskActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    TasksViewContainer: {
      backgroundColor: theme.palette.success.main,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "3px",
      marginTop: "20px",
      marginBottom:"50px",
      padding: "10px",
      paddingTop: "20px",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    JobListAndFilterContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "10px",
      maxWidth: "100%",
    },
    containerTitleText: {
      fontFamily: "-webkit-pictograph",
    },
    addTaskButton: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    filterContainer:{
      backgroundColor:"green",
      marginTop:"10px",
      marginBottom:"3px",
      width:"100%",
      padding:"3px",
      display:"flex",
      flexDirection:"row",
      justifyContent:"space-around",
      borderRadius:"3px"
    },
  })
);

export const TasksView: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const currentUser: any = useSelector((state: RootState) => state.user);
  const [createTaskDialog, setCreateTaskDialog] = useState(false);

  useEffect(() => {
    if (currentUser?.user?.roles.length === 0) {
      history.push("/sign-up");
    }
  }, [currentUser]);

  return (
    <Container maxWidth="md" style={{ marginTop: "98px" }}>
      {createTaskDialog ? (
        <RemoveCircleIcon
          fontSize="large"
          onClick={() => setCreateTaskDialog((prev) => !prev)}
          color="primary"
          className={classes.addTaskButton}
        />
      ) : (
        <AddCircleIcon
          fontSize="large"
          onClick={() => setCreateTaskDialog((prev) => !prev)}
          color="primary"
          className={classes.addTaskButton}
        />
      )}
      {createTaskDialog && <TaskActions task={null} />}
      <div className={classes.TasksViewContainer}>
        <Typography
          component="h2"
          variant="h5"
          color="primary"
          align="center"
          className={classes.containerTitleText}
        >
          Tasks
          <Divider />
        </Typography>
        <TasksPipeline />
      </div>
    </Container>
  );
};
