import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PeopleIcon from "@material-ui/icons/People";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
  activeItem: {
    backgroundColor: "#d2c3c3",
  },
}));

interface sideMenuProps {
  currentView: any;
  setCurrentView: any;
}

const SideMenu: React.FC<sideMenuProps> = ({ currentView, setCurrentView }) => {
  const classes = useStyles();

  return (
    <div>
      <div>
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItem>
        <ListItem
          button
          onClick={() => setCurrentView("users")}
          className={`${currentView === "users" && classes.activeItem}`}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="All Users" />
        </ListItem>
        <ListItem
          button
          onClick={() => setCurrentView("newusers")}
          className={`${currentView === "newusers" && classes.activeItem}`}
        >
          <ListItemIcon>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary="New Users" />
        </ListItem>
        <ListItem
          button
          onClick={() => setCurrentView("organisations")}
          className={`${currentView === "organisations" && classes.activeItem}`}
        >
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Organisations" />
        </ListItem>
        <ListItem
          button
          onClick={() => setCurrentView("settings")}
          className={`${currentView === "settings" && classes.activeItem}`}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </div>
      <Divider />
      <div>
        <ListSubheader inset>Issues & tickets</ListSubheader>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Crash Reports" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Analytics" />
        </ListItem>
      </div>
    </div>
  );
};

export default SideMenu;
