import { ActionTypes } from "./types";
import { Dispatch } from "redux";

//USER ACTION NAMES
export interface ChangeValueHandlerAction {
  type: ActionTypes.changeValueHandler;
  payload: {
    key: string;
    value: string;
  };
}
export interface ClearFormAction{
  type:ActionTypes.clearForm,
  payload:any
}
export interface ChangeAllFormValuesAction{
  type:ActionTypes.changeAllFormValues,
  payload:any
}

export const changeAllFormValues = (form:any) =>{
  try {
    return async (dispatch: Dispatch) => {
      dispatch({type: ActionTypes.changeAllFormValues, payload:form})
    };
  } catch (e) {
    console.log(e)
  }
}

export const changeValueHandler = (key: any, value: any) => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ActionTypes.changeValueHandler,
        payload: { key, value },
      });
    };
  } catch (e) {
    console.log(e)
  }
};

export const clearForm = () =>{
  try {
    return async (dispatch:Dispatch)=>{
      dispatch({
        type:ActionTypes.clearForm,
        payload:null
      })
    }
  } catch (e) {
    console.log(e)
  }
}

