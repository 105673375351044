import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import GetAppIcon from "@material-ui/icons/GetApp";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import * as fileStorage from "../../utils/fileStorage";
import * as streamSaver from "streamsaver";
import { FileTagModal } from "./FileTagModal";

//DUMMY DATA:
import { Typography } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import { errorHandler } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useLazyQuery } from "@apollo/react-hooks";
import { QUERY_JOB_BY_ID } from "../JobList/query";
import JSZip from "jszip";
import { RootState } from "../../store";
import { ROLES_CONFIG } from "../../utils/helpers";
import { FileDeleteConfirmModal } from "./FileDeleteConfirmModal";

const useStyles = makeStyles({
  table: {
    maxWidth: "100%",
  },
  downloadIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  tagIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableCell: {
    padding: "3px",
    fontSize: "12px",
  },
  tableCellName: {
    padding: "3px",
    fontSize: "12px",
  },
  tableCellDownload: {
    padding: "3px",
    paddingTop: "15px",
    fontStyle: "italic",
    fontSize: "13px",
  },
  loadingSpinner: {
    width: "10px",
  },
});

function createData(name: string, uploadDate: string, size: string, tag: JobFileTag) {
  return { name, uploadDate, size, tag };
}

enum doctypenames {
  architecturaldoc = "architecturaldoc",
  detailingdoc = "detailingdoc",
  engineeringdoc = "engineeringdoc",
  designform = "designform",
  inspectiondoc = "inspectiondoc",
  inspectionform = "inspectionform",
  prelimdesignform = "prelimdesignform"
}

function getDoctypeName(doctype: string) {
  switch (doctype) {
    case doctypenames.architecturaldoc:
      return "Architectural";
    case doctypenames.detailingdoc:
      return "Detailing";
    case doctypenames.engineeringdoc:
      return "Engineering";
    case doctypenames.prelimdesignform:
      return "Preliminary design form"
    case doctypenames.designform:
      return "Design form";
    case doctypenames.inspectiondoc:
      return "Inspection";
    case doctypenames.inspectionform:
      return "Inspection form";
    default:
      break;
  }
}

interface fileInterface {
  name: string;
  uploadDate: string;
  size: string;
  tag: JobFileTag
}

interface JobFileTag {
  category: string,
  storey: number,
  documentDate: string
}

interface FileListProps {
  jobID: number;
  documents: any[];
  doctype: string;
}

const FileList: React.FC<FileListProps> = ({ jobID, documents, doctype }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [rows, setRows] = useState<fileInterface[]>([]);
  const [ fileTagModalOpen, setFileTagModalOpen ] = useState(false);
  const [ fileTagFilename, setFileTagFilename ] = useState("");
  const [ fileDeleteConfirmModalOpen, setFileDeleteConfirmModalOpen ] = useState(false);
  const [ fileDeleteFilename, setFileDeleteFilename ] = useState("");
  const [ tags, setTags ] = useState<{[id: string]: JobFileTag; }>({});
  const [readJob, readJobResp] = useLazyQuery(QUERY_JOB_BY_ID);
  const currentUser: any = useSelector((state: RootState) => state.user.user);

  const getJobDocumentTagEndpoint = (jobID:number, doctype:string) => {
    const my_uri = `${process.env.REACT_APP_BACKEND_BASE_URL}/jobdocument/${jobID}/${doctype}/tag`
    return my_uri;
  };

  const getJobDocumentTags = async (jobID:number, doctype:string, filenames:string[]) => {
    const token = await getAccessTokenSilently();
    let config = {
      headers:{
          "Authorization": `Bearer ${token}`
      }
    }
    const res = await axios.post(getJobDocumentTagEndpoint(jobID, doctype),
        filenames,
        config
    );
    return res.data.tags;
  };

  const refreshJobDocumentTags = async () => {
      setTags(await getJobDocumentTags(jobID, doctype, documents.map(file => file.name)));
  }

  useEffect(() => {
    readJob({variables: {
      'jobId': jobID
    }});
  }, [jobID]);

  useEffect(() => {
    refreshJobDocumentTags();
  }, [documents, fileTagModalOpen]);

  useEffect(() => {
    const newRows: fileInterface[] = [];
    documents.map((file) => {
      const uploadDate = new Date(file.uploadDate);
      const fileData = createData(
        file.name,
        uploadDate.toLocaleDateString('en-AU'),
        file.size,
        tags[file.name]
      );
      newRows.push(fileData);
    });
    setRows(newRows);
  }, [tags]);

  //SINGLE FILE DOWNLOAD HANDLER
  const downloadHandler = async (row: any, jobID: number, doctype: string) => {
    const token = await getAccessTokenSilently();

    try {
      const jobDocument = await fileStorage.downloadJobDocument(
        [row.name],
        jobID,
        doctype,
        token
      );

      if (jobDocument[0]) {
        const fileStream = streamSaver.createWriteStream(row.name, {
          size: jobDocument[0].size, // Makes the procentage visiable in the download
        });

        const readableStream = jobDocument[0].stream();

        const writer = fileStream.getWriter();
        const reader = readableStream.getReader();
        const pump = (): any =>
          reader
            .read()
            .then((res) =>
              res.done ? writer.close() : writer.write(res.value).then(pump)
            );
        pump();
      }
    } catch (e) {
      dispatch(errorHandler(`Download failed: ${e}`));
    }
  };

  const tagHandler = async (row: any, jobID: number, doctype: string) => {
    setFileTagFilename(row.name);
    setFileTagModalOpen(true);
  };

  const deleteHandler = (row: any, jobID: number, doctype: string) => {
    setFileDeleteFilename(row.name);
    setFileDeleteConfirmModalOpen(true);
  };

  //MULTIPLE FILE DOWNLOAD HANDLER
  const multipleDownloadHandler = async (
    documents: any,
    jobID: number,
    doctype: string
  ) => {
    const token = await getAccessTokenSilently();

    try {

      let documentNames: string[] = [];
      documents.map((doc: any) => {
          documentNames.push(doc.name);
      });

      //DOWNLOAD FILES
      if (documentNames.length > 0) {
        const jobDocument = await fileStorage.downloadJobDocument(
          documentNames,
          jobID,
          doctype,
          token
        );

        if (jobDocument[0]) {
          const zipName = `${readJobResp?.data?.readJob?.jobNumber}-${doctype}`;
          const zip = new JSZip();
          const folder = zip.folder(zipName);
          for (let i = 0; i < jobDocument.length; i++)
          {
            const doc = jobDocument[i];
            const name = documentNames[i];
            folder?.file(name, doc!);
          }
          const zipContent : Blob = await zip.generateAsync({type: "blob"});

          const fileStream = streamSaver.createWriteStream(`${zipName}.zip`, {
            size: zipContent.size, // Makes the procentage visiable in the download
          });

          const readableStream = zipContent.stream();

          const writer = fileStream.getWriter();
          const reader = readableStream.getReader();
          const pump = (): any =>
            reader
              .read()
              .then((res: any) =>
                res.done ? writer.close() : writer.write(res.value).then(pump)
              );
          pump();
        }
      }
    } catch (e) {
      dispatch(errorHandler(`Download failed: ${e}`));
    }
  };


  if (rows.length < 1) {
    return (
      <Typography component="h6" variant="body1">
        There are no documents.
      </Typography>
    );
  }

  return (
    <>
      <FileTagModal jobID={jobID} doctype={doctype} filename={fileTagFilename} isOpen={fileTagModalOpen} setIsOpen={setFileTagModalOpen} />
      <FileDeleteConfirmModal jobID={jobID} doctype={doctype} filename={fileDeleteFilename} isOpen={fileDeleteConfirmModalOpen} setIsOpen={setFileDeleteConfirmModalOpen} />
      <TableContainer style={{ marginBottom: "10px" }} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>File Name</TableCell>
              <TableCell className={classes.tableCell}>Upload Date</TableCell>
              <TableCell className={classes.tableCell} align="center">
                File Size
              </TableCell>
              {readJobResp?.data?.readJob?.jobAddress?.province === "Victoria" && doctype === "detailingdoc" &&
                <TableCell className={classes.tableCell} align="center">
                  Tag
                </TableCell>
              }
              <TableCell className={classes.tableCell} align="center">
                Download
              </TableCell>
              {currentUser.roles.includes(ROLES_CONFIG.Administrator.RoleName) &&
                <TableCell className={classes.tableCell} align="center">
                  Delete
                </TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell
                  className={classes.tableCellName}
                  component="th"
                  scope="row"
                >
                  {row.name}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row.uploadDate}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  {(parseInt(row.size) / 1000).toFixed(2).toString()}kb
                </TableCell>
                {readJobResp?.data?.readJob?.jobAddress?.province === "Victoria" && doctype === "detailingdoc" &&
                  <TableCell className={classes.tableCell} align="center">
                    {
                      row?.tag?.category !== null && row?.tag?.category?.length > 0 ?
                      <EditIcon
                        className={classes.tagIcon}
                        onClick={() => {
                          tagHandler(row, jobID, doctype);
                        }}
                      />
                      :
                      <AddCircleIcon
                        className={classes.tagIcon}
                        onClick={() => {
                          tagHandler(row, jobID, doctype);
                        }}
                      />
                    }
                  </TableCell>
                }
                <TableCell className={classes.tableCell} align="center">
                  <GetAppIcon
                    className={classes.downloadIcon}
                    onClick={() => {
                      downloadHandler(row, jobID, doctype);
                    }}
                  />
                </TableCell>
                {currentUser.roles.includes(ROLES_CONFIG.Administrator.RoleName) &&
                  <TableCell className={classes.tableCell} align="center">
                    <DeleteIcon
                      className={classes.tagIcon}
                      onClick={() => {
                        deleteHandler(row, jobID, doctype);
                      }}
                    />
                  </TableCell>
                }
              </TableRow>
            ))}
            <TableRow>
              <TableCell className={classes.tableCellDownload}>
                {`Download all ${getDoctypeName(doctype)} documents: `}
                <GetAppIcon
                  className={classes.downloadIcon}
                  onClick={() =>
                    multipleDownloadHandler(documents, jobID, doctype)
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FileList;
