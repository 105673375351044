import React, { useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { changeValueHandler, changeValueHandler1, changeValueHandler2, errorHandler } from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  buttonClass: {},
  rowDirection: {
    display: "flex",
    flexDirection: "row",
  },
  columDirection: {
    display: "flex",
    flexDirection: "column",
  },
  groupLabelClass: {
    fontWeight: 500,
    width:"75px",
    marginBottom:'8px'
  },
  radioButtonsContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center'
  }
}));

interface RadioButtonsProps {
  buttons: string[];
  group_label: string;
  directionRow: boolean;
  name: string;
  reducer_name: string;
  children?:any;
  defaultValue:any
}

const RadioButtons: React.FC<RadioButtonsProps> = ({
  name,
  buttons,
  group_label,
  directionRow,
  reducer_name,
  children,
  defaultValue
}) => {
  const [value, setValue] = React.useState("N/A");

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let handler:any;
    switch (reducer_name) {
      case 'inspectionChecklistFormReducer':
        handler = changeValueHandler;
        break;
      case 'engineeringDetailsFormReducer':
        handler = changeValueHandler1;
        break;
      case 'inspectionDetailsFormReducer':
        handler = changeValueHandler2;
        break;
    
      default:
        dispatch(errorHandler("Form failure please refresh the page and try again. " + reducer_name))
    }

    try {
      dispatch(
        handler(name, (event.target as HTMLInputElement).value)
      );
      setValue((event.target as HTMLInputElement).value);
    } catch (e) {
      dispatch(errorHandler("Value did not change: " + e));
    }
  };

  useEffect(() => {
    if(defaultValue){
      setValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <FormControl component="fieldset" className={classes.radioButtonsContainer}>
      <Typography component={"span"} variant={"body2"} className={classes.groupLabelClass}>
        {group_label}{children}
      </Typography>
      
      <>
      <RadioGroup
        className={directionRow ? classes.rowDirection : classes.columDirection}
        aria-label={group_label}
        name={group_label}
        value={value}
        onChange={handleChange}
      >
        {buttons.map((btn) => {
          return (
            <FormControlLabel
              key={btn}
              value={btn}
              control={<Radio color="primary" />}
              className={classes.buttonClass}
              label={btn}
            />
          );
        })}
      </RadioGroup>
      </>
    </FormControl>
  );
};

export default RadioButtons;
