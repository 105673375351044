import axios from "axios";
import * as azureStorage from "./azureStorage";


function getJobDocumentEndpoint(jobID:number, doctype:string, type:number) { // fix this
    const my_uri = `${process.env.REACT_APP_BACKEND_BASE_URL}/jobdocument/${jobID}/${doctype}/${type}`
    return my_uri;
}

function getSignatureEndPoint(type:number) {
    const my_uri = `${process.env.REACT_APP_BACKEND_BASE_URL}/signature/${type}`
    return my_uri;
}



export async function uploadJobDocument(files:File[], jobID:number, doctype:string, token:string) {

    let config = {
        headers:{
            "Authorization": `Bearer ${token}`
        }
    }

    const res = await axios.post(getJobDocumentEndpoint(jobID, doctype, 1),
        files.map(file => file.name), config
    );

    const promises = files.map(file => {
        const uri =  res.data.uris[file.name]

        return azureStorage.upload(uri, file);
    });
    return await Promise.all(promises);

}

export async function downloadJobDocument(filenames:string[], jobID:number, doctype:string,  token:string) {
    let config = {
        headers:{
            "Authorization": `Bearer ${token}`
        }
    }
    
    const res = await axios.post(getJobDocumentEndpoint(jobID, doctype, 0),
        filenames,config
      );

    const promises = filenames.map(filename => {
        const uri =  res.data.uris[filename]
        return azureStorage.download(uri);
    });
    return await Promise.all(promises);
}

export async function uploadSignature(file:File, token:string, type:number) {

    let config = {
        headers:{
            "Authorization": `Bearer ${token}`
        }
    }

    const res = await axios.post(getSignatureEndPoint(type),
        file.name, config
    );

    const promise = async () =>{
        const uri =  res.data
        return await azureStorage.upload(uri, file);
    }

    return await promise();
}

export async function downloadSignature(type:number,  token:string) {
    let config = {
        headers:{
            "Authorization": `Bearer ${token}`
        }
    }
    
    const res = await axios.post(getSignatureEndPoint(type),null,config);
    return res.data
}

export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {

    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
}