import {
  Button,
  Checkbox,
  createStyles,
  LinearProgress,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";
import { Job, useAdvanceJobMutation, useRejectJobMutation } from "../generated/graphql";
import { advanceJobRedux, errorHandler, rejectJobRedux } from "../store/actions";
import { useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { READ_CURRENT_USER } from "../components/JobList/query";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "20px",
    },
    providerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    instructionsContainer: {
      backgroundColor: "#e2f4ff",
      maxWidth: "600px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "5px",
      padding: "10px",
      fontSize: "14px",
      color: "black",
    },
    toDoBottomContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    commentFieldContainer: {
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "3px",
      padding: "6px",
      minWidth: "500px",
      marginBottom: "15px",
    },
    checkBoxesContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "flex-end",
      maxWidth: "75%",
    },
    checkBoxRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    checkBoxControl: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    checkBox:{
      color:theme.palette.primary.main
    },
    linearIndeterminate:{
      marginTop:"10px",
      width: '90%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    }
  })
);

const validationSchema = yup.object({
  comment: yup.string().defined(),
});
const validationSchemaApproval = yup.object({
  Agree: yup.boolean().oneOf([true], "You must check Agree before approval"),
  Permissions: yup.boolean().oneOf([true], "You do not have sufficient permissions to approve this job"),
});

interface ApproveOrRejectProps {
  job: any;
  reject: boolean;
  checkboxText: string;
  advanceEnabled?: boolean;
}

const ApproveOrReject: React.FC<ApproveOrRejectProps> = ({ job, reject, checkboxText,advanceEnabled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showCommentForm, setShowCommentForm] = useState<boolean>(false);
  const [advanceEnabledState, setAdvanceEnabledState] = useState<boolean>(true);
  const [readUser, readUserResponse] = useLazyQuery(READ_CURRENT_USER);

  useEffect(() => {
    readUser();
  }, [readUser]);
  
  const formikReject = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert("Comment: " + values.comment);
      rejectJob()
    },
  });

  const formikApproval = useFormik({
    initialValues: {
      Agree: false,
      Permissions: false
    },
    validationSchema: validationSchemaApproval,
    onSubmit: (values) => {
      // alert("Agree: " + values.Agree);
      advanceJob()
    },
  });

  const [advanceJob, advanceJobResponse] = useAdvanceJobMutation({
    variables: {
      jobId:job.id
    }
  });

  const [rejectJob, rejectJobResponse] = useRejectJobMutation({
    variables: {
      jobId:job.id,
      comment: formikReject.values.comment
    }
  });

  useEffect(()=>{
    if(advanceJobResponse.data?.advanceJob){
      dispatch(advanceJobRedux(advanceJobResponse.data.advanceJob))
    }

    if(rejectJobResponse.data){
      dispatch(rejectJobRedux(rejectJobResponse.data.rejectJob))
    }
  },[advanceJobResponse.data, rejectJobResponse.data])

  useEffect(() =>{
    if(advanceJobResponse.error){
      dispatch(errorHandler(`Failed to advance the job. Network error: ${advanceJobResponse.error.message}`))
    }else if(rejectJobResponse.error){
      dispatch(errorHandler(`Failed to reject the job. Network error: ${rejectJobResponse.error.message}`))
    }
  },[rejectJobResponse.error,advanceJobResponse.error])

  const rejectHandler = () => {
    setShowCommentForm(true);
    formikReject.submitForm();
  };

  const onCheckBoxChange = (fieldName: string, event: any) => {
    formikApproval.setFieldValue(fieldName, event.target.checked);
  };

  const getAdvanceEnabled = (job: Job) : boolean =>{
    let approve = true;
    if (advanceJobResponse.loading === true) {
      approve = false;
    }
    if (advanceEnabled === false) {
      approve = false;
    }
    if (formikApproval.errors.Agree) {
      approve = false;
    }
    if (readUserResponse?.data != null && (job.state === "ENGINEERING" || job.state === "REVIEW_INSPECTION_RESULTS")) {
      formikApproval.setFieldValue("Permissions", true);
    } else {
      formikApproval.setFieldValue("Permissions", true);
    }
    return approve;
  }

  useEffect(() => {
    let enabled = getAdvanceEnabled(job);
    setAdvanceEnabledState(enabled);
  }, [advanceJobResponse.loading, advanceEnabled, job, readUserResponse, formikApproval.errors])

  return (
    <div className={classes.providerContainer}>
      {/* Check boxes */}
      <div className={classes.checkBoxesContainer}>
        <div className={classes.checkBoxRow}>
          <Typography component="h6" variant="body2" color="textPrimary">
            {checkboxText}
          </Typography>
          <div className={classes.checkBoxControl}>
            <Checkbox
              onChange={(value) => onCheckBoxChange("Agree", value)}
              color="primary"
              className={classes.checkBox}
              value={formikApproval.values.Agree}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
        </div>
      </div>

      {showCommentForm && (
        <div className={classes.commentFieldContainer}>
          <TextField
            fullWidth
            multiline
            rows={5}
            rowsMax={50}
            id="comment"
            name="comment"
            label="Reject Comment"
            value={formikReject.values.comment}
            onChange={formikReject.handleChange}
            error={
              formikReject.touched.comment &&
              Boolean(formikReject.errors.comment)
            }
          />
        </div>
      )}
      {formikApproval.errors.Permissions && (
            <Alert style={{ margin: "5px", padding: "5px" }} severity="error">
              {formikApproval.errors.Permissions}
            </Alert>
          )}
      {formikApproval.errors.Agree && (
            <Alert style={{ margin: "5px", padding: "5px" }} severity="error">
              {formikApproval.errors.Agree}
            </Alert>
          )}
      <div className={classes.toDoBottomContainer}>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          size="small"
          onClick={() => formikApproval.submitForm()}
          disabled={!advanceEnabledState}
        >
          Advance
        </Button>
        {reject && (
          <Button
            onClick={() => rejectHandler()}
            className={classes.button}
            color="primary"
            style={{ backgroundColor: "#5d4141" }}
            variant="contained"
            size="small"
            disabled={rejectJobResponse.loading}
          >
            Reject
          </Button>
        )}
      </div>
      {advanceJobResponse.loading  && 
          <div className={classes.linearIndeterminate}>
            <LinearProgress />
          </div>
      }
      {rejectJobResponse.loading  && 
          <div className={classes.linearIndeterminate}>
            <LinearProgress />
          </div>
      }
    </div>
  );
};

export default ApproveOrReject;
