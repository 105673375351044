export const components_buttons = ["Wall Framing including studs, plates and lintels", "Wall bracing", "Roof rafters", "Floor frame"];

export const basis_of_certification_buttons = [
	"AS/NZS 1170.0:2002 Structural Design Actions: Part 0: General principles",
	"AS/NZS 1170.1:2002 Structural Design Actions: Part 1: Permanent, imposed and other actions",
	"AS4100:1998 Steel Structure Code",
	"AS4100:2020 Steel Structures Standard",
	"AS4055:2006 Wind Loads for Housing",
	"AS/NZS 4600:2018 Cold-formed Steel Structures",
	"AS4773:2015 Masonry in Small Buildings",
	"NASH Standards",
	"Steel House Frames Standard Documentation",
];

export const reference_documentation_buttons = [
	"General framing, tie down and structural steel drawing set",
	"Wall panel shop drawings set",
	"Roof truss shop drawings set",
	"Floor panel shop drawings set",
];

export const ref_doc_buttons_for_inspection_form = [
	"General framing, tie down and structural steel drawing set",
	"Wall panel shop drawings set",
	"Roof truss shop drawings set",
	"Floor panel shop drawings set",
];
