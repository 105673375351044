import {
  Button,
  createStyles,
  LinearProgress,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useCreateInvitationMutation } from "../../generated/graphql";
import { errorHandler } from "../../store/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inviteMemberMainContainer: {
      display: "flex",
      flexDirection: "column",
      minWidth: "300px",
      padding: "3px",
      margin: "5px",
    },
    inviteButton: {
      marginTop: "5px",
    },
    alertClass: {
      color: "#fff",
      backgroundColor: "green",
      marginTop: "5px",
      padding: "0px 10px",
      width: "100%",
    },
    linearIndeterminate: {
      marginTop: "10px",
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);

const validationSchema = yup.object({
  inviteEmail: yup.string().required(),
});

interface InviteMemberProps {
  user: any;
}

const InviteMember: React.FC<InviteMemberProps> = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = React.useState<string>("");

  const formik = useFormik({
    initialValues: {
      inviteEmail: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await createInvitation();
      } catch (error) {
        dispatch(
          errorHandler(`${error.message}, check your internet connection`)
        );
      }
    },
  });

  const [
    createInvitation,
    createInvitationResponse,
  ] = useCreateInvitationMutation({
    variables: {
      email: formik.values.inviteEmail,
    },
  });

  useEffect(() => {
    if (createInvitationResponse.data) {
      if (createInvitationResponse.data.createInvitation) {
        setShowAlert(
          `Invitation code: ${createInvitationResponse.data.createInvitation.code}`
        );
      }
    }
  }, [createInvitationResponse.data]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={classes.inviteMemberMainContainer}
    >
      <Typography component="h5" variant="body2">
        Invite member to your organisation
      </Typography>
      <TextField
        fullWidth
        onChange={formik.handleChange}
        name="inviteEmail"
        id="standard-basic"
        label="Email"
        error={formik.touched.inviteEmail && Boolean(formik.errors.inviteEmail)}
        helperText={formik.touched.inviteEmail && formik.errors.inviteEmail}
      />

      <Button
        type="submit"
        className={classes.inviteButton}
        color="primary"
        variant="contained"
        size="small"
      >
        Invite
      </Button>
      {createInvitationResponse.loading && (
        <div className={classes.linearIndeterminate}>
          <LinearProgress />
        </div>
      )}
      {showAlert.length > 0 && (
        <>
          <Alert
            className={classes.alertClass}
            onClose={() => {
              setShowAlert("");
            }}
          >
            {showAlert}
          </Alert>
          Instructions:
          <br />
          1. Copy invitation code
          <br />
          2. Send invitation code to user that you just invited.
          <br />
          3. User needs to login to EngiFlow,
          <br />
          and enter invitation code in Join Organisation field.
        </>
      )}
    </form>
  );
};

export default InviteMember;
