import { combineReducers } from 'redux'
import { useDispatch as _useDispatch } from 'react-redux'
import { notificationsReducer } from './notifications/reducers'
import { authenticationReducer } from './authentication/reducers'
import { NotificationActionTypes } from './notifications/types'
import { inspectionChecklistForm } from './reducers/inspectionChecklistFormReducer'
import { engineeringDetailsForm } from './reducers/engineeringDetailsFormReducer'
import { inspectionDetailsForm } from './reducers/inspectionDetailsFormReducer'
import { job } from './reducers/job'
import { user } from './reducers/user'

export const rootReducer = combineReducers({
  notifications: notificationsReducer,
  authentication: authenticationReducer,
  // Needs refactoring
  jobs: job,
  user: user,
  inspectionChecklistForm: inspectionChecklistForm,
  engineeringDetailsForm: engineeringDetailsForm,
  inspectionDetailsForm: inspectionDetailsForm
})

type StoreActionTypes = NotificationActionTypes;

// Ensure we have a typesafe useDispatch function that works with typescript
export function useDispatch() {
  const dispatch = _useDispatch()
  return (event: StoreActionTypes) => {
    dispatch(event)
  }
}

export type RootState = ReturnType<typeof rootReducer>