import { useLazyQuery } from "@apollo/client";
import { Button, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CreateOrganisation } from "../../components/CreateOrganisation/CreateOrganisation";
import { READ_CURRENT_USER } from "../../components/JobList/query";
import { JoinOrganisation } from "../../components/JoinOrganisation/JoinOrganisation";
import { doYouNeedRefetch } from "../../store/actions";
import { RootState } from "../../store";
import JoinOrCreateInstructions from "./JoinOrCreateInstructions";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  image: {
    marginTop: theme.spacing(9),
    backgroundImage:
      "url(https://images.pexels.com/photos/1769409/pexels-photo-1769409.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)",
    minHeight: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "3px",
    flexBasis: "fit-content",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "90%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formContainer: {
    marginTop: "3%",
    marginBottom: "50px",
    borderRadius: "5px",
    justifyContent: "center",
    display: "flex",
    height: "fit-content",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  inputContainer: {
    margin: "5px",
    padding: "4px!important",
  },
  joinOrCreateContainer: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    marginTop: "5%",
    minHeight: "400px",
    minWidth: "600px",
    padding: "5%",
    justifyContent: "space-around",
    backgroundColor: "#f7f7f7",
  },
  helper: {
    display: "flex",
    height: "fit-content",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "400px",
  },
  messageContainer: {
    height: "fit-content",
    marginTop: "5%",
    padding: "2%",
  },
}));

export enum showComponentEnum {
  "JOIN OR CREATE PAGE" = 0,
  "CREATE ORGANISATION" = 1,
  "JOIN ORGANISATION" = 2,
  "WAIT FOR APPROVAL" = 3,
}

const JoinOrCreateView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showComponent, setShowComponent] = useState<showComponentEnum>(
    showComponentEnum["JOIN OR CREATE PAGE"]
  );
  let currentUser: any = useSelector((state: RootState) => state.user.user);
  let jobsState: any = useSelector((state: RootState) => state.jobs);

  const [readUser, readUserResponse] = useLazyQuery(READ_CURRENT_USER);

  useEffect(() => {
    if (jobsState.doYouNeedRefetch) {
      readUserResponse.refetch!(readUserResponse.data);
      dispatch(doYouNeedRefetch());
    }
    readUser();
  }, [jobsState.doYouNeedRefetch]);

  //Redirect & Show (what to show) logic
  useEffect(() => {
    if (readUserResponse.data) {

      //If user is member of organisation and have role -> redirect
      if (
        readUserResponse.data.readCurrentUser?.organisation &&
        currentUser.roles.length > 0
      ) {
        history.push("/jobs");
      }

      //If user is member or organisation but does not have a role -> msg
      if (
        readUserResponse.data.readCurrentUser?.organisation &&
        currentUser.roles.length === 0
      ) {
        setShowComponent(showComponentEnum["WAIT FOR APPROVAL"]);
      }
    }
  }, [readUserResponse]);

  

  if (showComponent === showComponentEnum["CREATE ORGANISATION"]) {
    return <CreateOrganisation setShowComponent={setShowComponent} />;
  }

  if (showComponent === showComponentEnum["JOIN ORGANISATION"]) {
    return <JoinOrganisation setShowComponent={setShowComponent} />;
  }

  if (showComponent === showComponentEnum["JOIN OR CREATE PAGE"]) {
    return (
      <div className={classes.image}>
        <Paper elevation={3} className={classes.joinOrCreateContainer}>
          <Button
            onClick={() =>
              setShowComponent(showComponentEnum["CREATE ORGANISATION"])
            }
            variant="contained"
            size="large"
            color="primary"
          >
            Create Organisation
          </Button>
          <Button
            onClick={() =>
              setShowComponent(showComponentEnum["JOIN ORGANISATION"])
            }
            variant="contained"
            size="large"
            style={{ backgroundColor: "green", color: "#fff" }}
          >
            Join Organisation
          </Button>
        </Paper>
      </div>
    );
  }

  if (showComponent === showComponentEnum["WAIT FOR APPROVAL"]) {
    return <JoinOrCreateInstructions />
  }

  return <>Error</>;
};

export default JoinOrCreateView;
