import {
  Button,
  createStyles,
  makeStyles,
  LinearProgress,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import * as fileStorage from "../../utils/fileStorage";
import { errorHandler } from "../../store/actions";
import AlertMessage from "../AlertMessage/AlertMessage";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    DropzoneContainer: {
      display: "flex",
      flexDirection: "column",
      width: "270px",
      margin: "20px",
    },
    DropSection: {
      backgroundColor: theme.palette.success.main,
      border: "2px dashed #b99191 ",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "180px",
      "&:hover": {
        cursor: "pointer",
      },
      "&:focus": {
        outline: 0,
      },
    },
    fileListRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "2px",
      width: "100%",

      maxWidth: "95%",
      marginLeft: "5px",
    },
    deleteIconClass: {
      color: "#f13f3f",
      "&:hover": {
        cursor: "pointer",
      },
    },
    titleClass: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textDecoration: "underline",
      textDecorationColor: theme.palette.primary.main,
      marginBottom: "1px",
    },
    table: {
      maxWidth: "100%",
    },
    tableCell: {
      padding: "8px",
      maxWidth: "160px",
      overflowWrap: "break-word",
    },
    instructions: {
      color: "gray",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "7px",
      fontSize: "12px",
      fontWeight: 300,
    },
    uploadButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    backArrow: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      borderRadius: "50%",
      fontSize: "25px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    linearIndeterminate: {
      marginTop: "10px",
      width: "90%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  });
});

interface Ifile {
  fileName: string;
  size: any;
}

interface IUploadSignature {
  user: any;
  goBack?: any;
}

const UploadSignature: React.FC<IUploadSignature> = ({ user, goBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [uploading, setUploading] = useState<boolean>(false);
  const [open1, setOpen1] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  const removeFileHandler = (fileName: string) => {
    const newFiles = selectedFiles.filter((f) => f.name !== fileName);
    setSelectedFiles(newFiles);
  };

  function createData(fileName: string, size: any) {
    return { fileName, size };
  }

  const rows: Ifile[] = [];

  selectedFiles.map((file) => {
    const fileData = createData(file.name, file.size);
    rows.push(fileData);
  });

  const submitHandler = async () => {
    try {
      if (selectedFiles.length > 0) {
        const token = await getAccessTokenSilently();
        setUploading(true);
        await fileStorage.uploadSignature(selectedFiles[0], token, 1);
        setUploading(false);
        setOpen1(true);
      }
    } catch (e) {
      dispatch(errorHandler(`File upload failed: ${e}`));
    }
  };

  return (
    <div>
      <Dropzone multiple={false} accept=".png"  onDrop={(acceptedFiles) => setSelectedFiles(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <div className={classes.DropzoneContainer}>
            <ArrowBackIcon
              onClick={() => goBack(false)}
              fontSize="large"
              className={classes.backArrow}
              color="primary"
            />
            <Typography
              className={classes.titleClass}
              variant="subtitle1"
              color="primary"
            >
              Upload Signature
            </Typography>
            <Typography className={classes.instructions} variant="subtitle2">
              Please select .PNG signature file for upload
            </Typography>
            <div className={classes.DropSection} {...getRootProps()}>
              <input {...getInputProps()} />

              <Typography
                style={{ margin: 0, textAlign: "center" }}
                variant="subtitle2"
                color="textPrimary"
              >
                Drag some files here
                <br /> or click to select files
              </Typography>
            </div>
            <div>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        File Name
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        File Size
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.fileName}>
                        <TableCell
                          className={classes.tableCell}
                          component="th"
                          scope="row"
                        >
                          {row.fileName}{" "}
                          <HighlightOffIcon
                            onClick={() => removeFileHandler(row.fileName)}
                            className={classes.deleteIconClass}
                            fontSize="small"
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                          {(parseInt(row.size) / 1000).toFixed(2).toString()}kb
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Button
              className={classes.uploadButton}
              onClick={() => submitHandler()}
              variant="contained"
              size="small"
              fullWidth={false}
            >
              upload
            </Button>
            {open1 && (
              <AlertMessage
                alertWidth="100%"
                open={open1}
                setOpen={setOpen1}
                color="info"
                message="File(s) uploaded successfully!"
              />
            )}
            {uploading && (
              <div className={classes.linearIndeterminate}>
                <LinearProgress />
              </div>
            )}
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default UploadSignature;
