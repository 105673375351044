import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import {
  Divider,
} from "@material-ui/core";
import AwaitingForDocuments from "../../views/AwaitingForDocumentsView/AwaitingForDocuments";
import { JobStateEnum } from "../../generated/graphql";
import Detailing from "../../views/DetailingView/Detailing";
import Engineering from "../../views/EngineeringView/Engineering";
import WaitingForInspection from "../../views/WaitingForInspectionView/WaitingForInspection";
import WaitingForApproval from "../../views/WaitingForApprovalView/WaitingForApproval";
import Finished from "../../views/FinishedView/Finished";
import JobStageNotFound from "../../views/JobStageNotFound";
import DocumentTree from "../JobDetail/DocumentTree";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "20px",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding:"20px"
    },
    instructionsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
    },
    toDoBottomContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    accordionDetails: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    stepButtonClass: {
      "&:hover": {
        cursor: "auto",
      },
    },
    divider1: {
      marginBottom: "20px",
    },
    divider2: {
      marginBottom: "20px",
      marginTop: "20px",
    },
  })
);

interface StageProps {
  job: any;
  state?: any;
  childrenComponent?: any;
}

const Stage: React.FC<StageProps> = ({ job, state, childrenComponent }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set<number>());
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const steps = getSteps();

  const currentState: any = useSelector((state: RootState) => state.jobs);

  //Get current job stage and move to that step. (0)-(1)-(2)-(3)...
  useEffect(() => {
    if (job?.state) {
      switch (job.state) {
        case JobStateEnum.AwaitingDocuments:
          setActiveStep(0);
          break;
        case JobStateEnum.Detailing:
          setActiveStep(1);
          break;
        case JobStateEnum.Engineering:
          setActiveStep(2);
          break;
        case JobStateEnum.WaitingForInspectionResults:
          setActiveStep(3);
          break;
        case JobStateEnum.ReviewInspectionResults:
          setActiveStep(4);
          break;
        case JobStateEnum.Finished:
          setActiveStep(5);
          break;
        default:
          setActiveStep(99);
          break;
      }
    }
  }, [job, currentState]);

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return AwaitingForDocuments(job);
      case 1:
        return Detailing(job);
      case 2:
        return Engineering(job);
      case 3:
        return WaitingForInspection(job);
      case 4:
        return WaitingForApproval(job);
      case 5:
        return Finished(job);
      default:
        return JobStageNotFound();
    }
  }

  function getSteps() {
    return [
      "Documents",
      "Detailing",
      "Engineering",
      "Inspection",
      "Approval",
      "Finish",
    ];
  }

  const totalSteps = () => {
    return getSteps().length;
  };
  const isStepOptional = (step: number) => {
    return step === 9;
  };

  const skippedSteps = () => {
    return skipped.size;
  };
  const completedSteps = () => {
    return completed.size;
  };
  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted(new Set<number>());
    setSkipped(new Set<number>());
  };
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  function isStepComplete(step: number) {
    return completed.has(step);
  }

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const buttonProps: { optional?: React.ReactNode } = {};
          if (isStepOptional(index)) {
            buttonProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                className={classes.stepButtonClass}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <div>
            <Typography component={"span"} className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div className={classes.instructionsContainer}>
            <Typography component={"span"} className={classes.instructions}>
              <Divider className={classes.divider1} />
              <DocumentTree
                alldocuments={state}
                job={job}
                childrenComponent={childrenComponent}
              />
              <Divider className={classes.divider2} />

              {getStepContent(activeStep)}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default Stage;
