import React, { useEffect, useState } from "react";
import TableHead from "@material-ui/core/TableHead";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import { READ_ORGANISATION_USERS } from "../../JobList/query";
import MemberList from "../MemberList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "auto",
    },
    MainMemberListContainer: {
      backgroundColor: "aqua",
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: "100%",
      maxHeight: 500,
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    userRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      minWidth: "650px",
    },
    table: {
      minWidth: 650,
    },
    tableRow: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#d2c3c3",
      },
    },
    editIcon: {
      fontSize: "18px",
      color: theme.palette.primary.main,
      "&:hover": {
        cursor: "pointer",
      },
    },
    visIcon: {
      fontSize: "18px",
      color: "#4e4e90",
      "&:hover": {
        cursor: "pointer",
      },
    },
    heading: {
      width: "100%",
      textAlign: "center",
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
    tableCell: {
      padding: "10px",
    },
    centerMe: {
      display: "flex",
      justifyContent: "center",
      alignItems:"center",
      width: "100%",
    },
    backArrow: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      borderRadius: "50%",
      fontSize: "25px",
    },
    organisationNameHeading:{
      color:"#5a5454",
      backgroundColor:"#ffd17c",
      fontSize:"18px",
      padding:"2px",
      marginLeft:"5%",
      borderRadius:"5px"
    }
  })
);

interface OrganisationListProps {
  organisations: {}[];
  title: string;
}

const OrganisationList: React.FC<OrganisationListProps> = ({
  organisations,
  title,
}) => {
  const classes = useStyles();
  const [rows, setRows] = useState<any>([]);
  const [clickedOrganisation, setClickedOrganisation] = useState<any>(null);
  const [organisationUsers, setOrganisationUsers] = useState<any>(null);

  // Query
  const [readOrganisationUsers, readOrganisationUsersResponse] = useLazyQuery(
    READ_ORGANISATION_USERS
  );

  useEffect(() => {
    const newRows = organisations.map((organisation) => {
      return organisation;
    });

    setRows(newRows);

    console.log("ORGANISATIONS: ", organisations);
  }, [organisations]);

  //  If clicked Organisation :
  //  Query users from org
  //  pass users to MemberList, Chips, MaxDialog
  useEffect(() => {
    if (clickedOrganisation) {
      readOrganisationUsers({
        variables: {
          organisationId: clickedOrganisation.id,
        },
      });
    }
  }, [clickedOrganisation]);

  console.log('clickedOrganisation',clickedOrganisation)
    console.log('organisationUsers',organisationUsers)

  //If reading users success store it in local state
  useEffect(() => {
    if (readOrganisationUsersResponse.data) {
      setOrganisationUsers(
        readOrganisationUsersResponse.data.readOrganisationUsers
      );
    }
  }, [readOrganisationUsersResponse.data]);

  if (organisationUsers !== null) {
    return (
      <div>
        <Grid spacing={1} container>
          <Grid item xs={4}>
          <IconButton disableFocusRipple onClick={() => {
            setOrganisationUsers(null)
            setClickedOrganisation(null);
          }}>
            <ArrowBackIcon
              fontSize="large"
              className={classes.backArrow}
              color="primary"
            />
          </IconButton>
          Go back to all organisations
          </Grid>
          <Grid className={classes.centerMe} item xs={4}>
          <span className={classes.organisationNameHeading}>
            {` organisation: ${clickedOrganisation.name}`}
          </span>
          </Grid>
          </Grid>
          <MemberList users={organisationUsers} title="Organisation members" />
        </div>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Typography className={classes.heading} component="h6" variant="body2">
        List of all organisations
      </Typography>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell} align="left">
              Organisation id
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              Organisation Name
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow
              className={classes.tableRow}
              onClick={() => {
                setClickedOrganisation(row);
              }}
              key={row.timeStamp}
            >
              <TableCell className={classes.tableCell} align="left">
                {row.id}
              </TableCell>
              <TableCell className={classes.tableCell} align="left">
                {row.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrganisationList;
