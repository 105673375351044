import { useState, useEffect } from "react";
import CommentType from "./commentType";
import * as yup from "yup";
import { useFormik } from "formik";
import { useCreateCommentMutation } from "../../generated/graphql";
import { useLazyQuery } from "@apollo/react-hooks";
import { READ_JOB_COMMENTS } from "../JobList/query";
import { useDispatch } from "react-redux";
import { doYouNeedRefetch, errorHandler } from "../../store/actions";

const validationSchema = yup.object({
  Agree: yup.boolean().oneOf([true], "You must check Agree before approval"),
  Comment: yup.string().defined().max(1000),
});

const useJobComments = (jobID: number) => {
  const dispatch = useDispatch();
  const [comments, setComments] = useState<CommentType[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [count, setCount] = useState<number>(0);

  const formikJobComment = useFormik({
    initialValues: {
      Comment: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      try {
        await createComment({
          variables: {
            jobId: jobID,
            text: values.Comment,
          },
        });
        dispatch(doYouNeedRefetch());
      } catch (e) {
        dispatch(errorHandler("Check your internet connection " + e));
      }
    },
  });

  const changeRowsHandler = (rows: number) => {
    setRowsPerPage(rows);
  };

  const changePageHandler = (page: any) => {
    setPage(page);
  };


  const [createComment, createCommentResponse] = useCreateCommentMutation();
  const [readComments, readCommentsResponse] = useLazyQuery(READ_JOB_COMMENTS, {
    fetchPolicy: "no-cache",
  });

  //this should execute when rows or page is changed
  useEffect(() => {
    readComments({
      variables: {
        jobId: jobID,
        page: page,
        rowsPerPage: rowsPerPage,
      },
    });
  }, [rowsPerPage, page]);

  //When data is changed - set new comments
  useEffect(() => {
    if (readCommentsResponse.data) {
      setComments(readCommentsResponse.data?.readComments?.comments);
      setCount(readCommentsResponse.data?.readComments?.totalComments);
    }
  }, [readCommentsResponse.data]);

  useEffect(() => {
    setPage(0);
  }, [count])


  return {
    comments,
    readCommentsResponse,
    setComments,
    formikJobComment,
    createComment,
    createCommentResponse,
    page,
    rowsPerPage,
    count,
    changePageHandler,
    changeRowsHandler,
  };
};

export default useJobComments;
