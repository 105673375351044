import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useEffect } from "react";

const TermsAndConditions = ({ maximumHeight }: any) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      indent10px: {
        marginLeft: "10px",
      },
    })
  );

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: "30px 50px",
      }}
    >
      <div
        style={
          maximumHeight
            ? {
                maxHeight: maximumHeight,
                overflow: "scroll",
                display: "flex",
                flexDirection: "column",
                maxWidth: "800px",
              }
            : { display: "flex", flexDirection: "column", maxWidth: "800px" }
        }
      >
        <h1>Terms and Conditions</h1>
        <p>
          <strong>Effective starting date: 31 January 2021</strong>
        </p>
        <p>
          Welcome to <a href="https://engiflow.co/">https://engiflow.co</a>.
          These Terms and Conditions that govern the use of our Platform (as
          defined below) and Services (as defined below) and all content,
          services and/or products available on or through the Platform.
        </p>
        <p>
          The EngiFlow services are offered to you subject to your acceptance,
          without modification (other than Special Terms (as defined below)
          agreed by the parties pursuant to these Terms and Conditions contained
          herein and all other operating rules, policies (including, without
          limitation, our Privacy Policy, and any future modifications thereof,
          and procedures that may be published from time to time on the Platform
          or made available to you on or through EngiFlow website. When accepted
          by you (as defined below), these Terms form a legally binding contract
          between you and Supplier (as defined below). If you are entering into
          these Terms on behalf of an entity, such as your employer or the
          company you work for, you represent that you have the legal authority
          to bind that entity.
        </p>
        <p>
          <strong>
            <em>
              Please read these terms and conditions carefully. By registering
              for, accessing, browsing, and/or otherwise using the EngiFlow
              Platform, you acknowledge that you have read, understood and agree
              to be bound by these terms and conditions. If you do not agree to
              be bound by these terms and conditions, do not access, browse or
              otherwise use the EngiFlow platform.
            </em>
          </strong>
        </p>
        <p>
          Supplier may, in its sole discretion, elect to suspend or terminate
          access to, or use of EngiFlow to anyone who violates these Terms.
        </p>
        <p>
          <strong>1. </strong>
          <strong>Definitions </strong>
        </p>

        <table style={{ border: "none" }} cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Account</strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  <strong>
                    The primary means for accessing and using EngiFlow, subject
                    to payment of a Fee designated in the selected Plan.
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Additional Terms</strong>
                  <strong></strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  Provisions applicable to the Client’s use of a particular
                  Feature as indicated by the Supplier in relevant areas of
                  EngiFlow services.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Authorisation</strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  The set of rights and privileges on the Web Site assigned to a
                  User by a Client.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Client</strong>
                  <strong></strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  A natural or legal person who has accepted these Terms with
                  the Supplier.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Client Data</strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  Files and any other digital data and information, which is
                  subjected to the EngiFlow Services or otherwise inserted to
                  the System by the Client.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Content</strong>
                  <strong></strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  Any data and information available through EngiFlow Services
                  or contained within the structure of the System, articles,
                  documents, brochures, presentations, pictures, images,
                  audiovisual works, other informational materials, calculators
                  and any comments.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>EngiSeek Pty Ltd</strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  EngiSeek Pty Ltd is a private limited liability company
                  established in Australia.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>EngiFlow</strong>
                  <strong></strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>EngiFlow is owned by EngiSeek Pty Ltd.</p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>EngiFlow materials</strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  The visual interfaces, graphics, design, systems, methods,
                  information, computer code, software, services, “look and
                  feel”, organisation, compilation of the content, code, data,
                  and all other elements of the EngiFlow Services.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>EngiFlow services</strong>
                  <strong></strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  The Web Site, System, Content, Platform and all content,
                  services and/or products available on or through the Platform.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Feature</strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  A function or set of functions providing a particular
                  capability within EngiFlow Services as determined by the
                  Supplier.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Fee</strong>
                  <strong></strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  Regular payment for using the activated Account. All fees are
                  in Australian dollars unless otherwise indicated.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Files</strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  Documents of any kind (images, spreadsheets, text files, etc.)
                  that are inserted to the System by the Client.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Free Trial</strong>
                  <strong></strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  Temporary access for the purposes of trying out the Web Site
                  and EngiFlow Services in accordance with any selected Plan
                  without paying a Fee.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Intellectual Property Rights</strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  Intellectual Property Rights means industrial and intellectual
                  property rights of whatever nature throughout the world
                  conferred on EngiFlow under statute, common law or equity,
                  whether existing now or at any time in the future in relation
                  to the EngiFlow Services Platform or otherwise. It should be
                  noted that Intellectual Property Rights includes:
                </p>
                <p>
                  · everything belonging to EngiFlow in respect of or in
                  connection with copyright, inventions (including patents),
                  formulae, engineering calculators, databases, business
                  processes and methods, trademarks, service marks, business
                  names, trade names, domain names, designs, confidential
                  information, trade secrets or otherwise.
                </p>
                <p>
                  · all concepts, ideas, methods, methodologies and business
                  methods developed in relation to the Platform.
                </p>
                <p>
                  · the copyright subsisting in all documents and electronically
                  stored files encompassing the things referred to in this
                  definition; and
                </p>
                <p>
                  · any invention, discovery, or right to apply for a patent,
                  trade mark or design in relation to anything referred to in
                  this definition.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Plan</strong>
                  <strong></strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  Various criteria related to the use and functionality of the
                  EngiFlow Services and on which the Fee is based.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Platform</strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  The EngiFlow online application that allows for design and
                  construction management.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Registered Business Address</strong>
                  <strong></strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  The registered business address of EngiSeek Pty Ltd trading as
                  EngiFlow is Suite 3, 39-41 Nerang Street, Nerang Qld 4211.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Special Terms</strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  Any particulars, specifications and conditions by which the
                  parties have agreed to deviate from these Terms.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Supplier</strong>
                  <strong></strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  As the context requires, EngiFlow or EngiSeek Pty Ltd; and
                  collectively, the “Suppliers”.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>System</strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  The integrated cloud computing solution for providing the
                  EngiFlow Services, including applications, software, hardware,
                  data bases, interfaces, associated media, documentation,
                  updates, new releases, calculators and other components or
                  materials provided therewith.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>User</strong>
                  <strong></strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  A natural person granted with the Authorisation to use the
                  Account on behalf of a Client.
                </p>
              </td>
            </tr>
            <tr>
              <td width="132" valign="top">
                <p>
                  <strong>Website</strong>
                </p>
              </td>
              <td width="510" valign="top">
                <p>
                  The compilation of all web documents (including images, php
                  and html files) made available via{" "}
                  <a href="https://engiflow.co/">https://engiflow.co</a> or its
                  sub domains or domains with identical names under other top
                  domains and owned by Supplier.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>2. </strong>
          <strong>Authority to Enter into These Terms with Supplier</strong>
        </p>
        <p>
          The use of the EngiFlow Services is subject to acceptance of these
          Terms and Conditions. To accept these Terms for itself or on behalf of
          a Client, a person must have the legal capacity to do so. In the case
          of a legal entity, the entity must be duly incorporated and in good
          standing.
        </p>
        <p>
          You may not, without Supplier’s prior written consent, access the
          EngiFlow Services:
        </p>
        <p className={classes.indent10px}>(i) for production purposes;</p>
        <p className={classes.indent10px}>(ii) if you are a competitor of EngiFlow or EngiSeek Pty Ltd;</p>
        <p className={classes.indent10px}>
          (iii) to monitor the availability, performance or functionality of the
          EngiFlow Services; or
        </p>
        <p className={classes.indent10px}>(iv) for other benchmarking or competitive purposes.</p>
        <p>
          Once accepted, these Terms and Conditions remain effective until
          terminated as provided for herein.
        </p>
        <p>
          <strong>3. </strong>
          <strong>Modifications to Terms</strong>
        </p>
        <p>
          Supplier reserves the right, at its sole discretion, to change,
          modify, add, or remove portions of the Terms and Conditions at any
          time by posting such changes on or through the Platform. Please check
          these Terms periodically for changes. Your continued use of the
          EngiFlow Services after such changes have been posted as provided
          above constitutes your binding acceptance of such changes. Such
          amended Terms will automatically be effective upon the earlier of
        </p>
        <p className={classes.indent10px}>(i) your continued use of the EngiFlow Services; or</p>
        <p className={classes.indent10px}>
          (ii) 30 days from posting of such modified Terms on or through the
          Platform.
        </p>
        <p>
          Notwithstanding the foregoing, the resolution of any dispute that
          arises between you and Supplier will be governed by the Terms in
          effect at the time such dispute arose.
        </p>
        <p>
          <strong>4. </strong>
          <strong>Responsibilities</strong>
        </p>
        <p>
          <strong>4.1 </strong>
          <strong>Provision of EngiFlow Services</strong>
        </p>
        <p>
          Supplier will (a) make the EngiFlow Services, Content and Client Data
          available to a Client pursuant to these Terms and Conditions, (b)
          provide applicable standard support for the EngiFlow Services to
          Client at no additional charge, and/or upgraded support (for an
          additional charge, if applicable), (c) use commercially reasonable
          efforts to make the EngiFlow Services available 24 hours a day, 7 days
          a week, except for:
        </p>
        <p className={classes.indent10px}>
          (i) planned downtime (of which Supplier shall give advance electronic
          notice as provided in the Guidelines), and
        </p>
        <p className={classes.indent10px}>
          (ii) any unavailability caused by circumstances beyond Supplier’s
          reasonable control, including, for example, an act of God, act of
          government, flood, fire, earthquake, civil unrest, act of terror,
          strike or other labor problem, Internet service provider failure or
          delay, or denial of service attack.
        </p>
        <p>
          <strong>4.2 </strong>
          <strong>Protection of Client Data</strong>
        </p>
        <p>
          Supplier will maintain administrative, physical, and technical
          safeguards for protection of the security, confidentiality and
          integrity of Client Data. Those safeguards will include, but will not
          be limited to, measures for preventing access, use, modification or
          disclosure of Client Data by Supplier personnel except (a) to provide
          the EngiFlow Services and prevent or address service or technical
          problems, (b) as compelled by law in accordance with Section 7.4
          (Compelled Disclosure) below, or (c) as a Client or User expressly
          permit in writing.
        </p>
        <p>
          Supplier may use service providers to perform the Services. Supplier
          will make commercially reasonable efforts to ensure that data
          transfers to service providers meet requirements applicable to
          Clients’ processing of data.
        </p>
        <p>
          <strong>5. </strong>
          <strong>Using the EngiFlow Services</strong>
        </p>
        <p>
          <strong></strong>
        </p>
        <p>
          <strong>5.1 </strong>
          <strong>Establishing an Account</strong>
        </p>
        <p>
          Certain Features, functions, parts or elements of the EngiFlow
          Services can be used or accessed only by holders of an Account. The
          person who wishes to create an Account must a) complete the sign-up
          form on the Web Site; and b) accept these Terms by clicking “Sign up”
          or other similar button.
        </p>
        <p>
          Each Client may have only one Account. If several persons need to use
          an Account on behalf of Client, Client must designate such persons as
          Users. Each such User shall be subject to the restrictions set forth
          in these Terms.
        </p>
        <p>
          If Client has designated Users and granted them Authorisation, such
          Users will be deemed to be authorised to act on behalf of Client when
          using the Account. Supplier is not responsible for and shall have no
          liability for verifying the validity of Authorisation of any User.
          However, Supplier may, in its discretion, request additional
          information or proof of the person’s credentials. If Supplier is not
          certain if a User has been granted Authorisation, Supplier may, in its
          sole discretion, prevent such User from accessing the EngiFlow
          Services.
        </p>
        <p>
          The Client and any User associated with an Account must provide
          Supplier with true, accurate, current, and complete information about
          the Client, Users or Account and keep it up to date.
        </p>
        <p>
          <strong>5.2 </strong>
          <strong>Logging into an Account</strong>
        </p>
        <p>
          Supplier shall provide Client with a username and password (“Login
          Credentials”) to be used to log in to its Account unless the Client.
          These Login Credentials must not be used by multiple persons. If
          Client has designated several Users, each User will be provided with
          separate Login Credentials. Client and each User are responsible for
          keeping confidential all login credentials associated with an Account.
          Client must promptly notify Supplier a) of any disclosure, loss or
          unauthorised use of any Login Credentials; b) of a User’s departure
          from the Client’s organisation; c) of a change in a User’s role in the
          Client’s organisation; and d) of any termination of a User’s right for
          any reason.
        </p>
        <p>
          <strong>5.3 </strong>
          <strong>Termination of an Account</strong>
        </p>
        <p>
          Client may terminate these Terms at any time as provided in Section
          17.
        </p>
        <p>
          Supplier shall permanently delete the Account within six (6) months of
          the effective date of the termination.
        </p>
        <p>
          <strong>5.4 </strong>
          <strong>Fees</strong>
        </p>
        <p>
          The use of an Account is subject to a Fee. Upon sign-up for an
          Account, the Client must select a Plan. Different rates apply to
          different Plans. The applicable Fee is charged in advance on monthly
          or annual payment intervals, unless agreed otherwise between parties.
          All Fees are non-refundable, i.e. there are no refunds or credits for
          periods where the Client did not use an activated Account, used it
          only partially, or deactivated the Account or terminated these Terms
          during an ongoing payment interval. If, after signing up, Client
          elects to upgrade to a more expensive Plan, the unused portion of any
          prepaid Fees shall be applied to the Fee of the more expensive Plan.
        </p>
        <p>
          All Fees are exclusive of all taxes, levies or duties applicable under
          any applicable law, unless stated otherwise stated herein. Client is
          solely responsible for the payment of such taxes, levies or duties.
        </p>
        <p>
          <strong>5.5 </strong>
          <strong>Changing Plans</strong>
        </p>
        <p>
          Any Client has the right to upgrade or downgrade a current Plan at any
          time by selecting a new Plan among the collection of Plans determined
          by the Supplier. In such an event, the Client’s credit card on file
          with the Supplier will automatically be charged with a Fee for the
          next payment interval with the rate stipulated in the new Plan.
        </p>
        <p>
          Downgrading of the current Plan may cause the loss of Features,
          functionality, or capacity of the Account, as well as the loss of
          Client Data.
        </p>
        <p>
          <strong>5.6 </strong>
          <strong>Free Trial</strong>
        </p>
        <p>
          A new Client may be entitled to a Free Trial, unless the Client has
          applied for the Account as a result of an ongoing marketing campaign
          organised by the Supplier in co-operation with its partners. The
          Client is not required to provide any credit card information during
          the period of Free Trial. If the period of Free Trial has expired, the
          Account will be automatically deactivated. In order to prevent
          deactivation or to reactivate the Account, the Client is required to
          select a suitable Plan and pay the first Fee. If the Client does not
          pay the first Fee within 2 weeks as of the expiry of the Free Trial,
          Supplier has the right to permanently delete the Account, including
          all Client Data therein.
        </p>
        <p>
          In addition to the current collection of Plans, Supplier may offer
          special discounts and motivation schemes (for example finder’s fees,
          etc.).
        </p>
        <p>
          <strong>6. </strong>
          <strong>Payment</strong>
        </p>
        <p>
          The following provisions are applicable only if you purchase access to
          the EngiFlow Services directly from Supplier.
        </p>
        <p>
          <strong>6.1 </strong>
          <strong>Payment Card Authorisation</strong>
        </p>
        <p>
          Supplier may seek pre-authorisation of Client’s payment card account
          prior to your purchase of EngiFlow Services in order to verify that
          the card is valid and has the necessary funds or credit available to
          cover your purchase. You authorise such payment card account to pay
          any amounts described herein, and authorise Supplier to charge all
          sums described in these Terms to such card account. You agree to
          provide Supplier updated information regarding your payment card
          account upon Supplier’s request and any time the information earlier
          provided is no longer valid.
        </p>
        <p>
          <strong>6.2 </strong>
          <strong>Direct Debit Payments</strong>
        </p>
        <p>
          In some markets Supplier may, if Client elects, request that Client
          complete a direct debit mandate to enable direct debit payments. In
          such cases Supplier shall comply with all applicable national rules
          and regulations related to direct debit payments.
        </p>
        <p>
          <strong>6.3 </strong>
          <strong>Electronic Invoice</strong>
        </p>
        <p>
          If Supplier has not sought pre-authorisation of your payment card,
          then before the end of each payment interval, Client will be issued an
          electronic invoice for payment of the Fee of the next payment
          interval. Client must pay the invoice by the due date indicated on the
          invoice.
        </p>
        <p>
          <strong>6.4 </strong>
          <strong>Overage Charges</strong>
        </p>
        <p>
          Upon delay with any payments, Supplier may require the Client to pay
          interest on the delay (penalty for late payment) for the period as of
          the time the payment obligation falls due until conforming performance
          is rendered. The interest rate of penalty for late payment due shall
          be 15.00% a year, if any interest paid to EngiFlow is determined to be
          in excess of the then legal maximum rate, then that portion of each
          interest payment representing an amount in excess of the then legal
          maximum rate shall be deemed a payment of amounts owing under Section
          6.2. Upon calculation of the penalty for late payment, one year shall
          be deemed to contain 365 calendar days.
        </p>
        <p>
          <strong>7. </strong>
          <strong>Client data</strong>
        </p>
        <p>
          <strong></strong>
        </p>
        <p>
          <strong>7.1 </strong>
          <strong>Uploading Client Data to Platform</strong>
        </p>
        <p>
          If the Client uploads Client Data to the Platform, such Client Data
          and any processing of such Client Data must be in compliance with
          these Terms and applicable law. All rights, title and interest in and
          to the Client Data belong to the Client or third persons (including
          Users, persons and Organisations) whether posted and/or uploaded by
          you or made available on or through the EngiFlow Services by Supplier.
          By uploading Client Data to the Platform, Client authorises Supplier
          to process the Client Data. The Client is responsible for ensuring
          that
        </p>
        <p>
          a) the Client and any of the Users associated with the Account do not
          create, transmit, display or make otherwise available any Client Data
          that violates the terms of these Terms, the rights of Supplier, other
          Clients or Users, persons or Organisations or is harmful (for example
          viruses, worms, malware and other destructive codes), offensive,
          threatening, abusive, harassing, tortuous, defamatory, vulgar,
          obscene, invasive of another’s privacy, hateful or otherwise unlawful;
          and
        </p>
        <p>
          b) the Client and all of the Users associated with the Account have
          the necessary rights to use the Client Data, including to insert it
          into the Platform and process it by means of the Account.
        </p>
        <p>
          <strong>7.2 </strong>
          <strong>No Guarantee of Accuracy</strong>
        </p>
        <p>
          Supplier does not guarantee any accuracy with respect to any
          information contained in any Client Data, and strongly recommends that
          you think carefully about what you transmit, submit or post to or
          through the EngiFlow Services. You understand that all information
          contained in Client Data is the sole responsibility of the person from
          whom such Client Data originated. This means that Client, and not
          Supplier, is entirely responsible for all Client Data that is
          uploaded, posted, transmitted, or otherwise made available through the
          EngiFlow Services, as well as for any actions taken by the Suppliers
          or other Clients or Users as a result of such Client Data.
        </p>
        <p>
          <strong>7.3 </strong>
          <strong>Unlawful Client Data</strong>
        </p>
        <p>
          Supplier is not obliged to pre-screen, monitor or filter any Client
          Data or acts of its processing by the Client in order to discover any
          unlawful nature therein. However, if such unlawful Client Data or the
          action of its unlawful processing is discovered or brought to the
          attention of Supplier or if there is reason to believe that certain
          Client Data is unlawful, Supplier has the right to:
        </p>
        <p>a) notify the Client of such unlawful Client Data;</p>
        <p>
          b) deny its publication on the Web Site or its insertion to the
          System;
        </p>
        <p>
          c) demand that the Client bring the unlawful Client Data into
          compliance with these Terms and applicable law; and/or
        </p>
        <p>
          d) temporarily or permanently remove the unlawful Client Data from the
          Web Site or Account, restrict access to it or delete it.
        </p>
        <p>
          If Supplier is presented convincing evidence that the Client Data is
          not unlawful, Supplier may, at its sole discretion, restore such
          Client Data, which was removed from the Web Site or Account or access
          to which was restricted.
        </p>
        <p>
          In addition, in the event Supplier believes in its sole discretion
          Client Data violates applicable laws, rules or regulations or these
          Terms, Supplier may (but has no obligation), to remove such Client
          Data at any time with or without notice.
        </p>
        <p>
          <strong>7.4 </strong>
          <strong>Compelled Disclosure</strong>
        </p>
        <p>
          Supplier may disclose a Client’s confidential information to the
          extent compelled by law to do so. In such instance, Supplier will use
          commercially reasonable efforts to provide Client with prior notice of
          the compelled disclosure (to the extent legally permitted) and Client
          shall provide reasonable assistance, at its cost, if Client wishes to
          contest the disclosure. If Supplier is compelled by law to disclose
          Client’s confidential information as part of a civil proceeding to
          which Supplier is a party, and Client is not contesting the
          disclosure, Client will reimburse Supplier for its reasonable cost of
          compiling and providing secure access to that confidential
          information.
        </p>
        <p>
          <strong>8. </strong>
          <strong>Services</strong>
        </p>
        <p>
          <strong></strong>
        </p>
        <p>
          <strong>8.1 </strong>
          <strong>Use of the EngiFlow Services</strong>
        </p>
        <p>
          Subject to these Terms, and the payment of the applicable service Fee,
          Supplier grants Client and its authorised users a non-exclusive,
          non-transferable, non-sub-licensable license to use the EngiFlow
          Services to:
        </p>
        <p>
          a) collect, store and organise Client Data, such as add new Persons
          and Organisations, create estimating, detailing, engineering,
          inspection and other design processes, utilise engineering
          calculators, generate reports based on Client Data, add new Users and
          grant them Authorisations, assign Activities to a particular User;
        </p>
        <p>b) modify and delete Client Data;</p>
        <p>
          c) customise the standard Features or functionality of the EngiFlow
          Services; and
        </p>
        <p>
          d) receive reasonable help and guidance and from Supplier regarding
          the use of the EngiFlow Services.
        </p>
        <p>
          If Supplier determines Client usage of the Services to be outside of
          the permitted and intended use as outlined herein, or bandwidth usage
          of the Service or any Features or functionality to be significantly
          excessive in relation to other Users, Supplier reserves the right to
          suspend respective Client Account, (or part thereof) until Client
          assures Supplier that Client shall refrain from further abuse of the
          Services.
        </p>
        <p>
          Client may link our Platform as long as the following conditions are
          observed:
        </p>
        <p>
          a) You present the links in a way that fairly represents Supplier;
        </p>
        <p>
          b) The context of the link must not suggest that we endorse you in any
          way, or have any connection with your site; and
        </p>
        <p>
          c) The context of the link must not suggest that we have created any
          of your content.
        </p>
        <p>
          <strong>8.2 </strong>
          <strong>Technical Support</strong>
        </p>
        <p>
          Supplier shall provide reasonable technical support to Client and its
          authorised User at the reasonable request of the Client. Supplier
          shall respond to enquiries of support from a Client utilising the
          contacts set forth below as soon as reasonably possible. Responding to
          enquiries of Clients and Users who have accepted these Terms but do
          not have an Account may be less expedient, or may not occur at all.
        </p>
        <p>The contacts for all enquiries of support are:</p>
        <p>
          a) e-mail:{" "}
          <a href="mailto:engiflow@engiseek.com">engiflow@engiseek.com</a>.
        </p>
        <p>
          <strong>8.3 </strong>
          <strong>Modifications to Service</strong>
        </p>
        <p>
          Supplier reserves the right to modify the EngiFlow Services or any
          part or element thereof from time to time without prior notice,
          including, without limitation:
        </p>
        <p>a) rebranding the EngiFlow Services at its sole discretion;</p>
        <p>
          b) ceasing providing or discontinuing the development any particular
          EngiFlow Service or part or element of the Platform temporarily or
          permanently;
        </p>
        <p>
          c) taking such action as is necessary to preserve Supplier’s rights
          upon any use of the EngiFlow Services that may be reasonably
          interpreted as violation of Supplier’s intellectual property rights,
          distribution of Internet viruses, worms, Trojan horses, malware, and
          other destructive activities or illegal activity.
        </p>
        <p>
          As applicable, Client may be notified of such modifications when
          logging in to the Account. Modifications, including change in
          applicable rates for the EngiFlow Services, will become effective
          thirty (30) days before the effective date of such modification.
        </p>
        <p>
          If the Client does not accept the modification, the Client shall
          notify Supplier before the effective date of the modification, and
          these Terms will terminate on the effective date of the modification.
          The Client’s continued use of the EngiFlow Services, or any part or
          element thereof, after the effective date of a modification shall
          indicate its consent to the modifications. Supplier shall not be
          liable to the Client or to any third person for any modification,
          suspension or discontinuance of the EngiFlow Services, or any part or
          element thereof.
        </p>
        <p>
          <strong>8.4 </strong>
          <strong>Additional Features</strong>
        </p>
        <p>
          Supplier may from time to time make additional Features available
          through the EngiFlow Services, which may be subject to Additional
          Terms. The Client's use of any such Feature is subject to their
          acceptance of Additional Terms presented within the relevant Feature
          or these Terms and Conditions.
        </p>
        <p>
          <strong>9. </strong>
          <strong>Data Processing Contract</strong>
        </p>
        <p>
          <strong></strong>
        </p>
        <p>
          <strong>9.1 </strong>
          <strong>Subject matter and nature of processing</strong>
        </p>
        <p>
          The Supplier provides the Platform where the Client, as the data
          controller, can collect, store and organise data determined by the
          Client.
        </p>
        <p>
          The Platform has been designed to work as a design and construction
          management tool but, to the extent not regulated by these Terms, the
          Client decides how they use the Platform.
        </p>
        <p>
          <strong>9.2 </strong>
          <strong>Duration</strong>
        </p>
        <p>
          The Supplier will process data on behalf of the Client until the
          termination of the EngiFlow Services in accordance with these Terms
          and Conditions. Upon termination, Supplier will store the Client’s
          data for a period of six months, should the Client wish to reopen the
          Account to resume the use of the EngiFlow Services or to export Client
          Data, unless instructed otherwise by the Client. After that, the
          contents of the account may be recoverable from Supplier’s backups for
          another three months.
        </p>
        <p>
          <strong>9.3 </strong>
          <strong>Parties’ rights and obligations</strong>
        </p>
        <p>
          The Client’s rights and obligations regarding Client Data are provided
          in sections 4 through 10 of these Terms and Conditions. The Supplier
          ensures that persons authorised to process the personal data have
          committed themselves to confidentiality or are under an appropriate
          statutory obligation of confidentiality.
        </p>
        <p>
          <strong>10. </strong>
          <strong>Restrictions</strong>
        </p>
        <p>
          <strong></strong>
        </p>
        <p>
          <strong>10.1 </strong>
          <strong>Prohibited Activities</strong>
        </p>
        <p>
          Client and its authorised Users may use the EngiFlow Services and any
          part or element thereof only in the scope, with the means and for
          purposes as identified in these Terms and Conditions and applicable
          law.
        </p>
        <p>By way of example, neither the Client nor any User may:</p>
        <p>
          a) May not decompile, reverse engineer, disassemble, rent, lease,
          loan, sell, sublicense or create derivative works from the Site or the
          user content. Nor may you use any network monitoring or discovery
          software to determine the Site architecture, or extract information
          about usage, individual usage, individual identities or users;
        </p>
        <p>
          b) May not copy, duplicate, distribute, modify, adapt, hack, create
          derivative works, reverse engineer or decompile the EngiFlow Services
          or any part or element thereof, or attempt to extract the source code
          thereof, unless (i) it is expressly allowed under applicable law, and
          (ii) to the extent that the Supplier is not permitted by that
          applicable law to exclude or limit the foregoing rights;
        </p>
        <p>
          c) May not copy, modify, reproduce, republish, distribute, display or
          transmit for commercial, non-profit or public purposes or any portion
          of the Site or the user content without our prior written permission;
        </p>
        <p>
          d) Use the EngiFlow Services or any part or element thereof to commit
          a crime, breach any applicable law or entice or invite others to carry
          out such illegal actions; or
        </p>
        <p>
          e) use the EngiFlow Services or any part or element thereof unless it
          has agreed to these Terms.
        </p>
        <p>
          <strong>10.2 </strong>
          <strong>Certain Uses Require Supplier Consent</strong>
        </p>
        <p>
          The Client or any User may not, without Supplier’s prior express
          written consent (e-mail, facsimile, instant messaging, etc.):
        </p>
        <p>
          a) sell, resell, lease, license, sublicense, distribute, provide,
          disclose, divulge, exploit or otherwise grant Access or make the
          EngiFlow Services available in whole or in part to any third persons,
          unless such third person is another authorised User of the same
          Client;
        </p>
        <p>
          b) use the EngiFlow Services or any part or element thereof in a
          scope, with means or for purposes other than those for which their
          functionality was created;
        </p>
        <p>
          c) use the EngiFlow Services or any part or element thereof by means
          of programs that send them automatic enquiries or requests, unless
          such program has been made available by Supplier.
        </p>
        <p>
          <strong>11. </strong>
          <strong>Privacy</strong>
        </p>
        <p>
          Supplier takes the privacy of its Clients and Users very seriously.
          Supplier’s Privacy Policy at{" "}
          <a href="https://engiflow.co/privacy">https://engiflow.co/privacy</a>{" "}
          is hereby incorporated into these Terms and Conditions by reference.
          Please read the Privacy Policy carefully as it governs Supplier’s
          collection, use, and disclosure of Client’s or User’s personal
          information.
        </p>
        <p>
          <strong>12. </strong>
          <strong>Intellectual Property Rights</strong>
        </p>
        <p>
          <strong>12.1 </strong>
          <strong>
            EngiFlow’s Intellectual Property Rights in the EngiFlow Services
          </strong>
        </p>
        <p>
          The EngiFlow Services, EngiFlow Materials, EngiFlow trade names and
          trademarks, and any parts or elements thereof are solely and
          exclusively owned and operated by Supplier and its third party vendors
          and hosting partners.
        </p>
        <p>
          EngiFlow Materials are protected by copyright, trade dress, patent,
          trade secrets, and trademark laws, international conventions and
          treaties, and all other relevant intellectual property and proprietary
          rights laws.
        </p>
        <p>
          Supplier, its affiliates and licensors retains all right, title and
          interest in such EngiFlow Services, EngiFlow Materials, EngiFlow trade
          names and trademarks, and any parts or elements. Your use of the
          EngiFlow Services and EngiFlow Materials, and any parts or elements
          does not grant to you any ownership right or intellectual property
          rights therein.
        </p>
        <p>
          Any commercial or promotional distribution, publishing or exploitation
          of the EngiFlow Materials is strictly prohibited unless you have
          received the express prior written permission from Supplier or the
          otherwise applicable rights holder. Supplier reserves all rights to
          the EngiFlow Services, EngiFlow Materials and EngiFlow trade names and
          trademarks not expressly granted in the Terms and Conditions.
        </p>
        <p>
          <strong>12.2 </strong>
          <strong>Content Owned by EngiFlow</strong>
        </p>
        <p>
          Subject to these Terms and Conditions and the payment of the
          applicable service Fee, Supplier grants Client and its authorised
          users a non-exclusive, non-transferable, non-sub-licensable license to
          download a single copy of any part of the Content solely for your
          personal, non-commercial use if you retain all copyright and
          proprietary notices that are contained in such part of the Content.
          You expressly acknowledge that you do not acquire any ownership rights
          by downloading any copyrighted material from or through the Platform
          or the EngiFlow Services. You shall not copy, distribute or publish
          any Content or any information obtained or derived therefrom except as
          permitted on or through the EngiFlow Services or as otherwise
          permitted by applicable law.
        </p>
        <p>
          <strong>12.3 </strong>
          <strong>Client Data</strong>
        </p>
        <p>
          Supplier may use Client Data in an aggregated or anonymised format for
          research, educational and other similar purposes. Supplier may not
          otherwise use or display Client Data without Client’s written consent.
          Supplier respects your right to exclusive ownership of your Client
          Data. Unless specifically permitted by you, your use of the EngiFlow
          Services does not grant Supplier the license to use, reproduce, adapt,
          modify, publish or distribute the Client Data created by you or stored
          in your Account for Supplier’s commercial, marketing or any similar
          purpose. Client expressly grants Supplier the right to use and analyse
          aggregate system activity data associated with use of the EngiFlow
          Services by Client and its Users for the purposes of optimising,
          improving or enhancing the way the EngiFlow Services operate, and to
          create new Features and functionality in connection with the EngiFlow
          Services in the sole discretion of Supplier.
        </p>
        <p>
          Client is solely responsible for its own Client Data and the
          consequences of posting or publishing them on or through the EngiFlow
          Service. In connection with Client Data, Client affirms, represents,
          and warrants that: (i) Client either owns its Client Data or has the
          necessary licenses, rights, consents, and permissions to use and
          authorise the Supplier to display or otherwise use the Client Data
          under all patent, trademark, copyright, trade secrets, or other
          proprietary rights in and to your Client Data in a manner consistent
          with the intended Features and functionality of the EngiFlow Services
          and these Terms, and (ii) Client Data, Supplier’s or any EngiFlow
          Licensee’s use of such Client Data pursuant to these Terms, and
          Supplier’s or any EngiFlow Licensee’s exercise of the license rights
          set forth in Section 11.3(a), do not and will not: (a) infringe,
          violate, or misappropriate any third-party right, including any
          copyright, trademark, patent, trade secret, moral right, privacy
          right, right of publicity, or any other intellectual property or
          proprietary right; (b) violate any applicable law or regulation
          anywhere in the world; or (c) require obtaining a license from or
          paying any fees and/or royalties by Supplier to any third party for
          the performance of any EngiFlow Service Client has chosen to be
          performed by Supplier or for the exercise of any rights granted in
          these Terms, unless Client and Supplier otherwise agree.
        </p>
        <p>
          <strong>12.4 </strong>
          <strong>Feedback</strong>
        </p>
        <p>
          If Client or a User provides Suppliers with any comments, bug reports,
          feedback, or modifications for the EngiFlow Services (“Feedback”),
          Supplier shall have the right to use such feedback at its discretion,
          including, but not limited to the incorporation of such suggested
          changes into the EngiFlow Services.
        </p>
        <p>
          Client or User (as applicable) hereby grants Supplier a perpetual,
          irrevocable, nonexclusive, royalty free license under all rights
          necessary to incorporate, publish, reproduce, distribute, modify,
          adapt, prepare derivative works of, publicly display, publicly
          perform, exploit and use your Feedback for any purpose. Supplier shall
          have the right to modify or remove any Feedback provided in the public
          areas of the Web Site if the Supplier deems, at its discretion,
          harmful, offensive, threatening, abusive, harassing, tortuous,
          defamatory, vulgar, obscene, invasive of another’s privacy, hateful or
          otherwise unlawful.
        </p>
        <p>
          <strong>13. Third-Party Sites, Products and Services</strong>
        </p>
        <p>
          The EngiFlow Services may include links to other websites or services
          (“Linked Sites”) solely as a convenience to Clients. Unless otherwise
          specifically and explicitly indicated, Supplier does not endorse any
          such Linked Sites or the information, material, products, or services
          contained on or accessible through Linked Sites. Furthermore, Supplier
          makes no express or implied warranties with regard to the information,
          material, products, or services that are contained on or accessible
          through Linked Sites.
        </p>
        <p>
          Access and use of linked sites, including the information, material,
          products, and services on linked sites or available through linked
          sites, is solely at your own risk.
        </p>
        <p>
          <strong>14. Disclaimers; No Warranty</strong>
        </p>
        <p>
          Unless otherwise expressly stated by supplier, the EngiFlow Services,
          EngiFlow Material, and any content, services, or features made
          available in conjunction with or through the EngiFlow Services are
          provided “as is” and “as available” without warranties of any kind
          either express or implied. To the fullest extent permissible pursuant
          to applicable law, supplier and its affiliates disclaim all
          warranties, statutory, express or implied, including, but not limited
          to, implied warranties of merchantability, fitness for a particular
          purpose, non-infringement of proprietary rights, correctness,
          accuracy, and reliability.
        </p>
        <p>
          Unless otherwise expressly stated by supplier, supplier and its
          affiliates do not warrant that the EngiFlow Services and any content,
          client data services, or features made available in conjunction with
          or through the EngiFlow Services will be uninterrupted or error-free,
          that defects will be corrected, or that the EngiFlow Services and any
          content, client data, services, or features made available in
          conjunction with or through the EngiFlow Services or the server that
          makes them available are free of viruses or other harmful components.
        </p>
        <p>
          Unless otherwise expressly stated by supplier, supplier and its
          affiliates do not warrant or make any representations regarding the
          use or the results of the use of the platform, the EngiFlow Services,
          EngiFlow Material or any linked sites, in terms of correctness,
          accuracy, reliability, or otherwise.
        </p>
        <p>
          The laws of certain countries and states do not allow limitations on
          implied warranties or the exclusion or limitation of certain damages.
          If these laws apply to you, some or all of the above disclaimers,
          exclusions, or limitations may not apply to you, and you might have
          additional rights.
        </p>
        <p>
          <strong>15. Indemnification</strong>
        </p>
        <p>
          You agree to defend, indemnify and hold harmless Supplier and its
          affiliates, and their respective directors, officers, employees and
          agents, from any claims, losses, damages, liabilities, including legal
          fees, arising out of your use or misuse of the EngiFlow Services,
          EngiFlow Material, representations made to the Supplier, its
          affiliates and/or third parties, violation of these Terms, violation
          of the rights of any other person or entity, or any breach of the
          foregoing representations, warranties, and covenants. Supplier
          reserves the right, at its own expense, to assume the exclusive
          defense and control of any matter for which you are required to
          indemnify Supplier, and you agree to cooperate with such defense of
          these claims.
        </p>
        <p>
          <strong>16. Limitation of Liability</strong>
        </p>
        <p>
          <strong>16.1 No Liability</strong>
        </p>
        <p>
          Supplier shall not be liable to the Client or User for any
          consequences resulting from:
        </p>
        <p>
          a) any modifications in these Terms, calculation and rates of Fees,
          the EngiFlow Services, EngiFlow Material, or any part or element
          thereof (including but not limited to Account), including any error,
          permanent or temporary interruption, discontinuance, suspension or
          other type of unavailability of the EngiFlow Services or EngiFlow
          Material;
        </p>
        <p>
          b) deletion of, corruption of, or failure to store any Client Data;
        </p>
        <p>
          c) use of Client Data by the Client or any of the Users associated
          with the Account;
        </p>
        <p>d) upgrading or downgrading the current Plan;</p>
        <p>
          e) any disclosure, loss or unauthorised use of the login credentials
          of Client or any authorised User due to Client’s failure to keep them
          confidential;
        </p>
        <p>
          f) the Client’s use of the Account or the EngiFlow Services by means
          of browsers other than those accepted or supported by the Supplier;
        </p>
        <p>
          g) the application of any remedies against the Client or authorized
          Users by the Supplier, for example if the Client or User has committed
          a crime or conducted a breach of applicable law by using the EngiFlow
          Services or any part or element thereof;
        </p>
        <p>
          h) the differences between technologies and platforms used for access,
          for example if certain Features, functions, parts or elements of the
          EngiFlow Services are designed for use on a personal computer or
          laptop and do not function on a mobile platform or a tablet;
        </p>
        <p>
          i) the Supplier’s application of the remedies described in these
          Terms, even if the reasonable grounds or legal basis for the
          application of these remedies turned out to be unfounded or invalid
          afterwards.
        </p>
        <p>
          In addition, Supplier and its affiliates shall not be liable to the
          Client for any claim by any User, person, Organisation or third
          persons against the Client arising out of the Client’s failure to:
        </p>
        <p>
          a) provide Supplier with accurate information about the Client, Users
          or Account;
        </p>
        <p>
          b) notify Supplier of any reasons due to which a User does not have
          the right to use the Account on behalf of the Client;
        </p>
        <p>
          c) provide any Products which it has agreed to provide to such a
          person or Organisation (whether such failure arises as a result of
          Supplier’s negligence, breach of these Terms or otherwise);
        </p>
        <p>d) ensure the lawfulness of the Client Data;</p>
        <p>e) obtain the necessary rights to use the Client Data; or</p>
        <p>f) abide by any of the restrictions described in these Terms.</p>
        <p>
          <strong>16.2 Limitation of Liability</strong>
        </p>
        <p>
          Subject to our obligations under any implied conditions and
          warranties, our maximum aggregate liability for all claims under or
          relating to these terms and conditions or their subject matter,
          whether in contract, tort (including without limitation negligence),
          in equity, under statute, under an indemnity or on any other basis, is
          limited to AU$100.
        </p>
        <p>
          In no event shall the aggregate liability of EngiFlow and its
          affiliates arising out of or related to these terms exceed the total
          amount paid by client hereunder for the EngiFlow Services giving rise
          to the liability in the six months preceding the first incident out of
          which the liability arose.
        </p>
        <p>
          The foregoing limitation will apply whether an action is in contract
          or tort and regardless of the theory of liability, but will not limit
          client’s payment obligations under the “payment” section above.
        </p>
        <p>
          <strong>16.3 Exclusion of Consequential and Related Damages</strong>
        </p>
        <p>
          In no event will either party or its affiliates have any liability
          arising out of or related to these terms for any lost profits,
          revenues, goodwill, or indirect, special, incidental, consequential,
          cover, business interruption or punitive damages, whether an action is
          in contract or tort and regardless of the theory of liability, even if
          a party or its affiliates have been advised of the possibility of such
          damages or if a party’s or its affiliates’ remedy otherwise fails of
          its essential purpose.
        </p>
        <p>
          The foregoing disclaimer will not apply to the extent prohibited by
          law.
        </p>
        <p>
          <strong>17. Termination of These Terms</strong>
        </p>
        <p>
          <strong>17.1 For Convenience</strong>
        </p>
        <p>
          These Terms may be terminated for convenience in the following
          situations;
        </p>
        <p>
          a) by the Client any time by emailing engiflow@engiseek.com where the
          support team will guide them through the cancellation process;
        </p>
        <p>
          b) by Supplier upon decision to end provision of the EngiFlow Services
          and close the Platform; or
        </p>
        <p>
          c) immediately by either party, if proceedings are initiated for the
          other party’s liquidation or insolvency or a negotiated settlement
          with the other party’s creditors is concluded or an assignment is made
          on behalf of the other party for the benefit of creditors.
        </p>
        <p>
          <strong>17.2 For Default</strong>
        </p>
        <p>
          These Terms may be terminated for default upon written notice to the
          other party as indicated in the “Notice” Section below:
        </p>
        <p>
          a) by either party in case of breach of these Terms by the other
          party, if the breach has not been cured within 30 days of receipt of a
          notice from the non-breaching party; or
        </p>
        <p>
          b) immediately by either party if the other party breaches its
          obligations, as applicable under Sections 12 [Intellectual Property
          Rights], 15 [Indemnification], or 10 [Restrictions] of these Terms.
        </p>
        <p>
          <strong>17.3 Effect of Termination</strong>
        </p>
        <p>Upon termination of these Terms,</p>
        <p>
          a) Supplier shall deactivate and permanently delete the Account,
          within six months of the effective date of termination of these Terms.
          If the Client has specifically requested for an earlier deletion of
          the Account, Supplier shall fulfill such request within 1 month of its
          receipt of such request.
        </p>
        <p>b) Client must:</p>
        <p className={classes.indent10px}>
          i) stop using and prevent the further usage of the EngiFlow Services,
          including, without limitation, the Platform;
        </p>
        <p className={classes.indent10px}>ii) pay any amounts owed to Supplier under these Terms; and</p>
        <p className={classes.indent10px}>
          iii) discharge any liability incurred by the Client before under these
          Terms prior to their termination; and
        </p>
        <p className={classes.indent10px}>
          iv) The following provisions shall survive the termination of these
          Terms: Sections 1, 7.4, 10, 11, 12, 14, 15, 16, 18 and 19.
        </p>
        <p>
          <strong>17.4 Remedies</strong>
        </p>
        <p>
          If Supplier terminates these Terms as a result of an uncured breach by
          a Client or User, Supplier is entitled to use the same or similar
          remedies against any other persons who use the EngiFlow Services in
          conflict with these Terms. Notwithstanding the foregoing, Supplier may
          also apply any other remedies available to it under the applicable
          law. Upon application of any remedies, the Client or User may lose
          Access or suffer a loss of certain Features, functions, parts or
          elements of the EngiFlow Services.
        </p>
        <p>
          If Supplier has reasonable grounds to believe that the Client’s or
          User’s use of the EngiFlow Services, including the Account may harm
          any third persons, Supplier has the right to take adequate measures
          under its control to prevent, stop and eliminate the harm, where
          possible, in order to protect those third persons.
        </p>
        <p>
          The Supplier has the right to suspend access to all or any part of the
          Service, including removing Content, at any time for violation of this
          Agreement or to protect the integrity, operability, and security of
          the Service, effective immediately, with or without notice. Unless
          prohibited by law or legal process or to prevent imminent harm to the
          Service or any third party, Supplier typically provides notice in the
          form of a banner or email on or before such suspension. Supplier will,
          in its discretion and using good faith, tailor any suspension as
          needed to preserve the integrity, operability, and security of the
          Service.
        </p>
        <p>
          <strong>18. Who You Are Contracting With</strong>
        </p>
        <p>
          <strong>18.1 Governing Law and Jurisdiction</strong>
        </p>
        <p>
          These Terms are governed by the laws of Queensland, Australia, and you
          irrevocably submit to the exclusive jurisdiction of the courts of
          Queensland, Australia.
        </p>
        <p>
          <strong>19. General Provisions</strong>
        </p>
        <p>
          <strong>19.1 Severability</strong>
        </p>
        <p>
          If any term, condition or provision of these Terms is held to be
          invalid, unenforceable or illegal in whole or in part for any reason,
          that provision shall be enforced to the maximum extent permissible so
          as to effect the intent of the parties. The validity and
          enforceability of the remaining terms, conditions or provisions, or
          portions of them, shall not be affected.
        </p>
        <p>
          <strong>19.2 Entire Agreement</strong>
        </p>
        <p>
          These Terms are the entire agreement between Client and Supplier
          regarding Client’s use of the EngiFlow Services and supersedes all
          prior and contemporaneous agreements, proposals or representations,
          written or oral, concerning its subject matter. Except as otherwise
          provided herein, no modification, amendment, or waiver of any
          provision of these Terms will be effective unless in writing and
          signed by the party against whom the modification, amendment or waiver
          is to be asserted.
        </p>
        <p>
          If any part of these Terms and Conditions is held to be unenforceable,
          the unenforceable part is to be given effect to the greatest extent
          possible and the remainder will remain in full force and effect.
          Should that provision be unlawful, void or unenforceable, then that
          provision shall be deemed severable from the remaining provisions and
          shall not affect their validity and enforceability.
        </p>
        <p>
          These Terms constitute the entire agreement between us and you in
          relation to the Platform and your use of the EngiFlow Services and
          supersede all other (prior or contemporaneous) communications or
          displays whether electronic, oral, or written, between us and you in
          relation to the Platform and the EngiFlow Services.
        </p>
        <p>
          Your use of the Platform is conducted electronically and you agree
          that we may communicate with you electronically for all aspects of
          your use of the Platform, including sending you electronic notices.
        </p>
        <p>
          The provisions of these Terms which by their nature survive
          termination or expiry of these Terms will survive cancellation of your
          registration or termination or expiry of these Terms.
        </p>
        <p>
          <strong>19.4 Assignment</strong>
        </p>
        <p>
          Client may not, directly or indirectly, in whole or in part, by
          operation of law or otherwise, assign or transfer these Terms or
          delegate any of its rights and/or obligations under these Terms
          without Supplier’s prior written consent. Any attempted assignment,
          transfer or delegation without such prior written consent will be void
          and unenforceable. Notwithstanding the foregoing, the Client, or its
          permitted successive assignees or transferees, may assign or transfer
          these Terms or delegate any rights or obligations hereunder without
          consent: (1) to any entity controlled by, or under common control with
          the Client, or its permitted successive assignees or transferees; or
          (2) in connection with a merger, reorganisation, transfer, sale of
          assets or product lines, or change of control or ownership of the
          Client, or its permitted successive assignees or transferees.
        </p>
        <p>
          <strong>19.5 No Waiver</strong>
        </p>
        <p>
          Failure of either Party to exercise or enforce any provision of or any
          of its rights under these Terms shall not be deemed a waiver of future
          enforcement of that or any other provision or right.
        </p>
        <p>
          <strong>19.6 Notices</strong>
        </p>
        <p>
          Except as otherwise specified in these Terms, all notices related to
          these Terms will be in writing and will be effective upon (a) personal
          delivery, (b) the second business day after mailing, or (c), except
          for notices of termination or an indemnifiable claim (“Legal
          Notices”), which shall clearly be identifiable as Legal Notices, the
          day of sending by email. Billing-related notices to you will be
          addressed to the relevant billing contact designated by you. All other
          notices to you will be addressed to the relevant Services system
          administrator designated by you.
        </p>
        <p>
          <strong>20. Dispute Resolution</strong>
        </p>
        <p>
          If you have any problems with the Platform or your Membership or
          complaint about the services you receive, contact us by email at
          engiflow@engiseek.com. We will resolve your complaint quickly and
          fairly.
        </p>
        <p>
          If you still aren’t satisfied, you can contact the Australian
          Financial Complaints Authority (AFCA) at:
        </p>
        <ul>
          <li>Phone: 1800 931 678</li>
          <li>Website: https://www.afca.org.au/</li>
        </ul>
        <p>
          You can also contact the Australian Securities &amp; Investments
          Commission (ASIC) on free call Infoline 1300 300 630 to make a
          complaint and obtain information about your rights.
        </p>
        <p>Last update: 17 February 2021</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
