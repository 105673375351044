import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Button, Container, Divider } from "@material-ui/core";
import { grey, indigo } from "@material-ui/core/colors";
import Notifications from "./Notifications";
import { useAuth0 } from "@auth0/auth0-react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { addNotification } from "../../store/notifications/actions";
import { useDispatch } from "../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      "& .MuiContainer-maxWidthLg": {
        maxWidth: "1460px",
      },
    },

    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      "&:hover": {
        color: theme.palette.primary.dark,
      },
      textTransform: "initial",
      fontWeight: "lighter",
      // fontSize: ".9em",
      transition: "0.3s",
      color: theme.palette.secondary.light,
    },
    titleActive: {
      textDecoration: "none",
      flexGrow: 1,
      color: theme.palette.primary.dark,

      textDecorationColor: theme.palette.secondary.main,
      textDecorationThickness: "2px",
      textUnderlineOffset: "2px",
      fontWeight:"bold"
    },
    linkClass: {
      margin: theme.spacing(1, 1.5),
      "&:hover": {
        textDecoration: "none",
      },
    },
    logo: {
      maxWidth: "150px",
      imageRendering: "inherit",
      color: theme.palette.secondary.main,
      fontWeight: 600,
      // fontFamily: "Audiowide",
    },
    leftSide: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      minWidth: "350px",
    },
    accountIconButton: {
      color: theme.palette.secondary.main,
    },
    loginButton: {
      border: "1px solid #fff",
      borderRadius: "5em",
      margin: theme.spacing(1, 1.5),
      fontWeight: "normal",
      color: theme.palette.secondary.main,
      transition: "background .2s ease-in",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
      },
    },
    appBar: {
      padding: theme.spacing(2, 0),
    },
    toolbar: {
      flexWrap: 'wrap',
    },
    toolbarTitle: {"display":"table"},
    flexGrow_1:{
      flexGrow:1
    }
  })
);

interface NavigationBarProps {
  isAuthenticated: boolean;
  authButtonMethod: any;
  user: any;
}

export const NavigationBar: React.FC<NavigationBarProps> = ({
  isAuthenticated,
  user,
  authButtonMethod,
}) => {
  const dispatch = useDispatch();
  const { loginWithRedirect } = useAuth0();
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activePage, setActivePage] = React.useState<string>();
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const customRedirect = (): void => {
    // window.location.href = window.location.origin;
    history.push("/jobs")
  };

  const isOwner = () => {
    if (user.roles) {
      if (user.roles.includes("Owner")) {
        return (
          <MenuItem
            onClick={() => {
              handleClose();
              history.push("/organisation-dashboard");
            }}
          >
            Organisation
          </MenuItem>
        );
      }
    } else {
      return <div></div>;
    }
  };

  const scrollTo = (id: string) => {
    let elem: HTMLElement | null = document.getElementById(id);

    if (elem) {
      window.scrollTo({
        top: elem.offsetTop - 108,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  // useEffect(() => {
  //   if (isAuthenticated === true) {
  //     const connect = new HubConnectionBuilder()
  //     .withUrl("http://localhost:5000/notificationHub", { accessTokenFactory: () => getAccessTokenSilently() })
  //     .withAutomaticReconnect()
  //     .build();
  //     setConnection(connect);
  // }
  // }, [isAuthenticated]);

  // useEffect(() => {
  //   if (signalRConnection) {
  //     signalRConnection
  //           .start()
  //           .then(() => {
  //             signalRConnection.on("SendNotificationAsync", (notification) => {
  //                   dispatch(addNotification(notification))
  //               });
  //           })
  //           .catch((error) => console.log(error));
  //   }
  // }, [signalRConnection]);

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        elevation={0}
      >
        <Container maxWidth="lg">
          <Toolbar className={classes.toolbar}>
            <div className={`${classes.flexGrow_1}`}>
              <Link
                to="/"
                className={`${classes.linkClass} ${classes.toolbarTitle}`}
              >
                <Typography
                  variant="h6"
                  color="secondary"
                  className={classes.logo}
                  onClick={() => customRedirect()}
                >
                  EngiFlow
                  {/* <img className={classes.logo} src={logo.default} /> */}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  style={{ fontWeight: "lighter" }}
                >
                  Go with the EngiFlow
                </Typography>
              </Link>
            </div>

            {isAuthenticated && (
              <>
                <Link
                  to="/jobs"
                  className={classes.linkClass}
                  onClick={() => setActivePage("jobs")}
                >
                  <Typography
                    variant="button"
                    className={
                      activePage === "jobs"
                        ? `${classes.title} ${classes.titleActive}`
                        : classes.title
                    }
                  >
                    Jobs
                  </Typography>
                </Link>
                <Link
                  to="/contact-us"
                  onClick={() => setActivePage("contact-us")}
                  className={classes.linkClass}
                >
                  <Typography
                    variant="button"
                    className={
                      activePage === "contact-us"
                        ? `${classes.title} ${classes.titleActive}`
                        : classes.title
                    }
                  >
                    Contact
                  </Typography>
                </Link>

                <Notifications />
              </>
            )}

            {isAuthenticated && (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  className={classes.accountIconButton}
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem>{user.email}</MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      history.push("/profile");
                    }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      history.push("/tasks");
                    }}
                  >
                    Tasks
                  </MenuItem>

                  {isOwner()}

                  <MenuItem
                    onClick={() => authButtonMethod(window.location.origin)}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            )}
            {!isAuthenticated && (
              <>
                <nav>
                  <Link
                    to="/#home"
                    className={classes.linkClass}
                    onClick={() => {
                      scrollTo("home");
                      setActivePage("/");
                    }}
                  >
                    <Typography
                      variant="button"
                      className={
                        activePage === "/"
                          ? `${classes.title} ${classes.titleActive}`
                          : classes.title
                      }
                    >
                      Home
                    </Typography>
                  </Link>
                  <Link
                    to="/#about-us"
                    className={classes.linkClass}
                    onClick={() => {
                      scrollTo("about-us");
                      setActivePage("/about");
                    }}
                  >
                    <Typography
                      variant="button"
                      className={
                        activePage === "/about"
                          ? `${classes.title} ${classes.titleActive}`
                          : classes.title
                      }
                    >
                      About
                    </Typography>
                  </Link>
                  <Link
                    to="/#services"
                    className={classes.linkClass}
                    onClick={() => {
                      scrollTo("services");
                      setActivePage("/services");
                    }}
                  >
                    <Typography
                      variant="button"
                      className={
                        activePage === "/services"
                          ? `${classes.title} ${classes.titleActive}`
                          : classes.title
                      }
                    >
                      Services
                    </Typography>
                  </Link>
                  <Link
                    to="/contact-us"
                    onClick={() => setActivePage("contact-us")}
                    className={classes.linkClass}
                  >
                    <Typography
                      variant="button"
                      className={
                        activePage === "/contact"
                          ? `${classes.title} ${classes.titleActive}`
                          : classes.title
                      }
                    >
                      Contact
                    </Typography>
                  </Link>
                  <Link
                    to="/"
                    className={classes.linkClass}
                    onClick={() => loginWithRedirect()}
                  >
                    <Typography
                      variant="button"
                      className={
                        activePage === "/signup"
                          ? `${classes.title} ${classes.titleActive}`
                          : classes.title
                      }
                    >
                      Sign Up
                    </Typography>
                  </Link>
                </nav>

                <Button
                  className={classes.loginButton}
                  variant="outlined"
                  onClick={() => authButtonMethod()}
                >
                  Log In
                </Button>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
