import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";

import { errorHandler } from "../../store/actions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      padding: theme.spacing(2, 4, 3),
      maxWidth:"75%"

    },
    closeContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

interface TransitionModalProps {
  modalMessage: string;
  isOpen: boolean;
}

export const TransitionModal: React.FC<TransitionModalProps> = ({
  modalMessage,
  isOpen,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(errorHandler(""));
  };

  useEffect(() => {
    if (isOpen) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [isOpen]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h5 id="transition-modal-title">Something went wrong!</h5>
            <p id="transition-modal-description">
              Message: {modalMessage}
            </p>
            <div className={classes.closeContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => dispatch(errorHandler(""))}
              >
                close
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
