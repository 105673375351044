import {
    BlockBlobClient,
    newPipeline,
  } from "@azure/storage-blob";
  
  export async function upload(sas:any,blob:Blob) {
    const blockBlobClient = new BlockBlobClient(sas, newPipeline());
    return await blockBlobClient.uploadData(blob);
  }

  export async function download(sas:any) {
    const blockBlobClient = new BlockBlobClient(sas, newPipeline());
    const response = await blockBlobClient.download();
    return response.blobBody;
  }