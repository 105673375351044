import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useStyles } from "./taskStyles";
import * as yup from "yup";
import { errorHandler } from "../../store/actions";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import {
  FILTER_TASKS,
  READ_CURRENT_ORGANISATION_USERS,
} from "../JobList/query";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { JobStateEnum } from "../../generated/graphql";

enum ERegisteredFields {
  subject = "Subject",
  description = "Description",
  assignedById = "Assigned by",
  assignedToId = "Assigned to",
  taskId = "Task #",
  jobState = "Job State",
  dateFrom = "Due from",
  dateTo = "Due to",
}

const validationSchema = yup.object({});

interface ITaskFilter {
  task?: any;
  passTasksMethod: any;
}

const TasksFilter: React.FC<ITaskFilter> = ({ task, passTasksMethod }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [availableUsers, setAvailableUsers] = useState([]);
  const [readCurrentOrgUsers, readCurrentOrgUsersResp] = useLazyQuery(
    READ_CURRENT_ORGANISATION_USERS
  );
  const [filterTasks, filterTasksResp] = useLazyQuery(FILTER_TASKS, {
    fetchPolicy: "no-cache",
  });

  const onChangeDateHandler = (fieldName: string, value: any) => {
    let date = JSON.stringify(value);
    date = date.slice(1, 11);
    formik.setFieldValue(fieldName, date);
  };

  useEffect(() => {
    readCurrentOrgUsers();
  }, []);

  useEffect(() => {
    if (readCurrentOrgUsersResp.data) {
      setAvailableUsers(
        readCurrentOrgUsersResp.data.readCurrentOrganisationUsers
      );
    }
  }, [readCurrentOrgUsersResp]);

  useEffect(() => {
    if (filterTasksResp.data) {
      passTasksMethod(filterTasksResp.data.filterTasks);
    }
  }, [filterTasksResp]);

  const formik = useFormik({
    initialValues: {
      assignedToId: null,
      assignedById: null,
      dateFrom: "12/12/2000",
      dateTo: "12/12/2030",
      jobState: null,
      taskId: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        let taskFilterCriteria = {
          ...values
        }

        taskFilterCriteria.taskId =  values.taskId !== "" ? values.taskId : null

        filterTasks({
          variables: {
            taskFilterCriteria
          },
        });
      } catch (e) {
        console.log("ERROR: ", e);
        dispatch(errorHandler("Filtering tasks failed: " + e))
      }
    },
  });

  return (
    <Accordion style={{ height: "25px!important" }}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography component={"span"} variant={"body2"}>
          Filter
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.AccordionDetails}>
        <form className={classes.filterContainer}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              {/* assignedById */}
              <InputLabel>{ERegisteredFields.assignedById}</InputLabel>
              <Select
                className={classes.formRow}
                style={{ minWidth: "200px" }}
                id="assignedById"
                fullWidth={true}
                name="assignedById"
                label={ERegisteredFields.assignedById}
                value={formik.values.assignedById}
                onChange={formik.handleChange}
                error={
                  formik.touched.assignedById &&
                  Boolean(formik.errors.assignedById)
                }
              >
                {/* @ts-ignore */}
                <MenuItem value={null}>
                  <em>None</em>
                </MenuItem>
                {availableUsers.map((user: any) => {
                  return (
                    <MenuItem
                      value={user.id}
                    >{`${user.firstName} ${user.lastName} | ${user.position}`}</MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={4}>
              {/* assignedToId */}
              <div className={classes.filterColumn}>
                <InputLabel>{ERegisteredFields.assignedToId}</InputLabel>
                <Select
                  className={classes.formRow}
                  style={{ minWidth: "200px" }}
                  id="assignedToId"
                  name="assignedToId"
                  fullWidth={true}
                  label={ERegisteredFields.assignedToId}
                  value={formik.values.assignedToId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.assignedToId &&
                    Boolean(formik.errors.assignedToId)
                  }
                >
                  {/* @ts-ignore */}
                  <MenuItem value={null}>
                    <em>None</em>
                  </MenuItem>
                  {availableUsers.map((user: any) => {
                    return (
                      <MenuItem
                        value={user.id}
                      >{`${user.firstName} ${user.lastName} | ${user.position}`}</MenuItem>
                    );
                  })}
                </Select>
              </div>
            </Grid>
            {/* jobState */}
            <Grid item xs={4}>
              <div className={classes.filterColumn}>
                <InputLabel>{ERegisteredFields.jobState}</InputLabel>
                <Select
                  className={classes.formRow}
                  style={{ minWidth: "200px" }}
                  id="jobState"
                  name="jobState"
                  label={ERegisteredFields.jobState}
                  value={formik.values.jobState}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.jobState && Boolean(formik.errors.jobState)
                  }
                >
                  {/* @ts-ignore */}
                  <MenuItem value={null}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={JobStateEnum.AwaitingDocuments}>
                    Awaiting Documents
                  </MenuItem>
                  <MenuItem value={JobStateEnum.Detailing}>Detailing</MenuItem>
                  <MenuItem value={JobStateEnum.Engineering}>
                    Engineering
                  </MenuItem>
                  <MenuItem value={JobStateEnum.WaitingForInspectionResults}>
                    Waiting for inspection results
                  </MenuItem>
                  <MenuItem value={JobStateEnum.ReviewInspectionResults}>
                    Review inspection results
                  </MenuItem>
                  <MenuItem value={JobStateEnum.Finished}>Finished</MenuItem>
                </Select>
              </div>
            </Grid>

            {/* dateFrom */}
            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                  <KeyboardDatePicker
                    margin="normal"
                    id="dateFrom"
                    label={ERegisteredFields.dateFrom}
                    format="yyyy-MM-dd"
                    fullWidth={true}
                    value={formik.values.dateFrom}
                    onChange={(value: any) =>
                      onChangeDateHandler("dateFrom", value)
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </Grid>
            {/* dateTo */}
            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                  <KeyboardDatePicker
                    margin="normal"
                    fullWidth={true}
                    id="dateTo"
                    label={ERegisteredFields.dateTo}
                    format="yyyy-MM-dd"
                    value={formik.values.dateTo}
                    onChange={(value: any) =>
                      onChangeDateHandler("dateTo", value)
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </Grid>
            {/* taskId # */}
            <Grid item xs={4}>
              <div style={{}} className={classes.formRow}>
                <TextField
                  style={{ minWidth: "200px" }}
                  disabled={false}
                  fullWidth={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      padding: 8,
                    },
                  }}
                  id="taskId"
                  name="taskId"
                  label={ERegisteredFields.taskId}
                  value={formik.values.taskId}
                  onChange={formik.handleChange}
                  error={formik.touched.taskId && Boolean(formik.errors.taskId)}
                  helperText={
                    formik.touched.taskId && Boolean(formik.errors.taskId)
                      ? "input error"
                      : null
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={12}>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  formik.submitForm();
                }}
                type="submit"
                className={classes.searchIconButton}
                color="primary"
                disableFocusRipple
              >
                <SearchIcon fontSize="small" className={classes.searchIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default TasksFilter;
