import {
  NotificationsState,
  NotificationActionTypes,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  FETCH_NOTIFICATIONS,
  SET_NOTIFICATIONS
} from './types'

const initialState: NotificationsState = {
  notifications: []
}

export function notificationsReducer(
  state = initialState,
  action: NotificationActionTypes
): NotificationsState {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        notifications: [action.payload, ...state.notifications]
      }
      case REMOVE_NOTIFICATION:
        var notificationsCopy = [...state.notifications]
        var index = notificationsCopy.indexOf(action.payload)
        if(index != -1) {
          notificationsCopy.splice(index, 1)
        }
        return {
          notifications: [...notificationsCopy]
        }
      case SET_NOTIFICATIONS:
      return {
        notifications: action.payload
      }
      case FETCH_NOTIFICATIONS:
      return {
        notifications: state.notifications
      }
    default:
      return state
  }
}
