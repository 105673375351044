import {
  Button,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  LinearProgress,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import * as fileStorage from "../../utils/fileStorage";
import { useAuth0 } from "@auth0/auth0-react";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useDispatch } from "react-redux";
import { doYouNeedRefetch, errorHandler } from "../../store/actions";
import AlertMessage from "../AlertMessage/AlertMessage";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
var moment = require("moment-timezone");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider2: {
      margin: "25px",
      marginTop: "10%",
    },
    WebCamContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    bottomContainer: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minWidth: "300px",
    },
    uploadContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: "50px",
    },
    refreshIcon: {
      color: theme.palette.primary.main,
    },
    linearIndeterminate: {
      marginTop: "10px",
      width: "90%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    cameraIconContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    cameraIcon: {
      fontSize: "50px",
      color: theme.palette.primary.main,
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

interface CameraProps {
  job: any;
  doctype: string;
}

const Camera: React.FC<CameraProps> = ({ job, doctype }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const webcamRef = useRef<Webcam>(null);
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [ready, setReady] = useState<boolean>(false);
  const [imageName, setImageName] = useState<string>("item");
  const [uploading, setUploading] = useState<boolean>(false);
  const [open1, setOpen1] = useState<boolean>(false);
  const [cameraIconClicked, setCameraIconClicked] = useState<boolean>(false);

  const capture = React.useCallback(() => {
    if (webcamRef.current) {
      const cameraSS = webcamRef.current.getScreenshot();
      setImageSrc(cameraSS);
      setReady(true);
    }
  }, [webcamRef]);

  const uploadScreenshoot = async () => {
    if (imageName === "") {
    } else {
      setUploading(true);
      try {
        moment().tz("Australia/Brisbane").format();
        let date = new Date();
        date = moment.utc(date).toDate();
        const australianDate = moment(date)
          .local()
          .format("YYYY-MM-DD-HH:mm:ss");

        const fileName = job.jobNumber + "-" +australianDate + "-" + imageName + ".jpg";

        console.log(fileName)

        const file = await fileStorage.dataUrlToFile(imageSrc, fileName);
        const token = await getAccessTokenSilently();
        const files = [];
        files.push(file);
        await fileStorage.uploadJobDocument(files, job.id, doctype, token);
        setOpen1(true);
        setUploading(false);

        //Renew job documents tree
        dispatch(doYouNeedRefetch());
      } catch (e) {
        dispatch(errorHandler(e.message));
      }

      setReady(false);
    }
  };

  if (cameraIconClicked == false) {
    return (
      <div className={classes.cameraIconContainer}>
        <PhotoCameraIcon
          className={classes.cameraIcon}
          onClick={() => setCameraIconClicked(true)}
        />
      </div>
    );
  } else {
    return (
      <div className={classes.WebCamContainer}>
        {!ready && <Webcam ref={webcamRef} />}

        {imageSrc && ready && <img src={imageSrc} />}

        <div className={classes.bottomContainer}>
          {/* Toggle button logic */}
          {ready ? (
            <>
              <div className={classes.uploadContainer}>
                <TextField
                  id="ssName"
                  label="Photo name"
                  value={imageName}
                  onChange={(e) => setImageName(e.target.value)}
                />
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => uploadScreenshoot()}
                >
                  Upload photo
                </Button>
              </div>

              {uploading && (
                <div className={classes.linearIndeterminate}>
                  <LinearProgress />
                </div>
              )}

              <IconButton disableFocusRipple onClick={() => setReady(false)}>
                <RefreshIcon fontSize="small" className={classes.refreshIcon} />
              </IconButton>
            </>
          ) : (
            <>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={capture}
              >
                Capture photo
              </Button>
              {open1 && (
                <AlertMessage
                  alertWidth="500px"
                  open={open1}
                  setOpen={setOpen1}
                  color="info"
                  message="Photo uploaded successfully!"
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
};

export default Camera;
