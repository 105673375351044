import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DateFnsUtils from "@date-io/date-fns";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useHistory } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  makeStyles,
  IconButton,
  Divider,
  MenuItem,
  Select,
  InputLabel,
  Container,
  Typography,
  LinearProgress,
  Checkbox,
} from "@material-ui/core";
import {
  Job,
  JobStateEnum,
  PriorityEnum,
  useFilterJobsLazyQuery,
  useUpdateJobMutation,
} from "../../generated/graphql";
import { doYouNeedRefetch, errorHandler } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { ROLES_CONFIG } from "../../utils/helpers";

const useStyles = makeStyles((theme) => ({
  error_msg: {
    color: "red",
  },
  checkBox: {
    color: theme.palette.primary.main,
  },
  servicesCheckBoxesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    maxWidth: "65%",
  },
  checkBoxRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  checkBoxControl: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  AddJobsButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  checkBoxesContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: "3px",
    marginBottom: "3px",
  },
  addJobFormContainer: {
    padding: "10px",
  },
  formRow: {
    marginBottom: "10px",
  },
  twoInRowFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  EditJobFormMain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
    maxWidth: "500px",
    marginBottom: "50px",
  },
  EditJobFormContainer: {
    backgroundColor: theme.palette.success.main,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 10px",
    marginTop: theme.spacing(15),
    marginBottom: "50px",
    borderRadius: "3px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  linearIndeterminate:{
    marginTop:"10px",
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  containerTitleText:{
    fontFamily:'auto'
  },
  backArrow:{
    backgroundColor:theme.palette.primary.main,
    color:"#fff",
    borderRadius:"50%",
    fontSize:"25px"
  }
}));

const validationSchema = yup.object({
  jobNumber: yup.string().defined(),
  builder: yup.string().defined(),
  buildingDescription: yup.string().defined(),
  deliveryDate: yup.date().defined(),
  level: yup.string().defined(),
  priority: yup.string().defined(),
  detailingRequested: yup.boolean().defined(),
  engineeringRequested: yup.boolean().defined(),
  prelimDesignRequested: yup.boolean().defined(),
  inspectionRequested: yup.boolean().defined(),
  engineeringIssued: yup.boolean().defined(),
  prelimDesignIssued: yup.boolean().defined(),
  inspectionIssued: yup.boolean().defined(),
  street: yup.string().defined(),
  streetNumber: yup.string().defined(),
  postcode: yup.number().defined(),
  suburb: yup.string().defined(),
  province: yup.string().defined(),
  localGovernment: yup.string().defined(),
  lot: yup.string().defined(),
})
/*.test(
  'atleast-one-service-requested',
  'At least one service must be requested',
  (state) => {
    if (state?.detailingRequested || state?.engineeringRequested || state?.inspectionRequested) {
      return true;
    }
    return new yup.ValidationError(
      'At least one service must be requested',
      null,
      'inspectionRequested'
    );
  }
);*/

interface EditJobFormProps {
  state: any;
}

enum addJobField {
  jobNumber = "Job Number",
  builder = "Builder",
  buildingDescription = "Description of Building/Structure",
  storeys = "Number of Storeys",
  deliveryDate = "Delivery Date",
  level = "Level",
  priority = "Priority",
  detailingRequested = "Detailing",
  prelimDesignRequested = "Preliminary Design Form",
  engineeringRequested = "Engineering",
  inspectionRequested = "Inspection",
  prelimDesignIssued = "Preliminary Design Form",
  engineeringIssued = "Engineering",
  inspectionIssued = "Inspection",
  street = "Street Name",
  streetNumber = "Street number",
  postcode = "Post Code",
  suburb = "Suburb",
  province = "State/Province",
  localGovernment = "Local Government",
  lot = "Lot",
}

export const EditJobForm: React.FC<EditJobFormProps> = ({ state }) => {
  const job = state.job;
  const jobState = job.state;

  const [filterJobs, filterJobsResp] = useFilterJobsLazyQuery();

  const currentUser: any = useSelector((state: RootState) => state.user.user);
  const canEditRequestedServices = currentUser.roles.includes(ROLES_CONFIG.Client.RoleName) || currentUser.roles.includes(ROLES_CONFIG.Owner.RoleName) || currentUser.roles.includes(ROLES_CONFIG.Administrator.RoleName);

  const classes = useStyles();
  const dispatch = useDispatch();

  const onChangeDateHandler = (fieldName: string, value: any) => {
    let date = JSON.stringify(value);
    date = date.slice(1, 11);
    formik.setFieldValue(fieldName, date);
  };
  const history = useHistory();

  const onCheckBoxChange = (fieldName: string, event: any) => {
    formik.setFieldValue(fieldName, event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      jobNumber: job.jobNumber,
      builder: job.builder,
      buildingDescription: job.buildingDescription,
      storeys: job.storeys,
      deliveryDate: JSON.stringify(job.deliveryDate).slice(1,11),
      level: job.level,
      priority: job.priority,
      detailingRequested: job.detailingRequested,
      engineeringRequested: job.engineeringRequested,
      prelimDesignRequested: job.prelimDesignRequested,
      inspectionRequested: job.inspectionRequested,
      engineeringIssued: job.engineeringIssued,
      prelimDesignIssued: job.prelimDesignIssued,
      inspectionIssued: job.inspectionIssued,
      street: job.jobAddress.street,
      streetNumber: job.jobAddress.streetNumber,
      postcode: job.jobAddress.postcode,
      suburb: job.jobAddress.suburb,
      province: job.jobAddress.province,
      localGovernment: job.jobAddress.localGovernment,
      lot: job.jobAddress.lot,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //alert(JSON.stringify(values, null, 2));
      try {
        await updateJobMutation();
        dispatch(doYouNeedRefetch())
      } catch (error) {
        dispatch(
          errorHandler(`${error.message}, check your internet connection`)
        );
      }
    },
  });

  const [updateJobMutation, { data, loading, error }] = useUpdateJobMutation({
    variables: {
      job: {
        jobNumber: formik.values.jobNumber,
        builder: formik.values.builder,
        buildingDescription: formik.values.buildingDescription,
        storeys: formik.values.storeys,
        deliveryDate: formik.values.deliveryDate,
        level: formik.values.level,
        priority: formik.values.priority,
        detailingRequested: formik.values.detailingRequested,
        engineeringRequested: formik.values.engineeringRequested,
        prelimDesignRequested: formik.values.prelimDesignRequested,
        inspectionRequested: formik.values.inspectionRequested,
        engineeringIssued: formik.values.engineeringIssued,
        prelimDesignIssued: formik.values.prelimDesignIssued,
        inspectionIssued: formik.values.inspectionIssued,
        jobAddress: {
          street: formik.values.street,
          streetNumber: formik.values.streetNumber,
          postcode: formik.values.postcode,
          suburb: formik.values.suburb,
          province: formik.values.province,
          localGovernment: formik.values.localGovernment,
          lot: formik.values.lot,
        },
        windRating: job.windRating
      },
      jobId: job.id,
    },
  });

  useEffect(() => {
    if (filterJobsResp.data?.filterJobs?.jobs) {
      if (filterJobsResp.data?.filterJobs?.jobs.length > 0) {
        const existingJobs = filterJobsResp.data?.filterJobs?.jobs.filter(filteredJob => filteredJob?.id! !== job.id && filteredJob?.jobNumber === formik.values.jobNumber);
        if (existingJobs.length > 0) {
          const existingJob = existingJobs[0]!;
          formik.setFieldError('jobNumber', `Job #${existingJob.jobNumber} already exists`);
          formik.setFieldTouched('jobNumber', true);
          return;
        }
      }
      formik.setFieldError('jobNumber', undefined);
    }
  }, [filterJobsResp]);

  const checkUniqueJobNumber = () => {
    filterJobs({
      variables: {
        page: 1,
        rowsPerPage: 2,
        jobFilterCriteria: {
          jobNumber: formik.values.jobNumber
        }
      }
    });
  };

  if (data) {
    return (
      <Container maxWidth="lg">
        <div className={classes.EditJobFormContainer}>
          <div className={classes.EditJobFormMain}>
            {/*Go back button*/}
            <div className={classes.AddJobsButtonContainer}>
              <IconButton
                disableFocusRipple
                onClick={() => history.goBack()}
              >
                <ArrowBackIcon fontSize="large" className={classes.backArrow} color="primary" />
              </IconButton>
            </div>
            <div>
              Job updated successfully
              <CheckCircleIcon fontSize="large" style={{ color: "green" }} />
            </div>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <div className={classes.EditJobFormContainer}>
        <Typography
          component="h2"
          variant="h5"
          color="primary"
          align="center"
          className={classes.containerTitleText}
        >
          Edit Job
          <Divider />
        </Typography>
        <div className={classes.EditJobFormMain}>
          {/*Go back button*/}
          <div className={classes.AddJobsButtonContainer}>
            <IconButton
              disableFocusRipple
              onClick={() => history.push("/jobs")}
            >
              <ArrowBackIcon className={classes.backArrow} color="primary" />
            </IconButton>
          </div>

          <Paper>
            <Divider light />
            <form
              onSubmit={formik.handleSubmit}
              className={classes.addJobFormContainer}
            >
              {/*jobNumber*/}
              <TextField
                className={classes.formRow}
                fullWidth
                autoFocus
                id="jobNumber"
                name="jobNumber"
                label={addJobField.jobNumber}
                value={formik.values.jobNumber}
                onChange={e => {
                  formik.handleChange(e);
                }}
                onBlur={checkUniqueJobNumber}
                error={
                  formik.touched.jobNumber && Boolean(formik.errors.jobNumber)
                }
                helperText={formik.touched.jobNumber && formik.errors.jobNumber}
              />
              {/*builder*/}
              <TextField
                className={classes.formRow}
                fullWidth
                id="builder"
                name="builder"
                label={addJobField.builder}
                value={formik.values.builder}
                onChange={formik.handleChange}
                error={formik.touched.builder && Boolean(formik.errors.builder)}
                helperText={formik.touched.builder && formik.errors.builder}
              />
              {/*description*/}
              <TextField
                className={classes.formRow}
                fullWidth
                multiline
                rows={4}
                id="buildingDescription"
                name="buildingDescription"
                label={addJobField.buildingDescription}
                value={formik.values.buildingDescription}
                onChange={formik.handleChange}
                error={
                  formik.touched.buildingDescription &&
                  Boolean(formik.errors.buildingDescription)
                }
                helperText={
                  formik.touched.buildingDescription &&
                  formik.errors.buildingDescription
                }
              />
              {/*storeys*/}
              <TextField
                className={classes.formRow}
                fullWidth
                rows={4}
                id="storeys"
                name="storeys"
                label={addJobField.storeys}
                value={formik.values.storeys}
                onChange={formik.handleChange}
                error={
                  formik.touched.storeys &&
                  Boolean(formik.errors.storeys)
                }
                helperText={
                  formik.touched.storeys &&
                  formik.errors.storeys
                }
              />
              {/*date picker*/}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className={classes.formRow}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="deliveryDate"
                    label={addJobField.deliveryDate}
                    format="yyyy-MM-dd"
                    value={formik.values.deliveryDate}
                    onChange={(value) =>
                      onChangeDateHandler("deliveryDate", value)
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </div>
              </MuiPickersUtilsProvider>

              {/*streetNumber*/}
              <TextField
                className={classes.formRow}
                fullWidth
                id="streetNumber"
                name="streetNumber"
                label={addJobField.streetNumber}
                value={formik.values.streetNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.streetNumber &&
                  Boolean(formik.errors.streetNumber)
                }
                helperText={
                  formik.touched.streetNumber && formik.errors.streetNumber
                }
              />
              {/* street name */}
              <TextField
                className={classes.formRow}
                fullWidth
                id="street"
                name="street"
                label={addJobField.street}
                value={formik.values.street}
                onChange={formik.handleChange}
                error={formik.touched.street && Boolean(formik.errors.street)}
                helperText={formik.touched.street && formik.errors.street}
              />
              {/* level*/}
              <TextField
                className={classes.formRow}
                fullWidth
                id="level"
                name="level"
                label={addJobField.level}
                value={formik.values.level}
                onChange={formik.handleChange}
                error={formik.touched.level && Boolean(formik.errors.level)}
                helperText={formik.touched.level && formik.errors.level}
              />
              {/*postcode*/}
              <TextField
                className={classes.formRow}
                fullWidth
                id="postcode"
                name="postcode"
                label={addJobField.postcode}
                value={formik.values.postcode}
                onChange={formik.handleChange}
                error={
                  formik.touched.postcode && Boolean(formik.errors.postcode)
                }
                helperText={formik.touched.postcode && formik.errors.postcode}
              />
              {/*state*/}
              <InputLabel>{addJobField.province}</InputLabel>
              <Select
                className={classes.formRow}
                fullWidth
                id="province"
                name="province"
                label={addJobField.province}
                value={formik.values.province}
                onChange={formik.handleChange}
                error={formik.touched.province && Boolean(formik.errors.province)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Queensland">Queensland</MenuItem>
                <MenuItem value="New South Wales">New South Wales</MenuItem>
                <MenuItem value="Victoria">Victoria</MenuItem>
                <MenuItem value="South Australia">South Australia</MenuItem>
                <MenuItem value="Tasmania">Tasmania</MenuItem>
                <MenuItem value="Western Australia">Western Australia</MenuItem>
                <MenuItem value="Australian Capital Territory">Australian Capital Territory</MenuItem>
              </Select>
              {/*suburb*/}
              <TextField
                className={classes.formRow}
                fullWidth
                id="suburb"
                name="suburb"
                label={addJobField.suburb}
                value={formik.values.suburb}
                onChange={formik.handleChange}
                error={formik.touched.suburb && Boolean(formik.errors.suburb)}
                helperText={formik.touched.suburb && formik.errors.suburb}
              />
              {/*localGovernment*/}
              <TextField
                className={classes.formRow}
                fullWidth
                id="localGovernment"
                name="localGovernment"
                label={addJobField.localGovernment}
                value={formik.values.localGovernment}
                onChange={formik.handleChange}
                error={
                  formik.touched.localGovernment &&
                  Boolean(formik.errors.localGovernment)
                }
                helperText={
                  formik.touched.localGovernment &&
                  formik.errors.localGovernment
                }
              />
              {/*priority*/}
              <InputLabel>{addJobField.priority}</InputLabel>
              <Select
                className={classes.formRow}
                fullWidth
                id="priority"
                name="priority"
                label={addJobField.priority}
                value={formik.values.priority}
                onChange={formik.handleChange}
                error={
                  formik.touched.priority && Boolean(formik.errors.priority)
                }
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={PriorityEnum.Normal}>Normal</MenuItem>
                <MenuItem value={PriorityEnum.Urgent}>Urgent</MenuItem>
              </Select>
              {/*lot*/}
              <TextField
                className={classes.formRow}
                fullWidth
                id="lot"
                name="lot"
                label={addJobField.lot}
                value={formik.values.lot}
                onChange={formik.handleChange}
                error={formik.touched.lot && Boolean(formik.errors.lot)}
                helperText={formik.touched.lot && formik.errors.lot}
              />
              {/* Services check boxes */}
              
              {[JobStateEnum.AwaitingDocuments, JobStateEnum.Engineering].includes(jobState) && canEditRequestedServices &&
              <div className={classes.servicesCheckBoxesContainer}>
                <Typography component="strong" color="textPrimary">
                  Requested services:
                </Typography>
                {jobState === JobStateEnum.AwaitingDocuments && <div className={classes.checkBoxRow}>
                  <div className={classes.checkBoxControl}>
                    <Typography variant={"body2"} component="h6">
                      {addJobField.detailingRequested}
                    </Typography>
                    <Checkbox
                      onChange={(value) =>
                        onCheckBoxChange("detailingRequested", value)
                      }
                      className={classes.checkBox}
                      color="primary"
                      checked={formik.values.detailingRequested}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </div>}

                {jobState === JobStateEnum.AwaitingDocuments && <div className={classes.checkBoxRow}>
                  <div className={classes.checkBoxControl}>
                    <Typography variant={"body2"} component="h6">
                      {addJobField.engineeringRequested}
                    </Typography>
                    <Checkbox
                      onChange={(value) =>
                        onCheckBoxChange("engineeringRequested", value)
                      }
                      checked={formik.values.engineeringRequested}
                      className={classes.checkBox}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </div>}

                {[JobStateEnum.AwaitingDocuments, JobStateEnum.Engineering].includes(jobState) && <div className={classes.checkBoxRow}>
                  <div className={classes.checkBoxControl}>
                    <Typography variant={"body2"} component="h6">
                      {addJobField.prelimDesignRequested}
                    </Typography>
                    <Checkbox
                      onChange={(value) =>
                        onCheckBoxChange("prelimDesignRequested", value)
                      }
                      checked={formik.values.prelimDesignRequested}
                      className={classes.checkBox}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </div>}

                {jobState === JobStateEnum.AwaitingDocuments && <div className={classes.checkBoxRow}>
                  <div className={classes.checkBoxControl}>
                    <Typography variant={"body2"} component="h6">
                      {addJobField.inspectionRequested}
                    </Typography>
                    <Checkbox
                      onChange={(value) =>
                        onCheckBoxChange("inspectionRequested", value)
                      }
                      checked={formik.values.inspectionRequested}
                      className={classes.checkBox}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </div>}
                {Boolean(formik.errors.inspectionRequested) && <p className={classes.error_msg}>{formik.errors.inspectionRequested}</p>}
              </div>
              }
              <div className={classes.servicesCheckBoxesContainer}>
                <Typography component="strong" color="textPrimary">
                  Issued services:
                </Typography>

                <div className={classes.checkBoxRow}>
                  <div className={classes.checkBoxControl}>
                    <Typography variant={"body2"} component="h6">
                      {addJobField.engineeringIssued}
                    </Typography>
                    <Checkbox
                      onChange={(value) =>
                        onCheckBoxChange("engineeringIssued", value)
                      }
                      checked={formik.values.engineeringIssued}
                      className={classes.checkBox}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </div>

                <div className={classes.checkBoxRow}>
                  <div className={classes.checkBoxControl}>
                    <Typography variant={"body2"} component="h6">
                      {addJobField.prelimDesignIssued}
                    </Typography>
                    <Checkbox
                      onChange={(value) =>
                        onCheckBoxChange("prelimDesignIssued", value)
                      }
                      checked={formik.values.prelimDesignIssued}
                      className={classes.checkBox}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </div>

                <div className={classes.checkBoxRow}>
                  <div className={classes.checkBoxControl}>
                    <Typography variant={"body2"} component="h6">
                      {addJobField.inspectionIssued}
                    </Typography>
                    <Checkbox
                      onChange={(value) =>
                        onCheckBoxChange("inspectionIssued", value)
                      }
                      checked={formik.values.inspectionIssued}
                      className={classes.checkBox}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.twoInRowFlex}></div>
              <Divider light />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                onClick={() => console.log(formik.values)}
              >
                Update
              </Button>
              {loading  && 
                <div className={classes.linearIndeterminate}>
                  <LinearProgress />
                </div>
              }
            </form>
          </Paper>
        </div>
      </div>
    </Container>
  );
};

export default EditJobForm;
