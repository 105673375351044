import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

//
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useCreateOrganisationMutation } from "../../generated/graphql";
import { doYouNeedRefetch, errorHandler } from "../../store/actions";
import BusinessIcon from "@material-ui/icons/Business";
import { useLazyQuery } from "@apollo/client";
import { READ_CURRENT_USER } from "../JobList/query";
import { showComponentEnum } from "../../views/JoinOrCreateView/JoinOrCreateView";
import { RootState } from "../../store";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  image: {
    marginTop: theme.spacing(9),
    backgroundImage:
      "url(https://images.pexels.com/photos/1769409/pexels-photo-1769409.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)",
    minHeight: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "3px",
    flexBasis: "fit-content",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "90%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formContainer: {
    marginTop: "5%",
    marginBottom: "50px",
    borderRadius: "5px",
    display: "flex",
    height: "fit-content",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  inputContainer: {
    margin: "5px",
    padding: "4px!important",
  },
  linearProgress: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const validationSchema = yup.object({});

interface CreateOrganisationProps {
  setShowComponent?: any;
}

export const CreateOrganisation: React.FC<CreateOrganisationProps> = ({
  setShowComponent,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser: any = useSelector((state: RootState) => state.user);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      organization: "",
      addressLine1: "",
      addressLine2: "",
      suburb: "",
      postcode: "",
      province: "",
      position: "",
      country: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //alert(JSON.stringify(values, null, 2));
      try {
        await createOrganisation();
      } catch (error) {
        dispatch(
          errorHandler(`${error.message}, check your internet connection`)
        );
      }
    },
  });

  const [
    createOrganisation,
    createOrganistaionResponse,
  ] = useCreateOrganisationMutation({
    variables: {
      organisation: {
        name: formik.values.organization,
        address: {
          addressLine1: formik.values.addressLine1,
          addressLine2: formik.values.addressLine2,
          postcode: formik.values.postcode,
          suburb: formik.values.suburb,
          province: formik.values.province,
          country: formik.values.country,
        },
      },
    },
  });

  const [readUser, readUserResponse] = useLazyQuery(READ_CURRENT_USER);

  useEffect(() => {
    readUser();
  }, []);

  //If organisation is created successfully go back
  useEffect(() => {
    if (createOrganistaionResponse) {
      if (createOrganistaionResponse.data) {
        dispatch(doYouNeedRefetch());
        setShowComponent(showComponentEnum["JOIN OR CREATE PAGE"]);
      }
    }
  }, [createOrganistaionResponse]);

  //If user is have organisation force him to /jobs
  useEffect(() => {
    // console.log(readUserResponse);
    if (readUserResponse.data?.readCurrentUser) {
      if (readUserResponse.data.readCurrentUser.organisation !== null) {
        history.push("/jobs");
      }
    }
  }, [readUserResponse]);

  enum registerField {
    organization = "Organization Name",
    addressLine1 = "Address Line 1",
    addressLine2 = "Address Line 2",
    suburb = "Suburb",
    postcode = "Postcode",
    state = "State",
    country = "Country",
  }

  const form = () => {
    return (
      <>
        <Avatar className={classes.avatar}>
          <BusinessIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create Organisation
        </Typography>
        <Typography align="center" component="h6" variant="body2">
          Welcome {currentUser.user.firstName}. Please complete this form to
          create new organisation.
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          {/* newOrganization */}
          <TextField
            inputProps={{
              style: {
                padding: 10,
              },
            }}
            className={classes.inputContainer}
            variant="outlined"
            margin="normal"
            fullWidth
            id="organization"
            name="organization"
            label={registerField.organization}
            value={formik.values.organization}
            onChange={formik.handleChange}
            error={
              formik.touched.organization && Boolean(formik.errors.organization)
            }
          />
          {/* addressLine1 */}
          <TextField
            inputProps={{
              style: {
                padding: 10,
              },
            }}
            className={classes.inputContainer}
            variant="outlined"
            margin="normal"
            fullWidth
            id="addressLine1"
            name="addressLine1"
            label={registerField.addressLine1}
            value={formik.values.addressLine1}
            onChange={formik.handleChange}
            error={
              formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)
            }
          />
          {/* addressLine2 */}
          <TextField
            inputProps={{
              style: {
                padding: 10,
              },
            }}
            className={classes.inputContainer}
            variant="outlined"
            margin="normal"
            fullWidth
            id="addressLine2"
            name="addressLine2"
            label={registerField.addressLine2}
            value={formik.values.addressLine2}
            onChange={formik.handleChange}
            error={
              formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)
            }
          />
          {/* suburb */}
          <TextField
            inputProps={{
              style: {
                padding: 10,
              },
            }}
            className={classes.inputContainer}
            variant="outlined"
            margin="normal"
            fullWidth
            id="suburb"
            name="suburb"
            label={registerField.suburb}
            value={formik.values.suburb}
            onChange={formik.handleChange}
            error={formik.touched.suburb && Boolean(formik.errors.suburb)}
          />
          {/* postcode */}
          <TextField
            inputProps={{
              style: {
                padding: 10,
              },
            }}
            className={classes.inputContainer}
            variant="outlined"
            margin="normal"
            fullWidth
            id="postcode"
            name="postcode"
            label={registerField.postcode}
            value={formik.values.postcode}
            onChange={formik.handleChange}
            error={formik.touched.postcode && Boolean(formik.errors.postcode)}
          />
          {/* state */}
          <TextField
            inputProps={{
              style: {
                padding: 10,
              },
            }}
            className={classes.inputContainer}
            variant="outlined"
            margin="normal"
            fullWidth
            id="province"
            name="province"
            label={registerField.state}
            value={formik.values.province}
            onChange={formik.handleChange}
            error={formik.touched.province && Boolean(formik.errors.province)}
          />
          {/* Country */}
          <TextField
            inputProps={{
              style: {
                padding: 10,
              },
            }}
            className={classes.inputContainer}
            variant="outlined"
            margin="normal"
            fullWidth
            id="country"
            name="country"
            label={registerField.country}
            value={formik.values.country}
            onChange={formik.handleChange}
            error={formik.touched.country && Boolean(formik.errors.country)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Complete
          </Button>
          {createOrganistaionResponse.loading && (
            <div className={classes.linearProgress}>
              <LinearProgress />
            </div>
          )}
          <Typography align="center" variant="body2" component="h6">
            Note: After completing this form you will be able to invite members
            to your organisation by sharing unique invitation #code with them.
          </Typography>
        </form>
      </>
    );
  };

  //UI GRID
  return (
    <Grid container component="main" className={classes.image}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={8}
        md={8}
        lg={4}
        component={Paper}
        className={classes.formContainer}
        elevation={6}
        square
      >
        <div className={classes.paper}>{form()}</div>
      </Grid>
    </Grid>
  );
};
