import { Button, createStyles, Grid, makeStyles, Theme, Typography, withStyles, withWidth } from "@material-ui/core";
import React from "react";
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "calc(100vw/3)"
        },
        container: {
            backgroundSize: "cover", backgroundRepeat: "no-repeat"
        },
        image: {
            position: "absolute",
            width: "100%",
            height: "auto",
            zIndex: -1
        },
        title: {
            padding: "20% 0", whiteSpace: "nowrap", color: theme.palette.primary.dark, fontWeight: "bold",
            fontSize: "2em",
        },
        titleBack:{
            padding: 10,backgroundColor: "rgb(26 116 186 / 45%)",color:"white",borderRadius: 2
        },
        buttonContainer: {
            marginTop: "2%", display: "flex", justifyContent: "center", backgroundColor: "rgba(176, 182, 198, 0.4)",
            [theme.breakpoints.down("sm")]: {
                marginTop: "50%"
            }
        }


    })
)

const GetInTouchButton = withStyles((theme) => ({
    root: {
        color: "white",
        margin: "30px 0",
        boxShadow: "none",
        padding: "7px 50px",
        borderRadius: "28px",
        fontSize: "15px",
        fontWeight: "lighter",
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main + "a8",
            boxShadow: "none",
        },
    },
}))(Button);

interface GetInTouchProps {
    title: string,
    image: string,
    width: Breakpoint,
    children: React.ReactNode,
    onClick: (content: React.ReactNode) => void
}

function GetInTouch(props: GetInTouchProps) {
    const { title, image, width, children, onClick } = props;
    const classes = useStyles();

    const handleReadMore = () => {
        onClick(children);
    }

    return <Grid item sm={6} md={4}>
        <div style={{ backgroundImage: `url(${width == "sm" ? image + ".jpg" : image + "2.jpg"})` }} className={classes.container} >
            <div>
                <Typography component="h4" variant="h4" align="center" className={classes.title}>
                    <span className={classes.titleBack}>
                        {title}
                    </span>
                </Typography>
                <div className={classes.buttonContainer}>
                    <GetInTouchButton onClick={handleReadMore}>
                        Read More

                    </GetInTouchButton>
                </div>
            </div>
        </div>
    </Grid>
}

export default withWidth()(GetInTouch);