import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import PersonIcon from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
//
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Checkbox, FormControl } from "@material-ui/core";
import {
  useCreateUserMutation,
} from "../../generated/graphql";
import { errorHandler } from "../../store/actions";
import { READ_CURRENT_USER } from "../JobList/query";
import { useLazyQuery } from "@apollo/client";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import { RootState } from "../../store";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  image: {
    marginTop: theme.spacing(9),
    backgroundImage:
      "url(https://images.pexels.com/photos/1769409/pexels-photo-1769409.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)",
    minHeight: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "3px",
    flexBasis: "fit-content",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "90%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formContainer: {
    marginTop: "20px",
    marginBottom: "20px",
    height: "fit-content",
    textAlign: "center",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    maxWidth: "100%",
  },
  inputContainer: {
    margin: "5px",
    padding: "4px!important",
  },
  floatingLabelFocusStyle: {
    color: theme.palette.text.primary,
  },
  linearProgress: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  checkBoxesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    maxWidth: "75%",
  },
  checkBoxRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  checkBoxControl: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  checkBox:{
    color:theme.palette.primary.main
  },
}));

const validationSchema = yup.object({
  addressLine1: yup.string().defined(),
  addressLine2: yup.string(),
  country: yup.string().defined(),
  postcode: yup.string().defined(),
  province: yup.string().defined(),
  suburb: yup.string().defined(),
  contactPerson: yup.string(),
  firstName: yup.string().defined(),
  lastName: yup.string().defined(),
  licenseClass: yup.string(),
  licenseNumber: yup.string(),
  victoriaLicenseNumber: yup.string(),
  licenseArea: yup.string(),
  mobileNumber: yup.string(),
  phone: yup.string(),
  position: yup.string().defined(),
});

interface CreateUserProps {
  test?: any;
}

export const CreateUser: React.FC<CreateUserProps> = ({ test }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  //Display it only if user status is unregistered
  const history = useHistory();
  const currentUser: any = useSelector((state: RootState) => state.user);
  const [termsAndConditions, setTermsAndConditions] = useState<any>(false);

  useEffect(() => {
    if (currentUser?.user?.roles.length > 0) {
      history.push("/");
    }
  }, [currentUser]);

  const formik = useFormik({
    initialValues: {
      addressLine1: "",
      addressLine2: "",
      country: "",
      postcode: "",
      province: "",
      suburb: "",
      contactPerson: "",
      firstName: "",
      lastName: "",
      licenseClass: "",
      licenseNumber: "",
      victoriaLicenseNumber: "",
      licenseArea: "",
      mobileNumber: "",
      phone: "",
      position: "",
      userId: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (termsAndConditions === false) {
          dispatch(
            errorHandler(
              "Something went wrong, you must read and accept the terms and conditions to proceed!"
            )
          );
        } else {
          await createUser();
        }
      } catch (error) {
        dispatch(
          errorHandler(`${error.message}, check your internet connection`)
        );
      }

      //Here i need query which does the following:
      //-Change user onboarding status enum
    },
  });

  const [createUser, { data, loading, error }] = useCreateUserMutation({
    variables: {
      user: {
        contactPerson: formik.values.contactPerson,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        licenseClass: formik.values.licenseClass,
        licenseNumber: formik.values.licenseNumber,
        victoriaLicenseNumber: formik.values.victoriaLicenseNumber,
        licenseArea: formik.values.licenseArea,
        mobileNumber: formik.values.mobileNumber,
        phone: formik.values.phone,
        position: formik.values.position,
        address: {
          addressLine1: formik.values.addressLine1,
          addressLine2: formik.values.addressLine2,
          postcode: formik.values.postcode,
          suburb: formik.values.suburb,
          province: formik.values.province,
          country: formik.values.country,
        },
      },
    },
  });

  useEffect(() => {
    if (data?.createUser) {
      history.push("/join-or-create");
    }
  }, [data]);

  const [readUser, readUserResponse] = useLazyQuery(READ_CURRENT_USER);

  useEffect(() => {
    readUser();
  }, []);

  useEffect(() => {
    // console.log(readUserResponse);
    if (readUserResponse.data) {
      if (readUserResponse.data.readCurrentUser === null) {
        // setFormSubmitted(true);
      }
      if (readUserResponse.data.readCurrentUser) {
        history.push("/join-or-create");
      }
    }
  }, [readUserResponse]);

  enum registerField {
    addressLine1 = "Address Line 1",
    addressLine2 = "Address Line 2",
    country = "Country",
    postcode = "Post code",
    province = "State",
    suburb = "Suburb",
    contactPerson = "Contact Person",
    firstName = "First Name",
    lastName = "Last Name",
    licenseClass = "License Class",
    licenseNumber = "RPEQ License Number",
    victoriaLicenseNumber = "Victoria License Number",
    licenseArea = "License Area",
    mobileNumber = "Mobile Number",
    phone = "Phone",
    position = "Position",
  }
  

  const form = () => {
    return (
      <>
        <Avatar className={classes.avatar}>
          <PersonIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create New User
        </Typography>
        <Typography align="center" component="h6" variant="body2">
          Welcome {currentUser.user.firstName}. Please complete this form to
          finalise your account creation.
        </Typography>
        <form className={classes.formContainer} onSubmit={formik.handleSubmit}>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="firstName"
              name="firstName"
              variant="outlined"
              label={registerField.firstName}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="lastName"
              name="lastName"
              variant="outlined"
              label={registerField.lastName}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="addressLine1"
              name="addressLine1"
              variant="outlined"
              label={registerField.addressLine1}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.addressLine1}
              onChange={formik.handleChange}
              error={
                formik.touched.addressLine1 &&
                Boolean(formik.errors.addressLine1)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="addressLine2"
              name="addressLine2"
              variant="outlined"
              label={registerField.addressLine2}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.addressLine2}
              onChange={formik.handleChange}
              error={
                formik.touched.addressLine2 &&
                Boolean(formik.errors.addressLine2)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="postcode"
              name="postcode"
              variant="outlined"
              label={registerField.postcode}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.postcode}
              onChange={formik.handleChange}
              error={formik.touched.postcode && Boolean(formik.errors.postcode)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="suburb"
              name="suburb"
              variant="outlined"
              label={registerField.suburb}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.suburb}
              onChange={formik.handleChange}
              error={formik.touched.suburb && Boolean(formik.errors.suburb)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="province"
              name="province"
              variant="outlined"
              label={registerField.province}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.province}
              onChange={formik.handleChange}
              error={formik.touched.province && Boolean(formik.errors.province)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="country"
              name="country"
              variant="outlined"
              label={registerField.country}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.country}
              onChange={formik.handleChange}
              error={formik.touched.country && Boolean(formik.errors.country)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="contactPerson"
              name="contactPerson"
              variant="outlined"
              label={registerField.contactPerson}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.contactPerson}
              onChange={formik.handleChange}
              error={
                formik.touched.contactPerson &&
                Boolean(formik.errors.contactPerson)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="licenseClass"
              name="licenseClass"
              variant="outlined"
              label={registerField.licenseClass}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.licenseClass}
              onChange={formik.handleChange}
              error={
                formik.touched.licenseClass &&
                Boolean(formik.errors.licenseClass)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="licenseNumber"
              name="licenseNumber"
              variant="outlined"
              label={registerField.licenseNumber}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.licenseNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.licenseNumber &&
                Boolean(formik.errors.licenseNumber)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="victoriaLicenseNumber"
              name="victoriaLicenseNumber"
              variant="outlined"
              label={registerField.victoriaLicenseNumber}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.victoriaLicenseNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.victoriaLicenseNumber &&
                Boolean(formik.errors.victoriaLicenseNumber)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="licenseArea"
              name="licenseArea"
              variant="outlined"
              label={registerField.licenseArea}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.licenseArea}
              onChange={formik.handleChange}
              error={
                formik.touched.licenseArea &&
                Boolean(formik.errors.licenseArea)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="mobileNumber"
              name="mobileNumber"
              variant="outlined"
              label={registerField.mobileNumber}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.mobileNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.mobileNumber &&
                Boolean(formik.errors.mobileNumber)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="phone"
              name="phone"
              variant="outlined"
              label={registerField.phone}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              inputProps={{
                style: {
                  padding: "10px",
                },
              }}
              id="position"
              name="position"
              variant="outlined"
              label={registerField.position}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.position}
              onChange={formik.handleChange}
              error={formik.touched.position && Boolean(formik.errors.position)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <br />
            <TermsAndConditions maximumHeight={250} />
            <div className={classes.checkBoxesContainer}>
              <div className={classes.checkBoxRow}>
                <Typography component="h6" variant="body2" color="textPrimary">
                   I`ve read and accept the <a href="/terms-and-conditions">terms & conditions</a>
                </Typography>
                <div className={classes.checkBoxControl}>
                  <Checkbox
                    onChange={() => setTermsAndConditions(!termsAndConditions)}
                    color="primary"
                    className={classes.checkBox}
                    value={termsAndConditions}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </div>
              </div>
            </div>
          </FormControl>
          <Button
            disabled={!termsAndConditions}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Create User
          </Button>
          {loading && (
            <div className={classes.linearProgress}>
              <LinearProgress />
            </div>
          )}
          <Typography align="center" variant="body2" component="h6">
            Note: After completing this form you will need to wait for account
            approval from our team. This usually takes up to 24 hours.
          </Typography>
        </form>
      </>
    );
  };

  if (readUserResponse.loading || readUserResponse.error) {
    return (
      <Grid container component="main" className={classes.image}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={4}
          component={Paper}
          className={classes.formContainer}
          elevation={6}
          square
        >
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {readUserResponse.error && (
            <span>Check your internet connection</span>
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container component="main" className={classes.image}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={8}
        md={8}
        lg={4}
        component={Paper}
        className={classes.formContainer}
        elevation={6}
        square
      >
        <div className={classes.paper}>
          {currentUser?.user?.roles.length === 0 && form()}
        </div>
      </Grid>
    </Grid>
  );
};
