import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
      backgroundSize: "cover",
      backgroundImage: "url(/images/hero.jpg)",
      backgroundRepeat: "no-repeat",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    container:{
        backgroundColor: "rgba(176, 182, 198, 0.32)",
        width: "100%",
        color: theme.palette.primary.dark,
        fontWeight: "bold",
      },
      subtitle:{ maxWidth: 650, margin: "auto" }
  })
);

const MaintenanceMode = () => {
    const classes = useStyles();
  return (
    <div className={classes.root}>
      <div
        className={classes.container}
      >
        <Typography component="h1" variant="h1" align="center">
          Maintenance Mode
        </Typography>
        <Typography
          variant="h4"
          align="center"
          component="h4"
          className={classes.subtitle}
        >
          Sorry for the inconvenience. We are currently working on something new.
          EngiFlow will be back to normal as soon as possible.
        </Typography>
      </div>
    </div>
  );
};

export default MaintenanceMode;
