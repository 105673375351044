//ASK JAN ABOUT THAT MULTIPLE STEP FORM...
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import SearchIcon from "@material-ui/icons/Search";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FromAndToDate } from "./FromAndToDate";
import RefreshIcon from "@material-ui/icons/Refresh";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./FilterJobsComponent.css";
import {
  Typography,
  makeStyles,
  IconButton,
  Select,
  MenuItem,
  Theme,
  createStyles,
  FormControl,
} from "@material-ui/core";
import { JobCustomDateRange, JobCustomDateRangeTypeEnum, JobServiceEnum, JobStateEnum, useReadOrganisationsLazyQuery } from "../../generated/graphql";
import { QUERY_FILTER_JOBS } from "../JobList/query";
import { useLazyQuery } from "@apollo/client";
import {
  filterJobs,
  resetPage,
  doYouNeedRefetch,
  errorHandler,
} from "../../store/actions";
import { RootState } from "../../store";
import { CSVExportButton } from "./CSVExportButton";
import { ROLES_CONFIG } from "../../utils/helpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    AddJobsButtonContainer: {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
    },
    formControl: {
      minWidth: "100%",
    },
    accordion:{
    },
    accordionSummary:{
      borderBottom:"2px solid",
      borderBottomColor:theme.palette.primary.main,
      maxHeight:"40px",
      marginTop:"5px",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    customPaperClass: {
      marginRight: 5,
      backgroundColor: theme.palette.secondary.main,
    },
    fromAndToDate: {
      display: "flex",
      flexDirection: "row",
    },
    filterHeadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      borderTopLeftRadius: "3px",
      borderTopRightRadius: "3px",
    },
    formRow: {
      marginBottom: "10px",
    },
    formElementContainer: {
      padding: "5px",
      background: theme.palette.success.main,
    },
    AccordionDetails: {
      padding: "5px",
      display: "flex",
      justifyContent: "center",
    },
    searchIconButton: {
      backgroundColor: theme.palette.primary.main,
      cursor: "pointer",
      justifyContent: "center",
      alignItems: "center",
      marginTop:"5px",
      borderRadius:"0px",
      padding: "12px",
      width:"100%",
      height: "44px",
      "&:hover":{
        backgroundColor:theme.palette.primary.light
      }
    },
    refreshIcon: {
      color: theme.palette.secondary.main,
    },
    searchIcon: {
      color: theme.palette.secondary.main,
    },
    spinner: {
      color: theme.palette.secondary.main,
    },
    floatingLabelFocusStyle: {
      color: theme.palette.text.primary,
    },
    selectColors: {
      color: theme.palette.text.primary,
    },
  })
);

const getDateString = (date: Date = new Date()): string => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

const validationSchema = yup.object({
  jobNumber: yup.string().nullable(),
  builder: yup.string().nullable(),
  state: yup.string().nullable(),
  priority: yup.string().nullable(),
  dateType: yup.string().nullable(),
  dateState: yup.string().nullable(),
  dateService: yup.string().nullable(),
  dateFrom: yup.string().nullable(),
  dateTo: yup.string().nullable(),

  street: yup.string().nullable(),
  streetNumber: yup.string().nullable(),
  suburb: yup.string().nullable(),
  postcode: yup.string().nullable(),
  province: yup.string().nullable(),
  localGovernment: yup.string().nullable(),
  lot: yup.string().nullable(),

  organisationName: yup.string().nullable()
});

interface FilterJobsComponentProps {
  test: string;
}

enum filterField {
  jobNumber = "Job Number",
  builder = "Builder",
  state = "State",
  priority = "Priority",

  dateType = "Date Range Type",
  dateState = "Date Range State",
  dateService = "Date Range Service",
  dateFrom = "Date From",
  dateTo = "Date To",

  street = "Street",
  streetNumber = "Street Number",
  suburb = "Suburb",
  postcode = "Postcode",
  localGovernment = "Local Government",
  lot = "Lot",
  province = "State/Province",

  organisationName = "Organisation Name"
}

const FilterJobsComponent: React.FC<FilterJobsComponentProps> = ({ test }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // const [countSubmit, setCountSubmit] = useState(1);

  const [filter, { loading, data, error, refetch }] = useLazyQuery(
    QUERY_FILTER_JOBS
  );

  const { page, rowsPerPage }: any = useSelector(
    (state: RootState) => state.jobs
  );

  const [reRender, setReRender] = useState(false);
  const currentState: any = useSelector((state: RootState) => state.jobs);
  const currentUser: any = useSelector((state: RootState) => state.user.user);
  const [readOrganisations, organisationsResponse] = useReadOrganisationsLazyQuery();

  //used to prevent component re-rendering constantly
  const ref = useRef(true);
  //do not dispatch same error message twice
  const ref1 = useRef(true);

  const getInputCriteria = (values: any) => {
    const inputCriteria = {
      jobNumber: values.jobNumber === "" ? null : values.jobNumber,
      builder: values.builder === "" ? null : values.builder,
      state: values.state === "" ? null : values.state,
      priority: values.priority === "" ? null : values.priority,
      dateRange: values.dateType === '' ? null : {
        dateType: values.dateType === '' ? null : values.dateType,
        state: values.dateState === '' ? null : values.dateState,
        service: values.dateService === '' ? null : values.dateService,
        dateFrom: values.dateFrom,
        dateTo: values.dateTo
      },
      street: values.street === "" ? null : values.street,
      streetNumber: values.streetNumber === "" ? null : values.streetNumber,
      suburb: values.suburb === "" ? null : values.suburb,
      postcode: values.postcode === "" ? null : values.postcode,
      province: values.province === "" ? null : values.province,
      localGovernment: values.localGovernment === "" ? null : values.localGovernment,
      lot: values.lot === "" ? null : values.lot,
      organisationName: values.organisationName === "" ? null : values.organisationName
    };
    return inputCriteria;
  };

  useEffect(() => {
    if (currentUser?.roles?.includes(ROLES_CONFIG.Administrator.RoleName)) {
      readOrganisations();
    }
  }, [currentUser]);

  useEffect(() => {
    if (data && ref.current) {
      //console.log("filterJobsComponent lazyquery data: ", data);
      dispatch(filterJobs(data.filterJobs.jobs, data.filterJobs.totalJobs));
      ref.current = false;
    }
  }, [data, dispatch]);

  /*useEffect(() => {
    const inputCriteria = getInputCriteria(formik.values);
    filter({
      variables: {
        jobFilterCriteria: inputCriteria,
        page: page + 1,
        rowsPerPage: rowsPerPage,
      },
    });
  }, []);*/

  useEffect(() => {
    if (error && ref1.current) {
      dispatch(errorHandler(error.message));
      ref1.current = false;
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (currentState.doYouNeedRefetch) {
      if (refetch) {
        refetch!(data);
        dispatch(doYouNeedRefetch());
      }
    }
  }, [currentState, refetch, data, dispatch]);

  //Update list if user change rowsPerPage
  //Submit form when user change the page
  const testRef = useRef({
    rows: currentState.rowsPerPage,
    page: currentState.page,
  });
  useEffect(() => {
    if (
      testRef.current.rows !== currentState.rowsPerPage ||
      testRef.current.page !== currentState.page
    ) {
      //refresh form if user change rows per page
      testRef.current = {
        rows: currentState.rowsPerPage,
        page: currentState.page,
      };
      formik.submitForm();
    }
  }, [rowsPerPage, page]);

  const initSubmitRef = useRef(0);
  useEffect(() => {
    if (initSubmitRef.current === 0) {
      initSubmitRef.current = 1;
      formik.submitForm();
    }
  }, []);

  //16 Fields
  const formik = useFormik({
    initialValues: {
      jobNumber: "",
      builder: "",
      state: "",
      complexityLevel: "",
      priority: "",
      dateType: "",
      dateState: "",
      dateService: "",
      dateFrom: getDateString(),
      dateTo: getDateString(),

      street: "",
      streetNumber: "",
      suburb: "",
      postcode: "",
      province: "",
      localGovernment: "",
      lot: "",

      organisationName: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));


      //This means its not rendering logic its apollo or something else...
        

      const inputCriteria = getInputCriteria(values);
      // test


      filter({
        variables: {
          jobFilterCriteria: inputCriteria,
          page: page + 1,
          rowsPerPage: rowsPerPage,
        },
      });

      // console.log(`FORMIK SUBMIT no:${countSubmit}`)
      // setCountSubmit(prev=>prev+1);    

      //Reset
      ref.current = true;
      ref1.current = true;
      //dispatch(saveFilterStateAction(values));
    },
  });

  const onChangeDateHandler = useCallback((fieldName: string, value: any) => {
    let date = JSON.stringify(value);
    date = date.slice(1, 11);
    formik.setFieldValue(fieldName, date);
    delayReRender();
  }, []);

  const delayReRender = () => {
    const delayDebounceFn = setTimeout(() => {
      setReRender((prev) => !prev);
    }, 20);
    return () => clearTimeout(delayDebounceFn);
  };

  return (
      <Paper className={classes.customPaperClass}>
        <div className="FilterJobsContainerMain">
          <div className={classes.filterHeadingContainer}>
            <Typography component="h3" variant="subtitle2" align="center">
              Filter jobs
            </Typography>
            <IconButton
              disableFocusRipple
              onClick={() => {
                formik.resetForm();
                delayReRender();
              }}
            >
              <RefreshIcon fontSize="small" className={classes.refreshIcon} />
            </IconButton>
          </div>

          <div
            className={classes.formElementContainer}
          >
            <form>
              <Accordion>
                <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                  <Typography component={"span"} variant={"body2"}>
                    Job Details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.AccordionDetails}>
                  <Typography component={"span"} variant={"body2"}>
                    <FormControl className={classes.formControl}>
                      <TextField
                        fullWidth
                        id="jobNumber"
                        name="jobNumber"
                        label={filterField.jobNumber}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        value={formik.values.jobNumber}
                        onChange={(et: React.ChangeEvent<any>) => {
                          formik.handleChange(et);
                          delayReRender();
                        }}
                        error={
                          formik.touched.jobNumber &&
                          Boolean(formik.errors.jobNumber)
                        }
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        fullWidth
                        id="builder"
                        name="builder"
                        label={filterField.builder}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        value={formik.values.builder}
                        onChange={(et: React.ChangeEvent<any>) => {
                          formik.handleChange(et);
                          delayReRender();
                        }}
                        error={
                          formik.touched.builder && Boolean(formik.errors.builder)
                        }
                      />
                    </FormControl>

                    {/*job state*/}
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {filterField.state}
                      </InputLabel>
                      <Select
                        className={classes.formRow}
                        labelId="demo-simple-select-helper-label"
                        fullWidth
                        id="state"
                        name="state"
                        label={filterField.state}
                        value={formik.values.state}
                        onChange={(et: React.ChangeEvent<any>) => {
                          formik.handleChange(et);
                          delayReRender();
                        }}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={JobStateEnum.AwaitingDocuments}>
                          Awaiting Documents
                        </MenuItem>
                        <MenuItem value={JobStateEnum.Detailing}>
                          Detailing
                        </MenuItem>
                        <MenuItem value={JobStateEnum.Engineering}>
                          Engineering
                        </MenuItem>
                        <MenuItem value={JobStateEnum.WaitingForInspectionResults}>
                          Waiting for inspection results
                        </MenuItem>
                        <MenuItem value={JobStateEnum.ReviewInspectionResults}>
                          Review inspection results
                        </MenuItem>
                        <MenuItem value={JobStateEnum.Finished}>
                          Finished
                        </MenuItem>
                      </Select>
                    </FormControl>

                    {/*Priority*/}
                    <FormControl className={classes.formControl}>
                      <InputLabel>{filterField.priority}</InputLabel>
                      <Select
                        fullWidth
                        id="priority"
                        name="priority"
                        label={filterField.priority}
                        value={formik.values.priority}
                        onChange={(et: React.ChangeEvent<any>) => {
                          formik.handleChange(et);
                          delayReRender();
                        }}
                        error={
                          formik.touched.priority &&
                          Boolean(formik.errors.priority)
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Normal">Normal</MenuItem>
                        <MenuItem value="Urgent">Urgent</MenuItem>
                      </Select>
                    </FormControl>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Accordion>
                  <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                    <Typography component={"span"} variant={"body2"}>
                      Dates
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.AccordionDetails}>
                    <Typography component={"span"} variant={"body2"} style={{minWidth: "100%"}}>
                      {/* date range type */}
                      <FormControl className={classes.formControl}>
                        <InputLabel id="dateType-select-helper-label">
                          {filterField.dateType}
                        </InputLabel>
                        <Select
                          className={classes.formRow}
                          labelId="dateType-select-helper-label"
                          fullWidth
                          id="dateType"
                          name="dateType"
                          label={filterField.dateType}
                          value={formik.values.dateType}
                          onChange={(et: React.ChangeEvent<any>) => {
                            formik.handleChange(et);
                            delayReRender();
                          }}
                          error={
                            formik.touched.dateType && Boolean(formik.errors.dateType)
                          }
                        >
                          <MenuItem value=''>
                            <em>Select a date range type</em>
                          </MenuItem>
                          <MenuItem value={JobCustomDateRangeTypeEnum.Creation}>
                            Creation Date
                          </MenuItem>
                          <MenuItem value={JobCustomDateRangeTypeEnum.Delivery}>
                            Delivery Date
                          </MenuItem>
                          <MenuItem value={JobCustomDateRangeTypeEnum.StateEntry}>
                            State Entry Date
                          </MenuItem>
                          <MenuItem value={JobCustomDateRangeTypeEnum.StateExit}>
                            State Exit Date
                          </MenuItem>
                          <MenuItem value={JobCustomDateRangeTypeEnum.ServiceRequest}>
                            Service Requested Date
                          </MenuItem>
                          <MenuItem value={JobCustomDateRangeTypeEnum.ServiceIssue}>
                            Service Issued Date
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {/* date range state */}
                      {[JobCustomDateRangeTypeEnum.StateEntry as string, JobCustomDateRangeTypeEnum.StateExit as string].includes(formik.values.dateType) &&
                      <FormControl className={classes.formControl}>
                        <InputLabel id="dateState-select-helper-label">
                          {filterField.dateState}
                        </InputLabel>
                        <Select
                          className={classes.formRow}
                          labelId="dateState-select-helper-label"
                          fullWidth
                          id="dateState"
                          name="dateState"
                          label={filterField.dateState}
                          value={formik.values.dateState}
                          onChange={(et: React.ChangeEvent<any>) => {
                            formik.handleChange(et);
                            delayReRender();
                          }}
                          error={
                            formik.touched.dateState && Boolean(formik.errors.dateState)
                          }
                        >
                          <MenuItem value=''>
                            <em>Select a date range state</em>
                          </MenuItem>
                          <MenuItem value={JobStateEnum.AwaitingDocuments}>
                            Awaiting Documents
                          </MenuItem>
                          <MenuItem value={JobStateEnum.Detailing}>
                            Detailing
                          </MenuItem>
                          <MenuItem value={JobStateEnum.Engineering}>
                            Engineering
                          </MenuItem>
                          <MenuItem value={JobStateEnum.WaitingForInspectionResults}>
                            Waiting for inspection results
                          </MenuItem>
                          <MenuItem value={JobStateEnum.ReviewInspectionResults}>
                            Review inspection results
                          </MenuItem>
                          <MenuItem value={JobStateEnum.Finished}>
                            Finished
                          </MenuItem>
                        </Select>
                      </FormControl>}
                      {/* date range service */}
                      {[JobCustomDateRangeTypeEnum.ServiceRequest as string, JobCustomDateRangeTypeEnum.ServiceIssue as string].includes(formik.values.dateType) &&
                      <FormControl className={classes.formControl}>
                        <InputLabel id="dateService-select-helper-label">
                          {filterField.dateService}
                        </InputLabel>
                        <Select
                          className={classes.formRow}
                          labelId="dateService-select-helper-label"
                          fullWidth
                          id="dateService"
                          name="dateService"
                          label={filterField.dateService}
                          value={formik.values.dateService}
                          onChange={(et: React.ChangeEvent<any>) => {
                            formik.handleChange(et);
                            delayReRender();
                          }}
                          error={
                            formik.touched.dateService && Boolean(formik.errors.dateService)
                          }
                        >
                          <MenuItem value=''>
                            <em>Select a date range service</em>
                          </MenuItem>
                          <MenuItem value={JobServiceEnum.Detailing}>
                            Detailing
                          </MenuItem>
                          <MenuItem value={JobServiceEnum.Engineering}>
                            Engineering
                          </MenuItem>
                          <MenuItem value={JobServiceEnum.PrelimDesign}>
                            Preliminary Design Form
                          </MenuItem>
                          <MenuItem value={JobServiceEnum.Inspection}>
                            Inspection
                          </MenuItem>
                        </Select>
                      </FormControl>}
                      {/* Date Range */}
                      {formik.values.dateType !== '' && <FromAndToDate
                        idFrom="dateFrom"
                        idTo="dateTo"
                        labelFrom={filterField.dateFrom}
                        labelTo={filterField.dateTo}
                        valueFrom={formik.values.dateFrom}
                        valueTo={formik.values.dateTo}
                        onChangeDateHandler={onChangeDateHandler}
                      />}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </MuiPickersUtilsProvider>

              {/* ADDRESS SECTIN */}
              <Accordion className={classes.accordion}>
                <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                  <Typography component={"span"} variant={"body2"}>
                    Address
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.AccordionDetails}>
                  <Typography component={"span"} variant={"body2"}>
                    {/*Street*/}
                    <TextField
                      fullWidth
                      id="street"
                      name="street"
                      label={filterField.street}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      value={formik.values.street}
                      onChange={(et: React.ChangeEvent<any>) => {
                        formik.handleChange(et);
                        delayReRender();
                      }}
                      error={
                        formik.touched.street &&
                        Boolean(formik.errors.street)
                      }
                    />
                    {/*Street number*/}
                    <TextField
                      fullWidth
                      id="streetNumber"
                      name="streetNumber"
                      label={filterField.streetNumber}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      value={formik.values.streetNumber}
                      onChange={(et: React.ChangeEvent<any>) => {
                        formik.handleChange(et);
                        delayReRender();
                      }}
                      error={
                        formik.touched.streetNumber &&
                        Boolean(formik.errors.streetNumber)
                      }
                    />
                    {/*suburb*/}
                    <TextField
                      fullWidth
                      id="suburb"
                      name="suburb"
                      label={filterField.suburb}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      value={formik.values.suburb}
                      onChange={(et: React.ChangeEvent<any>) => {
                        formik.handleChange(et);
                        delayReRender();
                      }}
                      error={
                        formik.touched.suburb && Boolean(formik.errors.suburb)
                      }
                    />
                    {/*postal code*/}
                    <TextField
                      fullWidth
                      id="postcode"
                      name="postcode"
                      label={filterField.postcode}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      value={formik.values.postcode}
                      onChange={(et: React.ChangeEvent<any>) => {
                        formik.handleChange(et);
                        delayReRender();
                      }}
                      error={
                        formik.touched.postcode &&
                        Boolean(formik.errors.postcode)
                      }
                    />
                    {/*state*/}
                    <TextField
                      fullWidth
                      id="province"
                      name="province"
                      label={filterField.province}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      value={formik.values.province}
                      onChange={(et: React.ChangeEvent<any>) => {
                        formik.handleChange(et);
                        delayReRender();
                      }}
                      error={formik.touched.province && Boolean(formik.errors.province)}
                    />
                    {/*Local Government*/}
                    <TextField
                      fullWidth
                      id="localGovernment"
                      name="localGovernment"
                      label={filterField.localGovernment}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      value={formik.values.localGovernment}
                      onChange={(et: React.ChangeEvent<any>) => {
                        formik.handleChange(et);
                        delayReRender();
                      }}
                      error={
                        formik.touched.localGovernment &&
                        Boolean(formik.errors.localGovernment)
                      }
                    />
                    {/*Lot*/}
                    <TextField
                      fullWidth
                      id="lot"
                      name="lot"
                      label={filterField.lot}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      value={formik.values.lot}
                      onChange={(et: React.ChangeEvent<any>) => {
                        formik.handleChange(et);
                        delayReRender();
                      }}
                      error={formik.touched.lot && Boolean(formik.errors.lot)}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>


              {/* ORGANISATION */}
              {!!organisationsResponse.data?.readOrganisations && <Accordion className={classes.accordion}>
                <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                  <Typography component={"span"} variant={"body2"}>
                    Organisation
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.AccordionDetails}>
                  <Typography component={"span"} variant={"body2"} style={{width:"100%"}}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="organisationName-select-helper-label">
                        {filterField.organisationName}
                      </InputLabel>
                      <Select
                        labelId="organisationName-select-helper-label"
                        fullWidth
                        id="organisationName"
                        name="organisationName"
                        label={filterField.organisationName}
                        value={formik.values.organisationName}
                        onChange={(et: React.ChangeEvent<any>) => {
                          formik.handleChange(et);
                          delayReRender();
                        }}
                        error={
                          formik.touched.organisationName && Boolean(formik.errors.organisationName)
                        }
                      >
                        <MenuItem value=''>
                          <em>Select an organisation</em>
                        </MenuItem>
                        {
                          !!organisationsResponse.data?.readOrganisations ? organisationsResponse.data?.readOrganisations.filter(organisation => !!organisation).map(organisation => <MenuItem value={organisation!.name}>
                            {organisation!.name}
                          </MenuItem>) : null
                        }
                      </Select>
                    </FormControl>
                  </Typography>
                </AccordionDetails>
              </Accordion>}
              {/* ORGANISATION END */}

              
                <IconButton
                  onClick={(e) => {
                    e.preventDefault()
                    dispatch(resetPage())
                    formik.submitForm()
                  }}
                  type="submit"
                  className={classes.searchIconButton}
                  color="primary"
                  disableFocusRipple
                >
                  {loading && ref1.current ? (
                    <CircularProgress className={classes.spinner} size={20} />
                  ) : (
                    <SearchIcon fontSize="small" className={classes.searchIcon} />
                  )}
                </IconButton>
            </form>
            <CSVExportButton filterCriteria={getInputCriteria(formik.values)} />
          </div>
        </div>
      </Paper>
    );
};

export default FilterJobsComponent;
