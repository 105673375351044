import React from "react";
import { useSelector } from "react-redux";
import Client from "./Client";
import Provider from "./Provider";
import { RootState } from "../../store";
import Unauthorized from "../Unauthorized";
import { devRole, ROLES_CONFIG } from "../../utils/helpers";

const Detailing = (job: any) => {
  let currentUser: any = useSelector((state: RootState) => state.user.user);

  if (devRole.roles.length > 0) {
    currentUser = devRole;
  }

  let user_role;
  if (
    currentUser.roles.includes(ROLES_CONFIG.Client.RoleName) ||
    currentUser.roles.includes(ROLES_CONFIG.Owner.RoleName)
  ) {
    user_role = "Client";
  }
  if(
    currentUser.roles.includes(ROLES_CONFIG.Detailer.RoleName) ||
    currentUser.roles.includes(ROLES_CONFIG.Administrator.RoleName)
  ) {
    user_role = "Provider";
  }

  switch (user_role) {
    case "Provider":
      return <Provider job={job} />;
    case "Client":
      return <Client />;
    default:
      return <Unauthorized />;
  }
};

export default Detailing;
