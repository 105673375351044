import { createStyles, Divider, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InspectionDetailsForm from "../../components/FormChecklist/InspectionDetailsForm";

import ApproveOrReject from "../ApproveOrReject";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "20px",
    },
    divider2: {
      margin: "25px 0px",
    },
  })
);

const Provider = ({ job }: any) => {
  const classes = useStyles();
  const [advanceEnabled, setAdvanceEnabled] = useState(false);

  useEffect(() => {
    if (job.jobInspection?.data) {
      setAdvanceEnabled(true);
    }
  }, [job.jobInspection]);

  return (
    <div>
      <InspectionDetailsForm setAdvanceEnabled={setAdvanceEnabled} job={job} />
      <Divider className={classes.divider2} />
      <ApproveOrReject
        advanceEnabled={advanceEnabled}
        job={job}
        reject={false}
        checkboxText="I agree that I checked all documentation for this stage."
      />
    </div>
  );
};

export default Provider;
