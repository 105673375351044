import { createStyles, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React from "react";
import Dropzone from "react-dropzone";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    DropzoneContainer: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "700px",
      minWidth: "500px",
      margin: "20px",
    },
    DropSection: {
      backgroundColor: theme.palette.success.main,
      border: "2px dashed #b99191 ",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "180px",
      "&:hover": {
        cursor: "pointer",
      },
      "&:focus": {
        outline: 0,
      },
    },
    fileListRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "2px",
      width: "100%",

      maxWidth: "95%",
      marginLeft: "5px",
    },
    deleteIconClass: {
      color: "#f13f3f",
      "&:hover": {
        cursor: "pointer",
      },
    },
    titleClass: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textDecoration: "underline",
      textDecorationColor: theme.palette.primary.main,
      marginBottom: "1px",
    },
    table: {
      maxWidth: "100%",
    },
    tableCell:{
      padding:'8px'
    },
    instructions:{
      color:'gray',
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "7px",
      fontSize:"12px",
      fontWeight:300
    }
  })
);

interface FileSelectionProps {
  title: string;
  instructions: string;
  fileType: string;
  selectedFiles: File[];
  setSelectedFiles: any;
}

const FileSelection: React.FC<FileSelectionProps> = ({
  title,
  fileType,
  instructions,
  selectedFiles,
  setSelectedFiles,
}) => {
  const classes = useStyles();

  const removeFileHandler = (fileName: string) => {
    const newFiles = selectedFiles.filter((f) => f.name !== fileName);
    setSelectedFiles(newFiles);
  };

  function createData(fileName: string, size: any) {
    return { fileName, size };
  }

  interface fileInterface {
    fileName: string;
    size: any;
  }

  const rows: fileInterface[] = [];

  selectedFiles.map((file) => {
    const fileData = createData(file.name, file.size);
    rows.push(fileData);
  });

  return (
    <Dropzone onDrop={(acceptedFiles) => setSelectedFiles(acceptedFiles)}>
      {({ getRootProps, getInputProps }) => (
        <div className={classes.DropzoneContainer}>
          <Typography
            className={classes.titleClass}
            variant="subtitle1"
            color="primary"
          >
            {title}
          </Typography>
          <Typography
            className={classes.instructions}
            variant="subtitle2"
          >
            {instructions}
          </Typography>
          <div className={classes.DropSection} {...getRootProps()}>
            <input {...getInputProps()} />

            <Typography
              style={{ margin: 0 }}
              variant="subtitle2"
              color="textPrimary"
            >
              Drag some files here, or click to select files
            </Typography>
          </div>
          <div>
              
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>File Name</TableCell>
                    <TableCell className={classes.tableCell} align="center">File Size</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.fileName}>
                      <TableCell className={classes.tableCell} component="th" scope="row">
                        {row.fileName} <HighlightOffIcon
                      onClick={() => removeFileHandler(row.fileName)}
                      className={classes.deleteIconClass}
                      fontSize="small"
                    />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">{(parseInt(row.size) / 1000).toFixed(2).toString() }kb</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default FileSelection;
