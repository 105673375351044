import {  useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Container, createStyles, Grid, makeStyles, Switch, Theme, Typography } from "@material-ui/core";
import axios from "axios";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {  useUpdateMaintenanceModeMutation } from "../../../generated/graphql";
import { errorHandler } from "../../../store/actions";
import { MAINTENANCE_MODE } from "../../JobList/query";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root:{
        padding:"20px 30px"
    },
    title:{
        paddingBottom:10
    }

  })
);


const Settings = () => {
    const classes = useStyles();
  const dispatch = useDispatch();
  const [maintenanceMode, setMaintenanceMode] = useState(0);
  const [ updateMaintenanceMode, updateMaintenanceModeResponse  ] = useUpdateMaintenanceModeMutation();
  const { loading, error, data } = useQuery(MAINTENANCE_MODE);
  const { getAccessTokenSilently } = useAuth0();
  const [migrationLoading, setMigrationLoading] = useState<boolean>(false);
  
  useEffect(() => {
      if(data)
        setMaintenanceMode(data.maintenanceMode);
  }, [data]);

  const maintenanceModeChangeHandler=async (event:ChangeEvent<HTMLInputElement>)=>{
    try {
        let value=event.target.checked?1:0
        const result = await updateMaintenanceMode({
            variables:{
                state:value
            }
        });
        setMaintenanceMode(value);
    }catch(e){
        dispatch(errorHandler("Check your internet connection " + e));
    }
  }

  const migrateJobLogs = async () => {
    setMigrationLoading(true);
    const token = await getAccessTokenSilently();
    const config = {
      headers:{
          "Authorization": `Bearer ${token}`
      }
    };
  
    await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/maintenance/migratejobservicelogs`, {}, config).finally(() => setMigrationLoading(false));
  }
  
  return (
    <Container className={classes.root}>
      <Typography variant="h3" component="h3" className={classes.title} >
        Settings
      </Typography>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={9}>
          <Typography component="div" variant="body1">
            Maintenance mode
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}>
          <Switch
            checked={maintenanceMode===1}
            onChange={maintenanceModeChangeHandler}
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <Button onClick={migrateJobLogs} disabled={migrationLoading}>
            Migrate Job Service Request Audit Logs
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;
