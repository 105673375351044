import {
  Container,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import JobDetailsView from "../../views/JobDetailsView";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    JobsViewContainer: {
      backgroundColor: theme.palette.success.main,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "3px",
      marginTop: theme.spacing(15),
      marginBottom: "100px",
      padding: "10px",
      paddingTop: "20px",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    JobListAndFilterContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "10px",
      maxWidth: "100%",
    },
    containerTitleText: {
      fontFamily: "-webkit-pictograph",
    },
  })
);

const JobDetailViewContainer = () => {
  const classes = useStyles();
  return (
    <Container style={{ maxWidth: "1600px"}} maxWidth="lg">
      <div className={classes.JobsViewContainer}>
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          align="center"
          className={classes.containerTitleText}
        >
          Job View
          <Divider />
        </Typography>

        <div className={classes.JobListAndFilterContainer}>
          <Grid
            container
            spacing={0}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12} md={12} lg={12}>
              <JobDetailsView />
            </Grid>
          </Grid>
        </div>
      </div>
    </Container>
  );
};

export default JobDetailViewContainer;
