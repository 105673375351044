import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import "./index.css";
import App from "./App";

import { theme } from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";
// import { Router } from "react-router-dom";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { rootReducer } from "./store";
import streamSaver from 'streamsaver';

// Create the Apollo Client
//
// https://blog.logrocket.com/build-a-graphql-react-app-with-typescript/
//
// https://github.com/apollographql/apollo-client/issues/2042
//
// Requires apollo being installed as described in:
//      https://www.apollographql.com/docs/react/get-started/
//
// Issues that might happen when installed wrong
//      https://github.com/apollographql/apollo-client/issues/2042
//
// Simply run: npx graphql-codegen in ./clientapp to update the queries in ./generated.
//
// Detailed call with individual config files:
//  npx graphql-codegen --config ./path/to/config.yml
//
// Apollo Toolchain:
//  https://github.com/apollographql/apollo-tooling
//

//Apollo access token: https://www.apollographql.com/docs/react/networking/authentication/

declare module "*.png";

//Redux DevTools for chrome extension do not delete
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let isProd;
if(window.location.hostname === 'localhost'){
  isProd = false;
}else{
  isProd = true;
}

streamSaver.mitm = process.env.REACT_APP_FRONTEND_BASE_URL + "/streamsaver/mitm.html";

//Redux store
let store;

if(isProd){
  store =  createStore(rootReducer, applyMiddleware(thunk));
}else{
  store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk))); //include dev tools
}

ReactDOM.render(
  <Auth0Provider
    domain="engiseek.au.auth0.com"
    clientId="KMCCC7v3bwU2q6jZ78m9UWDztbgun4V2"
    redirectUri={window.location.origin}
    audience="engiflow-backend-services"
    scope="openid profile email rw:jobs"
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
            <App/>
        </Router>
      </Provider>
    </ThemeProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
