import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {
  Button,
  createStyles,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Theme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Job, JobStateEnum } from "../../generated/graphql";
import { setUncaughtExceptionCaptureCallback } from "process";
import { gql, useLazyQuery, useMutation } from "@apollo/react-hooks";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    alertClass: {
      color: "#fff",
      backgroundColor: "green",
      margin: "7px",
    }
  })
);

interface StateChangeProps {
  job: Job;
}

const UPDATE_JOB_STATE = gql`
  mutation updateJobState($jobId: Int!, $state: JobStateEnum!) {
    updateJobState(jobId: $jobId, state: $state) {
      id
    }
  }
`;

const StateChange: React.FC<StateChangeProps> = ({ job }) => {
  const classes = useStyles();
  const [jobState, setJobState] = useState<JobStateEnum>(job.state as JobStateEnum);
  const [updateJobState, {data, loading, error}] = useMutation(UPDATE_JOB_STATE);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);

  const submitState = () => {
    updateJobState({variables: {jobId: job.id, state: jobState}});
  };

  useEffect(() => {
    if (data && !loading && !error) {
      setShowAlert(true);
      window.location.reload();
    }
  }, [data, loading, error]);

  useEffect(() => {
    setJobState(job.state as JobStateEnum);
  }, [job]);

  return (
    <Container maxWidth="md">
      <Grid
        container
        spacing={2}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={12} md={4} lg={4}>
          <Select
            id="change-state"
            value={jobState}
            label="New Job State"
            defaultValue={job.state}
            onChange={event => setJobState(event.target.value as JobStateEnum)}
          >
            <MenuItem value={job.state as JobStateEnum}><i>Select state</i></MenuItem>
            {Object.values(JobStateEnum).filter(key => key !== job.state).map(value => (
              <MenuItem value={value}>{value}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6} md={8} lg={8}>
          <Button
            variant="contained"
            color="primary"
            onClick={submitState}
          >
            Submit New State
          </Button>
        </Grid>
        <Grid item xs={6} md={8} lg={8}>
          {showAlert && (
            <Alert
              className={classes.alertClass}
              onClose={() => {
                setShowAlert(false);
              }}
            >
              State has been changed successfully
            </Alert>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default StateChange;