import {
  Button,
  createStyles,
  Divider,
  LinearProgress,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FileSelection from "../../components/FileUpload/FileSelection";
import { stagesText } from "../../utils/helpers";
import * as fileStorage from "../../utils/fileStorage";
import { doYouNeedRefetch, errorHandler } from "../../store/actions";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import ApproveOrReject from "../ApproveOrReject";
import InstructionsContainer from "../InstructionsContainer";
import AlertMessage from "../../components/AlertMessage/AlertMessage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "20px",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    toDoBottomContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    divider2: {
      width: "100%",
      margin: "25px",
    },
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    linearIndeterminate: {
      marginTop: "10px",
      width: "90%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);

interface ClientProps {
  job: any;
}

const Client: React.FC<ClientProps> = ({ job }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const [uploading, setUploading] = useState<boolean>(false);
  const [open1, setOpen1] = useState<boolean>(false);
  const [architecturalFiles, setArchitecturalFiles] = useState<any[]>([]);
  const [detailingFiles, setDetailingFiles] = useState<any[]>([]);
  const [engineeringFiles, setEngineeringFiles] = useState<any[]>([]);
  const [inspectionFiles, setInspectionFiles] = useState<any[]>([]);

  const [reqDocs, setReqDocs] = useState<any[]>([]);

  const submitHandler = async () => {
    try {
      if (
        architecturalFiles.length > 0 ||
        detailingFiles.length > 0 ||
        engineeringFiles.length > 0 ||
        inspectionFiles.length > 0
      ) {
        setUploading(true);
        // setTimeout(async () => {
        const token = await getAccessTokenSilently();
        await fileStorage.uploadJobDocument(
          architecturalFiles,
          job.id,
          "architecturaldoc",
          token
        );
        await fileStorage.uploadJobDocument(
          detailingFiles,
          job.id,
          "detailingdoc",
          token
        );
        await fileStorage.uploadJobDocument(
          engineeringFiles,
          job.id,
          "engineeringdoc",
          token
        );
        await fileStorage.uploadJobDocument(
          inspectionFiles,
          job.id,
          "inspectiondoc",
          token
        );

        //Renew job documents tree
        dispatch(doYouNeedRefetch());

        setOpen1(true);
        setUploading(false);
      }
    } catch (e) {
      dispatch(errorHandler(`File upload failed: ${e}`));
    }
  };

  //Determine what to render helper method
  const whichDocumentsAreRequired = () => {
    // Outcome example:
    // [detailing][engineering][inspection]
    // [y][n][y] -> render: arhitectural
    // [n][n][y] -> render: architectural,detailing,engineering
    // [n][y][n] -> render: architectural,detailing

    let requiredDocumentsOutcome: any[] = [];
    const templateServicesArray = [
      "detailingRequested",
      "engineeringRequested",
      "inspectionRequested",
    ];
    const requestedServicesArray = [
      job.detailingRequested,
      job.engineeringRequested,
      job.inspectionRequested,
    ];

    // find first true and return array of services before it
    for (let i = 0; i < requestedServicesArray.length; i++) {
      if (requestedServicesArray[i] === true) {
        requiredDocumentsOutcome = templateServicesArray.slice(0, i);
        break;
      }
    }
    return requiredDocumentsOutcome;
  };

  useEffect(() => {
    const requiredDocs = whichDocumentsAreRequired();
    // console.log(requiredDocs);

    setReqDocs(requiredDocs);
  }, []);

  //Reset alert on file selection
  useEffect(() => {
    if (open1) {
      setOpen1(false);
    }
  }, [architecturalFiles,
    detailingFiles,
    engineeringFiles,
    inspectionFiles]);

  return (
    <div className={classes.mainContainer}>
      <InstructionsContainer instructionsText={stagesText.stage1} />
      {/* Uncomment this if you want to debug something */}
      {/* <pre>{JSON.stringify(job, null, 2)}</pre> */}
      <FileSelection
        title="Architectural documents"
        instructions="Please upload Architectural documents"
        fileType="architecturaldoc"
        selectedFiles={architecturalFiles}
        setSelectedFiles={setArchitecturalFiles}
      />

      {/* Which DOCUMENTS needs to be uploaded LOGIC starts here */}
      {reqDocs.includes("detailingRequested") && (
        <FileSelection
          title="Detailing documents"
          instructions="Please upload detailing documents"
          fileType="detailingdoc"
          selectedFiles={detailingFiles}
          setSelectedFiles={setDetailingFiles}
        />
      )}
      {reqDocs.includes("engineeringRequested") && (
        <FileSelection
          title="Engineering documents"
          instructions="Please upload engineering documents"
          fileType="engineeringdoc"
          selectedFiles={engineeringFiles}
          setSelectedFiles={setEngineeringFiles}
        />
      )}
      {reqDocs.includes("inspectionRequested") && (
        <FileSelection
          title="Inspection documents"
          instructions="Please upload inspection documents"
          fileType="inspectiondoc"
          selectedFiles={inspectionFiles}
          setSelectedFiles={setInspectionFiles}
        />
      )}
      {/* Which DOCUMENTS needs to be uploaded LOGIC ends here */}

      <div className={classes.toDoBottomContainer}>
        <Button
          onClick={() => submitHandler()}
          className={classes.button}
          color="primary"
          variant="contained"
          size="small"
          disabled={uploading}
        >
          upload
        </Button>
        {uploading && (
          <div className={classes.linearIndeterminate}>
            <LinearProgress />
          </div>
        )}
        {open1 && (
          <AlertMessage
            alertWidth="500px"
            open={open1}
            setOpen={setOpen1}
            color="info"
            message="File(s) uploaded successfully!"
          />
        )}
      </div>
      <Divider className={classes.divider2} />
      <ApproveOrReject
        job={job}
        reject={false}
        checkboxText="I have uploaded all necessary documentation."
      />
    </div>
  );
};

export default Client;
