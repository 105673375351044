import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useFormik } from "formik";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";

import "./JobDetail.css";

import { makeStyles } from "@material-ui/core";
import Stage from "../Stage/Stage";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { addCurentJob, doYouNeedRefetch } from "../../store/actions";
import { useLazyQuery } from "@apollo/client";
import { QUERY_JOB_BY_ID } from "../JobList/query";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  AddJobsButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  backArrow: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "50%",
    fontSize: "25px",
  },
  checkBoxesContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: "3px",
    marginBottom: "3px",
  },
  viewJobContainer: {
    padding: "10px",
    paddingBottom: "0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    maxWidth: "700px",
  },
  viewSide: {
    padding: "10px",
    paddingBottom: "5px",
    width: "100%",
  },
  formRow: {
    marginBottom: "10px",
  },
  centerMe: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px",
  },
  editButton: {
    marginLeft: "20px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  optionsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    marginBottom: "25px",
  },
  AccordionDetails: {
    paddingLeft: "10%",
  },
  AccordionDetailsForFiles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowX: "auto",
  },
  customPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  accordionContainer: {
    maxWidth: "580px",
  },
  detailsContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  labelClass: {
    backgroundColor: theme.palette.primary.main,
    padding: "3px",
    color: "#fff",
    minWidth: "350px",
    fontSize: "20px",
    borderRadius: "1px",
  },
}));

interface JobDetailProps {
  jobProp: any;
}

//Block
const initialState = {
  architecturaldoc: [],
  detailingdoc: [],
  engineeringdoc: [],
  inspectiondoc: [],
  designform: [],
  prelimdesignform: [],
  inspectionform: [],
};

export enum ActionTypes {
  prelimdesignform = "prelimdesignform",
  designform = "designform",
  inspectionform = "inspectionform",
  architecturaldoc = "architecturaldoc",
  detailingdoc = "detailingdoc",
  engineeringdoc = "engineeringdoc",
  inspectiondoc = "inspectiondoc",
}

function reducer(state: any, action: any) {
  switch (action.type) {
    case ActionTypes.architecturaldoc:
      return {
        ...state,
        architecturaldoc: action.payload,
      };
    case ActionTypes.detailingdoc:
      return {
        ...state,
        detailingdoc: action.payload,
      };
    case ActionTypes.engineeringdoc:
      return {
        ...state,
        engineeringdoc: action.payload,
      };
    case ActionTypes.inspectiondoc:
      return {
        ...state,
        inspectiondoc: action.payload,
      };
    case ActionTypes.inspectionform:
      return {
        ...state,
        inspectionform: action.payload,
      };
    case ActionTypes.designform:
      return {
        ...state,
        designform: action.payload,
      };
    case ActionTypes.prelimdesignform:
      return {
        ...state,
        prelimdesignform: action.payload,
      };
    default:
      return state;
  }
}
//Block end

const JobDetail: React.FC<JobDetailProps> = ({ jobProp }) => {
  const history = useHistory();
  const [job, setJob] = useState(jobProp);
  const dispatch_redux = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [state, dispatch] = useReducer(reducer, initialState);

  // console.log("job detail", job);

  //Get Job documents list
  const ListJobDocuments = async (doctype: ActionTypes) => {
    const token = await getAccessTokenSilently();

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const my_uri = `${process.env.REACT_APP_BACKEND_BASE_URL}/jobdocument/${job.id}/${doctype}`;

    const res = await axios.get(my_uri, config);
    dispatch({ type: ActionTypes[doctype], payload: res.data });
  };

  const currentState: any = useSelector((state: RootState) => state.jobs);

  useEffect(() => {
    const customAsyncUseEffect = async () => {
      await ListJobDocuments(ActionTypes.inspectiondoc);
      await ListJobDocuments(ActionTypes.inspectionform);
      await ListJobDocuments(ActionTypes.architecturaldoc);
      await ListJobDocuments(ActionTypes.detailingdoc);
      await ListJobDocuments(ActionTypes.engineeringdoc);
      await ListJobDocuments(ActionTypes.designform);
      await ListJobDocuments(ActionTypes.prelimdesignform);
    };
    customAsyncUseEffect();
  }, [job]);

  //This is used if user upload documents, so document tree is updated!
  useEffect(() => {
    if (currentState.doYouNeedRefetch) {
      const customAsyncUseEffect = async () => {
        await ListJobDocuments(ActionTypes.inspectiondoc);
        await ListJobDocuments(ActionTypes.inspectionform);
        await ListJobDocuments(ActionTypes.architecturaldoc);
        await ListJobDocuments(ActionTypes.detailingdoc);
        await ListJobDocuments(ActionTypes.engineeringdoc);
        await ListJobDocuments(ActionTypes.designform);
        await ListJobDocuments(ActionTypes.prelimdesignform);
      };
      customAsyncUseEffect();

      dispatch_redux(doYouNeedRefetch());
    }
  }, [currentState, dispatch_redux]);

  const addJobField = {
    jobNumber: "Job Number",
    builder: "Builder",
    deliveryDate: "Delivery Date",
    buildingDescription: "Building Description",
    storeys: "Number of Storeys",
    street: "Street",
    streetNumber: "Street Number",
    suburb: "Suburb",
    postcode: "Postcode",
    province: "State/Province",
    lot: "Lot",
    localGovernment: "Local Government",
    organisationName: "Organisation Name",
    address: "Address",
    services: "Services Requested",
    servicesIssued: "Services Issued",
    windRating: "Wind Rating",
  };

  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      jobNumber: job.jobNumber,
      builder: job.builder,
      deliveryDate: job.deliveryDate,
      buildingDescription: job.buildingDescription,
      storeys: job.storeys,
      organisationName: job.organisation.name,
      street: job.jobAddress.street,
      streetNumber: job.jobAddress.streetNumber,
      suburb: job.jobAddress.suburb,
      postcode: job.jobAddress.postcode,
      province: job.jobAddress.province,
      lot: job.jobAddress.lot,
      localGovernment: job.jobAddress.localGovernment,
      detailingRequested: job.detailingRequested,
      engineeringRequested: job.engineeringRequested,
      prelimDesignRequested: job.prelimDesignRequested,
      inspectionRequested: job.inspectionRequested,
      engineeringIssued: job.engineeringIssued,
      prelimDesignIssued: job.prelimDesignIssued,
      inspectionIssued: job.inspectionIssued,
      windRating: job.windRating,
    },
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const jobDetails = () => {

    const getServiceNames = (
      engineering?: boolean,
      prelimDesign?: boolean,
      inspection?: boolean,
      detailing?: boolean
    ) => {
      let services = "";
      if (detailing) {
        services = services + "Detailing\n";
      }
      if (engineering) {
        services = services + "Engineering\n";
      }
      if (prelimDesign) {
        services = services + "Preliminary Design Form\n";
      }
      if (inspection) {
        services = services + "Inspection";
      }

      if (
        !detailing &&
        !engineering &&
        !inspection
      ) {
        services = "No services applicable";
      }
      return services;
    };

    if (jobProp) {
      return (
        <div className={classes.detailsContainer}>
          <form
            onSubmit={formik.handleSubmit}
            className={classes.viewJobContainer}
          >
            {/* LEFT SIDE */}
            <div className={classes.viewSide}>
              {/*jobNumber*/}
              <TextField
                focused={false}
                className={classes.formRow}
                fullWidth
                multiline
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.labelClass }}
                id="jobNumber"
                name="jobNumber"
                label={addJobField.jobNumber}
                value={job.jobNumber}
              />
              {/*builder*/}
              <TextField
                focused={false}
                className={classes.formRow}
                fullWidth
                multiline
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.labelClass }}
                id="builder"
                name="builder"
                label={addJobField.builder}
                value={job.builder}
              />
              {/*description*/}
              <TextField
                focused={false}
                className={classes.formRow}
                fullWidth
                multiline
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{
                  className: classes.labelClass,
                  shrink: true,
                }}
                id="buildingDescription"
                name="buildingDescription"
                label={addJobField.buildingDescription}
                value={job.buildingDescription}
              />
              {/*storeys*/}
              <TextField
                focused={false}
                className={classes.formRow}
                fullWidth
                InputProps={{ disableUnderline: true, inputProps: {inputMode: 'numeric', pattern: '[0-9]*'} }}
                InputLabelProps={{
                  className: classes.labelClass,
                  shrink: true,
                }}
                id="storeys"
                name="storeys"
                label={addJobField.storeys}
                value={job.storeys}
              />
              {/*Lot*/}
              <TextField
                focused={false}
                className={classes.formRow}
                fullWidth
                multiline
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.labelClass }}
                id="lot"
                name="lot"
                label={addJobField.lot}
                value={job.jobAddress.lot}
              />
              {/*delivery date*/}
              <TextField
                focused={false}
                className={classes.formRow}
                fullWidth
                multiline
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.labelClass }}
                id="deliveryDate"
                name="deliveryDate"
                label={addJobField.deliveryDate}
                value={JSON.stringify(job.deliveryDate).slice(1, 11)}
              />
            </div>
            {/* RIGHT SIDE */}
            <div className={classes.viewSide}>
              {/*Requests*/}
              <TextField
                focused={false}
                className={classes.formRow}
                fullWidth
                multiline
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.labelClass }}
                id="services"
                name="services"
                label={addJobField.services}
                value={getServiceNames(
                  job.engineeringRequested,
                  job.prelimDesignRequested,
                  job.inspectionRequested,
                  job.detailingRequested
                )}
              />
              {/*Issued*/}
              <TextField
                focused={false}
                className={classes.formRow}
                fullWidth
                multiline
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.labelClass }}
                id="servicesIssued"
                name="servicesIssued"
                label={addJobField.servicesIssued}
                value={getServiceNames(
                  job.engineeringIssued,
                  job.prelimDesignIssued,
                  job.inspectionIssued
                )}
              />
              {/*Address*/}
              <TextField
                focused={false}
                className={classes.formRow}
                fullWidth
                multiline
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.labelClass }}
                id="street"
                name="street"
                label={addJobField.address}
                value={`${job.jobAddress.streetNumber} ${job.jobAddress.street}\n${job.jobAddress.suburb} ${job.jobAddress.province} ${job.jobAddress.postcode}`}
              />
              {/*Local government*/}
              <TextField
                focused={false}
                className={classes.formRow}
                fullWidth
                multiline
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.labelClass }}
                id="localGovernment"
                name="localGovernment"
                label={addJobField.localGovernment}
                value={job.jobAddress.localGovernment}
              />
              {/*Organisation name*/}
              <TextField
                focused={false}
                className={classes.formRow}
                fullWidth
                multiline
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.labelClass }}
                id="organisationName"
                name="organisationName"
                label={addJobField.organisationName}
                value={job.organisation.name}
              />
              {/*Wind Rating*/}
              <TextField
                focused={false}
                className={classes.formRow}
                fullWidth
                multiline
                InputProps={{ disableUnderline: true }}
                InputLabelProps={{ className: classes.labelClass }}
                id="windRating"
                name="windRating"
                label={addJobField.windRating}
                value={job.windRating}
              />
            </div>
          </form>
          <div className={classes.optionsContainer}>
            <Button
              size="small"
              className={classes.editButton}
              onClick={() => history.push("/edit-job", { job })}
            >
              Edit job
            </Button>
          </div>
        </div>
      );
    }
  };

  return useMemo(() => {
    return (
      <div className="JobDetail">
        <Paper className={classes.customPaper}>
          <Stage job={job} state={state} childrenComponent={jobDetails()} />
        </Paper>
      </div>
    );
  }, [reducer, ListJobDocuments, jobDetails]);
};

export default JobDetail;
