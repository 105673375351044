import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FileSelection from "../components/FileUpload/FileSelection";
import * as fileStorage from "../utils/fileStorage";
import { useDispatch } from "react-redux";
import { doYouNeedRefetch, errorHandler } from "../store/actions";
import AlertMessage from "../components/AlertMessage/AlertMessage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "20px",
    },
    providerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    uploadButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    divider2: {
      margin: "25px",
      marginTop: "10%",
    },
    linearIndeterminate: {
      marginTop: "10px",
      width: "90%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);

enum doctypenames {
  architecturaldoc = "architecturaldoc",
  detailingdoc = "detailingdoc",
  engineeringdoc = "engineeringdoc",
  inspectiondoc = "inspectiondoc",
}

function getDoctypeName(doctype: string) {
  switch (doctype) {
    case doctypenames.architecturaldoc:
      return "Architectural";
    case doctypenames.detailingdoc:
      return "Detailing";
    case doctypenames.engineeringdoc:
      return "Engineering";
    case doctypenames.inspectiondoc:
      return "Inspection";
    default:
      break;
  }
}

interface ProviderUploadProps {
  job: any;
  doctype: string;
}

const ProviderUpload: React.FC<ProviderUploadProps> = ({ job, doctype }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [open1, setOpen1] = useState<boolean>(false);

  const submitHandler = async () => {
    try {
      if (selectedFiles.length > 0) {
        const token = await getAccessTokenSilently();
        setUploading(true);
        await fileStorage.uploadJobDocument(
          selectedFiles,
          job.id,
          doctype,
          token
        );

        //Renew job documents tree
        dispatch(doYouNeedRefetch());

        setUploading(false);
        setOpen1(true);
      }
    } catch (e) {
      dispatch(errorHandler(`File upload failed: ${e}`));
    }
  };  


  //Reset alert on file selection
  useEffect(() => {
    if(open1){
      setOpen1(false);
    }
  }, [selectedFiles])

  let title = `${getDoctypeName(doctype)} documents`;
  let instructions = `Please upload ${getDoctypeName(doctype)} documents`;

  return (
    <div className={classes.providerContainer}>
      <FileSelection
        title={title}
        instructions={instructions}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        fileType={doctype}
      />
      <Button
        className={classes.uploadButton}
        onClick={() => submitHandler()}
        variant="contained"
        size="small"
        fullWidth={false}
        disabled={uploading}
      >
        upload
      </Button>
      {open1 && (
        <AlertMessage
          alertWidth="500px"
          open={open1}
          setOpen={setOpen1}
          color="info"
          message="File(s) uploaded successfully!"
        />
      )}
      {uploading && (
        <div className={classes.linearIndeterminate}>
          <LinearProgress />
        </div>
      )}
    </div>
  );
};

export default ProviderUpload;
