import {
    AuthenticationActionTypes, 
    RESET_AUTHENTICATION, 
    SET_ACCESS_TOKEN, 
    SET_IS_AUTHENTICATED
  } from './types'
  
  export function resetAuthentication(): AuthenticationActionTypes {
    return {
      type: RESET_AUTHENTICATION
    }
  }
  
  export function setIsAuthenticated(isAuthenticated: Boolean): AuthenticationActionTypes {
    return {
      type: SET_IS_AUTHENTICATED,
      payload: isAuthenticated
    }
  }
  
  export function setAccessToken(accessToken: string): AuthenticationActionTypes {
    return {
      type: SET_ACCESS_TOKEN,
      payload: accessToken
    }
  }
