import { ActionTypes } from "./types";
import { Dispatch } from "redux";

//USER ACTION NAMES
export interface AddCurrentUserAction {
  type: ActionTypes.addCurrentUser;
  payload: any;
}

export const addCurrentUser = (user: any) => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch({ type: ActionTypes.addCurrentUser, payload: user });
    };
  } catch (e) {
    console.log(e);
  }
};


