import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";

import {
  Button,
  createStyles,
  FormControl,
  LinearProgress,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import {
  useReadCurrentUserQuery,
  useUpdateCurrentUserMutation,
} from "../../generated/graphql";
import { useDispatch } from "react-redux";
import { errorHandler } from "../../store/actions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    userInfoContainer: {
      display: "flex",
    },
    userSecondaryInfo: {
      padding: "20px",
      margin: "1px",
      minHeight: "210px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
    userInfoLeftSide: {
      minWidth: "252px",
      marginRight:"15px"
    },
    userInfoRightSide: {
      minWidth: "252px",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "50%",
    },
    floatingLabelFocusStyle: {
      color: theme.palette.text.primary,
    },
    editInfoForm: {
      display: "flex",
      flexDirection: "column",
    },
    editIconContainer: {
      width: "5%",
      display: "flex",
      justifyContent: "flex-end",
    },
    editIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    infoLabel: {
      fontWeight: "bold",
    },
    alertClass: {
      color: "#fff",
      backgroundColor: "green",
      marginTop: "5px",
      padding: "0px 10px",
      width: "100%",
    },
    loadingSpinner: {
      width: "10px",
    },
    spinnerContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    linearIndeterminate: {
      marginTop: "10px",
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);

interface MainInfoProps {
  user: any;
}

const MainInfo: React.FC<MainInfoProps> = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [toggleEdit, setToggleEdit] = useState(false);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);

  const validationSchema = yup.object({
    addressLine1: yup.string().defined(),
    addressLine2: yup.string().defined(),
    country: yup.string().defined(),
    postcode: yup.string().defined(),
    province: yup.string().defined(),
    suburb: yup.string().defined(),
    contactPerson: yup.string().defined(),
    firstName: yup.string().defined(),
    lastName: yup.string().defined(),
    licenseClass: yup.string().defined(),
    licenseNumber: yup.string().defined(),
    victoriaLicenseNumber: yup.string(),
    licenseArea: yup.string().defined(),
    mobileNumber: yup.string().defined(),
    phone: yup.string().defined(),
    position: yup.string().defined(),
  });

  enum filterField {
    addressLine1 = "Address Line 1",
    addressLine2 = "Address Line 2",
    country = "Country",
    postcode = "Post Code",
    province = "State",
    suburb = "Suburb",
    contactPerson = "Contact Person",
    firstName = "First Name",
    lastName = "Last Name",
    licenseClass = "License Class",
    licenseNumber = "RPEQ License Number",
    victoriaLicenseNumber = "RPE (VIC) License Number",
    licenseArea = "License Area of Engineering",
    mobileNumber = "Mobile Number",
    phone = "Phone",
    position = "Position",
  }

  const formik = useFormik({
    initialValues: {
      addressLine1: "",
      addressLine2: "",
      country: "",
      postcode: "",
      province: "",
      suburb: "",
      contactPerson: "",
      firstName: "",
      lastName: "",
      licenseClass: "",
      licenseNumber: "",
      victoriaLicenseNumber: "",
      licenseArea: "",
      mobileNumber: "",
      phone: "",
      position: "",
      userId: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      try {
        await updateUserMutation();
        setShowAlert(true);
      } catch (error) {
        dispatch(
          errorHandler(`${error.message}, check your internet connection`)
        );
      }
    },
  });

  const [
    updateUserMutation,
    updateUserMutationResponse,
  ] = useUpdateCurrentUserMutation({
    variables: {
      user: {
        address: {
          addressLine1: formik.values.addressLine1,
          addressLine2: formik.values.addressLine2,
          country: formik.values.country,
          postcode: formik.values.postcode,
          province: formik.values.province,
          suburb: formik.values.suburb,
        },
        contactPerson: formik.values.contactPerson,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        licenseClass: formik.values.licenseClass,
        licenseNumber: formik.values.licenseNumber,
        victoriaLicenseNumber: formik.values.victoriaLicenseNumber,
        licenseArea: formik.values.licenseArea,
        mobileNumber: formik.values.mobileNumber,
        phone: formik.values.phone,
        position: formik.values.position,
      },
      userId: formik.values.userId,
    },
  });

  const getCurrentUser = useReadCurrentUserQuery();

  useEffect(() => {
    // console.log("ttttt", getCurrentUser);
    if (getCurrentUser.data?.readCurrentUser) {
      const userFromDb = getCurrentUser.data.readCurrentUser;
      if (userFromDb.address) {
        formik.setFieldValue("addressLine1", userFromDb.address.addressLine1);
        formik.setFieldValue("addressLine2", userFromDb.address.addressLine2);
        formik.setFieldValue("country", userFromDb.address.country);
        formik.setFieldValue("postcode", userFromDb.address.postcode);
        formik.setFieldValue("province", userFromDb.address.province);
        formik.setFieldValue("suburb", userFromDb.address.suburb);
        formik.setFieldValue("contactPerson", userFromDb.contactPerson);
        formik.setFieldValue("firstName", userFromDb.firstName);
        formik.setFieldValue("lastName", userFromDb.lastName);
        formik.setFieldValue("licenseClass", userFromDb.licenseClass);
        formik.setFieldValue("licenseNumber", userFromDb.licenseNumber);
        formik.setFieldValue("licenseArea", userFromDb.licenseArea);
        formik.setFieldValue("victoriaLicenseNumber", userFromDb.victoriaLicenseNumber);
        formik.setFieldValue("mobileNumber", userFromDb.mobileNumber);
        formik.setFieldValue("phone", userFromDb.phone);
        formik.setFieldValue("position", userFromDb.position);
        formik.setFieldValue("userId", userFromDb.id);

        // alert(JSON.stringify(formik.values, null, 2));
      }
    }
  }, [getCurrentUser.data]);

  //Close alert on value change
  useEffect(() => {
    setShowAlert(false);
  }, [formik.values]);

  const renderUserInfo = () => {
    if (getCurrentUser.data?.readCurrentUser) {
      const userFromDb = getCurrentUser.data.readCurrentUser;
      if (userFromDb.address) {
        // console.log("USER FROM DB, ", userFromDb);

        return (
          <>
            <div className={classes.userInfoLeftSide}>
              <Typography component="h5" variant="body2">
                <span className={classes.infoLabel}>First Name</span>{" "}
                {userFromDb.firstName}
              </Typography>
              <Typography component="h5" variant="body2">
                <span className={classes.infoLabel}>Last Name</span>{" "}
                {userFromDb.lastName}
              </Typography>
              <Typography component="h5" variant="body2">
                <span className={classes.infoLabel}>Position</span>{" "}
                {userFromDb.position}
              </Typography>
              <Typography component="h5" variant="body2">
                <span className={classes.infoLabel}>Mobile Number</span>{" "}
                {userFromDb.mobileNumber}
              </Typography>
              <Typography component="h5" variant="body2">
                <span className={classes.infoLabel}>Contact Person</span>{" "}
                {userFromDb.contactPerson}
              </Typography>
              {userFromDb.organisation && (
                <Typography component="h5" variant="body2">
                  <span className={classes.infoLabel}>Organisation</span>{" "}
                  {userFromDb.organisation.name}
                </Typography>
              )}
            </div>
            <div className={classes.userInfoRightSide}>
              <Typography component="h5" variant="body2">
                <span className={classes.infoLabel}>RPEQ Licence No.</span>{" "}
                {userFromDb.licenseNumber}
              </Typography>
              <Typography component="h5" variant="body2">
                <span className={classes.infoLabel}>Victoria Licence No.</span>{" "}
                {userFromDb.victoriaLicenseNumber}
              </Typography>
              <Typography component="h5" variant="body2">
                <span className={classes.infoLabel}>License Area of Engineering</span>{" "}
                {userFromDb.licenseArea}
              </Typography>
              <Typography component="h5" variant="body2">
                <span className={classes.infoLabel}>License Class</span>{" "}
                {userFromDb.licenseClass}
              </Typography>
              <Typography component="h5" variant="body2">
                <span className={classes.infoLabel}>Address</span>
                <br />
                {userFromDb.address.addressLine1}{" "}
                {userFromDb.address.addressLine2}
                <br />
                {userFromDb.address.province} {userFromDb.address.postcode}
                <br />
                {userFromDb.address.country}
              </Typography>
            </div>
          </>
        );
      }
    } else {
      return (
        <div className={classes.spinnerContainer}>
          <CircularProgress className={classes.loadingSpinner} size={30} />
          Loading user info...
        </div>
      );
    }
  };

  if (user.nickname === undefined) {
    return <></>;
  }

  return (
    <Paper className={classes.userSecondaryInfo}>
      {toggleEdit && (
        <form onSubmit={formik.handleSubmit} className={classes.editInfoForm}>
          <div className={classes.editIconContainer}>
            <CloseIcon
              color="primary"
              className={classes.editIcon}
              onClick={() => {
                setShowAlert(false);
                setToggleEdit((prev) => !prev);
              }}
            />
          </div>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="addressLine1"
              name="addressLine1"
              label={filterField.addressLine1}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.addressLine1}
              onChange={formik.handleChange}
              error={
                formik.touched.addressLine1 &&
                Boolean(formik.errors.addressLine1)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="addressLine2"
              name="addressLine2"
              label={filterField.addressLine2}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.addressLine2}
              onChange={formik.handleChange}
              error={
                formik.touched.addressLine2 &&
                Boolean(formik.errors.addressLine2)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="country"
              name="country"
              label={filterField.country}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.country}
              onChange={formik.handleChange}
              error={formik.touched.country && Boolean(formik.errors.country)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="postcode"
              name="postcode"
              label={filterField.postcode}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.postcode}
              onChange={formik.handleChange}
              error={formik.touched.postcode && Boolean(formik.errors.postcode)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="province"
              name="province"
              label={filterField.province}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.province}
              onChange={formik.handleChange}
              error={formik.touched.province && Boolean(formik.errors.province)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="suburb"
              name="suburb"
              label={filterField.suburb}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.suburb}
              onChange={formik.handleChange}
              error={formik.touched.suburb && Boolean(formik.errors.suburb)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="contactPerson"
              name="contactPerson"
              label={filterField.contactPerson}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.contactPerson}
              onChange={formik.handleChange}
              error={
                formik.touched.contactPerson &&
                Boolean(formik.errors.contactPerson)
              }
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label={filterField.firstName}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label={filterField.lastName}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="licenseClass"
              name="licenseClass"
              label={filterField.licenseClass}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.licenseClass}
              onChange={formik.handleChange}
              error={
                formik.touched.licenseClass &&
                Boolean(formik.errors.licenseClass)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="licenseNumber"
              name="licenseNumber"
              label={filterField.licenseNumber}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.licenseNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.licenseNumber &&
                Boolean(formik.errors.licenseNumber)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="victoriaLicenseNumber"
              name="victoriaLicenseNumber"
              label={filterField.victoriaLicenseNumber}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.victoriaLicenseNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.victoriaLicenseNumber &&
                Boolean(formik.errors.victoriaLicenseNumber)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="licenseArea"
              name="licenseArea"
              label={filterField.licenseArea}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.licenseArea}
              onChange={formik.handleChange}
              error={
                formik.touched.licenseArea &&
                Boolean(formik.errors.licenseArea)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="mobileNumber"
              name="mobileNumber"
              label={filterField.mobileNumber}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.mobileNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.mobileNumber &&
                Boolean(formik.errors.mobileNumber)
              }
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="phone"
              name="phone"
              label={filterField.phone}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              id="position"
              name="position"
              label={filterField.position}
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              value={formik.values.position}
              onChange={formik.handleChange}
              error={formik.touched.position && Boolean(formik.errors.position)}
            />
          </FormControl>

          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => formik.submitForm()}
          >
            Update
          </Button>
          {updateUserMutationResponse.loading && (
            <div className={classes.linearIndeterminate}>
              <LinearProgress />
            </div>
          )}
          {showAlert && (
            <Alert
              className={classes.alertClass}
              onClose={() => {
                setShowAlert(false);
              }}
            >
              User info updated!
            </Alert>
          )}
        </form>
      )}

      {!toggleEdit && (
        <div className={classes.userInfoContainer}>
          {renderUserInfo()}
          <div className={classes.editIconContainer}>
            <EditIcon
              color="primary"
              className={classes.editIcon}
              onClick={() => setToggleEdit((prev) => !prev)}
            />
          </div>
        </div>
      )}
    </Paper>
  );
};

export default MainInfo;
