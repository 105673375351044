import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { renderDateAndTime } from "../../utils/helpers";
import useStyles from "./jobCommentsStyles";

const JobComment = ({ comment }: any) => {
  const classes = useStyles();

  return (
    <Paper className={classes.commentContainer} elevation={2}>
      <Typography
        style={{ fontSize: "12px", marginBottom: "5px" }}
        component="h6"
        variant="body2"
      >
        {`${comment.user.firstName} ${comment.user.lastName} - ${renderDateAndTime(comment.dateTime)}`}
      </Typography>
      <Typography
        className={classes.commentText}
        component="h6"
        variant="body2"
      >
        {comment.text}
      </Typography>
    </Paper>
  );
};

export default JobComment;
