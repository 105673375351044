import { useLazyQuery } from "@apollo/client";
import {Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { READ_USER_TASKS } from "../JobList/query";
import TaskCard from "./TaskCard";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import TasksFilter from "./TasksFilter";
import { useStyles } from "./taskStyles";

const TasksPipeline = () => {
  const classes = useStyles();
  const [tasks, setTasks] = useState([]);
  const currentState: any = useSelector((state: RootState) => state.jobs);

  // 1. Fetch all tasks for this user. *both assignedBy assignedTo
  // 2. Have 3 local states: for different task states.
  // 3. Render tasks for each local state in its own column, use <Task /> component

  //1
  const [allTasks, allTasksResponse] = useLazyQuery(READ_USER_TASKS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    allTasks();
  }, [currentState.doYouNeedRefetch]);

  useEffect(() => {
    if (allTasksResponse.data) {
      setTasks(allTasksResponse.data.readUserTasks);
    }
  }, [allTasksResponse.data]);

  //2
  const [outstandingTasks, setOutstandingTasks] = useState<any[]>([]);
  const [inProgressTasks, setInProgressTasks] = useState<any[]>([]);
  const [finishedTasks, setFinishedTasks] = useState<any[]>([]);

  useEffect(() => {
    if (tasks) {
      let tmpOutstanding: any[] = [];
      let tmpInProgress: any[] = [];
      let tmpFinished: any[] = [];
      tasks.forEach((task: any) => {
        if (task.taskStatus === "ASSIGNED") {
          tmpOutstanding.push(task);
        } else if (task.taskStatus === "IN_PROGRESS") {
          tmpInProgress.push(task);
        } else {
          tmpFinished.push(task);
        }
      });
      setOutstandingTasks(tmpOutstanding);
      setInProgressTasks(tmpInProgress);
      setFinishedTasks(tmpFinished);
    }
  }, [tasks]);

  // 4 Set tasks from filter component
  const getTasksFromFilter = (filteredTasks:any) =>{
    setTasks(filteredTasks);
  }

  return (
    <div>
      <TasksFilter passTasksMethod={getTasksFromFilter} />

      <div className={classes.pipelineContainer}>
        <div className={classes.pipelineColumn}>
          <Typography
            className={classes.columHeading}
            variant="h6"
            component="h6"
          >
            Outstanding
          </Typography>
          {outstandingTasks.map((task) => {
            return <TaskCard task={task} key={task.id} />;
          })}
        </div>
        <div className={classes.pipelineColumn}>
          <Typography
            className={classes.columHeading}
            variant="h6"
            component="h6"
          >
            In progress
          </Typography>
          {inProgressTasks.map((task) => {
            return <TaskCard task={task} key={task.id} />;
          })}
        </div>
        <div className={classes.pipelineColumn}>
          <Typography
            className={classes.columHeading}
            variant="h6"
            component="h6"
          >
            Finished
          </Typography>
          {finishedTasks.map((task) => {
            return <TaskCard task={task} key={task.id} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default TasksPipeline;
