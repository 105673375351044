import { createStyles, Divider, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import ApproveOrReject from "../ApproveOrReject";
import ProviderUpload from "../ProviderUpload";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider2:{
      margin:"25px 0px"
    }
  })
);

const Provider = ({job}:any) => {
  const classes = useStyles();
  return (
    <div>
      <ProviderUpload job={job} doctype="detailingdoc" />
      <Divider className={classes.divider2} />
      <ApproveOrReject job={job} reject={true} checkboxText="I agree that I checked all documentation for this stage." />
    </div>
  );
};

export default Provider;
