import { Action, ActionTypes } from "./types";
import { Dispatch } from "redux";

//USER ACTION NAMES
export interface ChangeValueHandlerAction2 {
  type: ActionTypes.changeValueHandler2;
  payload: {
    key: string;
    value: string;
  };
}
export interface ChangeAllFormValuesAction2 {
  type: ActionTypes.changeAllFormValues2;
  payload: any;
}

export interface SelectAllInspectionDetailsForm{
  type: ActionTypes.selectAllInspectionDetailsForm;
  payload: {
    reference_documentation: string[];
  }
}

export const changeAllFormValues2 = (job: any) => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch({ 
        type: ActionTypes.changeAllFormValues2,
        payload: job });
    };
  } catch (e) {
    console.log(e);
  }
};

export const changeValueHandler2 = (key: any, value: any) => {
  try {
    // console.log(key, value);
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ActionTypes.changeValueHandler2,
        payload: { key, value },
      });
    };
  } catch (e) {
    console.log(e);
  }
};

export const selectAllInspectionDetailsForm = (
  reference_documentation:string[],
) => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ActionTypes.selectAllInspectionDetailsForm,
        payload: {
          reference_documentation : [...reference_documentation]
        },
      });
    };
  } catch (e) {
    console.log(e);
  }
};
