import React, { useEffect, useRef } from "react";
import * as yup from "yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
	Divider,
	Checkbox,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Button,
	Tooltip,
	TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { changeAllFormValues1, changeValueHandler1, errorHandler, clearFormValues1, selectAllEngineeringForm } from "../../store/actions";
import Checkboxes from "./CommonFormComponents/CheckBoxes";
import { RootState } from "../../store";
import { useUpdateJobEngineeringFormDataMutation } from "../../generated/graphql";
import Alert from "@material-ui/lab/Alert";
import {
	components_buttons,
	basis_of_certification_buttons,
	reference_documentation_buttons,
} from "./CommonFormComponents/inspectionDetailsFormButtons";
import { useStyles } from "./styles";
import RadioButtons from "./CommonFormComponents/RadioButtons";
import buildingClasses from "./CommonFormComponents/buildingClasses.png";
import HelpIcon from "@material-ui/icons/Help";
import Comment from "./CommonFormComponents/Comment";

interface IEngineeringDetailsForm {
	job: any;
	setAdvanceEnabled: any;
}
export const EngineeringDetailsForm: React.FC<IEngineeringDetailsForm> = ({ job, setAdvanceEnabled }) => {
	const engineeringDetailsField = {
		partOfBuilding: "Part of Building",
		riseInStoreys: "Rise in Storeys",
		effectiveHeight: "Effective Height",
		typeOfConstruction: "Type of Construction",
	};
	const classes = useStyles();
	const dispatch = useDispatch();
	const currentState: any = useSelector((state: RootState) => state.engineeringDetailsForm);
	const [errorArray, setErrorArray] = React.useState<string[]>([]);
	const [saveButton, setSaveButton] = React.useState<boolean>(true);
	const [showAlert, setShowAlert] = React.useState<boolean>(false);
	const [generateForm, response] = useUpdateJobEngineeringFormDataMutation();
	let existingFormData: any = null;
	const getExistingFormData = () => {
		if (job.jobEngineering?.data) {
			existingFormData = JSON.parse(job.jobEngineering.data);
		}
	};

	const renderCount = useRef(0);
	useEffect(() => {
		//console.log("---------------------------------------------------------------");
		//console.log("Current state: ", currentState.basis_of_certification);
		//setCount(count + 1);

		setSaveButton(true);
		setShowAlert(false);

		if (renderCount.current > 0) {
			setSaveButton(false);
		} else {
			renderCount.current = renderCount.current + 1;
		}
	}, [currentState]);

	useEffect(() => {
		if (renderCount.current > 0) {
			setSaveButton(false);
		} else {
			renderCount.current = renderCount.current + 1;
		}
	}, [currentState]);

	const responseRef = useRef(true);
	useEffect(() => {
		if (response.data?.updateJobEngineeringFormData) {
			if (responseRef.current === true) {
				setShowAlert(true);
				responseRef.current = false;
			}
		}
	}, [response]);

	useEffect(() => {
		getExistingFormData();
		if (existingFormData !== null) {
			dispatch(changeAllFormValues1(existingFormData));
			dispatch(changeValueHandler1("declaration", false)); //Invalidate declaration
		} else {
			dispatch(clearFormValues1());
		}
	}, [existingFormData]);

	const onCheckBoxChange = (fieldName: string, event: any) => {
		//console.log(event.target.checked);
		try {
			dispatch(changeValueHandler1("declaration", event.target.checked));
		} catch (e: any) {
			dispatch(errorHandler(e));
		}
	};

	const validationSchema = yup.object({
		class_of_building_structure: yup.string().required(),
		construction_type: yup.string().required(),
		part_of_building: yup.string().required(),
		rise_in_storeys: yup.string(),
		effective_height: yup.string(),
		type_of_construction: yup.string(),
		declaration: yup.boolean().required().oneOf([true]),
	});

	const validationHandler = async () => {
		let new_error_array: string[] = [];
		//console.log(currentState);
		let isValid = await validationSchema
			.validate(currentState, {
				abortEarly: false,
			})
			.then(() => {
				return true;
			})
			.catch((err) => {
				//console.log(err);
				err.errors.map((e_string: any) => {
					const e_array = e_string.split(" ");
					new_error_array.push(e_array[0]);
				});
				return false;
			});
		setErrorArray(new_error_array);
		return isValid;
	};

	const generateHandler = async () => {
		let getValidity = await validationHandler();

		// Validate standards
		let validStandarts: string[] = [];
		currentState.basis_of_certification.map((standart: any) => {
			let isValid = basis_of_certification_buttons.find((validStd) => validStd === standart);
			if (isValid) {
				validStandarts.push(standart);
				//console.log("Standart: ", standart);
			}
		});
		//console.log("CurrentState.basis_of_certifications: ", currentState.basis_of_certification);
		//console.log("Valid Standards: ", validStandarts);
		currentState.basis_of_certification = validStandarts;

		if (getValidity) {
			//console.log("Updated state: ", currentState.basis_of_certification);

			try {
				generateForm({
					variables: {
						jobId: job.id,
						formData: {
							data: JSON.stringify(currentState),
						},
					},
				});
				responseRef.current = true;
				setSaveButton(true);
				//dispatch(changeValueHandler1("declaration", false)); //Invalidate declaration
				setAdvanceEnabled(true);
			} catch (error) {
				console.log("Error: ", error);
			}
		}
	};

	// ------------------------- //
	//  ENGINEERING DETAILS FORM //
	// ------------------------- //

	return (
		<form className={classes.formContainer}>
			<div className={classes.formMainHeading}>
				<Typography component={"span"} variant={"body2"}>
					Engineering details
				</Typography>
			</div>
			{/* Details */}
			<Accordion className={classes.accordionContainer}>
				<AccordionSummary className={classes.formSubHeading} expandIcon={<ExpandMoreIcon color="secondary" />}>
					<Typography component={"span"} variant={"body2"}>
						Details
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.AccordionDetails}>
					<div className={classes.substatus_row}>
						<RadioButtons
							children={
								<a target="blank" href="https://www.qbcc.qld.gov.au/building-codes-australia-bca-classes-buildings">
									<Tooltip title={<img style={{ maxWidth: "980px" }} src={buildingClasses} />}>
										<HelpIcon color="primary" className={classes.helpIcon} />
									</Tooltip>
								</a>
							}
							buttons={["1a", "1b", "2", "3", "4", "5", "6", "7a", "8", "9a", "9b", "9c", "10a", "10b", "10c"]}
							group_label="Class of Building"
							name="class_of_building_structure"
							defaultValue={currentState.class_of_building_structure}
							directionRow={true}
							reducer_name="engineeringDetailsFormReducer"
						/>
					</div>
					{errorArray.includes("class_of_building_structure") && <p className={classes.error_msg}>*required</p>}
					<div className={classes.substatus_row}>
						<RadioButtons
							buttons={["New building", "Extension", "Alteration", "Change of use", "Demolition", "Removal", "Re-erection of a building"]}
							group_label="Construction Type"
							name="construction_type"
							defaultValue={currentState.construction_type}
							directionRow={true}
							reducer_name="engineeringDetailsFormReducer"
						/>
					</div>
					{errorArray.includes("construction_type") && <p className={classes.error_msg}>*required</p>}
					<div className={classes.substatus_row}>
						<Typography component={"span"} variant={"body2"}>
							{engineeringDetailsField.partOfBuilding}
						</Typography>
						<Comment name="part_of_building" defaultValue={currentState.part_of_building} reducer_name="engineeringDetailsFormReducer" />
					</div>
					{errorArray.includes("part_of_building") && <p className={classes.error_msg}>*required</p>}
					<div className={classes.substatus_row}>
						<Typography component={"span"} variant={"body2"}>
							{engineeringDetailsField.riseInStoreys}
						</Typography>
						<Comment name="rise_in_storeys" defaultValue={currentState.rise_in_storeys} reducer_name="engineeringDetailsFormReducer" />
					</div>
					<div className={classes.substatus_row}>
						<Typography component={"span"} variant={"body2"}>
							{engineeringDetailsField.effectiveHeight}
						</Typography>
						<Comment name="effective_height" defaultValue={currentState.effective_height} reducer_name="engineeringDetailsFormReducer" />
					</div>
					<div className={classes.substatus_row}>
						<Typography component={"span"} variant={"body2"}>
							{engineeringDetailsField.typeOfConstruction}
						</Typography>
						<Comment
							name="type_of_construction"
							defaultValue={currentState.type_of_construction}
							reducer_name="engineeringDetailsFormReducer"
						/>
					</div>
				</AccordionDetails>
			</Accordion>
			{/* Components */}
			<Accordion className={classes.accordionContainer}>
				<AccordionSummary className={classes.formSubHeading} expandIcon={<ExpandMoreIcon color="secondary" />}>
					<Typography component={"span"} variant={"body2"}>
						Components
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.AccordionDetails}>
					<div className={classes.substatus_row}>
						<Checkboxes
							buttons={components_buttons}
							group_label="Components"
							name="components"
							values={currentState.components}
							directionRow={false}
							reducer_name="engineeringDetailsFormReducer"
						/>
					</div>
				</AccordionDetails>
			</Accordion>
			{/* BASIS OF CERTIFICATION */}
			<Accordion className={classes.accordionContainer}>
				<AccordionSummary className={classes.formSubHeading} expandIcon={<ExpandMoreIcon color="secondary" />}>
					<Typography component={"span"} variant={"body2"}>
						Basis of Certification
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.AccordionDetails}>
					<div className={classes.substatus_row}>
						<Checkboxes
							buttons={basis_of_certification_buttons}
							group_label="Basis of certification"
							name="basis_of_certification"
							values={currentState.basis_of_certification}
							directionRow={false}
							reducer_name="engineeringDetailsFormReducer"
						/>
					</div>
				</AccordionDetails>
			</Accordion>
			{/* REFERENCE DOCUMENTATION */}
			{job.jobAddress.province !== "Victoria" && (
				<Accordion className={classes.accordionContainer}>
					<AccordionSummary className={classes.formSubHeading} expandIcon={<ExpandMoreIcon color="secondary" />}>
						<Typography component={"span"} variant={"body2"}>
							Reference Documentation
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.AccordionDetails}>
						<div className={classes.substatus_row}>
							<Checkboxes
								buttons={reference_documentation_buttons}
								group_label="Reference documentation"
								name="reference_documentation"
								values={currentState.reference_documentation}
								directionRow={false}
								reducer_name="engineeringDetailsFormReducer"
							/>
						</div>
					</AccordionDetails>
				</Accordion>
			)}
			{/* OUTCOME */}
			{
				<Button
					onClick={() => {
						dispatch(selectAllEngineeringForm(components_buttons, basis_of_certification_buttons, reference_documentation_buttons));
					}}
					className={classes.selectorButton}
					variant="text"
				>
					Select all
				</Button>
			}
			<div className={classes.checkBoxesContainer}>
				<div className={classes.checkBoxRow}>
					<Checkbox
						onChange={(value) => onCheckBoxChange("Agree", value)}
						color="primary"
						className={classes.checkBox}
						value={currentState.declaration}
						inputProps={{ "aria-label": "secondary checkbox" }}
					/>
					<Typography component="h6" variant="body2" color="textPrimary">
						I have checked all required fields.
					</Typography>
				</div>
				{errorArray.includes("declaration") && <p className={classes.error_msg}>*required</p>}
			</div>

			<Divider className={classes.divider2} />

			<div className={classes.generateButtonContainer}>
				<Button
					color="primary"
					variant="contained"
					size="small"
					onClick={() => generateHandler()}
					disabled={saveButton || !currentState.declaration}
				>
					Save
				</Button>
				{showAlert && (
					<Alert
						className={classes.alertClass}
						onClose={() => {
							setShowAlert(false);
						}}
					>
						Form has been saved successfully!
					</Alert>
				)}
				{errorArray.length > 0 && <p className={classes.error_msg}>You havent checked some required fields</p>}
			</div>
		</form>
	);
};

export default EngineeringDetailsForm;
