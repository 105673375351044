import {
  FetchJobsAction,
  AddNewJobAction,
  FilterJobsAction,
  ChangePageAction,
  ChangeRowsPerPageAction,
  SaveFilterStateAction,
  ResetPageAction,
  DoYouNeedRefetchAction,
  ErrorHandlerAction,
  ClickOnJobHandlerAction,
  AdvanceJobHandlerAction,
  RejectJobHandlerAction,
  AddCurrentJobAction,
  SaveJobListColumnSettings
} from "./job";
import {
  AddCurrentUserAction
} from "./user"
import {
  ChangeValueHandlerAction,
  ChangeAllFormValuesAction,
  ClearFormAction
} from "./inspectionChecklistFormActions"
import {
  ChangeValueHandlerAction1,
  ChangeAllFormValuesAction1,
  SelectAllEngineeringForm,
} from "./engineeringDetailsFormActions"
import {
  ChangeValueHandlerAction2,
  ChangeAllFormValuesAction2,
  SelectAllInspectionDetailsForm
} from "./inspectionDetailsFormActions"


export enum ActionTypes {
  fetchJobs,
  addJob,
  filterJobs,
  changePage,
  changeRowsPerPage,
  saveFilterStateAction,
  resetPage,
  doYouNeedRefetch,
  errorHandler,
  addCurrentUser,
  clickOnJob,
  advanceJob,
  rejectJob,
  changeValueHandler,
  changeValueHandler1,
  changeValueHandler2,
  clearForm,
  addCurrentJob,
  changeAllFormValues,
  changeAllFormValues1,
  changeAllFormValues2,
  saveJobListColumnSettings,
  selectAllEngineeringForm,
  selectAllInspectionDetailsForm
}

export type Action =
  | FetchJobsAction
  | AddNewJobAction
  | FilterJobsAction
  | ChangePageAction
  | ChangeRowsPerPageAction
  | SaveFilterStateAction
  | ResetPageAction
  | DoYouNeedRefetchAction
  | ErrorHandlerAction
  | AddCurrentUserAction
  | ClickOnJobHandlerAction
  | AdvanceJobHandlerAction
  | RejectJobHandlerAction
  | ChangeValueHandlerAction
  | ChangeValueHandlerAction1
  | ChangeValueHandlerAction2
  | ClearFormAction
  | AddCurrentJobAction
  | ChangeAllFormValuesAction
  | ChangeAllFormValuesAction1
  | ChangeAllFormValuesAction2
  | SaveJobListColumnSettings
  | SelectAllEngineeringForm
  | SelectAllInspectionDetailsForm
  

//Explanation:
//Action is interface which expects type and payload. type is something from enum ActionTypes...
//ActionTypes enumerator and its used as part of Action interface
