import React, { useEffect, useState, useRef } from "react";
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Badge, Popover } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState, useDispatch } from "../../store";
import { useSelector } from "react-redux";
import { Notification } from "../../store/notifications/types";
import { HubConnection } from "@microsoft/signalr";
import { useMarkNotificationAsReceivedMutation } from "../../generated/graphql";
import { removeNotification } from "../../store/notifications/actions";

interface NotificationsProps {

}
  
const Notifications:React.FC<NotificationsProps> = ({ }) => {
    const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const dispatch = useDispatch();

    const notifications: any = useSelector((state: RootState) => state.notifications.notifications);

    const [markNotificationAsReceived, markNotificationAsReceivedResp] = useMarkNotificationAsReceivedMutation();

    const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickNotification = (notification:Notification) => {
        markNotificationAsReceived({variables: {notificationId:notification.id}});
        dispatch(removeNotification(notification))
    }

    return (<>
        <Badge badgeContent={notifications.length} color="error" style={{ cursor: "pointer" }}>
            <NotificationsIcon onClick={handleClick} />
        </Badge>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            {notifications.map((notification:Notification, index:number) => {
                return <>
                    <List component="nav" aria-label="main mailbox folders" >
                        <ListItem key={notification.id} onClick={() => onClickNotification(notification)}>
                            <Link to={notification.link} >
                                <ListItemText key={notification.id} primary={notification.subject} secondary={notification.description} />
                            </Link>
                        </ListItem>
                    </List>
                    {index !== notifications.length - 1 && <Divider />}
                </>
            })}

        </Popover>
    </>)
}

export default Notifications;

