import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import { useLazyQuery, useQuery } from "@apollo/client";
import { READ_CURRENT_USER, UPDATE_USER } from "../JobList/query";
import { useUpdateCurrentUserMutation } from "../../generated/graphql";
import { useDispatch } from "react-redux";
import { errorHandler } from "../../store/actions";

interface Setting {
  name: string;
  title: string;
}

const NotificationSettings = () => {
  const dispatch = useDispatch();
  const [channels, setChannels] = React.useState({
    EngiFlow: false,
    Email: false,
  });

  const [readCurrentUser, readCurrentUserResp] = useLazyQuery(
    READ_CURRENT_USER
  );

  let user = {
    ...readCurrentUserResp.data?.readCurrentUser,
    notificationSettings: {
      email: channels.Email,
      signalr: channels.EngiFlow,
    },
  };

  //Delete unececery fields for graphQl user input (updateUser)
  const address = {
    ...user.address,
  };
  delete user["id"];
  delete user["organisation"];
  delete address["id"];
  user.address = address;

  const [
    updateUserMutation,
    updateUserMutationResponse,
  ] = useUpdateCurrentUserMutation({
    variables: {
      user,
      userId: readCurrentUserResp.data?.readCurrentUser.id,
    },
  });

  useEffect(() => {
    if (readCurrentUserResp.data?.readCurrentUser) {
      updateUserMutation();
    }
  }, [channels]);

  useEffect(() => {
    if (updateUserMutationResponse.error) {
      dispatch(errorHandler(updateUserMutationResponse.error.message));
    }
  }, [updateUserMutationResponse.error]);

  useEffect(() => {
    readCurrentUser();
  }, []);

  useEffect(() => {
    if (readCurrentUserResp.data) {
      const eMail =
        readCurrentUserResp.data.readCurrentUser.notificationSettings.eMail;
      const signalR =
        readCurrentUserResp.data.readCurrentUser.notificationSettings.signalR;
      setChannels({
        EngiFlow: signalR,
        Email: eMail,
      });
    }
  }, [readCurrentUserResp.data]);

  let items = [
    { name: "job_created", title: "Job Created" },
    { name: "job_finished", title: "Job Finished" },
    { name: "job_advanced", title: "Job Advanced" },
    { name: "job_rejected", title: "Job Rejected" },
    { name: "task_assigned", title: "Task Assigned" },
    { name: "tasl_finished", title: "Task Finished" },
  ];

  const [state, setState] = React.useState(
    items.map((item) => {
      return { [item.name]: false };
    })
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let newState = { ...state };
    newState[index][event.target.name] = event.target.checked;
    setState(newState);
  };

  const handleChangeChannel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChannels({ ...channels, [event.target.name]: event.target.checked });
  };

  return (
    <Paper style={{ padding: "15px 20px" }}>
      <Typography component="h6" variant="h6" style={{ marginBottom: 20 }}>
        Notifications
      </Typography>
      <FormControl component="fieldset">
        <FormLabel component="legend">Send Through </FormLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={channels.EngiFlow}
              onChange={handleChangeChannel}
              name="EngiFlow"
              color="primary"
            />
          }
          label="EngiFlow"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={channels.Email}
              onChange={handleChangeChannel}
              name="Email"
              color="primary"
            />
          }
          label="Email"
        />
      </FormControl>
      {/* <Divider style={{ width: "100%", marginBottom: 15 }} />
      {items.map((item, index) => {
        return (
          <Grid
            container
            spacing={3}
            direction="row"
            justify="space-between"
            alignItems="center"
            key={`notification_setting_item_${item.name}`}
          >
            {" "}
            <Grid item xs={9}>
              <Typography component="div" variant="body1">
                {item.title}
              </Typography>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}>
              <Switch
                checked={state[index][item.name]}
                onChange={(event) => handleChange(event, index)}
                color="primary"
                name={item.name}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
          </Grid>
        );
      })} */}
    </Paper>
  );
};

export default NotificationSettings;
