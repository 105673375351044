export interface User {

}
  
export interface AuthenticationState {
    isAuthenticated : Boolean
    accessToken : string
    user : any
}

export const RESET_AUTHENTICATION = 'RESET_AUTHENTICATION'
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'


interface ResetAuthenticationAction {
  type: typeof RESET_AUTHENTICATION
}

interface SetIsAuthenticatedAction {
  type: typeof SET_IS_AUTHENTICATED
  payload: Boolean
}

interface SetAccessTokenAction {
  type: typeof SET_ACCESS_TOKEN
  payload: string
}

export type AuthenticationActionTypes = ResetAuthenticationAction | SetIsAuthenticatedAction | SetAccessTokenAction
