import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import { useLazyQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { useRemoveRoleMutation } from "../../generated/graphql";
import { READ_USER_ROLES } from "../JobList/query";


interface ChipData {
  key: number;
  label: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  })
);

interface ChipsArrayProps {
  user: any;
  refetchData: any;
  setRefetchData: any;
}

const ChipsArray: React.FC<ChipsArrayProps> = ({
  user,
  refetchData,
  setRefetchData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState<any[]>([]);
  const [chipData, setChipData] = React.useState<ChipData[]>([]);

  console.log('roles: ',roles)
  console.log('chipData: ',chipData)

  //Read current user roles
  const [getUserRoles, getUserRolesResponse] = useLazyQuery(READ_USER_ROLES);

  //Remove current user role
  const [removeUserRole, removeUserRoleResponse] = useRemoveRoleMutation();

  //Trigger getUser roles on initial render
  useEffect(() => {
    getUserRoles({
      variables: {
        userId: user.id,
      },
    });
  }, []);

  //Check if data need to be renewed
  useEffect(() => {
    if (refetchData) {
      if (getUserRolesResponse.refetch) {
        getUserRolesResponse.refetch!(getUserRolesResponse.data);
        setRefetchData(false);
      }
    }
  }, [refetchData]);

  //If getUserRoles success, store roles in local roles
  useEffect(() => {
    // console.log(getUserRolesResponse);
    if (getUserRolesResponse.data?.readRoles) {
      setRoles(getUserRolesResponse.data.readRoles);
    }
  }, [getUserRolesResponse.data]);

  //When roles change get new data to render.
  useEffect(() => {
    if (roles.length > 0) {
      let newData = [];
      for (let key = 0; key < roles.length; key++) {
        newData.push({ key: key, label: roles[key] });
      }
      setChipData(newData);
    }
  }, [roles, removeUserRoleResponse.data]);

  //If removeRole is successfull
  useEffect(() => {
    if (removeUserRoleResponse.data) {
      if (removeUserRoleResponse.data.removeRole) {
        setRefetchData(true);
        setRoles(removeUserRoleResponse.data.removeRole);
      }
    }
  }, [removeUserRoleResponse.data]);

  console.log('getUserRolesResponse: ',getUserRolesResponse.data)

  const handleDelete = (chipToDelete: ChipData) => () => {
    removeUserRole({
      variables: {
        userId: user.id,
        role: chipToDelete.label,
      },
    });
  };

  if (getUserRolesResponse.loading || removeUserRoleResponse.loading) {
    return <CircularProgress size={18} />;
  }

  return (
    <div className={classes.root}>
      {chipData.map((data) => {
        let icon;

        if (data.label === "React") {
          icon = <TagFacesIcon />;
        }

        return (
          <li key={data.key}>
            <Chip
              icon={icon}
              label={data.label}
              onDelete={data.label === "React" ? undefined : handleDelete(data)}
              className={classes.chip}
            />
          </li>
        );
      })}
    </div>
  );
};

export default ChipsArray;
