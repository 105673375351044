import {
    Button,
    createStyles,
    LinearProgress,
    makeStyles,
    Theme,
  } from "@material-ui/core";
  import React, { useEffect } from "react";
  import { useRevertRectifyDetailsJobMutation, useRevertRequestInspectionJobMutation } from "../generated/graphql";
  import { advanceJobRedux } from "../store/actions";
  import { useDispatch } from "react-redux";
  
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        marginTop: "20px",
      },
      providerContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      button: {
        marginRight: theme.spacing(1),
      },
      instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      instructionsContainer: {
        backgroundColor: "#e2f4ff",
        maxWidth: "600px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        padding: "10px",
        fontSize: "14px",
        color: "black",
      },
      toDoBottomContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      },
      commentFieldContainer: {
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: "3px",
        padding: "6px",
        minWidth: "500px",
        marginBottom: "15px",
      },
      checkBoxesContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "flex-end",
        maxWidth: "75%",
      },
      checkBoxRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
      checkBoxControl: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
      checkBox:{
        color:theme.palette.primary.main
      },
      linearIndeterminate:{
        marginTop:"10px",
        width: '90%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
      }
    })
  );
  
  interface RevertButtonsProps {
    job: any;
  }
  
  const RevertButtons: React.FC<RevertButtonsProps> = ({ job }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
  
    const [revertRectifyDetailsJob, revertRectifyDetailsJobResponse] = useRevertRectifyDetailsJobMutation({
      variables: {
        jobId:job.id
      }
    });

    const [revertRequestInspectionJob, revertRequestInspectionJobResponse] = useRevertRequestInspectionJobMutation({
        variables: {
          jobId:job.id
        }
      });
  
    useEffect(()=>{
      if(revertRectifyDetailsJobResponse.data?.revertRectifyDetailsJob){
        dispatch(advanceJobRedux(revertRectifyDetailsJobResponse.data.revertRectifyDetailsJob))
      }
      if(revertRequestInspectionJobResponse.data?.revertRequestInspectionJob){
        dispatch(advanceJobRedux(revertRequestInspectionJobResponse.data.revertRequestInspectionJob))
      }
    },[revertRectifyDetailsJobResponse.data, revertRequestInspectionJobResponse.data, dispatch])

    const clickRevertRectifyDetails = async () => {
        return await revertRectifyDetailsJob({
            variables: {
              jobId:job.id
            }
          });
    };

    const clickRevertRequestInspection = async () => {
        return await revertRequestInspectionJob();
    };
  
    return (
      <div className={classes.providerContainer}>
        <div className={classes.toDoBottomContainer}>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            size="small"
            onClick={clickRevertRectifyDetails}
          >
            Revert Job to Amend/Rectify Details
          </Button>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            size="small"
            onClick={clickRevertRequestInspection}
            disabled={!job.engineeringRequested && !job.inspectionRequested}
          >
            Revert Job to Request Inspection
          </Button>
        </div>
        {revertRectifyDetailsJobResponse.loading  && 
            <div className={classes.linearIndeterminate}>
              <LinearProgress />
            </div>
        }
        {revertRequestInspectionJobResponse.loading  && 
            <div className={classes.linearIndeterminate}>
              <LinearProgress />
            </div>
        }
      </div>
    );
  };
  
  export default RevertButtons;