import {
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import UploadSignature from "./UploadSignature";
import { downloadSignature } from "../../utils/fileStorage";
import { useAuth0 } from "@auth0/auth0-react";

interface IAddSignature {
  user: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    signatureDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minHeight: "100px",
    },
  })
);

const AddSignature: React.FC<IAddSignature> = ({ user }) => {
  const classes = useStyles();
  const [signatureUrl, setSignatureUrl] = useState("");
  const [showUploadSignature, setShowUploadSignature] = useState<any>(false);
  const { getAccessTokenSilently } = useAuth0();

  const getExistingSignature = async () => {
    const token = await getAccessTokenSilently();
    const signature = await downloadSignature(0, token);
    setSignatureUrl(signature);
  };

  getExistingSignature();

  return (
    <Paper className={classes.root}>
      {showUploadSignature ? (
        <UploadSignature user={user} goBack={setShowUploadSignature} />
      ) : (
        <>
          {" "}
          <div className={classes.flexRow}>
            <IconButton
              disableFocusRipple
              onClick={() => setShowUploadSignature(!showUploadSignature)}
            >
              <AddCircleIcon fontSize="large" color="primary" />
            </IconButton>
            <Typography
              component="h2"
              variant="h5"
            >
              Add new signature
            </Typography>
          </div>
          <Divider style={{ width: "95%" }} />
          {signatureUrl && (
            <div className={classes.signatureDiv}>
              <img style={{maxWidth:"100%"}} src={signatureUrl} alt="signature image" />
            </div>
          )}
        </>
      )}
    </Paper>
  );
};

export default AddSignature;
