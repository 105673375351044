import { ActionTypes, Action } from "../actions";

interface StateType {
  [key: string]: any
}
const initState: StateType = {
  details_type:null,
  details_roofingType:null,

  subFloor_posts:null,
  subFloor_posts_status:null,
  subFloor_bracing:null,
  subFloor_bracing_status:null,
  subFloor_beams:null,
  subFloor_beams_status:null,
  subFloor_status:null,
  subFloor_comment:null,

  floor_bearers:null,
  floor_bearers_status:null,
  floor_joists:null,
  floor_joists_status:null,
  floor_flooring:null,
  floor_flooring_status:null,
  floor_status:null,
  floor_comment:null,

  walls_wall:null,
  walls_wall_status:null,
  walls_bracing:null,
  walls_bracing_status:null,
  walls_posts:null,
  walls_posts_status:null,
  walls_status:null,
  walls_comment:null,

  roof_roof:null,
  roof_roof_status:null,
  roof_beam:null,
  roof_beam_status:null,
  roof_bracingStrap:null,
  roof_bracingStrap_status:null,
  roof_battens:null,
  roof_battens_status:null,
  roof_status:null,
  roof_comment:null,

  comment:null,
  outcome:null,
  declaration:false,
};

export const inspectionChecklistForm = (state = initState, action: Action) => {
  switch (action.type) {
    case ActionTypes.changeValueHandler:
      if (action.payload) {
        const property_t: any = action.payload.key
        const new_state = {...state}
        new_state[property_t] = action.payload.value

        return new_state
      }
      return state;
    
    case ActionTypes.changeAllFormValues:
      if(action.payload){
        return action.payload          
      }
      return state;

    case ActionTypes.clearForm:
      return initState
      
    default:
      return state;
  }
};