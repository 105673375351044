import React, { useEffect, useRef } from "react";
import * as yup from "yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  makeStyles,
  Divider,
  Checkbox,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAllFormValues,
  changeValueHandler,
  errorHandler,
} from "../../store/actions";
import RadioButtons from "./CommonFormComponents/RadioButtons";
import Checkboxes from "./CommonFormComponents/CheckBoxes";
import Comment from "./CommonFormComponents/Comment";
import { RootState } from "../../store";
import { useUpdateJobInspectionChecklistFormDataMutation } from "../../generated/graphql";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "./styles";

interface IInspectionChecklistForm {
  job: any;
  setAdvanceEnabled:any;
}
export const InspectionChecklistForm: React.FC<IInspectionChecklistForm> = ({ job,setAdvanceEnabled }) => {
  const classes = useStyles();
  const currentState: any = useSelector((state: RootState) => state.inspectionChecklistForm);
  const [errorArray, setErrorArray] = React.useState<string[]>([]);
  const [saveButton, setSaveButton] = React.useState<boolean>(true);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [
    generateForm,
    response,
  ] = useUpdateJobInspectionChecklistFormDataMutation();

  const dispatch = useDispatch();

  let existingFormData: any = null;
  const getExistingFormData = () => {
    if (job.jobInspectionChecklist?.data) {
      existingFormData = JSON.parse(job.jobInspectionChecklist.data);
    }
  };

  useEffect(() => {
    getExistingFormData();
    if (existingFormData !== null) {
      dispatch(changeAllFormValues(existingFormData));
      dispatch(changeValueHandler("declaration", false)); //Invalidate declaration
    }
  }, [existingFormData]);

  const renderCount = useRef(0);
  useEffect(() => {
    if (renderCount.current > 0) {
      setSaveButton(false);
    } else {
      renderCount.current = renderCount.current + 1;
    }
  }, [currentState]);

  const responseRef = useRef(true);
  useEffect(() => {
    if (response.data?.updateJobInspectionChecklistFormData) {
      if (responseRef.current === true) {
        setShowAlert(true);
        responseRef.current = false;
      }
    }
  }, [response]);

  const onCheckBoxChange = (fieldName: string, event: any) => {
    try {
      dispatch(changeValueHandler("declaration", event.target.checked));
    } catch (e) {
      dispatch(errorHandler(e));
    }
  };

  const validationSchema = yup.object({
    declaration: yup.boolean().required().oneOf([true]),
    outcome: yup.string().required(),
    // subFloor_posts_status : yup.string().required(),
    // subFloor_bracing_status : yup.string().required(),
    // subFloor_beams_status : yup.string().required(),
    // subFloor_status : yup.string().required(),
    // floor_bearers_status : yup.string().required(),
    // floor_joists_status : yup.string().required(),
    // floor_flooring_status : yup.string().required(),
    // floor_status : yup.string().required(),
    // walls_wall_status : yup.string().required(),
    // walls_bracing_status : yup.string().required(),
    // walls_posts_status : yup.string().required(),
    // walls_status : yup.string().required(),
    // roof_roof_status : yup.string().required(),
    // roof_beam_status : yup.string().required(),
    // roof_bracingStrap_status : yup.string().required(),
    // roof_battens_status : yup.string().required(),
    // roof_status : yup.string().required(),
  });

  const validationHandler = async () => {
    let new_error_array: string[] = [];
    let isValid = await validationSchema
      .validate(currentState, {
        abortEarly: false,
      })
      .then(() => {
        return true;
      })
      .catch((err) => {
        err.errors.map((e_string: any) => {
          const e_array = e_string.split(" ");
          new_error_array.push(e_array[0]);
        });
        return false;
      });
    setErrorArray(new_error_array);
    return isValid;
  };

  const generateHandler = async () => {
    try {
      let getValidity = await validationHandler();
      if (getValidity) {
        generateForm({
          variables: {
            jobId: job.id,
            formData: {
              data: JSON.stringify(currentState),
            },
          },
        });
        responseRef.current = true;
        setSaveButton(true);
        dispatch(changeValueHandler("declaration", false)); //Invalidate declaration
        setAdvanceEnabled(true);
      }
    } catch (error) {
      dispatch(errorHandler(error))
    }
  };

  return (
    <form className={classes.formContainer}>
      <div className={classes.formMainHeading}>
        <Typography component={"span"} variant={"body2"}>
          Inspection Checklist
        </Typography>
      </div>
      {/* INSPECTION DETAILS */}
      <Accordion className={classes.accordionContainer}>
        <AccordionSummary
          className={classes.formSubHeading}
          expandIcon={<ExpandMoreIcon color="secondary" />}
        >
          <Typography component={"span"} variant={"body2"}>
            Inspection details
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          <Checkboxes
            buttons={[
              "Split Level",
              "H.Set",
              "L.Set",
              "D.Storey",
              "T.Storey",
              "Pation",
              "Carport",
              "Deck",
            ]}
            group_label="Type"
            name="details_type"
            values={currentState.details_type}
            directionRow={true}
            reducer_name="inspectionChecklistFormReducer"
          />
          <Checkboxes
            buttons={["Tile", "Metal"]}
            group_label="Roofing Type"
            name="details_roofingType"
            values={currentState.details_roofingType}
            directionRow={true}
            reducer_name="inspectionChecklistFormReducer"
          />
        </AccordionDetails>
      </Accordion>
      {/* SUB FLOOR */}
      <Accordion className={classes.accordionContainer}>
        <AccordionSummary
          className={classes.formSubHeading}
          expandIcon={<ExpandMoreIcon color="secondary" />}
        >
          <Typography component={"span"} variant={"body2"}>
            Sub Floor
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={["Timber", "Steel", "Brick Pier"]}
              group_label="Posts"
              name="subFloor_posts"
              values={currentState.subFloor_posts}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            <RadioButtons
              buttons={["OK", "NOT OK", "N/A"]}
              group_label="status"
              name="subFloor_posts_status"
              defaultValue={currentState.subFloor_posts_status}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            {errorArray.includes("subFloor_posts_status") && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                *required
              </Typography>
            )}
          </div>

          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={["Diagonal", "Horizontal"]}
              group_label="Bracing"
              name="subFloor_bracing"
              values={currentState.subFloor_bracing}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            <RadioButtons
              buttons={["OK", "NOT OK", "N/A"]}
              group_label="status"
              name="subFloor_bracing_status"
              defaultValue={currentState.subFloor_bracing_status}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            {errorArray.includes("subFloor_bracing_status") && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                *required
              </Typography>
            )}
          </div>
          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={["Timber", "Steel"]}
              group_label="Beams"
              name="subFloor_beams"
              values={currentState.subFloor_beams}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            <RadioButtons
              buttons={["OK", "NOT OK", "N/A"]}
              group_label="status"
              name="subFloor_beams_status"
              defaultValue={currentState.subFloor_beams_status}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            {errorArray.includes("subFloor_beams_status") && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                *required
              </Typography>
            )}
          </div>
          <Divider className={classes.divider2} />
          {errorArray.includes("subFloor_status") && (
            <Typography
              component={"span"}
              variant={"body2"}
              className={classes.error_msg}
            >
              *required
            </Typography>
          )}
          <RadioButtons
            buttons={["OK", "NOT OK", "N/A"]}
            group_label="Status"
            name="subFloor_status"
            defaultValue={currentState.subFloor_status}
            directionRow={true}
            reducer_name="inspectionChecklistFormReducer"
          />
          <Comment
            name="subFloor_comment"
            defaultValue={currentState.subFloor_comment}
            reducer_name="inspectionChecklistFormReducer"
          />
        </AccordionDetails>
      </Accordion>
      {/* FLOOR */}
      <Accordion className={classes.accordionContainer}>
        <AccordionSummary
          className={classes.formSubHeading}
          expandIcon={<ExpandMoreIcon color="secondary" />}
        >
          <Typography component={"span"} variant={"body2"}>
            Floor
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={["Timber", "Steel", "Concrete Block"]}
              group_label="Bearers"
              name="floor_bearers"
              values={currentState.floor_bearers}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            <RadioButtons
              buttons={["OK", "NOT OK", "N/A"]}
              group_label="status"
              name="floor_bearers_status"
              defaultValue={currentState.floor_bearers_status}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            {errorArray.includes("floor_bearers_status") && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                *required
              </Typography>
            )}
          </div>
          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={["Timber", "Steel", "Blocking"]}
              group_label="Joints"
              name="floor_joists"
              values={currentState.floor_joists}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            <RadioButtons
              buttons={["OK", "NOT OK", "N/A"]}
              group_label="status"
              name="floor_joists_status"
              defaultValue={currentState.floor_joists_status}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            {errorArray.includes("floor_joists_status") && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                *required
              </Typography>
            )}
          </div>
          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={["Timber", "FC Sheeting"]}
              group_label="Flooring"
              name="floor_flooring"
              values={currentState.floor_flooring}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            <RadioButtons
              buttons={["OK", "NOT OK", "N/A"]}
              group_label="status"
              name="floor_flooring_status"
              defaultValue={currentState.floor_flooring_status}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            {errorArray.includes("floor_flooring_status") && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                *required
              </Typography>
            )}
          </div>
          <Divider className={classes.divider2} />
          {errorArray.includes("floor_status") && (
            <Typography
              component={"span"}
              variant={"body2"}
              className={classes.error_msg}
            >
              *required
            </Typography>
          )}
          <RadioButtons
            buttons={["OK", "NOT OK", "N/A"]}
            group_label="Status"
            name="floor_status"
            defaultValue={currentState.floor_status}
            directionRow={true}
            reducer_name="inspectionChecklistFormReducer"
          />
          <Comment
            name="floor_comment"
            defaultValue={currentState.floor_comment}
            reducer_name="inspectionChecklistFormReducer"
          />
        </AccordionDetails>
      </Accordion>
      {/* WALLS */}
      <Accordion className={classes.accordionContainer}>
        <AccordionSummary
          className={classes.formSubHeading}
          expandIcon={<ExpandMoreIcon color="secondary" />}
        >
          <Typography component={"span"} variant={"body2"}>
            Walls
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={["Timber", "Steel", "Brick Pier"]}
              group_label="Wall"
              name="walls_wall"
              values={currentState.walls_wall}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            <RadioButtons
              buttons={["OK", "NOT OK", "N/A"]}
              group_label="Status"
              name="walls_wall_status"
              defaultValue={currentState.walls_wall_status}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            {errorArray.includes("walls_wall_status") && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                *required
              </Typography>
            )}
          </div>
          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={["Diagonal Strap", "K Brace", "Ply"]}
              group_label="Bracing"
              name="walls_bracing"
              values={currentState.walls_bracing}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            <RadioButtons
              buttons={["OK", "NOT OK", "N/A"]}
              group_label="Status"
              name="walls_bracing_status"
              defaultValue={currentState.walls_bracing_status}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            {errorArray.includes("walls_bracing_status") && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                *required
              </Typography>
            )}
          </div>
          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={["Timber", "Steel"]}
              group_label="Posts"
              name="walls_posts"
              values={currentState.walls_posts}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            <RadioButtons
              buttons={["OK", "NOT OK", "N/A"]}
              group_label="Status"
              name="walls_posts_status"
              defaultValue={currentState.walls_posts_status}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            {errorArray.includes("walls_posts_status") && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                *required
              </Typography>
            )}
          </div>
          <Divider className={classes.divider2} />
          <RadioButtons
            buttons={["OK", "NOT OK", "N/A"]}
            group_label="Status"
            name="walls_status"
            defaultValue={currentState.walls_status}
            directionRow={true}
            reducer_name="inspectionChecklistFormReducer"
          />
          {errorArray.includes("walls_status") && (
            <Typography
              component={"span"}
              variant={"body2"}
              className={classes.error_msg}
            >
              *required
            </Typography>
          )}
          <Comment
            name="walls_comment"
            defaultValue={currentState.walls_comment}
            reducer_name="inspectionChecklistFormReducer"
          />
        </AccordionDetails>
      </Accordion>
      {/* ROOF */}
      <Accordion className={classes.accordionContainer}>
        <AccordionSummary
          className={classes.formSubHeading}
          expandIcon={<ExpandMoreIcon color="secondary" />}
        >
          <Typography component={"span"} variant={"body2"}>
            Roof
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={["Truss", "Rafter"]}
              group_label="Roof"
              name="roof_roof"
              values={currentState.roof_roof}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            <RadioButtons
              buttons={["OK", "NOT OK", "N/A"]}
              group_label="Status"
              name="roof_roof_status"
              defaultValue={currentState.roof_roof_status}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            {errorArray.includes("roof_roof_status") && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                *required
              </Typography>
            )}
          </div>
          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={["Timber", "Steel"]}
              group_label="Beam"
              name="roof_beam"
              values={currentState.roof_beam}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            <RadioButtons
              buttons={["OK", "NOT OK", "N/A"]}
              group_label="Status"
              name="roof_beam_status"
              defaultValue={currentState.roof_beam_status}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            {errorArray.includes("roof_beam_status") && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                *required
              </Typography>
            )}
          </div>
          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={["Strap"]}
              group_label="Bracing"
              name="roof_bracingStrap"
              values={currentState.roof_bracingStrap}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            <RadioButtons
              buttons={["OK", "NOT OK", "N/A"]}
              group_label="Status"
              name="roof_bracingStrap_status"
              defaultValue={currentState.roof_bracingStrap_status}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            {errorArray.includes("roof_bracingStrap_status") && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                *required
              </Typography>
            )}
          </div>
          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={["Timber", "Steel"]}
              group_label="Battens"
              name="roof_battens"
              values={currentState.roof_battens}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            <RadioButtons
              buttons={["OK", "NOT OK", "N/A"]}
              group_label="Status"
              name="roof_battens_status"
              defaultValue={currentState.roof_battens_status}
              directionRow={true}
              reducer_name="inspectionChecklistFormReducer"
            />
            {errorArray.includes("roof_battens_status") && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                *required
              </Typography>
            )}
          </div>
          <Divider className={classes.divider2} />
          {errorArray.includes("roof_status") && (
            <Typography
              component={"span"}
              variant={"body2"}
              className={classes.error_msg}
            >
              *required
            </Typography>
          )}
          <RadioButtons
            buttons={["OK", "NOT OK", "N/A"]}
            group_label="Status"
            name="roof_status"
            defaultValue={currentState.roof_status}
            directionRow={true}
            reducer_name="inspectionChecklistFormReducer"
          />
          <Comment
            name="roof_comment"
            defaultValue={currentState.roof_comment}
            reducer_name="inspectionChecklistFormReducer"
          />
        </AccordionDetails>
      </Accordion>
      {/* COMMENT */}
      <Accordion className={classes.accordionContainer}>
        <AccordionSummary
          className={classes.formSubHeading}
          expandIcon={<ExpandMoreIcon color="secondary" />}
        >
          <Typography component={"span"} variant={"body2"}>
            Comment
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          <Comment
            name="comment"
            defaultValue={currentState.comment}
            reducer_name="inspectionChecklistFormReducer"
          />
        </AccordionDetails>
      </Accordion>
      {/* OUTCOME */}
      <Accordion className={classes.accordionContainer} expanded={true}>
        <AccordionSummary
          className={classes.formSubHeading}
          expandIcon={<ExpandMoreIcon color="secondary" />}
        >
          <Typography component={"span"} variant={"body2"}>
            Inspection Outcome
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          <RadioButtons
            buttons={["Green", "Orange", "Red"]}
            group_label="Outcome"
            name="outcome"
            defaultValue={null}
            directionRow={true}
            reducer_name="inspectionChecklistFormReducer"
          />
          {errorArray.includes("outcome") && (
            <Typography
              component={"span"}
              variant={"body2"}
              className={classes.error_msg}
            >
              *required
            </Typography>
          )}
          <div className={classes.checkBoxesContainer}>
            <div className={classes.checkBoxRow}>
              <Checkbox
                onChange={(value) => onCheckBoxChange("Agree", value)}
                color="primary"
                className={classes.checkBox}
                value={currentState.declaration}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <Typography component="h6" variant="body2" color="textPrimary">
                I DECLARE THAT THE BUILDING FULLY COMPLIES WITH THE DESIGN
                DRAWINGS AND NO RECTIFICATION IS REQUIRED
              </Typography>
            </div>
          </div>
          {errorArray.includes("declaration") && (
            <Typography
              component={"span"}
              variant={"body2"}
              className={classes.error_msg}
            >
              *required
            </Typography>
          )}

          <Divider className={classes.divider2} />

          <div className={classes.generateButtonContainer}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => generateHandler()}
              disabled={saveButton}
            >
              Save
            </Button>
            {showAlert && (
              <Alert
                className={classes.alertClass}
                onClose={() => {
                  setShowAlert(false);
                }}
              >
                Form has been saved successfully!
              </Alert>
            )}
            {errorArray.length > 0 && (
              <Typography
                component={"span"}
                variant={"body2"}
                className={classes.error_msg}
              >
                You havent checked some required fields
              </Typography>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </form>
  );
};

export default InspectionChecklistForm;
