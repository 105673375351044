import { Container, createStyles, Divider, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react"
import NewJobForm from "./NewJobForm";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        JobsViewContainer: {
            backgroundColor: theme.palette.success.main,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '3px',
            marginTop: theme.spacing(15),
            marginBottom: '50px',
            padding: '10px',
            paddingTop: '20px',
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        },
        containerTitleText: {
            fontFamily: "-webkit-pictograph"
        }
    })
);

const NewJobFormContainer = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="md">
            <div className={classes.JobsViewContainer}>
                <Typography
                    component="h2"
                    variant="h5"
                    color="primary"
                    align="center"
                    className={classes.containerTitleText}
                >
                    Create new job
              <Divider />
                </Typography>

                <NewJobForm />
            </div>
        </Container>
    );
}

export default NewJobFormContainer;