import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { READ_USERS } from '../../JobList/query';
import MemberList from '../MemberList';


const Users = () => {
    const [users, setUsers] = useState([])

    const [readUsers, readUsersResponse] = useLazyQuery(
        READ_USERS
      );
    
    useEffect(() => {
        readUsers()
    }, [])

    useEffect(()=>{
        // console.log(readUsersResponse.data)
        if(readUsersResponse.data){
            setUsers(readUsersResponse.data.readUsers)
        }
    },[readUsersResponse])

    return (
        <div>
           <MemberList title="All users" users={users}/>
        </div>
    )
}

export default Users