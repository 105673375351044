import { useAuth0 } from "@auth0/auth0-react";
import { Button, createStyles, Divider, makeStyles, Theme, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EngineeringDetailsForm from "../../components/FormChecklist/EngineeringDetailsForm";
import { RootState } from "../../store";
import ApproveOrReject from "../ApproveOrReject";
import ProviderUpload from "../ProviderUpload";
import { doYouNeedRefetch, errorHandler } from "../../store/actions";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		divider2: {
			margin: "25px 0px",
		},
		providerContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
		generateButton: {
			marginTop: "10px",
			marginBottom: "10px",
		},
		alertClass: {
			color: "#fff",
			backgroundColor: "green",
			margin: "7px",
		},
		alertClass_2: {
			color: "#fff",
			backgroundColor: "green",
			margin: "7px",
		},
	})
);

const Provider = ({ job }: any) => {
	const classes = useStyles();
	const { getAccessTokenSilently } = useAuth0();
	const [advanceEnabled, setAdvanceEnabled] = useState(false);
	const [showAlert, setShowAlert] = React.useState<boolean>(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (job.jobEngineering?.data) {
			setAdvanceEnabled(true);
		}
	}, [job.jobEngineering]);

	const generatePrelimHandler = async () => {
		const token = await getAccessTokenSilently();

		enum formType {
			"DESIGN_FORM" = 0,
			"INSPECTION_FORM" = 1,
			"PRELIM_DESIGN_FORM" = 2,
		}

		let config = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
		const res = await axios.post(
			`${process.env.REACT_APP_BACKEND_BASE_URL}/formexport/${formType.PRELIM_DESIGN_FORM}/${job.id}`,
			null,
			config
		);
		setShowAlert(true);
	};

	const refreshState = () => {
		try {
			dispatch(doYouNeedRefetch());
		} catch (e) {
			dispatch(errorHandler(e.message));
		}
	};

	return (
		<div className={classes.providerContainer}>
			{job.organisation.prelimDesignFormPermission && (
				<div className={classes.providerContainer}>
					<Button
						disabled={!advanceEnabled}
						className={classes.generateButton}
						variant="contained"
						color="primary"
						onClick={() => generatePrelimHandler()}
					>
						Generate preliminary design form
					</Button>
					<Button className={classes.generateButton} variant="contained" color="primary" onClick={() => refreshState()}>
						Refresh Page
					</Button>
					{showAlert && (
						<div>
							<Alert
								className={classes.alertClass}
								onClose={() => {
									setShowAlert(false);
								}}
							>
								Preliminary design form has been generated
							</Alert>
							<Alert
								className={classes.alertClass_2}
								onClose={() => {
									setShowAlert(false);
								}}
							>
								Click on the "Refresh Page" button to see the new form
							</Alert>
						</div>
					)}
					<br />
					<Typography color="textSecondary" variant="caption">
						Please finish Engineering details form first in order to use this button.
					</Typography>
				</div>
			)}
			<ProviderUpload job={job} doctype="engineeringdoc" />
			<Divider className={classes.divider2} />
			<EngineeringDetailsForm job={job} setAdvanceEnabled={setAdvanceEnabled} />
			<Divider className={classes.divider2} />
			<ApproveOrReject
				advanceEnabled={advanceEnabled}
				job={job}
				reject={true}
				checkboxText="I have checked all documentation for this stage."
			/>
		</div>
	);
};

export default Provider;
