import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import {
  addCurentJob,
  changePage,
  changeRowsPerPage,
  clearForm,
} from "../../store/actions";
import { RootState } from "../../store";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {
  createStyles,
  IconButton,
  LinearProgress,
  TablePagination,
  TableSortLabel,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import "./JobList.css";
import { useHistory } from "react-router";
import JobListColumnSettings from "./JobListColumnSettings";
import * as jobActions from "../../store/actions/job";
import { CSVExportButton } from "../FilterJobsComponent/CSVExportButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    AddJobsButtonContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    backArrow: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      borderRadius: "50%",
      fontSize: "25px",
    },
    tableRow: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "rgb(245,245,245)",
      },
    },
    centerMe: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    tableCell: {
      padding: "10px",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    Test: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      padding: "10px",
      "&active:": {
        color: "orange",
      },
    },
    linearIndeterminate: {
      marginTop: "10px",
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);

interface Data {
  jobNumber: string;
  state: number;
  subStatus: string;
  deliveryDate: number;
  organisation: number;
  builder: string;
  priority: string;
  level: number;
  address: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

let headCells: HeadCell[] = [
  { id: "jobNumber", numeric: false, disablePadding: true, label: "Job No." },
  { id: "state", numeric: true, disablePadding: false, label: "State" },
  {
    id: "deliveryDate",
    numeric: true,
    disablePadding: false,
    label: "Plan Delivery Date",
  },
  {
    id: "organisation",
    numeric: true,
    disablePadding: false,
    label: "Organisation",
  },
  {
    id: "builder",
    numeric: false,
    disablePadding: false,
    label: "Builder",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
  { id: "level", numeric: true, disablePadding: false, label: "Level" },
  { id: "priority", numeric: true, disablePadding: false, label: "Priority" },
];

type Order = "asc" | "desc";

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const renderAddress = (address: any) => {
  return (
    <div style={{ fontSize: "14px" }}>
      {address.streetNumber} {address.street} <br />
      {address.postcode} {address.suburb} <br />
      {address.province} <br />
    </div>
  );
};

const JobList: React.FC = () => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("jobNumber");
  const [loading, setLoading] = React.useState<boolean>(false);
  const currentState: any = useSelector((state: RootState) => state.jobs);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const setColumnSettings = (settings: string[]) => {
    dispatch(jobActions.saveJobListColumnSettings(settings));
  };

  // useEffect(() => {
  //   console.log("viewFilter: ", viewFilter);
  // }, [viewFilter]);

  //--------------------------//
  // SORTING LOGIC STARTS HERE//
  //--------------------------//
  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, property: keyof Data) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    let valueA: any;
    let valueB: any;

    if (orderBy === "jobNumber") {
      valueA = a[orderBy];
      let valA: number = parseInt(valueA);
      valueA = valA;
      valueB = b[orderBy];
      let valB: number = parseInt(valueB);
      valueB = valB;
    } else {
      valueA = a[orderBy];
      valueB = b[orderBy];
    }

    // if(orderBy === 'deliveryDate'){
    //   setOrderBy('deliveryDate')
    //   valueA = a[orderBy]
    //   valueA = valueA.replace("-",'')
    //   let valA:number = parseInt(valueA)
    //   valueA = valA;

    //   valueB = b[orderBy]
    //   valueB = valueB.replace("-",'')
    //   let valB:number = parseInt(valueB)
    //   valueB = valB;
    // }

    if (valueB < valueA) {
      return -1; //If b < a send -1
    }
    if (valueB > valueA) {
      return 1; //If b > a send 1
    }
    return 0;
  }
  useCallback(descendingComparator, []);

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    //check is a > b descendingComparator and then if asc -, if desc+
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  useCallback(getComparator, []);

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  useCallback(stableSort, []);

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (
      event: React.MouseEvent<unknown>
    ) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) =>
            currentState.jobListColumnSettings.includes(headCell.id) ? (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "left" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={classes.Test}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  <div>{headCell.label}</div>
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ) : null
          )}
        </TableRow>
      </TableHead>
    );
  }
  useCallback(EnhancedTableHead, []);
  //--------------------//
  // SORTING LOGIC ENDS //
  //--------------------//

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setLoading(true);
      dispatch(changePage(newPage));
    },
    [dispatch]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLoading(true);
      dispatch(changeRowsPerPage(parseInt(event.target.value, 10)));
      dispatch(changePage(0));
    },
    [dispatch]
  );

  const getState = useCallback((jobState: string) => {
    switch (jobState) {
      case "AWAITING_DOCUMENTS":
        return "Documents";
      case "DETAILING":
        return "Detailing";
      case "ENGINEERING":
        return "Engineering";
      case "REVIEW_INSPECTION_RESULTS":
        return "Inspection";
      case "WAITING_FOR_INSPECTION_RESULTS":
        return "Approval";
      case "FINISHED":
        return "Finished";
      default:
        return "";
    }
  }, []);

  const renderPriority = useCallback((priority: string) => {
    if (priority === "URGENT") {
      return (
        <>
          <FiberManualRecordIcon
            fontSize="small"
            style={{ color: "#ba000d", fontSize: "15px" }}
          />{" "}
          {priority}
        </>
      );
    } else {
      return (
        <>
          <FiberManualRecordIcon
            fontSize="small"
            style={{ color: "#018e2c", fontSize: "15px" }}
          />{" "}
          {priority}
        </>
      );
    }
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [currentState.jobs]);

  //Loading & Error
  if (!currentState) {
    return <div>Loading...</div>;
  } else {
    if (!currentState.jobs) {
      return (
        <div className="errorContainer">
          Something went wrong
          <ErrorIcon fontSize="large" />
        </div>
      );
    }

    return (
      <div className="JobListMain">
        <div className={classes.AddJobsButtonContainer}>
          {currentState.jobListColumnSettings && (
            <JobListColumnSettings
              state={currentState.jobListColumnSettings}
              setState={setColumnSettings}
            />
          )}
          <IconButton
            disableFocusRipple
            onClick={() => history.push("/jobs/new")}
          >
            <AddCircleIcon fontSize="large" color="primary" />
          </IconButton>
        </div>
        {/* JOB LIST TABLE STARTS HERE */}
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={currentState.jobs.length}
            />
            <TableBody>
              {stableSort(currentState.jobs, getComparator(order, orderBy)).map(
                (job: any) => (
                  <TableRow
                    className={classes.tableRow}
                    key={job.id}
                    onClick={() => {
                      dispatch(addCurentJob(job));
                      dispatch(clearForm());
                      history.push(`/jobs/${job.id}`);
                    }}
                  >
                    {currentState.jobListColumnSettings.includes("jobNumber") ? (
                      <TableCell
                        className={classes.tableCell}
                        component="th"
                        scope="row"
                      >
                        {job.jobNumber}
                      </TableCell>
                    ) : null}
                    {currentState.jobListColumnSettings.includes("state") ? (
                      <TableCell className={classes.tableCell} align="left">
                        {getState(job.state)}
                      </TableCell>
                    ) : null}
                    {currentState.jobListColumnSettings.includes("deliveryDate") ? (
                      <TableCell className={classes.tableCell} align="left">
                        {job.deliveryDate.slice(0, 10)}
                      </TableCell>
                    ) : null}
                    {currentState.jobListColumnSettings.includes("organisation") ? (
                      <TableCell className={classes.tableCell} align="left">
                        {job.organisation.name}
                      </TableCell>
                    ) : null}
                    {currentState.jobListColumnSettings.includes("builder") ? (
                      <TableCell className={classes.tableCell} align="left">
                        {job.builder}
                      </TableCell>
                    ) : null}
                    {currentState.jobListColumnSettings.includes("address") ? (
                      <TableCell className={classes.tableCell} align="left">
                        <Tooltip title={renderAddress(job.jobAddress)}>
                          <span>{`${job.jobAddress.streetNumber} ${job.jobAddress.street}`}</span>
                        </Tooltip>
                      </TableCell>
                    ) : null}
                    {currentState.jobListColumnSettings.includes("level") ? (
                      <TableCell className={classes.tableCell} align="left">
                        {job.level}
                      </TableCell>
                    ) : null}
                    {currentState.jobListColumnSettings.includes("priority") ? (
                      <TableCell className={classes.tableCell} align="left">
                        {renderPriority(job.priority)}
                      </TableCell>
                    ) : null}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={currentState.totalJobs}
            page={currentState.page}
            onChangePage={handleChangePage}
            rowsPerPage={currentState.rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
        {loading && (
          <div className={classes.linearIndeterminate}>
            <LinearProgress />
          </div>
        )}
        {currentState.jobs.length === 0 && (
          <div className={classes.centerMe}>
            <Typography component={"span"} variant={"body2"}>
              No Jobs found
            </Typography>
          </div>
        )}
      </div>
    );
  }
};

export default React.memo(JobList);
