import { Checkbox, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		jobsFilterViewMainContainer: {
			backgroundColor: "#fff",
			paddingLeft: "10px",
			paddingRight: "5px",
			borderRadius: "4px",
			border: "1px solid",
			borderColor: theme.palette.primary.main,
			marginBottom: "2px",
		},
	})
);

interface IJobListColumnSettings {
	state: any;
	setState: any;
}

const JobListColumnSettings: React.FC<IJobListColumnSettings> = ({ state, setState }) => {
	const classes = useStyles();

	useEffect(() => {
		//console.log("jlcs: ", state);
	}, [state]);

	const onCheckBoxChange = (fieldName: string, event: any) => {
		let updatedState = [...state];

		if (event.target.checked) {
			updatedState.push(fieldName);
			setState(updatedState);
		} else {
			updatedState = updatedState.filter((field: string) => {
				return field !== fieldName;
			});
			setState(updatedState);
		}
	};

	return (
		<details>
			<summary>Columns</summary>
			<div className={classes.jobsFilterViewMainContainer}>
				Job Number
				<Checkbox
					defaultChecked={state.includes("jobNumber") ? true : false}
					onChange={(value) => onCheckBoxChange("jobNumber", value)}
					color="primary"
					value={state.includes("jobNumber") ? false : true}
					inputProps={{ "aria-label": "secondary checkbox" }}
				/>
				State
				<Checkbox
					defaultChecked={state.includes("state") ? true : false}
					onChange={(value) => onCheckBoxChange("state", value)}
					color="primary"
					value={state.includes("state") ? false : true}
					inputProps={{ "aria-label": "secondary checkbox" }}
				/>
				Plan Delivery Date
				<Checkbox
					defaultChecked={state.includes("deliveryDate") ? true : false}
					onChange={(value) => onCheckBoxChange("deliveryDate", value)}
					color="primary"
					value={state.includes("deliveryDate") ? false : true}
					inputProps={{ "aria-label": "secondary checkbox" }}
				/>
				Organisation
				<Checkbox
					defaultChecked={state.includes("organisation") ? true : false}
					onChange={(value) => onCheckBoxChange("organisation", value)}
					color="primary"
					value={state.includes("organisation") ? false : true}
					inputProps={{ "aria-label": "secondary checkbox" }}
				/>
				Builder
				<Checkbox
					defaultChecked={state.includes("builder") ? true : false}
					onChange={(value) => onCheckBoxChange("builder", value)}
					color="primary"
					value={state.includes("builder") ? false : true}
					inputProps={{ "aria-label": "secondary checkbox" }}
				/>
				Address
				<Checkbox
					defaultChecked={state.includes("address") ? true : false}
					onChange={(value) => onCheckBoxChange("address", value)}
					color="primary"
					value={state.includes("address") ? false : true}
					inputProps={{ "aria-label": "secondary checkbox" }}
				/>
				Level
				<Checkbox
					defaultChecked={state.includes("level") ? true : false}
					onChange={(value) => onCheckBoxChange("level", value)}
					color="primary"
					value={state.includes("level") ? false : true}
					inputProps={{ "aria-label": "secondary checkbox" }}
				/>
				Priority
				<Checkbox
					defaultChecked={state.includes("priority") ? true : false}
					onChange={(value) => onCheckBoxChange("priority", value)}
					color="primary"
					value={state.includes("priority") ? false : true}
					inputProps={{ "aria-label": "secondary checkbox" }}
				/>
			</div>
		</details>
	);
};

export default JobListColumnSettings;
