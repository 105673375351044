import {
    AuthenticationState,
    AuthenticationActionTypes,
    SET_IS_AUTHENTICATED,
    RESET_AUTHENTICATION,
    SET_ACCESS_TOKEN
  } from './types'
  
  const initialState: AuthenticationState = {
    accessToken: "",
    isAuthenticated: false,
    user: null
  }
  
  export function authenticationReducer(
    state = initialState,
    action: AuthenticationActionTypes
  ): AuthenticationState {
    switch (action.type) {
      case RESET_AUTHENTICATION:
        return { ...initialState }
      case SET_IS_AUTHENTICATED:
        return { ...state, isAuthenticated:action.payload}
      case SET_ACCESS_TOKEN:
        return { ...state, accessToken:action.payload}
      default:
        return state
    }
  }
  