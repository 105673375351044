import { Button, createStyles, Fade, Grid, IconButton, makeStyles, Snackbar, TextField, Theme, Typography, withStyles } from "@material-ui/core";
import { grey, indigo } from "@material-ui/core/colors";
import React, { useEffect } from "react";
import useRequestQuote from "./useRequestQuote";
import CloseIcon from '@material-ui/icons/Close';
import Alert from "@material-ui/lab/Alert";
import { theme } from "../../theme";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: { "margin": "10px auto", "display": "flex", "flexFlow": "column", "width": "85%", "height": "100%", "justifyContent": "center" },
        textNormal: { fontWeight: "normal" },
        emailLink: { "fontWeight": "normal", "textDecoration": "none", "margin": "25px 0px", "color": "white" },
        textBold: {
            fontWeight: "bold"
        },
        input: {
            color: 'white',
            borderBottom: "white 1px solid"
        },
        textField: {
            backgroundColor: theme.palette.primary.main,
            padding: "0 10px"
        },
        inputLabel: {
            color: '#fff !important', padding: "0 10px"
        },
        alert: {
            backgroundColor: theme.palette.success.dark, color: theme.palette.secondary.main
        }
    })
);

const SubmitButton = withStyles((theme) => ({
    root: {
        color: grey[600],
        width: "100%",
        padding: "10px 0",
        margin: "30px 0",
        boxShadow: "none",
        borderRadius: 0,
        backgroundColor: theme.palette.secondary.main,
        fontSize: "1.1em",
        fontWeight: "lighter",
        '&:hover': {
            color: theme.palette.secondary.main,
            backgroundColor: indigo[100],
            boxShadow: "none",
        },
    },
}))(Button);

const QuoteForm = () => {
    const classes = useStyles();
    const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        setShowSuccessAlert(false);
    };

    const formSubmitted = () => {
        setShowSuccessAlert(true);
        setTimeout(() => {
            setShowSuccessAlert(false);
        }, 5000);
    }

    const { formikRequestQuote, requestQuoteResponse } = useRequestQuote(formSubmitted);


    return <form className={classes.form}>
        <Typography component="h3" variant="h3" align="center" gutterBottom className={classes.textBold}>
            Request a Quote
        </Typography>
        <Typography variant="caption" align="center" component="a" href="mailto:engiflow@engiseek.com" className={classes.emailLink} >
            engiflow@engiseek.com
        </Typography>
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    className={classes.textField}
                    InputProps={{
                        className: classes.input
                    }}
                    InputLabelProps={{
                        className: classes.inputLabel,
                    }}
                    size="medium" label="First Name" required={true}
                    value={formikRequestQuote.values.firstName}
                    onChange={(e) =>
                        formikRequestQuote.setFieldValue("firstName", e.target.value)
                    }
                    error={
                        formikRequestQuote.touched.firstName &&
                        Boolean(formikRequestQuote.errors.firstName)
                    }
                    helperText={
                        formikRequestQuote.touched.firstName &&
                            Boolean(formikRequestQuote.errors.firstName)
                            ? formikRequestQuote.errors.firstName
                            : null
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    className={classes.textField}
                    InputProps={{
                        className: classes.input
                    }}
                    InputLabelProps={{
                        className: classes.inputLabel,
                    }}
                    size="medium" label="Last Name" required={true}
                    value={formikRequestQuote.values.lastName}
                    onChange={(e) =>
                        formikRequestQuote.setFieldValue("lastName", e.target.value)
                    }
                    error={
                        formikRequestQuote.touched.lastName &&
                        Boolean(formikRequestQuote.errors.lastName)
                    }
                    helperText={
                        formikRequestQuote.touched.lastName &&
                            Boolean(formikRequestQuote.errors.lastName)
                            ? formikRequestQuote.errors.lastName
                            : null
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    className={classes.textField}
                    InputProps={{
                        className: classes.input
                    }}
                    InputLabelProps={{
                        className: classes.inputLabel,
                    }}
                    size="medium" label="Phone" required={true}

                    value={formikRequestQuote.values.mobileNumber}
                    onChange={(e) =>
                        formikRequestQuote.setFieldValue("mobileNumber", e.target.value)
                    }
                    error={
                        formikRequestQuote.touched.mobileNumber &&
                        Boolean(formikRequestQuote.errors.mobileNumber)
                    }
                    helperText={
                        formikRequestQuote.touched.mobileNumber &&
                            Boolean(formikRequestQuote.errors.mobileNumber)
                            ? formikRequestQuote.errors.mobileNumber
                            : null
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    className={classes.textField}
                    InputProps={{
                        className: classes.input
                    }}
                    InputLabelProps={{
                        className: classes.inputLabel,
                    }}
                    size="medium" label="Email" required={true}

                    value={formikRequestQuote.values.eMail}
                    onChange={(e) =>
                        formikRequestQuote.setFieldValue("eMail", e.target.value)
                    }
                    error={
                        formikRequestQuote.touched.eMail &&
                        Boolean(formikRequestQuote.errors.eMail)
                    }
                    helperText={
                        formikRequestQuote.touched.eMail &&
                            Boolean(formikRequestQuote.errors.eMail)
                            ? formikRequestQuote.errors.eMail
                            : null
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    rows={6}
                    className={classes.textField}
                    InputProps={{
                        className: classes.input
                    }}
                    InputLabelProps={{
                        className: classes.inputLabel,
                    }}
                    size="medium" label="Type your message here..."

                    value={formikRequestQuote.values.message}
                    onChange={(e) =>
                        formikRequestQuote.setFieldValue("message", e.target.value)
                    }
                    error={
                        formikRequestQuote.touched.message &&
                        Boolean(formikRequestQuote.errors.message)
                    }
                    helperText={
                        formikRequestQuote.touched.message &&
                            Boolean(formikRequestQuote.errors.message)
                            ? formikRequestQuote.errors.message
                            : null
                    }
                />
            </Grid>
        </Grid>
        <SubmitButton disabled={requestQuoteResponse.loading} type="button"
            onClick={formikRequestQuote.submitForm}
        >Submit</SubmitButton>
        <Fade in={showSuccessAlert}>
            <Alert onClose={handleClose} severity="success" className={classes.alert}>
                The quote was submitted successfully
            </Alert>
        </Fade>
    </form>
}

export default QuoteForm;