import React, { useEffect, useRef } from "react";
import * as yup from "yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  makeStyles,
  Divider,
  Checkbox,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Tooltip,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAllFormValues2,
  changeValueHandler2,
  errorHandler,
  selectAllInspectionDetailsForm,
} from "../../store/actions";
import Checkboxes from "./CommonFormComponents/CheckBoxes";
import { RootState } from "../../store";
import RadioButtons from "./CommonFormComponents/RadioButtons";
import { useUpdateJobInspectionFormDataMutation } from "../../generated/graphql";
import Alert from "@material-ui/lab/Alert";
import Comment from "./CommonFormComponents/Comment"
import {ref_doc_buttons_for_inspection_form} from "./CommonFormComponents/inspectionDetailsFormButtons"
import { useStyles } from "./styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

interface IInspectionDetailsForm {
  job: any;
  setAdvanceEnabled: any;
}

// ------------------------- //
//  INSPECTION DETAILS FORM  //
// ------------------------- //


export const InspectionDetailsForm: React.FC<IInspectionDetailsForm> = ({ job, setAdvanceEnabled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentState: any = useSelector((state: RootState) => state.inspectionDetailsForm);
  const [errorArray, setErrorArray] = React.useState<string[]>([]);
  const [saveButton, setSaveButton] = React.useState<boolean>(true);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [generateForm, response] = useUpdateJobInspectionFormDataMutation();

  let existingFormData: any = null;
  const getExistingFormData = () => {
    if (job.jobInspection?.data) {
      existingFormData = JSON.parse(job.jobInspection.data);
    }
  };

  const renderCount = useRef(0);
  useEffect(() => {
    if (renderCount.current > 0) {
      setSaveButton(false);
    } else {
      renderCount.current = renderCount.current + 1;
    }
  }, [currentState]);

  useEffect(() => {
    getExistingFormData();
    if (existingFormData !== null) {
      dispatch(changeAllFormValues2(existingFormData));
      dispatch(changeValueHandler2("declaration", false));//Invalidate declaration
    }
  }, [existingFormData]);

  const responseRef = useRef(true);
  useEffect(() => {
    if (response.data?.updateJobInspectionFormData) {
      if (responseRef.current === true) {
        setShowAlert(true);
        responseRef.current = false;
      }
    }
  }, [response]);

  const onCheckBoxChange = (fieldName: string, event: any) => {
    try {
      dispatch(changeValueHandler2("declaration", event.target.checked));
    } catch (e) {
      dispatch(errorHandler(e));
    }
  };

  const validationSchema = yup.object({
    declaration: yup.boolean().required().oneOf([true]),
    building_description: yup.string().required(),
    inspection_date: yup.date().defined(),
  });

  const validationHandler = async () => {
    let new_error_array: string[] = [];
    let isValid = await validationSchema
      .validate(currentState, {
        abortEarly: false,
      })
      .then(() => {
        return true;
      })
      .catch((err) => {
        err.errors.map((e_string: any) => {
          const e_array = e_string.split(" ");
          new_error_array.push(e_array[0]);
        });
        return false;
      });
    setErrorArray(new_error_array);
    return isValid;
  };

  const generateHandler = async () => {
    let getValidity = await validationHandler();
    if (getValidity) {
      try {
        generateForm({
          variables: {
            jobId: job.id,
            formData: {
              data: JSON.stringify(currentState),
            },
          },
        });
        responseRef.current = true;
        setSaveButton(true);
        //dispatch(changeValueHandler2("declaration", false));//Invalidate declaration
        setAdvanceEnabled(true);
        
      } catch (e) {
        dispatch(errorHandler(e))
      }
    }
  };

  return (
    <form className={classes.formContainer}>
      <div className={classes.formMainHeading}>
        <Typography component={"span"} variant={"body2"}>
          Inspection details
        </Typography>
      </div>
      {/* INFORMATIONS */}
      <Accordion className={classes.accordionContainer}>
        <AccordionSummary
          className={classes.formSubHeading}
          expandIcon={<ExpandMoreIcon color="secondary" />}
        >
          <Typography component={"span"} variant={"body2"}>
            Informations
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          {/*date picker*/}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={classes.formRow}>
              <KeyboardDatePicker
                margin="normal"
                id="inspection_date"
                label="Inspection Date"
                format="yyyy-MM-dd"
                value={currentState.inspection_date}
                onChange={(e) => dispatch(changeValueHandler2("inspection_date", e))}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
          <div className={classes.substatus_row}>
            {/* SINGLE CHECKBOX WONT WORK BECAUSE OF CUSTOM COMPONENT LOGIC
              EITHER DONT USE CHECKBOXES, USE SIGNLE ONE OR ADJUST LOGIC INSIDE IT
            */}

            {/* <Checkboxes
              buttons={["Steel frame erection"]}
              group_label="Stage of Building Work"
              name="stage"
              values={currentState.stage}
              directionRow={true}
              reducer_name="InspectionDetailsForm"
            /> */}

            <TextField
              value={currentState.stage}
              label="Stage of Building Work"
              style={{marginBottom:"10px"}}
              onChange={(e)=>dispatch(changeValueHandler2("stage", e.target.value))}
            />
          </div>
          <Typography component={"span"} variant={"body2"}>
            Description of Building/Structure
          </Typography>
          <Comment name="building_description" reducer_name="inspectionDetailsFormReducer" defaultValue={currentState.building_description} />
          {errorArray.includes("building_description") && (
          <p className={classes.error_msg}>*required</p>
        )}
        </AccordionDetails>
      </Accordion>
      {/* REFERENCE DOCUMENTATION */}
      <Accordion className={classes.accordionContainer}>
        <AccordionSummary
          className={classes.formSubHeading}
          expandIcon={<ExpandMoreIcon color="secondary" />}
        >
          <Typography component={"span"} variant={"body2"}>
            Reference Documentation
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetails}>
          <div className={classes.substatus_row}>
            <Checkboxes
              buttons={ref_doc_buttons_for_inspection_form}
              group_label="Reference documentation"
              name="reference_documentation"
              values={currentState.reference_documentation}
              directionRow={false}
              reducer_name="inspectionDetailsFormReducer"
            />
          </div>
        </AccordionDetails>
      </Accordion>
      {/* OUTCOME */}

      <div className={classes.checkBoxesContainer}>
        <div className={classes.checkBoxRow}>
          <Checkbox
            onChange={(value) => onCheckBoxChange("Agree", value)}
            color="primary"
            className={classes.checkBox}
            value={currentState.declaration}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
          <Typography component="h6" variant="body2" color="textPrimary">
            I have checked all required fields.
          </Typography>
        </div>
        {errorArray.includes("declaration") && (
          <p className={classes.error_msg}>*required</p>
        )}
      </div>

      <Divider className={classes.divider2} />
      <Button
        onClick={() => {
          dispatch(
            selectAllInspectionDetailsForm(
              [...ref_doc_buttons_for_inspection_form]
            )
          );
        }}
        className={classes.selectorButton}
        variant="text"
      >
        Select all
      </Button>
      <div className={classes.generateButtonContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => generateHandler()}
          size="small"
          disabled={saveButton}
        >
          Save
        </Button>
        {showAlert && (
          <Alert
            className={classes.alertClass}
            onClose={() => {
              setShowAlert(false);
            }}
          >
            Form has been saved successfully!
          </Alert>
        )}
        {errorArray.length > 0 && (
          <p className={classes.error_msg}>
            You havent checked some required fields
          </p>
        )}
      </div>
    </form>
  );
};

export default InspectionDetailsForm;
