import { Button, createStyles, makeStyles, Popover, Theme, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { MouseEvent } from 'react'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instructionsContainer: {
      position: "relative", width: "80%"
    },
    instructions: {
      right: 0,
      top: "-35px",
      width:"28px",
      height:"28px",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      position: "absolute",
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      color:  "#fff",
      cursor:"pointer"
    },
    instructionsText: {
      backgroundColor: theme.palette.primary.main,
      maxWidth: '600px',
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "5px",
      padding: "10px",
      fontSize: "14px",
      color: theme.palette.success.main,
    },
    title: {
      fontStyle: "italic",
      textDecoration: "underline",
      marginBottom: "3px"
    },
  })
);

interface InstructionsContainerProps {
  instructionsText: string
}

const InstructionsContainer: React.FC<InstructionsContainerProps> = ({ instructionsText }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'instruction-popover' : undefined;

  return (
    <div className={classes.instructionsContainer}>
      <div
        className={classes.instructions}>
        <HelpIcon onClick={handleClick} style={{fontSize:"26px"}} />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.instructionsText} >
          <Typography className={classes.title} variant="body2">What do you need to do for this step ?</Typography>
          <Typography variant="body2">{instructionsText}</Typography>
        </div>
      </Popover>
    </div>
  )
}

export default InstructionsContainer
