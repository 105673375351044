import React, { useState } from "react";
import {
  Paper,
  Typography,
} from "@material-ui/core";
import Task, { ETaskComponentMode } from "./Task";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { removeHTMLtags } from "../../utils/helpers";
import { useStyles } from "./taskStyles";

const TaskCard = ({ task }: any) => {
  
  const [clickedTask, setClickedTask] = useState<any>(null);
  const classes = useStyles();

  // 1. Recieve task props (data) as param.
  // 2. Render task (small view)
  // 3. On click event -> redirect to tasks/id and pass task.

  const headingClasses = [
    classes.taskHeadingOutstanding,
    classes.taskHeadingInProgress,
    classes.taskHeadingFinished,
  ];

  const dueDateIndicatior = (jobDueDate: string) => {
    const now = moment(new Date()); //todays date
    const end = moment(jobDueDate); // another date
    const duration = moment.duration(end.diff(now));
    const days = duration.asDays();

    if (days > 2) {
      return;
    }

    return (
      <Tooltip title="Due date warning">
        <ErrorOutlinedIcon
          style={
            days < 0
              ? { color: "rgb(208 24 24)", fontSize: "17px" }
              : { color: "orange", fontSize: "17px" }
          }
        />
      </Tooltip>
    );
  };

  return (
    <Paper className={classes.taskContainer}>
      <Task
        task={clickedTask}
        setClickedTask={setClickedTask}
        initialMode={ETaskComponentMode.View}
      />
      <div onClick={() => setClickedTask(task)}>
        <Typography
          className={`
          ${task.taskStatus == "ASSIGNED" && headingClasses[0]}
          ${task.taskStatus == "IN_PROGRESS" && headingClasses[1]}
          ${task.taskStatus == "DONE" && headingClasses[2]}
          `}
          variant="h6"
          component="h6"
        >
          {task.taskStatus !== "DONE" && dueDateIndicatior(task.dueDate)}
          {task.subject}
        </Typography>
        <Typography className={classes.taskDescription}>
          {removeHTMLtags(task.description.substring(0, 75))}...
        </Typography>
        <Typography className={classes.taskInfo}>
          <a href={`${window.location.origin}/jobs/${task.job.id}`}>Job: {task.job.jobNumber}</a>/ Task: #{task.id} <br/>
            Due: <span style={{fontSize:"11px"}}>{task.dueDate.substring(0,10)}</span>
        </Typography>
      </div>
    </Paper>
  );
};

export default TaskCard;
