import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    formRow: {
      marginBottom:"12px"
    },
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content",
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    selectRoleRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "10px",
    },
    selectRoleClass: {
      minWidth: "150px",
    },
    addRoleIcon: {
      "&:hover": {
        cursor: "pointer",
      },
      color: "#030f67",
    },
    linearProgress: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    marginSides10:{
      marginLeft:"10px",
      marginRight:"10px"
    },
    checkBox:{
      color: theme.palette.primary.main
    },
    checkBoxRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    assignTasksRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    TasksViewContainer: {
      backgroundColor: theme.palette.success.main,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "3px",
      marginTop: "20px",
      marginBottom: "50px",
      padding: "10px",
      paddingTop: "20px",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    JobListAndFilterContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "10px",
      maxWidth: "100%",
    },
    containerTitleText: {
      fontFamily: "-webkit-pictograph",
    },
    addTaskButton: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    filterContainer: {
      backgroundColor: "#fff",
      marginTop: "10px",
      marginBottom: "3px",
      width: "100%",
      padding: "7px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      borderRadius: "3px",
    },
    filterColumn:{
      display:"flex",
      flexDirection:"column"
    },
    filterRow:{
      display:"flex",
      flexDirection:"row",
      width:"100%"
    },
    AccordionDetails: {
      padding: "5px",
      display: "flex",
      justifyContent: "center",
    },
    accordionSummary:{
      borderBottom:"2px solid",
      borderBottomColor:theme.palette.primary.main,
      maxHeight:"40px",
      marginTop:"5px",
    },
    searchIcon: {
      color: theme.palette.secondary.main,
    },
    spinner: {
      color: theme.palette.secondary.main,
    },
    floatingLabelFocusStyle: {
      color: theme.palette.text.primary,
    },
    searchIconButton: {
      backgroundColor: theme.palette.primary.main,
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop:"2px",
      maxHeight:"28px",
      borderRadius:"2px",
      width:"100%",
      "&:hover":{
        backgroundColor:theme.palette.primary.light
      }
    },
    taskContainer: {
      backgroundColor: "#FFFFFF",
      padding: "5px",
      width: "100%",
      minWidth: "120px",
      minHeight: "50px",
      marginBottom: "7px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    taskHeading: {
      width: "100%",
      fontSize: "14px",
      textAlign: "center",
    },
    taskInfo: {
      width: "100%",
      textAlign: "center",
      fontSize: "14px",
      color: "#172b4d",
      marginTop: "3px",
    },
    taskDescription: {
      fontSize: "13px",
      color: "#172b4d",
      width: "100%",
      textAlign: "center",
    },
    taskHeadingOutstanding: {
      width: "100%",
      fontSize: "14px",
      textAlign: "center",
      color: "#1f6eb1",
    },
    taskHeadingInProgress: {
      width: "100%",
      fontSize: "14px",
      textAlign: "center",
      color: "green",
    },
    taskHeadingFinished: {
      width: "100%",
      fontSize: "14px",
      textAlign: "center",
      color: "gray",
    },
    pipelineContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      backgroundColor: "#fff",
      flex: 3,
      width: "100%",
      padding: "10px",
      marginTop: "10px",
    },
    pipelineColumn: {
      padding: "5px",
      maxWidth: "300px",
      width:"100%",
      borderRight: "1px solid #e8e8e8",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    columHeading: {
      fontSize: "14px",
      textTransform: "uppercase",
      width: "100%",
      minWidth: "180px",
      textAlign: "center",
      background: "#f5f5f5",
      borderRadius: "3px",
      marginBottom: "5px",
    },
  }));