import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { NavigationBar } from "./components/NavigationBar/NavigationBar";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { TransitionModal } from "./components/TransitionModal/TransitionModal";
import { addCurrentUser } from "./store/actions/user";
import Footer from "./components/Footer/Footer";
import Routes from "./Routes";
import { RootState } from "./store";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { addNotification } from "./store/notifications/actions";
import { resetAuthentication, setAccessToken, setIsAuthenticated } from "./store/authentication/actions";

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  
  const accessToken: string = useSelector((state: RootState) => state.authentication.accessToken);
  
  const {
    user,
    isAuthenticated,
    logout,
    loginWithRedirect,
    loginWithPopup,
    getAccessTokenSilently,
  } = useAuth0();

  let client: any = null;

  //Get errorMessage from redux
  const { errorMessage }: any = useSelector((state: RootState) => state.jobs);

  const loginUser = async () => {
    loginWithRedirect();
  };

  const logoutUser = async (redirectUri: string) => {
    dispatch(resetAuthentication());
    logout({ returnTo: redirectUri });
  };

  useEffect(() => {
    const authenticateAsync = async () => {
      if(isAuthenticated === true) {
        dispatch(setIsAuthenticated(true));
        if(accessToken == null || accessToken == '') {
          var token = await getAccessTokenSilently();
          if (token != null && token != '') {
            const notificationHubUri = `${process.env.REACT_APP_NOTIFICATION_HUB_URL}`;

            dispatch(setAccessToken(token));
            user["roles"] = user["https://www.engiseek.com/roles"];
            dispatch(addCurrentUser(user));
  
            const connect = new HubConnectionBuilder()
            .withUrl(notificationHubUri, { accessTokenFactory: () => getAccessTokenSilently() })
            .withAutomaticReconnect()
            .build();
  
            connect
            .start()
            .then(() => { 
              connect.on("SendNotificationAsync", (notification) => {
                    dispatch(addNotification(notification))
                });
            }).catch((error) => console.log(error));
          }
        }
      } else {
        dispatch(resetAuthentication());
      }
    }
    authenticateAsync();
  }, [isAuthenticated]);

  const getApolloClient = (token: string) => {
    if (client === null) {
      const authLink = setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
          },
        };
      });

      const my_uri = `${process.env.REACT_APP_BACKEND_BASE_URL}/graphql`;

      const httpLink = createHttpLink({
        uri: my_uri,
      });

      client = new ApolloClient({
        uri: my_uri,
        cache: new InMemoryCache({
          addTypename: false
        }),
        link: authLink.concat(httpLink),
      });
    }
    return client;
  };

  return (
    <>

      <ApolloProvider client={getApolloClient(accessToken)}>
        <ApolloHooksProvider client={getApolloClient(accessToken)}>
          <TransitionModal modalMessage={errorMessage} isOpen={errorMessage} />
          <Routes 
       
            token={accessToken} 
            user={user}
            navigation={<NavigationBar
              isAuthenticated={isAuthenticated}
              authButtonMethod={isAuthenticated ? logoutUser : loginUser}
              user={user}
            />}
            footer={ <Footer />}
          
          >
          </Routes>
        </ApolloHooksProvider>
      </ApolloProvider>
     
    </>
  );

};

export default App;
