import { createStyles, Divider, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Camera from "../../components/Camera/Camera";
import FormChecklist from "../../components/FormChecklist/InspectionChecklistForm";
import ApproveOrReject from "../ApproveOrReject";
import ProviderUpload from "../ProviderUpload";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider2:{
      margin:"25px 0px"
    },
  })
);

const Provider = ({job}:any) => {
  const classes = useStyles();
  const [advanceEnabled, setAdvanceEnabled] = useState(false);

  useEffect(() => {
    if(job.jobInspectionChecklist?.data){
      setAdvanceEnabled(true)
    }
  }, [job.jobInspectionChecklist])
  return (
    <div >
      <Camera job={job} doctype="inspectiondoc" />
      <ProviderUpload job={job} doctype="inspectiondoc" />
      <Divider className={classes.divider2} />
      <FormChecklist setAdvanceEnabled={setAdvanceEnabled} job={job} />
      <Divider className={classes.divider2} />
      <ApproveOrReject advanceEnabled={advanceEnabled} job={job} reject={true} checkboxText="I agree that I checked all documentation for this stage." />
    </div>
  );
};

export default Provider;