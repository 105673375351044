import React from "react";

import Container from "@material-ui/core/Container";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles, IconButton } from "@material-ui/core";
import JobDetail from "../components/JobDetail/JobDetail";
import { addCurentJob } from "../store/actions";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import { QUERY_JOB_BY_ID } from "../components/JobList/query";
import { useHistory, useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  AddJobsButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    minWidth:"700px"
  },
  backArrow: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "50%",
    fontSize: "25px"
  },
  formControl: {
    minWidth: 120,
  },
  customPaperClass: {
    marginRight: 5,
    backgroundColor: "#fff",
  },
  fromAndToDate: {
    display: "flex",
    flexDirection: "row",
  },
  filterHeadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerClass: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));


interface ParamsType {
  id: string
}


const JobDetailsView: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<ParamsType | null>();

  const { error, data } = useQuery(
    QUERY_JOB_BY_ID,
    {
      variables: {
        jobId: params?.id,
      }
    },
  );



  return (
    <Container maxWidth="lg" className={classes.containerClass}>
      <div className={classes.AddJobsButtonContainer}>
        {/*Go back button*/}
        <IconButton
          disableFocusRipple
          disableRipple
          onClick={() => {
            dispatch(addCurentJob(null));
            history.push("/jobs");
          }}
        >
          <ArrowBackIcon fontSize="large" className={classes.backArrow} color="primary" />
        </IconButton>
      </div>
      {data && <JobDetail jobProp={(data.readJob)} />}
    </Container>
  );
};

export default JobDetailsView;
