import { ActionTypes, Action } from "../actions";

const initState = {
  currentJob: null,
  jobs: [],
  rowsPerPage: 10,
  page: 0,
  totalJobs: 0,
  doYouNeedRefetch: false,
  errorMessage: null,
  jobListColumnSettings: [
    "jobNumber",
    "state",
    "deliveryDate",
    "organisation",
    "builder",
    "address",
    "level",
    "priority",
  ],
};

export const job = (state = initState, action: Action) => {
  switch (action.type) {
    case ActionTypes.saveJobListColumnSettings:
      return {
        ...state,
        jobListColumnSettings: action.payload,
      };
    case ActionTypes.addCurrentJob:
      return {
        ...state,
        currentJob: action.payload,
      };

    case ActionTypes.fetchJobs:
      if (action.payload) {
        return {
          ...state,
          jobs: action.payload,
        };
      }
      return state;

    case ActionTypes.filterJobs:
      return {
        ...state,
        totalJobs: action.payload.totalJobs,
        jobs: action.payload.jobs,
      };

    case ActionTypes.changePage:
      if (state.page !== action.payload) {
        return {
          ...state,
          page: action.payload,
        };
      }
      return state;

    case ActionTypes.changeRowsPerPage:
      if (state.rowsPerPage !== action.payload) {
        return {
          ...state,
          rowsPerPage: action.payload,
        };
      }
      return state;

    case ActionTypes.saveFilterStateAction:
      return {
        ...state,
        formFilterCurrentState: action.payload,
      };
    case ActionTypes.resetPage:
      return {
        ...state,
        page: 0,
      };

    case ActionTypes.doYouNeedRefetch:
      return {
        ...state,
        doYouNeedRefetch: !state.doYouNeedRefetch,
      };

    case ActionTypes.errorHandler:
      // console.log(action.payload)
      if (action.payload === "") {
        return {
          ...state,
          errorMessage: null,
        };
      } else {
        return {
          ...state,
          errorMessage: action.payload,
        };
      }

    case ActionTypes.clickOnJob:
      return {
        ...state,
        currentJob: action.payload,
      };

    case ActionTypes.advanceJob:
      return {
        ...state,
        currentJob: action.payload,
      };

    case ActionTypes.rejectJob:
      return {
        ...state,
        currentJob: action.payload,
      };

    default:
      return state;
  }
};
