import React from 'react';
import { DefaultEditor } from 'react-simple-wysiwyg';

interface IRichEditor{
    onChangeHandler:any,
    value:any
}

const RichEditor:React.FC<IRichEditor> = ({value, onChangeHandler}) => {
  return (
    <DefaultEditor value={value} onChange={onChangeHandler} />
  );
}

export default RichEditor;