import {
  NotificationActionTypes, 
  Notification,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  FETCH_NOTIFICATIONS,
  SET_NOTIFICATIONS
} from './types'

// TypeScript infers that this function is returning AddNotificationAction
export function addNotification(newNotification: Notification): NotificationActionTypes {
  return {
    type: ADD_NOTIFICATION,
    payload: newNotification
  }
}

export function removeNotification(notification: Notification): NotificationActionTypes {
  return {
    type: REMOVE_NOTIFICATION,
    payload: notification
  }
}

export function setNotifications(notifications: Notification[]): NotificationActionTypes {
  return {
    type: SET_NOTIFICATIONS,
    payload: notifications
  }
}

// TypeScript infers that this function is returning FetchNotificationsAction
export function fetchNotifications(): NotificationActionTypes {
  return {
    type: FETCH_NOTIFICATIONS,
  }
}
