import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    mainContainer:{
        width:"100%",
        textAlign:"center"
    }
  }));


const Unauthorized = () => {
    const classes = useStyles();

    return (
        <div className={classes.mainContainer}>
            <span style={{fontWeight:"bolder"}}>To view job in this stage you need different role</span><br/>
            You dont have right permissions (role) to see this page.
        </div>
    )
}

export default Unauthorized
