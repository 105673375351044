import React, { useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ChipsArray from "./ChipsArray";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useAssignRoleMutation } from "../../generated/graphql";
import { useDispatch } from "react-redux";
import { errorHandler } from "../../store/actions/job";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content",
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    selectRoleRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "10px",
    },
    selectRoleClass: {
      minWidth: "150px",
    },
    addRoleIcon: {
      "&:hover": {
        cursor: "pointer",
      },
      color: "#030f67",
    },
    linearProgress: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  })
);

interface MaxWidthDialogProps {
  clickedUser: any;
  setClickedUser: any;
}

const MaxWidthDialog: React.FC<MaxWidthDialogProps> = ({
  clickedUser,
  setClickedUser,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [refetchData, setRefetchData] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState<string>("");

  useEffect(() => {
    if (clickedUser !== null) {
      setOpen(true);
    }
  }, [clickedUser]);


  const handleClose = () => {
    setClickedUser(null);
    setOpen(false);
  };

  const [assignRole, assignRoleResponse] = useAssignRoleMutation();

  const addHandler = async () => {
    try {
      await assignRole({
        variables: {
          userId: clickedUser.id,
          role: selectedRole,
        },
      });
    } catch (error) {
      dispatch(
        errorHandler(`${error.message}, check your internet connection`)
      );
    }
  };

  //This is used to renew roles in chips after adding role
  useEffect(() => {
    if (assignRoleResponse.data) {
      setRefetchData(true);
    }
  }, [assignRoleResponse.data?.assignRole]);

  if (clickedUser === null) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          {clickedUser.firstName} {clickedUser.lastName}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can click on x to delete user roles. <br /> To add role to the
            user you have to select role click on plus icon.
            <ChipsArray
              user={clickedUser}
              refetchData={refetchData}
              setRefetchData={setRefetchData}
            />
          </DialogContentText>
          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="max-width">Roles</InputLabel>
              <div className={classes.selectRoleRow}>
                <Select
                  className={classes.selectRoleClass}
                  autoFocus
                  value={selectedRole}
                  onChange={(e: React.ChangeEvent<any>) => {
                    setSelectedRole(e.target.value);
                  }}
                  inputProps={{
                    name: "max-width",
                    id: "max-width",
                  }}
                >
                  <MenuItem value="Null"></MenuItem>
                  <MenuItem value="Client">Client</MenuItem>
                  <MenuItem value="Owner">Owner</MenuItem>
                </Select>
                <Button
                  style={{ marginLeft: "5px" }}
                  onClick={() => addHandler()}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  Assign role
                </Button>
                {/* <AddCircleIcon
                  onClick={() => addHandler()}
                  className={classes.addRoleIcon}
                /> */}
              </div>
              {assignRoleResponse.loading && (
                <div className={classes.linearProgress}>
                  <LinearProgress />
                </div>
              )}
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default MaxWidthDialog;
