import {
    createStyles,
    Grid,
    makeStyles,
    Paper,
    Theme,
  } from "@material-ui/core";
import React, { useEffect, useState } from 'react'
import Typography from "@material-ui/core/Typography";
import OrganisationMainInfo from "./OrganisationMainInfo";
import MemberList from "./MemberList";
import Container from "@material-ui/core/Container";
import InviteMember from "./InviteMember";
import { useLazyQuery } from "@apollo/client";
import { READ_USERS } from "../JobList/query";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    OwnerDashboardContainer: {
      backgroundColor: theme.palette.success.main,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      marginTop: theme.spacing(15),
      borderRadius: "3px",
      paddingBottom:"15px"
    },
    avatar: {
      borderRadius: "50%",
      maxWidth:"100px"
    },
    OrganisationMainInfo: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "5px",
      margin: "1px",
      minHeight: "210px",
    },
    userOrganizationPanel: {
      display:"flex",
      justifyContent:"space-around",
      alignItems:"left"
    },
    invitationPanel: {
      display:"flex",
      justifyContent:"flex-start",
      alignItems:"left"
    },
    somethingElse: {
      minHeight: "220px",
    },
    dashboardTitle:{
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.secondary.main,
        textAlign:"center",
        fontWeight:"bolder",
        borderTopLeftRadius: "3px",
        borderTopRightRadius: "3px",
    }
  })
);

interface ProfileProps {
    user: any;
    heading:string
}

const OwnerDashboard:React.FC<ProfileProps> = ({user,heading}) => {
    const classes = useStyles();
    const [organisationUsers, setOrganisationUsers] = useState([])

    const [readUsers, readUsersResponse] = useLazyQuery(READ_USERS)

    useEffect(()=>{
      readUsers();
    },[])

    useEffect(() => {
      if(readUsersResponse.data?.readUsers){
        setOrganisationUsers(readUsersResponse.data.readUsers)
      }
    }, [readUsersResponse])



    return (
      <Container maxWidth="lg">
        <div className={classes.OwnerDashboardContainer}>
        <Grid
          container
          spacing={2}
          style={{ display: "flex", justifyContent: "center" }}
        >
        <Grid item xs={12}>
            <div className={classes.dashboardTitle}>
                {heading}
            </div>
        </Grid>
          <Grid item xs={6} md={6} lg={5}>
            <Paper className={classes.OrganisationMainInfo}>
              <img
                className={classes.avatar}
                src={user.picture}
                alt={`${user.nickname}_alt`}
              />
              <Typography component="h5" variant="h6">
                Owner: {user.nickname}
              </Typography>
              <Typography component="h5" variant="body2">
                {user.email}
              </Typography>
              
            </Paper>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <OrganisationMainInfo user={user} />
          </Grid>

          <Grid item xs={12} md={12} lg={11}>
            <Paper className={classes.userOrganizationPanel}>
              {/* Member list */}
              <MemberList users={organisationUsers} title="Organisation members"/>
              
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={11}>
            <Paper className={classes.invitationPanel}>
              {/* Member list */}
              <InviteMember user={user} />              
            </Paper>
          </Grid>

        </Grid>
      </div>
      </Container>
    )
}

export default OwnerDashboard
