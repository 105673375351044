import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useEffect } from "react";

const Privacy = () => {
  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    indent10px:{
      marginLeft:"10px"
    }
  })
);

const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width:"100%",
        padding: "30px 50px"
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", maxWidth:"800px" }}>
        <h1>
          <h1>Website Privacy Policy</h1>
        </h1>
        <p>
          <strong>Effective starting</strong>: 31 January 2021
        </p>
        <p>
          This Privacy Policy sets out our commitment to protecting the privacy
          of personal information provided to us, or otherwise collected by us,
          offline or online, including through our website{" "}
          <a href="https://engiflow.co/">https://engiflow.co</a> (“Site”) and
          any future devices/mobile applications.
        </p>
        <p>
          Engiseek Pty Ltd trading as EngiFlow and our related entities
          (“EngiFlow”, “us”, “we” and “our”) are committed to protecting the
          privacy of our customers and users .
        </p>
        <p>
          We are committed in treating the personal information we collect in
          accordance with the Australian Privacy Principles in the Privacy Act
          1988 (Cth). This website privacy policy does not apply to personal
          information collected by EngiFlow that is exempt under the Privacy
          Act, for example employee records.
        </p>
        <p>This Privacy Policy describes the reasons:</p>
        <p>· how and why we collect personal information about you;</p>
        <ul>
          <li>how we use this information;</li>
          <li>how we store this information;</li>
        </ul>
        <p>· how we disclose this information; and</p>
        <p>· how you can exercise your privacy rights.</p>
        <p>
          Our Privacy Policy also outlines how you can contact us to access and
          update your personal information or to raise any privacy concerns. We
          recommend that all users of EngiFlow read this Privacy Policy in full
          to ensure you are fully informed.
        </p>
        <p>
          We may update this Privacy Policy from time to time by posting a new
          version on our websites. When an updated Privacy Policy is posted, we
          will change the “Effective starting” date at the top of the policy
          accordingly.
        </p>
        <div>
          <p>1. Personal Information We Collect</p>
        </div>
        <p>
          <a>a) The types of personal information we collect include:</a>
        </p>
        <p className={classes.indent10px}>i. your name;</p>
        <p className={classes.indent10px}>ii. images of you;</p>
        <p className={classes.indent10px}>
          iii. your contact details, including email address, mailing address,
          street address and/or telephone number;
        </p>
        <p className={classes.indent10px}>iv. your marital status and/or anniversary;</p>
        <p className={classes.indent10px}>v. your age and/or date of birth;</p>
        <p className={classes.indent10px}>vi. your credit card details;</p>
        <p className={classes.indent10px}>vii. your demographic information, such as postcode;</p>
        <p className={classes.indent10px}>viii. your preferences and/or opinions;</p>
        <p className={classes.indent10px}>ix. information you provide to us through customer surveys;</p>
        <p className={classes.indent10px}>
          x. details of products and services we have provided to you and/or
          that you have enquired about, and our response to you;
        </p>
        <p className={classes.indent10px}>
          xi. your browser session and geo-location data, device and network
          information, statistics on page views and sessions, acquisition
          sources, search queries and/or browsing behaviour;
        </p>
        <p className={classes.indent10px}>
          xii. information about your access and use of our Site, including
          through the use of Internet cookies, your communications with our
          Site, the type of browser you are using, the type of operating system
          you are using and the domain name of your Internet service provider;
        </p>
        <p className={classes.indent10px}>
          xiii. additional personal information that you provide to us, directly
          or indirectly, through your use of our Site, associated applications,
          associated social media platforms and/or accounts from which you
          permit us to collect information; and
        </p>
        <p className={classes.indent10px}>
          xiv. any other personal information requested by us and/or provided by
          you or a third party.
        </p>
        <p>
          b) We may collect these types of personal information directly from
          you or from third parties.
        </p>
        <div>
          <p>
            <a>2. Collection and Use of Personal Information</a>
          </p>
        </div>
        <p>
          <a>
            a) We may collect, hold, use and disclose personal information for
            the following purposes:
          </a>
        </p>
        <p  className={classes.indent10px}>
          i. to enable you to access and use our Site, associated applications
          and associated social media platforms;
        </p>
        <p  className={classes.indent10px}>ii. to contact and communicate with you;</p>
        <p className={classes.indent10px}>iii. for internal record keeping and administrative purposes;</p>
        <p className={classes.indent10px}>
          iv. for analytics, market research and business development, including
          to operate and improve our Site, associated applications and
          associated social media platforms;
        </p>
        <p className={classes.indent10px}>v. to run competitions and/or offer additional benefits to you;</p>
        <p className={classes.indent10px}>
          vi. for advertising and marketing, including to send you promotional
          information about our products and services and information about
          third parties that we consider may be of interest to you;
        </p>
        <p className={classes.indent10px}>
          vii. to comply with our legal obligations and resolve any disputes
          that we may have; and
        </p>
        <p className={classes.indent10px}>viii. to consider your employment application.</p>
        <p>
          b) You can log into EngiFlow at any time where you can update your
          personal information. If you believe that any information we are
          holding on you is incorrect or incomplete and you cannot update it on
          the Website, please contact us as soon as possible. Where the
          information is inaccurate, we will take reasonable steps to correct
          the information within 30 days, unless you agree otherwise. We will
          not charge you for correcting your personal information.
        </p>
        <div>
          <p>3. Disclosure of Personal Information to Third Parties</p>
        </div>
        <p>a) We may disclose personal information to:</p>
        <p className={classes.indent10px}>
          i. third party service providers for the purpose of enabling them to
          provide their services, including (without limitation) IT service
          providers, data storage, web-hosting and server providers, debt
          collectors, maintenance or problem-solving providers, marketing or
          advertising providers, professional advisors and payment systems
          operators;
        </p>
        <p className={classes.indent10px}>ii. our employees, contractors and/or related entities;</p>
        <p className={classes.indent10px}>iii. our existing or potential agents or business partners;</p>
        <p className={classes.indent10px}>iv. sponsors or promoters of any competition we run;</p>
        <p className={classes.indent10px}>
          v. anyone to whom our business or assets (or any part of them) are, or
          may (in good faith) be, transferred;
        </p>
        <p className={classes.indent10px}>
          vi. credit reporting agencies, courts, tribunals and regulatory
          authorities, in the event you fail to pay for goods or services we
          have provided to you;
        </p>
        <p className={classes.indent10px}>
          vii. courts, tribunals, regulatory authorities and law enforcement
          officers, as required by law, in connection with any actual or
          prospective legal proceedings, or in order to establish, exercise or
          defend our legal rights;
        </p>
        <p className={classes.indent10px}>
          viii. third parties, including agents or sub-contractors, who assist
          us in providing information, products, services or direct marketing to
          you. This may include parties located, or that store data, outside of
          Australia;
        </p>
        <p className={classes.indent10px}>
          ix. third parties to collect and process data, such as Google
          Analytics or other relevant businesses. This may include parties that
          store data outside of Australia.
        </p>
        <p>
          b) By providing us with personal information, you consent to the
          disclosure of your personal information to third parties who reside
          outside Australia and acknowledge that we are not required to ensure
          that those third parties comply with Australian privacy laws.
        </p>
        <div>
          <p>4. Direct Marketing</p>
        </div>
        <p>
          a) We may only use personal information we collect from you for the
          purposes of direct marketing without your consent if:
        </p>
        <p  className={classes.indent10px}>
          i. The personal information does not include sensitive information;
        </p>
        <p className={classes.indent10px}>
          ii. You would reasonably expect us to use or disclose the information
          for the purpose of direct marketing;
        </p>
        <p className={classes.indent10px}>
          iii. We provide a simple way of opting out of direct marketing; and
        </p>
        <p className={classes.indent10px}>
          iv. You have not requested to opt out of receiving direct marketing
          from us.
        </p>
        <p>
          b) If you wish to opt out of receiving direct marketing, contact us.
        </p>
        <p>
          c) If we collect personal information about you from a third party, we
          will only use that information for the purposes of direct marketing if
          you have consented (or it is impracticable to obtain your consent).
          You can easily request not to receive direct marketing communications
          from us by contacting us. We will draw your attention to the fact you
          may make such a request in our direct marketing communications.
        </p>
        <p>
          d) You have the right to request us not to use or disclose your
          personal information for the purposes of direct marketing, or for the
          purposes of facilitating direct marketing by other organisations. We
          will give effect to the request within a reasonable time. You may also
          request that we provide you with the source of their information. If
          such a request is made, we must notify you of the source of the
          information free of charge within a reasonable period of time.
        </p>
        <div>
          <p>
            5. How we treat personal information that is also sensitive
            information
          </p>
        </div>
        <p>
          a) Sensitive information is a subset of personal information that is
          given a higher level of protection under the Australian Privacy
          Principles. <strong></strong>
        </p>
        <p>
          b) Sensitive information means information relating to:
          <strong></strong>
        </p>
        <p className={classes.indent10px}>i. an individual’s racial or ethnic origin;</p>
        <p className={classes.indent10px}>ii. health information;</p>
        <p className={classes.indent10px}>iii. political opinions;</p>
        <p className={classes.indent10px}>iv. membership of a political association;</p>
        <p className={classes.indent10px}>v. professional or trade association or trade union;</p>
        <p className={classes.indent10px}>vi. religious beliefs or affiliations;</p>
        <p className={classes.indent10px}>vii. philosophical beliefs;</p>
        <p className={classes.indent10px}>viii. sexual orientation or practices;</p>
        <p className={classes.indent10px}>ix. criminal record;</p>
        <p className={classes.indent10px}>x. genetic information; or</p>
        <p className={classes.indent10px}>
          xi. biometric information that is to be used for certain purposes, and
          biometric templates.
        </p>
        <div>
          <p>6. Your rights and controlling your personal information</p>
        </div>
        <p>
          a) <strong>Choice and consent</strong>: Please read this Privacy
          Policy carefully. By providing personal information to us, you consent
          to us collecting, holding, using and disclosing your personal
          information in accordance with this Privacy Policy. You do not have to
          provide personal information to us, however, if you do not, it may
          affect your use of this Site or the products and/or services offered
          on or through it. <strong></strong>
        </p>
        <p>
          b) <strong>Information from third parties:</strong> If we receive
          personal information about you from a third party, we will protect it
          as set out in this Privacy Policy. If you are a third party providing
          personal information about somebody else, you represent and warrant
          that you have such person’s consent to provide the personal
          information to us.
        </p>
        <p>
          c) <strong>Restrict:</strong> You may choose to restrict the
          collection or use of your personal information. If you have previously
          agreed to us using your personal information for direct marketing
          purposes, you may change your mind at any time by contacting us using
          the details below. <strong></strong>
        </p>
        <p>
          d) <strong>Access:</strong> You may request details of the personal
          information that we hold about you. Subject to exceptions in the
          Privacy Act, you can access the personal information that we hold
          about you by contacting the Privacy Officer. We will generally provide
          access within 30 days of your request. If we refuse to provide you
          with access to the information, we will provide reasons for the
          refusal. We will require identity verification and specific
          particulars of what information is required and may charge an
          administrative fee for searching and photocopying our records.
          <strong></strong>
        </p>
        <p>
          e) <strong>Correction:</strong> If you believe that any information we
          hold about you is inaccurate, out of date, incomplete, irrelevant or
          misleading, please contact us using the details below. We will take
          reasonable steps to correct any information found to be inaccurate,
          incomplete, misleading or out of date.
        </p>
        <p>
          f) <strong>Complaints:</strong> If you believe that we have breached
          the Australian Privacy Principles and wish to make a complaint, please
          contact us using the details below and provide us with full details of
          the alleged breach. We will promptly investigate your complaint and
          respond to you, in writing, setting out the outcome of our
          investigation and the steps we will take to deal with your complaint.
          <strong></strong>
        </p>
        <p>
          g) <strong>Unsubscribe:</strong> To unsubscribe from our e-mail
          database or opt-out of communications (including marketing
          communications), please contact us using the details below or opt-out
          using the opt-out facilities provided in the communication.
          <strong></strong>
        </p>
        <div>
          <p>7. Unsolicited information</p>
        </div>
        <p>
          a) If we receive unsolicited personal information about you, we will
          destroy or de-identify this information unless it is relevant to the
          purposes for which we collect personal information.<strong></strong>
        </p>
        <div>
          <p>8. Storage and Security</p>
        </div>
        <p>
          a) We are committed to ensuring that the personal information we
          collect is secure. In order to prevent unauthorised access or
          disclosure, we have put in place suitable physical, electronic and
          managerial procedures to safeguard and secure the personal information
          and protect it from misuse, interference, loss and unauthorised
          access, modification and disclosure. <strong></strong>
        </p>
        <p>
          b) We cannot guarantee the security of any information that is
          transmitted to or by us over the Internet. The transmission and
          exchange of information is carried out at your own risk. Although we
          take measures to safeguard against unauthorised disclosures of
          information, we cannot assure you that the personal information we
          collect will not be disclosed in a manner that is inconsistent with
          this Privacy Policy.<strong></strong>
        </p>
        <p>
          c) We use TLS encryption and authentication and we store all of our
          data on servers in a secure facility. We utilise hosting and DDOS
          protection from Microsoft Azure. We implement systematic processes and
          procedures for securing and storing data.<strong></strong>
        </p>
        <p>
          d) This site has been issued certificates from Let’s Encrypt, which
          are used for web services and email authentication. These certificates
          use a high standard of authentication and were specifically utilised
          to boost and maintain customer confidence in ecommerce through a
          rigorous verification process. You can identify this certificate by
          the green lock in the address bar. These certificates incorporate some
          of the highest standards for identity assurance to establish our
          legitimacy.<strong></strong>
        </p>
        <p>
          e) We will retain the information we collect from you for a period of
          7 years, as required by law, even if you cancel your membership with
          us. <strong></strong>
        </p>
        <div>
          <p>9. Cookies and web beacons</p>
        </div>
        <p>
          a) We may use cookies on our Site from time to time. Cookies are text
          files placed in your computer's browser to store your preferences.
          Cookies, by themselves, do not tell us your email address or other
          personally identifiable information. However, they do allow third
          parties, such as Google and Facebook, to cause our advertisements to
          appear on your social media and online media feeds as part of our
          retargeting campaigns. If and when you choose to provide our Site with
          personal information, this information may be linked to the data
          stored in the cookie. <strong></strong>
        </p>
        <p>
          b) We may use web beacons on our Site from time to time. Web beacons
          (also known as Clear GIFs) are small pieces of code placed on a web
          page to monitor the visitor’s behaviour and collect data about the
          visitor’s viewing of a web page. For example, web beacons can be used
          to count the users who visit a web page or to deliver a cookie to the
          browser of a visitor viewing that page.<strong></strong>
        </p>
        <div>
          <p>10. Links to other websites</p>
        </div>
        <p>
          a) Our Site may contain links to other websites. <strong></strong>
        </p>
        <p>
          b) Once you use these links to leave our Site, we do not have control
          over those websites. Therefore, we are not responsible for the
          protection and privacy of any information that you provide on those
          sites and such sites are not governed by this Privacy Policy. You
          should exercise caution and look at the privacy statement applicable
          to the website in question. Those websites are not governed by this
          Privacy Policy.<strong></strong>
        </p>
        <div>
          <p>11. Amendments</p>
        </div>
        <p>
          a) We may, at any time and at our discretion, vary this Privacy Policy
          by publishing the amended Privacy Policy on our Site. We recommend you
          check our Site regularly to ensure you are aware of our current
          Privacy Policy. <strong></strong>
        </p>
        <p>
          b) For any questions, notices or if you wish to make a complaint,
          please contact our Privacy Officer, Kathleen Sheehy, at:
          <strong></strong>
        </p>
        <p className={classes.indent10px}>i. EngiSeek Pty Ltd ABN [ 21 625 532 096]</p>
        <p className={classes.indent10px}>
           ii.
          Email:{" "}
          <a href="mailto:engiflow@engiseek.com">engiflow@engiseek.com</a>{" "}
          <strong></strong>
        </p>
        <p>
          c) If you are not satisfied with our response to your complaint, you
          can also refer your complaint to the Officer of the Australian
          Information Commissioner by:<strong></strong>
        </p>
        <p className={classes.indent10px}>iii. Telephoning: 1300 363 992</p>
        <p className={classes.indent10px}>
          iv. Writing: Director of Complaints, Office of the Australian
          Information Commissioner, GPO Box 5218, SYDNEY NSW 2001
        </p>
        <p className={classes.indent10px}>
          v. Emailing:{" "}
          <a href="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</a>
        </p>
        <p>
          <strong>Last updated: 18 February 2021</strong>
        </p>
        <br />
      </div>
    </div>
  );
};

export default Privacy;
