import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import FileList from "../FileList/FileList";
import JobHistory from "./JobHistory";
import JobComments from "../JobComments/JobComments";
import TaskActions from "../Tasks/TaskActions";
import AssignTasksTable from "../Tasks/AssignTasksTable";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import StateChange from "../StateChange/StateChange";

const useStyles = makeStyles({
	root: {
		height: 240,
		flexGrow: 1,
		minWidth: 550,
		display: "contents",
	},
	treeItem: {
		marginBottom: 10,
	},
	treeContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		width: "90%",
		minWidth: "600px",
	},
	downloadIcon: {
		"&:hover": {
			cursor: "pointer",
		},
	},
});

interface DocumentTreeProps {
	alldocuments: {
		architecturaldoc: [];
		detailingdoc: [];
		engineeringdoc: [];
		inspectiondoc: [];
		designform: [];
		prelimdesignform: [];
		inspectionform: [];
	};
	job: any;
	childrenComponent?: any;
}

const DocumentTree: React.FC<DocumentTreeProps> = ({ alldocuments, job, childrenComponent }) => {
	const classes = useStyles();
	const { user }: any = useSelector((state: RootState) => state.user);

	return (
		<div className={classes.treeContainer}>
			<TreeView className={classes.root} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
				{(user.roles.includes("Manager") || user.roles.includes("Administrator")) && (
					<TreeItem nodeId="13" label="Change State" children={<StateChange job={job} />} />
				)}
				{childrenComponent && <TreeItem nodeId="0" children={childrenComponent} label="Job Details" />}
				<TreeItem nodeId="1" label="All Documents">
					<TreeItem
						className={classes.treeItem}
						nodeId="2"
						label="Architectural"
						children={<FileList documents={alldocuments.architecturaldoc} jobID={job.id} doctype="architecturaldoc" />}
					/>
					<TreeItem
						className={classes.treeItem}
						nodeId="3"
						label="Detailing"
						children={<FileList documents={alldocuments.detailingdoc} jobID={job.id} doctype="detailingdoc" />}
					/>
					<TreeItem
						className={classes.treeItem}
						nodeId="4"
						label="Engineering"
						children={<FileList documents={alldocuments.engineeringdoc} jobID={job.id} doctype="engineeringdoc" />}
					/>
					{job.organisation.prelimDesignFormPermission && (
						<TreeItem
							className={classes.treeItem}
							nodeId="5"
							label="Preliminary Design Form"
							children={<FileList documents={alldocuments.prelimdesignform} jobID={job.id} doctype="prelimdesignform" />}
						/>
					)}
					<TreeItem
						className={classes.treeItem}
						nodeId="6"
						label="Design Form"
						children={<FileList documents={alldocuments.designform} jobID={job.id} doctype="designform" />}
					/>
					<TreeItem
						className={classes.treeItem}
						nodeId="7"
						label="Inspection"
						children={<FileList documents={alldocuments.inspectiondoc} jobID={job.id} doctype="inspectiondoc" />}
					/>
					<TreeItem
						className={classes.treeItem}
						nodeId="8"
						label="Inspection Form"
						children={<FileList documents={alldocuments.inspectionform} jobID={job.id} doctype="inspectionform" />}
					/>
				</TreeItem>
				<TreeItem
					style={{ maxWidth: "730px" }}
					nodeId="10"
					label="History"
					children={<JobHistory jobId={job.id} jobHistoryData="test" />}
				/>
				<TreeItem nodeId="11" label="Comments" children={<JobComments jobId={job.id} />} />
				{user.roles.includes("Manager") && (
					<TreeItem
						nodeId="12"
						label="Tasks"
						children={
							<div>
								<AssignTasksTable job={job} />
								{/* <TaskActions task={null} jobId={jobID} /> */}
							</div>
						}
					/>
				)}
			</TreeView>
		</div>
	);
};

export default DocumentTree;
