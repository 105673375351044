import { useFormik } from "formik";
import * as yup from "yup";
import { useRequestQuoteMutation } from "../../generated/graphql";
import { useDispatch } from "react-redux";
import { doYouNeedRefetch, errorHandler } from "../../store/actions";


const validationSchema = yup.object({
    eMail: yup.string().email().required().label("Email"),
    firstName: yup.string().required().label("First Name"),
    lastName: yup.string().required().label("Last Name"),
    mobileNumber: yup.string().required().label("Phone"),
    message: yup.string().max(1000).required().label("Message"),
});



const useRequestQuote = (formSubmitted: () => void) => {
    const dispatch = useDispatch();
    const formikRequestQuote = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            mobileNumber: "",
            message: "",
            eMail: "",
        },
        validationSchema: validationSchema,

        onSubmit: async (values, { resetForm }) => {
            try {
                const result = await requestQuote({
                    variables: {
                        RequestQuote: {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            mobileNumber: values.mobileNumber,
                            message: values.message,
                            eMail: values.eMail,
                        }
                    },
                });
                resetForm({})
                formSubmitted();

            } catch (e) {
                dispatch(errorHandler("Check your internet connection " + e));
            }
        },
    });

    const [requestQuote, requestQuoteResponse] = useRequestQuoteMutation();

    return {
        formikRequestQuote,
        requestQuote,
        requestQuoteResponse
    }
}

export default useRequestQuote;