import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import { doYouNeedRefetch } from "../../store/actions";
import {
  Divider,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  getJobStateName2,
  getTaskStateName,
} from "../../utils/helpers";
import TaskActions from "./TaskActions";
import { useStyles } from "./taskStyles";
import {
  TaskStatus,
  useUpdateTaskStateMutation,
} from "../../generated/graphql";

interface ITask {
  task: any;
  setClickedTask?: any;
  initialMode: ETaskComponentMode;
}

export enum ETaskComponentMode {
  "View",
  "Create",
  "Update",
}

const Task: React.FC<ITask> = ({ task, setClickedTask, initialMode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = React.useState<ETaskComponentMode>(initialMode);
  const [taskDone, setTaskDone] = React.useState<boolean>(true);
  const [taskStatus, setTaskState] = React.useState<TaskStatus | any>(
    "ASSIGNED"
  );

  const headingClasses = [
    classes.taskHeadingOutstanding,
    classes.taskHeadingInProgress,
    classes.taskHeadingFinished,
  ];

  const [
    updateTaskState,
    updateTaskStateResponse,
  ] = useUpdateTaskStateMutation();

  const changeStatusHandler = (newStatus: TaskStatus) => {
    updateTaskState({
      variables: {
        taskId: task.id,
        taskStatus: newStatus,
      },
    });
  };

  useEffect(() => {
    if (updateTaskStateResponse.data?.updateTaskState) {
      dispatch(doYouNeedRefetch());
    }
  }, [updateTaskStateResponse.data]);

  useEffect(() => {
    if (updateTaskStateResponse.data) {
      if (updateTaskStateResponse.data.updateTaskState) {
        setTaskState(updateTaskStateResponse.data.updateTaskState.taskStatus);
      }
    } else {
      if (task) {
        setTaskState(task.taskStatus);
      }
    }
  }, [updateTaskStateResponse]);

  const handleClose = () => {
    setMode(ETaskComponentMode.View);
    setClickedTask(null);
    setOpen(false);
  };

  useEffect(() => {
    if (task !== null) {
      setOpen(true);
    }
  }, [task]);

  const renderTask = () => {
    switch (mode) {
      case ETaskComponentMode.View:
        return (
          <div>
            <Typography
              style={{ maxWidth: "750px", minWidth:"500px" }}
              variant="body1"
              component="h5"
              dangerouslySetInnerHTML={{ __html: task.description }}
            ></Typography>
            <br />
            <Divider />
            <br />
            <DialogContentText style={{ color: "black" }}>
              <a
                href={`
              ${window.location.origin}/jobs/${task.job.id}`}
              >
                {`Job ID: ${task.job.id} | Job number: ${task.job.jobNumber}`}
              </a>
              <br />
              {`Assigned by: ${task.assignedBy?.firstName} ${task.assignedBy?.lastName}`}
              <br />
              {`Assigned to: ${task.assignedTo?.firstName} ${task.assignedTo?.lastName}`}
              <br />
              {`Due date: ${task.dueDate.substring(0, 10)}`}
              <br />
              {` Finished date: ${
                task.finishedDate !== "0001-01-01T00:00:00" ? task.finishedDate.substring(0,10) : "Not yet finished"
              }`}
              <br />
              {`Job state: ${getJobStateName2(task.job.state)}`}
              <br />
              Task state:{" "}
              <span
                className={`
          ${task.taskStatus == "ASSIGNED" && headingClasses[0]}
          ${task.taskStatus == "IN_PROGRESS" && headingClasses[1]}
          ${task.taskStatus == "DONE" && headingClasses[2]}
          `}
              >
                {" "}
                {getTaskStateName(task.taskStatus)}{" "}
              </span>
              <br />
            </DialogContentText>
            <br />
            <Divider />
            <br />
            <div>
              {/* Task State */}
              <Typography
                style={{ fontWeight: "bold" }}
                component="h6"
                variant="body1"
              >
                Task State
              </Typography>
              <Select
                className={classes.formRow}
                style={{ minWidth: "200px" }}
                id="taskStatus"
                name="taskStatus"
                label="Task State"
                value={taskStatus}
                onChange={(et: React.ChangeEvent<any>) =>
                  changeStatusHandler(et.target.value)
                }
              >
                <MenuItem value={taskStatus}>
                  <em></em>
                </MenuItem>
                <MenuItem value="ASSIGNED">Outstanding</MenuItem>
                <MenuItem value="IN_PROGRESS">In progress</MenuItem>
                <MenuItem value="DONE">Finished</MenuItem>
              </Select>
            </div>
          </div>
        );
      case ETaskComponentMode.Update:
        return <TaskActions task={task} />;
      case ETaskComponentMode.Create:
        return <TaskActions />;
      default:
        break;
    }
  };

  if (task) {
    return (
      <React.Fragment>
        <Dialog
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">
            {`Task ID: ${task.id} - ${task.subject}`}
          </DialogTitle>
          <DialogContent>{renderTask()}</DialogContent>
          <DialogActions>
            {mode === ETaskComponentMode.View && (
              <Button
                onClick={() => setMode(ETaskComponentMode.Update)}
                color="primary"
                disabled={!taskDone || task.taskStatus == "DONE"}
              >
                Edit
              </Button>
            )}
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  } else {
    return <></>;
  }
};

export default Task;
