import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DateFnsUtils from "@date-io/date-fns";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HelpIcon from "@material-ui/icons/Help";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  makeStyles,
  IconButton,
  Divider,
  MenuItem,
  Select,
  InputLabel,
  Checkbox,
  Typography,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import {
  PriorityEnum,
  useCreateJobMutation,
  useFilterJobsLazyQuery,
  WindRatingEnum,
} from "../../generated/graphql";
import { useDispatch } from "react-redux";
import { doYouNeedRefetch, errorHandler } from "../../store/actions";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  NewJobFormMain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
    maxWidth: "500px",
    marginBottom: "50px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  AddJobsButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  backArrow: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "50%",
    fontSize: "25px",
  },
  addJobFormContainer: {
    padding: "10px",
  },
  formRow: {
    marginBottom: "10px",
  },
  checkBoxesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    maxWidth: "65%",
  },
  checkBoxRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  checkBoxControl: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  checkBox: {
    color: theme.palette.primary.main,
  },
  helpIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  error_msg: {
    color: "red",
  },
  linearIndeterminate: {
    marginTop: "10px",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const validationSchema = yup.object({
  jobNumber: yup.string().defined(),
  builder: yup.string().defined(),
  buildingDescription: yup.string(),
  storeys: yup.number().integer().min(1),
  deliveryDate: yup.date().required(),
  level: yup.string().defined(),
  priority: yup.string().defined(),
  detailingRequested: yup.boolean().defined(),
  engineeringRequested: yup.boolean().defined(),
  prelimDesignRequested: yup.boolean().defined(),
  inspectionRequested: yup.boolean().defined(),
  street: yup.string().defined(),
  streetNumber: yup.string().defined(),
  postcode: yup.number().defined(),
  suburb: yup.string().defined(),
  province: yup.string().defined(),
  localGovernment: yup.string().defined(),
  lot: yup.string().defined(),
  windRating: yup.string().defined(),
})
.test(
  'atleast-one-service-requested',
  'At least one service must be requested',
  (state) => {
    if (state?.detailingRequested || state?.engineeringRequested || state?.inspectionRequested) {
      return true;
    }
    return new yup.ValidationError(
      'At least one service must be requested',
      null,
      'inspectionRequested'
    );
  }
);


// 17 fields
enum addJobField {
  jobNumber = "Job Number",
  builder = "Builder",
  buildingDescription = "Description of Building/Structure",
  storeys = "Number of Storeys",
  deliveryDate = "Delivery Date",
  level = "Level",
  priority = "Priority",
  detailingRequested = "Detailing",
  engineeringRequested = "Engineering",
  prelimDesignRequested = "Preliminary Design Form",
  inspectionRequested = "Inspection",
  street = "Street Name",
  streetNumber = "Street Number",
  postcode = "Post Code",
  suburb = "Suburb",
  province = "State/Province",
  localGovernment = "Local Government",
  lot = "Lot",
  windRating = "Wind Rating",
}

export const NewJobForm: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [filterJobs, filterJobsResp] = useFilterJobsLazyQuery();

  const onChangeDateHandler = (fieldName: string, value: any) => {
    let date = JSON.stringify(value);
    date = date.slice(1, 11);
    formik.setFieldValue(fieldName, date);
  };

  const onCheckBoxChange = (fieldName: string, event: any) => {
    formik.setFieldValue(fieldName, event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      jobNumber: "",
      builder: "",
      buildingDescription: "",
      storeys: 1,
      deliveryDate: null,
      level: "",
      priority: PriorityEnum.Normal,
      detailingRequested: false,
      engineeringRequested: false,
      prelimDesignRequested: false,
      inspectionRequested: false,
      street: "",
      streetNumber: "",
      postcode: "",
      suburb: "",
      province: "",
      localGovernment: "",
      lot: "",
      windRating: WindRatingEnum.C_1,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      try {
        await addjob();
        dispatch(doYouNeedRefetch());
      } catch (error) {
        dispatch(
          errorHandler(`${error.message}, check your internet connection`)
        );
      }
    },
  });

  const [addjob, { data, loading, error }] = useCreateJobMutation({
    variables: {
      job: {
        jobNumber: formik.values.jobNumber,
        builder: formik.values.builder,
        buildingDescription: formik.values.buildingDescription,
        storeys: formik.values.storeys,
        deliveryDate: formik.values.deliveryDate,
        level: formik.values.level,
        priority: formik.values.priority,
        detailingRequested: formik.values.detailingRequested,
        engineeringRequested: formik.values.engineeringRequested,
        prelimDesignRequested: formik.values.prelimDesignRequested,
        inspectionRequested: formik.values.inspectionRequested,
        jobAddress: {
          street: formik.values.street,
          streetNumber: formik.values.streetNumber,
          postcode: formik.values.postcode,
          suburb: formik.values.suburb,
          province: formik.values.province,
          localGovernment: formik.values.localGovernment,
          lot: formik.values.lot,
        },
        windRating: formik.values.windRating,
      },
    },
  });

  useEffect(() => {
    if (filterJobsResp.data?.filterJobs?.jobs) {
      if (filterJobsResp.data?.filterJobs?.jobs.length > 0) {
        const existingJobs = filterJobsResp.data?.filterJobs?.jobs.filter(filteredJob => filteredJob?.jobNumber === formik.values.jobNumber);
        if (existingJobs.length > 0) {
          const existingJob = existingJobs[0]!;
          formik.setFieldError('jobNumber', `Job #${existingJob.jobNumber} already exists`);
          formik.setFieldTouched('jobNumber', true);
          return;
        }
      }
      formik.setFieldError('jobNumber', undefined);
    }
  }, [filterJobsResp]);

  const checkUniqueJobNumber = () => {
    filterJobs({
      variables: {
        page: 1,
        rowsPerPage: 1,
        jobFilterCriteria: {
          jobNumber: formik.values.jobNumber
        }
      }
    });
  };

  if (data) {
    return (
      <div className={classes.NewJobFormMain}>
        {/*Go back button*/}
        <div className={classes.AddJobsButtonContainer}>
          <IconButton
            disableFocusRipple
            onClick={() => history.replace("/jobs")}
          >
            <ArrowBackIcon
              fontSize="large"
              className={classes.backArrow}
              color="primary"
            />{" "}
            Go Back
          </IconButton>
        </div>
        <div>
          Job created successfully
          <CheckCircleIcon fontSize="large" style={{ color: "green" }} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.NewJobFormMain}>
      {/*Go back button*/}
      <div className={classes.AddJobsButtonContainer}>
        <IconButton
          disableFocusRipple
          onClick={() => history.push("/jobs")}
        >
          <ArrowBackIcon
            fontSize="large"
            className={classes.backArrow}
            color="primary"
          />
        </IconButton>
      </div>

      <Paper>
        <Divider light />

        <form
          onSubmit={formik.handleSubmit}
          className={classes.addJobFormContainer}
        >
          {/*id*/}
          <TextField
            className={classes.formRow}
            fullWidth
            id="jobNumber"
            name="jobNumber"
            label={addJobField.jobNumber}
            value={formik.values.jobNumber}
            onChange={e => {
              formik.handleChange(e);
            }}
            onBlur={checkUniqueJobNumber}
            error={formik.touched.jobNumber && Boolean(formik.errors.jobNumber)}
            helperText={formik.touched.jobNumber && formik.errors.jobNumber}
          />
          {/*builder*/}
          <TextField
            className={classes.formRow}
            fullWidth
            id="builder"
            name="builder"
            label={addJobField.builder}
            value={formik.values.builder}
            onChange={formik.handleChange}
            error={formik.touched.builder && Boolean(formik.errors.builder)}
            helperText={formik.touched.builder && formik.errors.builder}
          />
          {/*description*/}
          <TextField
            className={classes.formRow}
            fullWidth
            multiline
            rows={4}
            id="buildingDescription"
            name="buildingDescription"
            label={addJobField.buildingDescription}
            value={formik.values.buildingDescription}
            onChange={formik.handleChange}
            error={
              formik.touched.buildingDescription &&
              Boolean(formik.errors.buildingDescription)
            }
            helperText={
              formik.touched.buildingDescription &&
              formik.errors.buildingDescription
            }
          />
          {/*storeys*/}
          <TextField
                className={classes.formRow}
                fullWidth
                rows={4}
                id="storeys"
                name="storeys"
                label={addJobField.storeys}
                value={formik.values.storeys}
                onChange={formik.handleChange}
                error={
                  formik.touched.storeys &&
                  Boolean(formik.errors.storeys)
                }
                helperText={
                  formik.touched.storeys &&
                  formik.errors.storeys
                }
              />
          {/*date picker*/}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={classes.formRow}>
              <KeyboardDatePicker
                margin="normal"
                id="deliveryDate"
                label={addJobField.deliveryDate}
                format="yyyy-MM-dd"
                value={formik.values.deliveryDate}
                onChange={(value) => onChangeDateHandler("deliveryDate", value)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
          {Boolean(formik.errors.deliveryDate) && (
            <p className={classes.error_msg}>Delivery date is invalid</p>
          )}

          {/*streetNumber*/}
          <TextField
            className={classes.formRow}
            fullWidth
            id="streetNumber"
            name="streetNumber"
            label={addJobField.streetNumber}
            value={formik.values.streetNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.streetNumber && Boolean(formik.errors.streetNumber)
            }
            helperText={
              formik.touched.streetNumber && formik.errors.streetNumber
            }
          />
          {/* street name */}
          <TextField
            className={classes.formRow}
            fullWidth
            id="street"
            name="street"
            label={addJobField.street}
            value={formik.values.street}
            onChange={formik.handleChange}
            error={formik.touched.street && Boolean(formik.errors.street)}
            helperText={formik.touched.street && formik.errors.street}
          />
          {/*postcode*/}
          <TextField
            className={classes.formRow}
            fullWidth
            id="postcode"
            name="postcode"
            label={addJobField.postcode}
            value={formik.values.postcode}
            onChange={formik.handleChange}
            error={formik.touched.postcode && Boolean(formik.errors.postcode)}
            helperText={formik.touched.postcode && formik.errors.postcode}
          />
          {/*suburb*/}
          <TextField
            className={classes.formRow}
            fullWidth
            id="suburb"
            name="suburb"
            label={addJobField.suburb}
            value={formik.values.suburb}
            onChange={formik.handleChange}
            error={formik.touched.suburb && Boolean(formik.errors.suburb)}
            helperText={formik.touched.suburb && formik.errors.suburb}
          />
          {/*province*/}
          <InputLabel>{addJobField.province}</InputLabel>
          <Select
            className={classes.formRow}
            fullWidth
            id="province"
            name="province"
            label={addJobField.province}
            value={formik.values.province}
            onChange={formik.handleChange}
            error={formik.touched.province && Boolean(formik.errors.province)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Queensland">Queensland</MenuItem>
            <MenuItem value="New South Wales">New South Wales</MenuItem>
            <MenuItem value="Victoria">Victoria</MenuItem>
            <MenuItem value="South Australia">South Australia</MenuItem>
            <MenuItem value="Tasmania">Tasmania</MenuItem>
            <MenuItem value="Western Australia">Western Australia</MenuItem>
            <MenuItem value="Australian Capital Territory">
              Australian Capital Territory
            </MenuItem>
          </Select>
          {/*localGovernment*/}
          <TextField
            className={classes.formRow}
            fullWidth
            id="localGovernment"
            name="localGovernment"
            label={addJobField.localGovernment}
            value={formik.values.localGovernment}
            onChange={formik.handleChange}
            error={
              formik.touched.localGovernment &&
              Boolean(formik.errors.localGovernment)
            }
            helperText={
              formik.touched.localGovernment && formik.errors.localGovernment
            }
          />
          {/*Level*/}
          <TextField
            className={classes.formRow}
            fullWidth
            id="level"
            name="level"
            label={addJobField.level}
            value={formik.values.level}
            onChange={formik.handleChange}
            error={formik.touched.level && Boolean(formik.errors.level)}
            helperText={formik.touched.level && formik.errors.level}
          />
          {/*priority*/}
          <InputLabel>{addJobField.priority}</InputLabel>
          <Select
            className={classes.formRow}
            fullWidth
            id="priority"
            name="priority"
            label={addJobField.priority}
            value={formik.values.priority}
            onChange={formik.handleChange}
            error={formik.touched.priority && Boolean(formik.errors.priority)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={PriorityEnum.Normal}>Normal</MenuItem>
            <MenuItem value={PriorityEnum.Urgent}>Urgent</MenuItem>
          </Select>
          {/*wind rating*/}
          {/* https://i-build.com.au/wp-content/uploads/2017/06/Wind-ratings-table.png */}
          <InputLabel>
            <a
              target="blank"
              href="https://i-build.com.au/wp-content/uploads/2017/06/Wind-ratings-table.png"
            >
              <Tooltip
                title={
                  <img
                    style={{ maxWidth: "980px" }}
                    src="https://i-build.com.au/wp-content/uploads/2017/06/Wind-ratings-table.png"
                    alt="wind rating table"
                  />
                }
              >
                <HelpIcon color="primary" className={classes.helpIcon} />
              </Tooltip>
            </a>
            {addJobField.windRating}
          </InputLabel>
          <Select
            className={classes.formRow}
            fullWidth
            id="windRating"
            name="windRating"
            label={addJobField.windRating}
            value={formik.values.windRating}
            onChange={formik.handleChange}
            error={
              formik.touched.windRating && Boolean(formik.errors.windRating)
            }
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={WindRatingEnum.N_1}>N1</MenuItem>
            <MenuItem value={WindRatingEnum.N_2}>N2</MenuItem>
            <MenuItem value={WindRatingEnum.N_3}>N3</MenuItem>
            <MenuItem value={WindRatingEnum.N_4}>N4</MenuItem>
            <MenuItem value={WindRatingEnum.N_5}>N5</MenuItem>
            <MenuItem value={WindRatingEnum.N_6}>N6</MenuItem>
            <MenuItem value={WindRatingEnum.C_1}>C1</MenuItem>
            <MenuItem value={WindRatingEnum.C_2}>C2</MenuItem>
            <MenuItem value={WindRatingEnum.C_3}>C3</MenuItem>
            <MenuItem value={WindRatingEnum.C_4}>C4</MenuItem>
          </Select>
          {/*lot*/}
          <TextField
            className={classes.formRow}
            fullWidth
            id="lot"
            name="lot"
            label={addJobField.lot}
            value={formik.values.lot}
            onChange={formik.handleChange}
            error={formik.touched.lot && Boolean(formik.errors.lot)}
            helperText={formik.touched.lot && formik.errors.lot}
          />
          {/* Check boxes */}
          <div className={classes.checkBoxesContainer}>
            <Typography component="strong" color="textPrimary">
              Requested services:
            </Typography>

            <div className={classes.checkBoxRow}>
              <div className={classes.checkBoxControl}>
                <Typography variant={"body2"} component="h6">
                  {addJobField.detailingRequested}
                </Typography>
                <Checkbox
                  onChange={(value) =>
                    onCheckBoxChange("detailingRequested", value)
                  }
                  className={classes.checkBox}
                  color="primary"
                  value={formik.values.detailingRequested}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
            </div>

            <div className={classes.checkBoxRow}>
              <div className={classes.checkBoxControl}>
                <Typography variant={"body2"} component="h6">
                  {addJobField.engineeringRequested}
                </Typography>
                <Checkbox
                  onChange={(value) =>
                    onCheckBoxChange("engineeringRequested", value)
                  }
                  value={formik.values.engineeringRequested}
                  className={classes.checkBox}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
            </div>

            <div className={classes.checkBoxRow}>
              <div className={classes.checkBoxControl}>
                <Typography variant={"body2"} component="h6">
                  {addJobField.prelimDesignRequested}
                </Typography>
                <Checkbox
                  onChange={(value) =>
                    onCheckBoxChange("prelimDesignRequested", value)
                  }
                  value={formik.values.prelimDesignRequested}
                  className={classes.checkBox}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
            </div>

            <div className={classes.checkBoxRow}>
              <div className={classes.checkBoxControl}>
                <Typography variant={"body2"} component="h6">
                  {addJobField.inspectionRequested}
                </Typography>
                <Checkbox
                  onChange={(value) =>
                    onCheckBoxChange("inspectionRequested", value)
                  }
                  value={formik.values.inspectionRequested}
                  className={classes.checkBox}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
            </div>
            {Boolean(formik.errors.inspectionRequested) && <p className={classes.error_msg}>{formik.errors.inspectionRequested}</p>}
          </div>
          <Divider light />
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
          >
            Submit
          </Button>
          {loading && (
            <div className={classes.linearIndeterminate}>
              <LinearProgress />
            </div>
          )}
        </form>
      </Paper>
    </div>
  );
};

export default NewJobForm;
