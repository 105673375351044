
const JobStageNotFound = () => {
    return (
        <div>
            Something went wrong, we could not found current job state, please restart the application and try again
        </div>
    )
}

export default JobStageNotFound
