import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, Select, MenuItem, InputLabel, TextField, FormControl, Box } from "@material-ui/core";

import { errorHandler } from "../../store/actions";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      padding: theme.spacing(2, 4, 3),
      maxWidth:"100%"

    },
    closeContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    substatus_row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "10px",
      marginBottom: "5px",
    },
    bottom_button: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "10px",
      marginBottom: "5px",
    },
    floatingLabelFocusStyle: {
      color: theme.palette.text.primary,
    },
    formContainer: {
      marginTop: "20px",
      marginBottom: "20px",
      height: "fit-content",
      textAlign: "center",
      borderRadius: "3px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
      margin: theme.spacing(1),
    },
    alertClass: {
      color: "#fff",
      backgroundColor: "green",
      margin: "7px",
    }
  })
);

interface FileTagModalProps {
  jobID: number;
  doctype: string;
  filename: string;
  isOpen: boolean;
  setIsOpen: any;
}

interface JobFileTagForm {
  category: string,
  storey: string,
  documentDate: string
}

export const FileTagModal: React.FC<FileTagModalProps> = ({
  jobID,
  doctype,
  filename,
  isOpen,
  setIsOpen
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const initialState : JobFileTagForm = {
    category: "",
    storey: "",
    documentDate: ""
  };
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = React.useState<boolean>(false);

  const categoryOptions = [
    {label: "None", value: ""},
    {label: "Layouts", value: "Layouts"},
    {label: "Welding", value: "Welding"},
    {label: "Joist", value: "Joist"},
    {label: "Trusses", value: "Trusses"},
    {label: "TCert", value: "TCert"},
    {label: "Wall Panels", value: "Wall Panels"},
    {label: "Ceiling", value: "Ceiling"},
    {label: "3D", value: "3D"},
  ];

  const categoryValues = categoryOptions.map(option => option.value);

  const categoryStorey : { [Key: string]: boolean; } = {
    "Layouts": false,
    "Welding": true,
    "Joist": false,
    "Trusses": true,
    "TCert": true,
    "Wall Panels": true,
    "Ceiling": true,
    "3D": false,
    "": false
  };

  const validationSchema = yup.object({
    category: yup.string().oneOf(categoryValues),
    documentDate: yup.date().required()
  });

  const postJobDocumentTagEndpoint = (jobID:number, doctype:string, filename:string) => {
    const my_uri = `${process.env.REACT_APP_BACKEND_BASE_URL}/jobdocument/${jobID}/${doctype}/${filename}/tag`
    return my_uri;
  };

  const postJobDocumentTag = async (jobID:number, doctype:string, filename:string, tag:any) => {
    const token = await getAccessTokenSilently();
    let config = {
      headers:{
          "Authorization": `Bearer ${token}`
      }
    }
    const res = await axios.post(postJobDocumentTagEndpoint(jobID, doctype, filename),
        tag,
        config
    );
    return res;
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const {category, storey, documentDate} = values;
        const numStorey = parseInt(storey);
        if (categoryStorey[category] && (typeof numStorey !== "number" || numStorey < 1 || !Number.isInteger(numStorey))) {
          dispatch(
            errorHandler(
              "Something went wrong, you must specify the number of storeys!"
            )
          );
        } else {
          const finalDate = new Date(documentDate);
          const finalTag = {
            category,
            documentDate: finalDate.toISOString(),
            storey: categoryStorey[category] ? numStorey : null
          };
          const res = await postJobDocumentTag(jobID, doctype, filename, finalTag);
          setShowAlert(true);
        }
      } catch (error) {
        dispatch(
          errorHandler(`${error.message}, check your internet connection`)
        );
      }
    },
  });

  const getJobDocumentTagEndpoint = (jobID:number, doctype:string) => {
    const my_uri = `${process.env.REACT_APP_BACKEND_BASE_URL}/jobdocument/${jobID}/${doctype}/tag`
    return my_uri;
  };

  const setCurrentState = (newState: JobFileTagForm) => {
    formik.setValues(newState);
  };

  const getJobDocumentTag = async (jobID:number, doctype:string, filenames:string[]) => {
    const token = await getAccessTokenSilently();
    let config = {
      headers:{
          "Authorization": `Bearer ${token}`
      }
    }
    const res = await axios.post(getJobDocumentTagEndpoint(jobID, doctype),
        [filename],
        config
    );
    const tag = res.data.tags[filename];
    return tag;
  };

  const setInitialState = async () => {
    const tag = await getJobDocumentTag(jobID, doctype, [filename]);
    if (tag.category === null) {
      // if null, set tag to initial
      setCurrentState(initialState);
    } else {
      // else, set tag as response
      setCurrentState(tag);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setInitialState();
    setShowAlert(false);
  };

  const handleClose = () => {
    setOpen(false);
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [isOpen, jobID]);

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          <form className={classes.formContainer} onSubmit={formik.handleSubmit}>
            <div className={classes.paper}>
              <h5>
                Tag {filename}
              </h5>
              <div className={classes.substatus_row}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="category-select-label">Category</InputLabel>
                  <Select
                    value={formik.values.category}
                    label="Category"
                    labelId="category-select-label"
                    onChange={formik.handleChange}
                    variant="outlined"
                    name="category"
                    id="category"
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                  >
                    {categoryOptions.map(option => {
                      return (<MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>);
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className={classes.substatus_row}>
                {categoryStorey[formik.values.category] &&
                  <TextField
                    fullWidth
                    inputProps={{
                      inputMode: 'numeric',
                      padding: "10px",
                      pattern: '[0-9]*'
                    }}
                    id="storey"
                    name="storey"
                    variant="outlined"
                    label="Storey"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    value={formik.values.storey}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.storey && Boolean(formik.errors.storey)
                    }
                  />
                }
              </div>
              <div className={classes.substatus_row}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="documentDate"
                    name="documentDate"
                    label="Document Date"
                    format="yyyy-MM-dd"
                    value={formik.values.documentDate}
                    InputLabelProps={{ shrink: true }}
                    onChange={(date) => {formik.setFieldValue("documentDate", date)}}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className={classes.bottom_button}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Submit Tag
                  </Button>
                  {showAlert && (
                    <Alert
                      className={classes.alertClass}
                      onClose={() => {
                        setShowAlert(false);
                      }}
                    >
                      File has been tagged successfully!
                    </Alert>
                  )}
              </div>
              <div className={classes.closeContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </div>
            </div>
          </form>
        </Fade>
      </Modal>
    </div>
  );
};
