import { makeStyles, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeValueHandler, changeValueHandler1, changeValueHandler2, errorHandler } from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  commentFieldContainer: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "3px",
    padding: "6px",
    width: "100%",
    marginBottom: "15px",
  },
}));

interface CommentProps{
  name:string,
  defaultValue:string,
  reducer_name:string
}

const Comment:React.FC<CommentProps> = ({name,defaultValue,reducer_name}) => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const [comment, setComment] = React.useState('');

  let handler:any;
    switch (reducer_name) {
      case 'inspectionChecklistFormReducer':
        handler = changeValueHandler;
        break;
      case 'engineeringDetailsFormReducer':
        handler = changeValueHandler1;
        break;
      case 'inspectionDetailsFormReducer':
        handler = changeValueHandler2;
        break;
    
      default:
        dispatch(errorHandler("Form failure please refresh the page and try again. " + reducer_name))
    }

  const handleChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setComment((event.target as HTMLInputElement).value);
    } catch (e) {
      dispatch(
        errorHandler(
          "Please refresh application and insert comment again: " + e
        )
      );
    }
  };

  useEffect(() => {
    if(defaultValue){
      setComment(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      try {
        dispatch(
          handler(name, comment)
        );
      } catch (e) {
        dispatch(
          errorHandler(
            "Please refresh application and insert comment again: " + e
          )
        );
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [comment])

  return (
    <div className={classes.commentFieldContainer}>
      <TextField
        fullWidth
        multiline
        rows={1}
        rowsMax={50}
        id={name}
        value={comment}
        name={name}
        label="Comment"
        onChange={handleChangeComment}
      />
    </div>
  );
};

export default Comment;
