import { ActionTypes } from "./types";
import { Dispatch } from "redux";

// ------------------------- //
//  ENGINEERING DETAILS FORM //
// ------------------------- //

//USER ACTION NAMES
export interface ChangeValueHandlerAction1 {
  type: ActionTypes.changeValueHandler1;
  payload: {
    key: string;
    value: string;
  };
}

export interface ChangeAllFormValuesAction1 {
  type: ActionTypes.changeAllFormValues1;
  payload: any;
}

export interface SelectAllEngineeringForm {
  type: ActionTypes.selectAllEngineeringForm;
  payload: {
    components: string[];
    basis_of_certification: string[];
    reference_documentation: string[];
  };
}



export const changeValueHandler1 = (key: any, value: any) => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ActionTypes.changeValueHandler1,
        payload: { key, value },
      });
    };
  } catch (e) {
    console.log(e);
  }
};

export const changeAllFormValues1 = (job: any) => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ActionTypes.changeAllFormValues1,
        payload: job,
      });
    };
  } catch (e) {
    console.log(e);
  }
};

export const clearFormValues1 = () => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ActionTypes.changeAllFormValues1,
        payload: {
          components: [],
          basis_of_certification: [],
          reference_documentation: []
        },
      });
    };
  } catch (e) {
    console.log(e);
  }
};

export const selectAllEngineeringForm = (
  components:string[],
  basis_of_certification:string[],
  reference_documentation:string[]
) => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ActionTypes.selectAllEngineeringForm,
        payload: {
          components: [...components],
          basis_of_certification : [...basis_of_certification],
          reference_documentation : [...reference_documentation]
        },
      });
    };
  } catch (e) {
    console.log(e);
  }
};
