import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, Select, MenuItem, InputLabel, TextField, FormControl, Box } from "@material-ui/core";

import { doYouNeedRefetch, errorHandler } from "../../store/actions";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      padding: theme.spacing(2, 4, 3),
      maxWidth:"100%"

    },
    closeContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    substatus_row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "10px",
      marginBottom: "5px",
    },
    bottom_button: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "10px",
      marginBottom: "5px",
    },
    floatingLabelFocusStyle: {
      color: theme.palette.text.primary,
    },
    formContainer: {
      marginTop: "20px",
      marginBottom: "20px",
      height: "fit-content",
      textAlign: "center",
      borderRadius: "3px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
      margin: theme.spacing(1),
    },
    alertClass: {
      color: "#fff",
      backgroundColor: "green",
      margin: "7px",
    }
  })
);

interface FileDeleteConfirmModalProps {
  jobID: number;
  doctype: string;
  filename: string;
  isOpen: boolean;
  setIsOpen: any;
}

export const FileDeleteConfirmModal: React.FC<FileDeleteConfirmModalProps> = ({
  jobID,
  doctype,
  filename,
  isOpen,
  setIsOpen
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [canDelete, setCanDelete] = React.useState<boolean>(true);

  const deleteJobDocumentEndpoint = (jobID:number, doctype:string, filename:string) => {
    const my_uri = `${process.env.REACT_APP_BACKEND_BASE_URL}/jobdocument/${jobID}/${doctype}/${encodeURIComponent(filename)}`
    return my_uri;
  };

  const clickHandler = async () => {
    const token = await getAccessTokenSilently();
    let config = {
      headers:{
          "Authorization": `Bearer ${token}`
      }
    }
    const res = await axios.delete(deleteJobDocumentEndpoint(jobID, doctype, filename),
        config
    );
    setShowAlert(true);
    setCanDelete(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setShowAlert(false);
    setCanDelete(true);
  };

  const refreshState = () => {
    try {
      dispatch(doYouNeedRefetch());
    } catch (e) {
      dispatch(errorHandler(e.message));
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [isOpen, jobID]);

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h5>
              Are you sure you want to delete "{filename}" ?
            </h5>
            <div className={classes.bottom_button}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={clickHandler}
                  disabled={!canDelete}
                >
                  Delete File
                </Button>
                {showAlert && (
                  <Alert
                    className={classes.alertClass}
                    onClose={() => {
                      setShowAlert(false);
                    }}
                  >
                    File has been deleted successfully!
                  </Alert>
                )}
            </div>
            <div className={classes.closeContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleClose();
                    refreshState();
                  }}
                >
                  Close
                </Button>
              </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
