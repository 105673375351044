import { CircularProgress, TablePagination } from "@material-ui/core";
import React from "react";
import CommentType from "./commentType";
import JobComment from "./JobComment";
import JobCommentInput from "./JobCommentInput";
import useJobComments from "./useJobComments";
import useStyles from "./jobCommentsStyles";

const JobComments = ({ jobId }: any) => {
  const classes = useStyles();
  const {
    comments,
    readCommentsResponse,
    page,
    rowsPerPage,
    count,
    changePageHandler,
    changeRowsHandler,
  }: any = useJobComments(jobId);

  if (readCommentsResponse.loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress style={{marginRight:"5px"}} className={classes.loadingSpinner} size={15} />{" "}Loading comments...
      </div>
    );
  }

  if (comments.length > 0) {
    return (
      <div>
        {comments.map((comment: CommentType) => {
          return <JobComment comment={comment} />;
        })}

        <JobCommentInput jobId={jobId} />

        {/* count={currentState.totalJobs} */}
        <TablePagination
          component="div"
          page={page}
          count={count}
          onChangePage={(e: any, page: any) => changePageHandler(page)}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={(e: any) => changeRowsHandler(e?.target?.value)}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </div>
    );
  } else {
    return <JobCommentInput jobId={jobId} />;
  }
};

export default JobComments;
